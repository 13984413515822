import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';

import messageC from 'src/components/Message';
import { useCustomerInfoQueryKey } from 'src/hooks/useUser';
import AuthStorage from 'src/utils/auth-storage';
import { fetchApiMarketplace } from 'src/utils/fetch-api';

export const klikStoreCart = 'klikStoreCart';
export const klikStoreCartValidate = 'klikStoreCartValidate';
export const checkoutGetVoucherList = 'checkoutGetVoucherList';
export const klikStoreGetShippingProviderDurationList = 'klikStoreGetShippingProviderDurationList';
export const klikStoreGetShippingProviderCourierList = 'klikStoreGetShippingProviderCourierList';
export const klikStorePaymentMethods = 'klikStorePaymentMethods';
export const klikStoreTransactionDetail = 'klikStoreTransactionDetail';
export const paymentV3SummaryDetail = 'paymentV3SummaryDetail';
export const paymentV3SummaryCheck = 'paymentV3SummaryCheck';

export interface UpdateProductQtyProps {
	merchant_id: number;
	merchant_sku: string;
	item_notes?: string;
	qty?: number;
}

export interface UseKlikStoreGetCart {
	quote_code: string;
	enabled?: boolean;
}

export interface UseCheckoutGetShippingProviderDurationList {
	quote_code: string;
	merchant_id: number;
}

export interface UseCheckoutGetShippingProviderCourierList {
	quote_code: string;
	merchant_id: number;
	shipping_provider_duration_id: number;
	enabled?: boolean;
}

export const queryKlikStoreCheckoutPromoList = 'useKlikStoreCheckoutPromoList';
export const queryKlikStoreCheckoutMyVoucherList = 'useKlikStoreCheckoutMyVoucherList';

export const getQuoteCode = async () => {
	try {
		const res = await fetchApiMarketplace({
			url: '/customer/info',
		});
		const quoteCode = res?.data?.record?.quote_code;
		return quoteCode;
	} catch (err: any) {
		if (err.status !== 401 && err.status !== 403) {
			messageC(err?.meta?.message || err?.message || 'Terjadi kesalahan sistem.', 'error');
		}
		return undefined;
	}
};

export const useCartSelect = () => {
	const cartSelect = async ({ data }: any) => {
		const quoteCode = await getQuoteCode();

		return fetchApiMarketplace({
			url: `/quote/selected-merchant-item/${quoteCode}`,
			options: { method: 'PUT' },
			payload: {
				selected: data,
			},
		});
	};

	return useMutation((params: any) => cartSelect(params));
};

export const useCartValidate = () => {
	const queryClient = useQueryClient();

	const cartValidate = async (quote_code: any) => {
		const quoteCode = await getQuoteCode();

		return fetchApiMarketplace({
			url: `/quote/validate/${quote_code ?? quoteCode}`,
			options: { method: 'GET' },
		});
	};

	return useMutation(({ quote_code }: any) => cartValidate(quote_code), {
		onSuccess: () => {
			queryClient.invalidateQueries(klikStoreCart);
		},
		onError: (err: any) => {
			if (err?.status === 403) {
				queryClient.invalidateQueries(useCustomerInfoQueryKey);
			}
		},
	});
};

export const useKlikStoreGetCart = ({ quote_code, enabled = true }: any) => {
	const queryClient = useQueryClient();

	const getCart = async (pQuoteCode: any) => {
		const quoteCode = await getQuoteCode();

		let res = null;

		if (quoteCode) {
			res = await fetchApiMarketplace({
				url: `/quote/${pQuoteCode ?? quoteCode}`,
				payload: {
					skip_payment: true,
				},
			});
		}

		return res;
	};
	return useQuery([klikStoreCart, quote_code], () => getCart(quote_code), {
		onError: (err: any) => {
			if (err?.status === 403) {
				queryClient.invalidateQueries(useCustomerInfoQueryKey);
			}
		},
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		enabled: AuthStorage.loggedIn && enabled,
	});
};

export const useCartUpdateProduct = () => {
	const queryClient = useQueryClient();

	const updateProductQty = async ({
		merchant_id,
		item_notes,
		merchant_sku,
		qty,
	}: UpdateProductQtyProps) => {
		const quoteCode = await getQuoteCode();

		const data = {
			orderQuoteMerchants: [
				{
					merchant_id: merchant_id,
					orderQuoteItems: [
						{
							merchant_sku: merchant_sku,
							item_notes,
							quantity: qty,
						},
					],
				},
			],
		};

		return fetchApiMarketplace({
			url: `/quote/${quoteCode}`,
			options: { method: 'PUT' },
			payload: data,
		});
	};

	return useMutation((params: UpdateProductQtyProps) => updateProductQty(params), {
		onSuccess: () => {
			queryClient.invalidateQueries(klikStoreCart);
		},
	});
};

export const useCartDeleteProduct = () => {
	const queryClient = useQueryClient();

	const deleteProduct = async ({ merchant_id, merchant_sku }: UpdateProductQtyProps) => {
		const quoteCode = await getQuoteCode();

		const data = {
			selected: [
				{
					merchant_id: merchant_id,
					merchant_skus: [merchant_sku],
				},
			],
		};

		return fetchApiMarketplace({
			url: `/quote/delete-merchant-item/${quoteCode}`,
			options: { method: 'DELETE' },
			payload: data,
		});
	};

	return useMutation((params: UpdateProductQtyProps) => deleteProduct(params), {
		onSuccess: () => {
			queryClient.invalidateQueries(klikStoreCart);
		},
	});
};

export const useCheckoutSetAddress = () => {
	const queryClient = useQueryClient();

	const setAddress = ({ quote_code, address_id }: any) => {
		const data = {
			orderQuoteAddress: {
				customer_address_id: address_id,
			},
		};

		return fetchApiMarketplace({
			url: `/quote/${quote_code}`,
			options: { method: 'PUT' },
			payload: data,
		});
	};

	return useMutation((params: any) => setAddress(params), {
		onSuccess: () => {
			queryClient.invalidateQueries(klikStoreCart);
			queryClient.invalidateQueries(klikStoreGetShippingProviderDurationList);
		},
		onError: (err: any) => {
			if (err?.status === 403) {
				queryClient.invalidateQueries(useCustomerInfoQueryKey);
			}
		},
	});
};

export const useCheckoutGetShippingProviderDurationList = (
	props: UseCheckoutGetShippingProviderDurationList,
) => {
	const queryClient = useQueryClient();

	return useQuery(
		[klikStoreGetShippingProviderDurationList, props],
		() => {
			return fetchApiMarketplace({
				url: `/quote/shipping-duration/${props.quote_code}/merchant/${props.merchant_id}`,
				options: { method: 'GET' },
			});
		},
		{
			onError: (err: any) => {
				if (err?.status === 403) {
					queryClient.invalidateQueries(useCustomerInfoQueryKey);
				}
			},
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);
};

export const useCheckoutGetShippingProviderCourierList = (
	props: UseCheckoutGetShippingProviderCourierList,
) => {
	const queryClient = useQueryClient();

	return useQuery(
		[klikStoreGetShippingProviderCourierList, props],
		() => {
			return fetchApiMarketplace({
				url: `/quote/shipping-courier/${props.quote_code}/merchant/${props.merchant_id}`,
				options: { method: 'GET' },
			});
		},
		{
			onError: (err: any) => {
				if (err?.status === 403) {
					queryClient.invalidateQueries(useCustomerInfoQueryKey);
				}
			},
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: props.enabled ?? true,
		},
	);
};

export const useCheckoutSetShippingMethod = () => {
	const queryClient = useQueryClient();

	const setShippingMethod = ({
		quote_code,
		merchant_id,
		shipping_provider_duration_id,
		shipping_provider_id,
		use_insurance,
	}: any) => {
		const data = {
			orderQuoteMerchants: [
				{
					merchant_id,
					orderQuoteShipping: {
						shipping_provider_duration_id,
						shipping_provider_id,
						use_insurance,
					},
				},
			],
		};

		return fetchApiMarketplace({
			url: `/quote/${quote_code}`,
			options: { method: 'PUT' },
			payload: data,
		});
	};

	return useMutation((params: any) => setShippingMethod(params), {
		onSuccess: () => {
			queryClient.invalidateQueries(klikStoreCart);
		},
		onError: (err: any) => {
			if (err?.status === 403) {
				queryClient.invalidateQueries(useCustomerInfoQueryKey);
			}
		},
	});
};

export const useKlikStoreCheckoutPromoList = () => {
	return useInfiniteQuery(
		[queryKlikStoreCheckoutPromoList],
		(fetchProps) => {
			const { pageParam = 1 } = fetchProps;

			return fetchApiMarketplace({
				url: '/search/voucher/category/all/store/1',
				payload: {
					sort: 'id',
					dir: 'desc',
					limit: 10,
					page: pageParam,
				},
			});
		},
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (res) => {
				const currentPage = parseInt(res?.meta?.pagination?.page);
				const totalPage = parseInt(res?.meta?.pagination?.total_page);

				return currentPage < totalPage ? currentPage + 1 : undefined;
			},
		},
	);
};

export const useKlikStoreCheckoutMyVoucherList = () => {
	return useInfiniteQuery(
		[queryKlikStoreCheckoutMyVoucherList],
		(fetchProps) => {
			const { pageParam = 1 } = fetchProps;

			return fetchApiMarketplace({
				url: '/checkout/voucher/1',
				payload: {
					sort: 'id',
					dir: 'desc',
					limit: 10,
					page: pageParam,
				},
			});
		},
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (res) => {
				const currentPage = parseInt(res?.meta?.pagination?.page);
				const totalPage = parseInt(res?.meta?.pagination?.total_page);

				return currentPage < totalPage ? currentPage + 1 : undefined;
			},
		},
	);
};

export const useCheckoutApplyVoucher = () => {
	const queryClient = useQueryClient();

	const voucherApplyTransaction = ({ coupon_code, quote_code }: any) => {
		return fetchApiMarketplace({
			url: '/promotion/carts/coupon',
			options: { method: 'PUT' },
			payload: {
				quote_code: quote_code,
				coupon_code: coupon_code,
				skip_payment: true,
			},
		});
	};

	return useMutation((params: any) => voucherApplyTransaction(params), {
		onSuccess: () => {
			queryClient.invalidateQueries(klikStoreCart);
		},
		onError: (err: any) => {
			if (err?.status === 403) {
				queryClient.invalidateQueries(useCustomerInfoQueryKey);
			}
		},
	});
};

export const useCheckoutRemoveVoucher = () => {
	const queryClient = useQueryClient();

	const voucherDeleteTransaction = ({ quote_code }: any) => {
		return fetchApiMarketplace({
			url: '/promotion/carts/coupon',
			options: { method: 'PUT' },
			payload: {
				quote_code: quote_code,
				coupon_code: '',
				skip_payment: true,
			},
		});
	};

	return useMutation((params: any) => voucherDeleteTransaction(params), {
		onSuccess: () => {
			queryClient.invalidateQueries(klikStoreCart);
		},
		onError: (err: any) => {
			if (err?.status === 403) {
				queryClient.invalidateQueries(useCustomerInfoQueryKey);
			}
		},
	});
};

export const useCheckoutPaymentMethodList = (props: any) => {
	return useQuery(
		[klikStorePaymentMethods, props],
		() => {
			return fetchApiMarketplace({
				url: '/master/paymentmethods',
				payload: { voucher_code: props.voucher_code },
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useCheckoutSetPaymentMethod = () => {
	const queryClient = useQueryClient();

	const updateProductQty = ({ quote_code, payment_method_id }: any) => {
		const data = {
			orderQuotePayment: {
				payment_method_id: payment_method_id,
			},
			with_gift: true,
		};

		return fetchApiMarketplace({
			url: `/quote/${quote_code}`,
			options: { method: 'PUT' },
			payload: data,
		});
	};

	return useMutation((params: any) => updateProductQty(params), {
		onError: (err: any) => {
			if (err?.status === 403) {
				queryClient.invalidateQueries(useCustomerInfoQueryKey);
			}
		},
	});
};

export const useCheckoutRecalculateShipping = () => {
	const queryClient = useQueryClient();

	const recalculateShipping = ({ quote_code }: any) => {
		return fetchApiMarketplace({
			url: `/quote/recalculate/shipping/${quote_code}`,
			payload: { with_gift: true },
		});
	};

	return useMutation((params: any) => recalculateShipping(params), {
		onSuccess: () => {
			queryClient.invalidateQueries(klikStoreCart);
		},
		onError: (err: any) => {
			if (err?.status === 403) {
				queryClient.invalidateQueries(useCustomerInfoQueryKey);
			}
		},
	});
};

export const useCheckoutSubmit = () => {
	const checkoutSubmit = ({ quote_code }: any) => {
		return fetchApiMarketplace({
			url: '/order',
			options: { method: 'POST' },
			payload: { quote_code: quote_code },
		});
	};

	return useMutation((params: any) => checkoutSubmit(params));
};
