export const MENU_TYPE = {
	model: {
		main: {
			success: 'GET_MENU_SUCCESS',
		},
		footer: {
			success: 'GET_FOOTER_MENU_SUCCESS',
		},
	},
};
