import cookie from 'react-cookies';

import { isBrowser } from 'src/constants/environment';
import g from 'src/constants/global';
import { TRANSACTION_ANALYTICS_ATTRIBUTE } from 'src/constants/localStorage';

const { consultationVideo, consultationFace, reservationDoctor, reservationService } =
	g.PROFILE.TRANSACTION.SERVICE;

const gaTransactionStorageKey = 'ga4_transaction';

export const typeMapperPayload = (module: string) =>
	({
		[consultationVideo]: 'videocall',
		[reservationDoctor]: 'janji-medis',
		[reservationService]: 'janji-medis',
	})[module] || 'chat';

export const transactionName = (module: string) =>
	({
		[consultationVideo]: 'Video Call',
		[consultationFace]: 'Tatap Muka',
		[reservationDoctor]: 'Buat Janji',
		[reservationService]: 'Buat Janji',
	})[module] || 'Video Call';

export const handleEndPointModalTnC = (module: string) =>
	({
		[consultationVideo]: 'sk-video-call',
		[consultationFace]: 'sk-tatap-muka',
		[reservationDoctor]: 'sk-buat-janji-temu-dokter',
		[reservationService]: 'sk-buat-janji-layanan',
	})[module] || 'sk-video-call';

type TransactionAnalytics = {
	orderId?: string;
	invoice?: string;
	promoCode?: string;
};

export const storeTransactionAttribute = ({
	orderId,
	invoice,
	promoCode,
}: TransactionAnalytics) => {
	if (orderId) {
		localStorage.setItem(
			TRANSACTION_ANALYTICS_ATTRIBUTE,
			JSON.stringify({
				[orderId]: {
					invoice,
					promoCode,
				},
			}),
		);
	}
};

export const getTransactionAttribute = (key?: string): TransactionAnalytics | null => {
	const rawData = isBrowser && localStorage.getItem(TRANSACTION_ANALYTICS_ATTRIBUTE);

	return !!rawData && key ? JSON.parse(rawData)?.[key] : null;
};

export const removeTransactionAttribute = () =>
	isBrowser && localStorage.removeItem(TRANSACTION_ANALYTICS_ATTRIBUTE);

export const saveSuccessPaymentAttribute = (attribs: Record<string, any>, invoice: string) =>
	cookie.save(
		gaTransactionStorageKey,
		{
			attribs,
			invoice,
		},
		{
			path: '/',
			// Set two days
			maxAge: 172800,
		},
	);

export const getSuccessPaymentAttribute = (): {
	attribs?: Record<string, any>;
	invoice?: string;
} => {
	const cookiesData = cookie.load(gaTransactionStorageKey);

	return cookiesData || {};
};

export const removeSuccessPaymentAttribute = () =>
	cookie.remove(gaTransactionStorageKey, { path: '/' });
