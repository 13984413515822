import { useMutation, useQuery, useQueryClient } from 'react-query';
import { notification } from 'antd';

import { CHAT, NOTIFICATION_CENTER } from 'src/constants/services';
import { fetchApiConsultation, fetchApiV3User } from 'src/utils/fetch-api';

const getNotification = (type: string, methods: any, payloads: any) => {
	return fetchApiV3User({
		url: `/${NOTIFICATION_CENTER.service}/${type}`,
		options: { method: methods ? methods : 'GET' },
		payload: payloads,
	});
};

const getTotalNewMessage = () => {
	return fetchApiConsultation({
		url: `/${CHAT.service}/${CHAT.model.unread}`,
		options: {
			method: 'GET',
		},
		payload: {},
	});
};

export const useNotificationTransaction = (pages: any, activeKey: any) => {
	return useQuery(
		['notification_transaction', pages],
		() =>
			getNotification(`list?type=${NOTIFICATION_CENTER.model.trasaction}&page=${pages}`, '', ''),
		{
			enabled: activeKey == '1',
		},
	);
};

export const useNotificationPromo = (pages: any, activeKey: any) => {
	return useQuery(
		['notification_promo', pages],
		() => getNotification(`list?type=${NOTIFICATION_CENTER.model.promo}&page=${pages}`, '', ''),
		{
			enabled: activeKey == '2',
		},
	);
};

export const useNotificationInfo = (pages: any, activeKey: any) => {
	return useQuery(
		['notification_info', pages],
		() => getNotification(`list?type=${NOTIFICATION_CENTER.model.info}&page=${pages}`, '', ''),
		{
			enabled: activeKey == '1',
		},
	);
};

export const UpdateNotificationRead = () => {
	const queryClient = useQueryClient();
	return useMutation(
		(payload: any) =>
			getNotification(`${NOTIFICATION_CENTER.model.update}`, 'PUT', {
				id: payload ? payload : '',
			}),
		{
			onError: (err: any) => {
				notification.error({ message: err?.meta?.message });
			},
			onSettled: () => {
				queryClient.invalidateQueries('notification_unread');
				queryClient.invalidateQueries('notification_transaction');
				queryClient.invalidateQueries('notification_info');
			},
		},
	);
};

export const useGetTotalNotification = (router: string) => {
	return useQuery(
		['notification_unread', router],
		() => {
			return getNotification(`${NOTIFICATION_CENTER.model.unread}`, '', '');
		},
		{
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			cacheTime: 2500,
		},
	);
};

export const useGetTotalNewMessages = (router: string) => {
	return useQuery(
		['message_unread', router],
		() => {
			return getTotalNewMessage();
		},
		{
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			cacheTime: 2500,
		},
	);
};
