import { Spin } from 'antd';
import classNames from 'classnames';

import LottieAnimation from 'src/components/LottieAnimation';
import { SpinPropsC } from 'src/interface/Layout/Spin';

import classes from './index.module.less';

const SpinC = (props: SpinPropsC) => {
	return props.children ? (
		<div className={classes.wrapper}>
			<Spin
				{...props}
				className={classNames(`${classes.spin} ${props.className}`, { 'mt-5': !props.className })}
				indicator={
					<LottieAnimation
						importAnimation={(cb) => import('src/utils/lotties/loading.json').then(cb)}
					/>
				}
			>
				{props.children}
			</Spin>
		</div>
	) : (
		<div className={classNames(`text-center ${props.className}`, { 'my-5': !props.className })}>
			<Spin
				{...props}
				indicator={
					<LottieAnimation
						importAnimation={(cb) => import('src/utils/lotties/loading.json').then(cb)}
					/>
				}
			/>
		</div>
	);
};

export default SpinC;
