import { useQuery } from 'react-query';

import { CATEGORY_TOPIC_REGISTER } from 'src/constants/services';
import { fetchApiV3User } from 'src/utils/fetch-api';

export const useCategoryTopicRegisters = () => {
	return useQuery(
		'categoryTopicRegisters',
		() => fetchApiV3User({ url: `/${CATEGORY_TOPIC_REGISTER.service}` }),
		{ refetchOnWindowFocus: false },
	);
};
