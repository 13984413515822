export const pushWithQS = (router: any, path: string, scroll: boolean = true) => {
	return router.push(
		{
			pathname: path,
			query: router.query,
		},
		undefined,
		{ scroll },
	);
};
