import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';

import { RATING } from 'src/constants/services';
import g from 'src/constants/urls';
import { fetchApiJM, fetchApiV3Rating } from 'src/utils/fetch-api';

const serviceDetail = 'medical_facilities_service_detail';
const serviceReview = 'service_review';
const createAppintmentJMService = 'create_appointment_medical_facilities_service';
const updateAppointmentJMService = 'update_appointment_medical_facilities_service';
const JMTransactionList = 'JMTransactionList';

export const useServiceDetail = (
	medFacilitySlug: string,
	serviceSlug: string,
	location: { lat: number; lng: number },
) => {
	return useQuery(
		[serviceDetail, medFacilitySlug, serviceSlug, location],
		() =>
			fetchApiJM({
				url: `/medical-facilities/${medFacilitySlug}/services/${serviceSlug}`,
				payload: { latitude: location.lat, longitude: location.lng },
			}),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			refetchOnMount: false,
		},
	);
};

export const useCreateAppointmentJMService = () => {
	const queryClient = useQueryClient();
	const createAppointment = (params: any) => {
		return fetchApiJM({
			url: '/appointment-services',
			options: { method: 'POST' },
			payload: { schedule_uid: params?.schedule_uid, book_date: params?.book_date },
		});
	};

	return useMutation((params: any) => createAppointment(params), {
		onSettled: () => {
			queryClient.invalidateQueries(createAppintmentJMService);
		},
	});
};

export const useUpdateAppointmentService = () => {
	const queryClient = useQueryClient();
	const updateAppointmentService = (params: any) => {
		return fetchApiJM({
			url: '/appointment-services',
			options: { method: 'PUT' },
			payload: params,
		});
	};

	return useMutation((params: any) => updateAppointmentService(params), {
		onSettled: () => {
			queryClient.invalidateQueries(updateAppointmentJMService);
		},
	});
};

export const useServiceReview = (uid: any) => {
	return useInfiniteQuery(
		[serviceReview],
		({ pageParam = 1 }) => {
			return fetchApiV3Rating({
				url: `/${RATING.service}/${RATING.model.service}/${uid}`,
				payload: { page: pageParam, limit: 5 },
			});
		},
		{
			retry: false,
			retryOnMount: false,
			enabled: !!uid,
			refetchOnWindowFocus: false,
			cacheTime: 2500,
			getNextPageParam: (res) =>
				res?.pagination?.page < res?.pagination?.total_page ? res?.pagination?.page + 1 : undefined,
		},
	);
};

export const useCreateServiceReview = () => {
	const queryClient = useQueryClient();
	const createServiceReview = (review: any) => {
		const payload = {
			...review,
			user_agent: navigator.userAgent,
			user_platform: g.API_PAYMENT_PLATFORM,
		};
		return fetchApiV3Rating({
			url: `/${RATING.model.ratingSubmissions}`,
			options: { method: 'POST' },
			payload: payload,
		});
	};

	return useMutation((review: any) => createServiceReview(review), {
		onSettled: () => {
			queryClient.invalidateQueries(JMTransactionList);
		},
	});
};
