import { useQuery } from 'react-query';
import { PaginationProps } from 'antd';

import { TYPESENSE } from 'src/constants/services';
import { DataFilterTypesense, DataOptions, UserLocation } from 'src/interface';
import { fetchApiTypesense } from 'src/utils/fetch-api';
import { filterBy, sortedBy } from 'src/utils/func';

export interface UseMeetDoctorSpecialization {
	pagination: PaginationProps;
	search: string;
	sortBy: string;
	dataSort: DataOptions[];
	filter: DataFilterTypesense[];
	location: UserLocation;
	specialization: string[];
	disableTriggerFetch?: boolean;
}

export const useMeetDoctorSpecialization = (props: UseMeetDoctorSpecialization) => {
	const { disableTriggerFetch, ...exProps } = props;
	const queryFilter = [
		{ field_name: 'specialist_uid', value: props.specialization },
		...props.filter,
	];

	return useQuery(
		['useMeetDoctorSpecialization', exProps],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityDoctors,
							facet_by: 'medical_facility_city,graduation_date,medical_facility_type_name,price',
							max_facet_values: 5,
							filter_by: filterBy(queryFilter),
							sort_by: sortedBy(props.sortBy, props.dataSort, {
								location: props.location,
								sortDefault: 'name:asc',
							}),
							include_fields:
								// eslint-disable-next-line max-len
								'name,slug,price,gender,specialist_name,photo_highres,medical_facility_name,graduation_date,master_uid,normal_price,medical_facility_type_name',
							page: props.pagination.current,
							per_page: props.pagination.pageSize,
						},
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityDoctors,
							facet_by: 'medical_facility_city',
							facet_query: `medical_facility_city:${props.search}`,
							max_facet_values: 100,
							include_fields: 'name',
							per_page: 1,
						},
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityDoctors,
							facet_by: 'medical_facility_type_name',
							max_facet_values: 5,
							include_fields: 'name',
							per_page: 1,
						},
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityDoctors,
							facet_by: 'price',
							max_facet_values: 1,
							filter_by: filterBy(queryFilter, { includeFieldName: ['specialist_uid'] }),
							include_fields: 'name',
							per_page: 1,
						},
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityDoctors,
							facet_by: 'medical_facility_city',
							max_facet_values: 5,
							include_fields: 'name',
							per_page: 1,
						},
					],
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !disableTriggerFetch },
	);
};
