import { Row } from 'antd';

import classes from '../../UserMenu/UserNotificationMenu.module.less';

const WrapNotif = (props: any) => {
	return (
		<div
			className={`${props.className} ${classes['card-notif']}
				${props.cardStatus ? 'bg-gray-primary ' : 'bg-white'} `}
			onClick={props.onClickNotifiation}
		>
			<Row justify="space-between">{props.children}</Row>
		</div>
	);
};

export default WrapNotif;
