type CommonObject = {
	custom?: Record<string, any>;
};

type UserObject = CommonObject & {
	uuid?: string;
	gender?: string;
	birthday?: string;
	has_transacted?: boolean;
	transaction_count?: number;
	push_optin?: boolean;
	name?: string;
	surname?: string;
	username?: string;
	email?: string;
	email_optin?: true;
	phone_number?: string;
	sms_optin?: true;
	whatsapp_optin?: true;
	language?: string;
	returning?: true;
	age?: number;
	login?: boolean;
	logout?: boolean;
};

type PageObject = CommonObject & {
	type:
		| 'Home'
		| 'Product'
		| 'Category'
		| 'Search'
		| 'Basket'
		| 'Checkout'
		| 'Confirmation'
		| 'Content';
	breadcrumb?: string[];
};

type ProductObject = CommonObject & {
	product_id?: string;
	name?: string;
	taxonomy?: string;
	currency?: 'IDR';
	price?: number;
	unit_sale_price?: number;
	image_url?: string;

	id?: string;
	// name?: string;
	// taxonomy?: string[];
	// currency?: string;
	unit_price?: number;
	// unit_sale_price?: number;
	url?: string;
	stock?: number;
	color?: string;
	size?: string;
	product_image_url?: string;
};

type ListingObject = CommonObject & {
	items?: (CommonObject & {
		id: string;
		name: string;
		taxonomy?: string[];
		currency: 'IDR';
		unit_price?: number;
		unit_sale_price?: number;
		url: string;
		stock?: number;
		color?: string;
		size?: string;
		product_image_url: string;
	})[];
};

type BasketObject = CommonObject & {
	currency: 'IDR';
	total: number;
	line_items: {
		product: {
			id: string;
			name: string;
			taxonomy: string[];
			currency: 'IDR';
			unit_price: number;
			unit_sale_price: number;
			url: string;
			stock?: number;
			color?: string;
			size?: string;
			product_image_url: string;
		};
		quantity: number;
		subtotal: number;
	}[];
	shipping_cost?: number;
};

type TransactionObject = CommonObject & {
	order_id: string;
	currency: 'IDR';
	line_items: {
		product: {
			id: string;
			name: string;
			taxonomy: string[];
			currency: 'IDR';
			unit_price: number;
			unit_sale_price: number;
			url: string;
			stock?: number;
			color?: string;
			size?: string;
			product_image_url: string;
		};
		quantity: number;
		subtotal: number;
	}[];
	total?: number;
	shipping_cost?: number;
	delivery?: string;
	payment_type?: string;
	bank_name?: string;
};

export type TrackingInsiderObject = {
	user?: UserObject;
	page?: PageObject;
	listing?: ListingObject;
	product?: ProductObject;
	basket?: BasketObject;
	transaction?: TransactionObject;
};

type FnMapper = Partial<Record<keyof TrackingInsiderObject, () => void>>;

const getInsiderObject = (): TrackingInsiderObject => window.insider_object || {};

export const trackInsiderObject =
	(key: keyof TrackingInsiderObject, isSpreadPreviousObj: boolean = false) =>
	(data: TrackingInsiderObject[typeof key], customData: Record<string, any> = {}) => {
		const fnMapper: FnMapper = {
			product: () => {
				trackInsiderObject('page')({
					type: 'Product',
				});
			},
		};

		fnMapper?.[key]?.();

		setTimeout(() => {
			const insiderObject = getInsiderObject();
			window.insider_object = {
				...insiderObject,
				[key]: {
					...((isSpreadPreviousObj && insiderObject[key]) || {}),
					...data,
					custom: {
						...((isSpreadPreviousObj && insiderObject?.[key]?.custom) || {}),
						...customData,
					},
				},
			};
			window.Insider?.eventManager?.dispatch?.('init-manager:re-initialize');
		}, 2000);
	};

export const resetInsiderObject = (keys: (keyof TrackingInsiderObject)[]) => {
	keys.forEach((key) => {
		if (window?.insider_object?.[key]) {
			delete window.insider_object[key];
		}
	});
};
