import classes from './style.module.less';

type SkeletonProps = {
	children?: any;
	isLoading?: boolean;
	className?: string;
	width?: string;
	height?: string;
	borderRadius?: string;
};

const Skeleton = ({
	children,
	isLoading = true,
	className = '',
	width,
	height,
	borderRadius = '',
}: SkeletonProps) => {
	return (
		<div>
			{isLoading && (
				<div
					className={`${classes.skeleton} ${className}`}
					style={{
						width: width || '100%',
						minHeight: height || '100%',
						borderRadius: borderRadius,
					}}
				/>
			)}
			<div className={isLoading ? 'd-none' : ''}>{children}</div>
		</div>
	);
};

export default Skeleton;
