import { useQuery } from 'react-query';

import { USER } from 'src/constants/services';
import AuthStorage from 'src/utils/auth-storage';
import { fetchApiConsultation } from 'src/utils/fetch-api';

export const useListDoctorFavorite = (limit: number, page: number, isUpdateDelete?: boolean) => {
	return useQuery(
		['doctorFavorite', page, isUpdateDelete],
		() => {
			return fetchApiConsultation({
				url: `/${USER.service}/${USER.model.favorite}`,
				payload: { limit, page, filter: JSON.stringify({ speciality: [] }) },
			});
		},
		{
			enabled: AuthStorage.loggedIn,
			keepPreviousData: true,
			refetchOnMount: true,
			refetchOnWindowFocus: false,
		},
	);
};
