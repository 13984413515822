import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { notification } from 'antd';

import g from 'src/constants/global';
import { COMMON, MEDICAL_HISTORY, UPLOAD, UPLOAD_IMAGES } from 'src/constants/services';
import { Upload } from 'src/interface/Common';
import {
	fetchApiConsultation,
	fetchApiJM,
	fetchApiPublishing,
	fetchApiV3User,
} from 'src/utils/fetch-api';

const socialMedia = 'social_media';

export const useCommonCity = (search: string) => {
	return useInfiniteQuery(
		['commonCity', search],
		({ pageParam = 1 }) => {
			return fetchApiConsultation({
				url: `/${COMMON.service}/${COMMON.model.kota}`,
				payload: { search, page: pageParam },
			});
		},
		{
			refetchOnWindowFocus: false,
			onSuccess: (res) => {
				if (res?.pages?.length === 1)
					res?.pages[0].data.records.unshift(...g.OPTION.SEARCH.LOCATION);
			},
			getNextPageParam: (res) =>
				res?.pagination?.page < res?.pagination?.total_page ? res?.pagination?.page + 1 : undefined,
		},
	);
};

export const useFilterDoctor = () => {
	return useQuery(
		'commonFilter',
		() => fetchApiConsultation({ url: `/${COMMON.service}/${COMMON.model.filter}` }),
		{ keepPreviousData: true, refetchOnMount: false, refetchOnWindowFocus: false },
	);
};

export const useShareSocmed = (module: string) => {
	return useQuery(
		[socialMedia, module],
		() => {
			return fetchApiPublishing({
				url: `/${COMMON.model.socialMedia}`,
				payload: { module },
			});
		},
		{ keepPreviousData: true, refetchOnMount: true, refetchOnWindowFocus: false },
	);
};

export const useUploadFile = () => {
	const upload = (payload: any) => {
		return fetchApiV3User({
			url: `/${UPLOAD.service}`,
			payload,
			options: {
				method: 'POST',
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		});
	};

	return useMutation((payload: any) => upload(payload), {
		onError: (error: any) => {
			notification.error({
				message: error?.meta?.message || 'Maaf Koneksi anda Terputus',
			});
		},
	});
};

export const useCreateFile = () => {
	const queryClient = useQueryClient();
	const create = (payload: Upload) => {
		return fetchApiV3User({
			url: `/${MEDICAL_HISTORY.service}`, // will be change if Endpoint Ready
			payload,
			options: {
				method: 'POST',
			},
		});
	};

	return useMutation((payload: Upload) => create(payload), {
		onSettled: () => {
			queryClient.invalidateQueries('createFile');
		},
	});
};

export const useReasonCancelVideo = (enabled: boolean) => {
	const getReason = () => {
		return fetchApiConsultation({
			url: `/${COMMON.service}/${COMMON.model.cancelReason}`,
		});
	};

	return useQuery([], () => getReason(), {
		enabled: enabled,
	});
};

export const useUploadImages = () => {
	const uploadImages = (payload: any) => {
		return fetchApiJM({
			url: `/${UPLOAD_IMAGES.service}`,
			payload,
			options: {
				method: 'POST',
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		});
	};

	return useMutation((payload: any) => uploadImages(payload), {
		onError: (error: any) => {
			notification.error({
				message: error?.meta?.message || 'Maaf Koneksi anda Terputus',
			});
		},
	});
};
