import { useQuery } from 'react-query';

import { CONTACTS_TOPIC } from 'src/constants/services';
import { fetchApiPublishing } from 'src/utils/fetch-api';

const query = 'contact-us-topic';

export const useContactTopic = () => {
	return useQuery(query, () => fetchApiPublishing({ url: `${CONTACTS_TOPIC.service}` }), {
		keepPreviousData: true,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
};
