import { Button, Typography } from 'antd';

import Image from 'src/components/Image';

import classes from './index.module.less';

type Props = {
	address?: any;
	closeModal: () => void;
};

const AddressListSuccess = ({ address, closeModal }: Props) => {
	const isEdit = !!address?.id;
	return (
		<div className={classes.success}>
			<Image width={200} height={200} src="/images/confirmation/success.svg" alt="success-image" />
			<Typography.Title level={5} className={classes.title}>
				{isEdit ? 'Alamat Berhasil Dirubah' : 'Alamat Berhasil Disimpan'}
			</Typography.Title>
			<Typography.Paragraph className={classes.subtitle}>
				{isEdit
					? 'Alamat kamu sudah berhasil dirubah, pastikan data kamu sudah sesuai'
					: 'Alamat kamu sudah berhasil disimpan didaftar alamat kamu'}
			</Typography.Paragraph>
			<Button type="primary" size="large" onClick={() => closeModal()}>
				Oke
			</Button>
		</div>
	);
};

export default AddressListSuccess;
