import Countdown from 'react-countdown';
import { Tag } from 'antd';
import classNames from 'classnames';

import { TagCountdownCProps, TagCProps } from 'src/interface/Layout/Tag';

import classes from './index.module.less';

const TagC = (props: TagCProps) => {
	return (
		<Tag
			{...props}
			className={classNames(
				props.type?.includes('chips') ? classes.chips : classes.tag,
				{
					[classes.primary]: props.type === 'primary-chips',
				},
				{
					[classes.active]: props.isactive,
				},
				props.className,
			)}
		>
			{props.children}

			{props.label && <Tag className={`${classes.label} ml-2`}>{props.labelText}</Tag>}
		</Tag>
	);
};

export const TagCountdownC = (props: TagCountdownCProps) => {
	const renderer = ({ completed }: any) => {
		if (completed) {
			return <TagC {...props}>{props.text}</TagC>;
		}

		return <TagC {...props}>{props.children}</TagC>;
	};

	return props.countdown > 0 ? (
		<Countdown date={Date.now() + props.countdown} renderer={renderer} />
	) : (
		<TagC {...props}>{props.children}</TagC>
	);
};

export default TagC;
