import { useQuery } from 'react-query';

import g from 'src/constants/global';
import { fetchApiMarketplace } from 'src/utils/fetch-api';

export const QUERYKEY = 'klikstore-transactions';
export const QUERYKEYDETAIL = 'klikstore-transaction-detail';

export const useKlikStoreOrderHistory = ({
	transactionListFilter,
}: {
	transactionListFilter: any;
}) => {
	const { search, pagination } = transactionListFilter;
	const defaultStatuses = Object.values(g.STATUS.KLIKSTORE.TRANSACTION).map((st: any) => {
		return st.value;
	});
	let statusFilter = `&status=${defaultStatuses}`;
	let searchFilter = '';

	if (search) {
		searchFilter = `&q=${search}`;
	}
	const fetchList = ({ pageParam = 1 }) => {
		const fields: any = [
			'merchant',
			'customer',
			'order_items',
			'status_id',
			'grand_total',
			'payment_info',
		];
		return fetchApiMarketplace({
			// eslint-disable-next-line max-len
			url: `/search/orders?fields=${fields?.join(',')}&sort=newest&dir=DESC&limit=${
				pagination.pageSize
			}${statusFilter}${searchFilter}&page=${pageParam}&event=false`,
		});
	};

	return useQuery(
		[QUERYKEY, search, pagination.current],
		() => fetchList({ pageParam: pagination.current }),
		{
			refetchOnWindowFocus: false,
		},
	);
};

export const useKlikstoreTransactionDetail = (invoice: string) => {
	return useQuery(
		[QUERYKEYDETAIL, 'detail', invoice],
		() => {
			return fetchApiMarketplace({
				url: `/order/${invoice}`,
				options: { method: 'GET' },
			});
		},
		{
			retry: false,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
};
