import Slider from 'react-slick';

import { NextArrow, PrevArrow } from 'src/components/Layout/Slick/Arrow';
import TagC from 'src/components/Tag';
import { useAddressLists } from 'src/hooks';
import { Address, UserLocation } from 'src/interface';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classes from './index.module.less';

interface LocationSliderCProps {
	setPosition: (e: UserLocation) => void;
	setLocationModal: (e: boolean) => void;
}

const LocationSliderC = (props: LocationSliderCProps) => {
	const { data, isLoading, isError } = useAddressLists('1');
	const items = data?.data?.records;
	const isEmpty = !isLoading && items ? items?.length === 0 : true;

	const handleUserAddress = (item: Address) => {
		props.setPosition({
			lat: Number(item.latitude),
			lng: Number(item.longitude),
			address: item.address,
			addressLabel: item.label,
		});
		props.setLocationModal(false);
	};

	return (
		<>
			{!isLoading && !isError && !isEmpty && (
				<Slider
					variableWidth
					infinite={false}
					nextArrow={<NextArrow size={32} sizeArrow={16} />}
					prevArrow={<PrevArrow size={32} sizeArrow={16} />}
					className={classes.slider}
				>
					{items.map((item: Address, i: number) => (
						<div key={i}>
							<TagC
								className="fs-14 fw-600 text-neutral-5 cursor-pointer"
								onClick={() => handleUserAddress(item)}
							>
								{item.label}
							</TagC>
						</div>
					))}
				</Slider>
			)}
		</>
	);
};

export default LocationSliderC;
