import g from 'src/constants/global';
import { platformEncoded } from 'src/constants/platform';
import { USER_TYPE } from 'src/constants/redux';
import { PHONE, USER } from 'src/constants/services';
import { MODIFY_DATA, RESET_DATA, SINGLE_API } from 'src/redux/actions/types';
import { forceLogout } from 'src/utils/auth';
import AuthStorage, { idTokenStorage, refreshTokenStorage } from 'src/utils/auth-storage';

export const actionUserCheck = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.check}`,
			options: { method: 'POST' },
			payload,
			successType: USER_TYPE.model.check.success,
			errorType: USER_TYPE.model.check.error,
		},
	};
};

export const actionUserCheckModify = async (payload: object | null = {}) => {
	return {
		type: MODIFY_DATA,
		payload: {
			apiType: 'v3_user',
			payload,
			successType: USER_TYPE.model.check.success,
			errorType: USER_TYPE.model.check.error,
		},
	};
};

export const actionUserCheckPin = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.checkPin}`,
			options: { method: 'POST' },
			payload,
			successType: USER_TYPE.model.checkPin.success,
			errorType: USER_TYPE.model.checkPin.error,
		},
	};
};

export const actionUserCheckRelation = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.checkRelation}`,
			options: { method: 'POST' },
			payload,
			successType: USER_TYPE.model.checkRelation.success,
			errorType: USER_TYPE.model.checkRelation.error,
		},
	};
};

export const actionUserLogged = async () => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.logged}`,
			successType: USER_TYPE.model.logged.success,
			errorType: USER_TYPE.model.logged.error,
		},
	};
};

export const actionUserLogin = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.login}`,
			options: { method: 'POST', headers: { 'x-api-platform': platformEncoded } },
			payload: {
				...payload,
				device: g.DEVICE,
			},
			successType: USER_TYPE.model.login.success,
			errorType: USER_TYPE.model.login.error,
			next: async (err: any, res: any = {}) => {
				if (!err) {
					const { token, refresh_token: refreshToken, id_token: idToken } = res?.data?.record || {};
					if (token && refreshToken && idToken) {
						AuthStorage.value = { token };
						refreshTokenStorage.value = { refreshToken };
						idTokenStorage.value = { idToken };
					}
				}
			},
		},
	};
};

export const actionUserReactivate = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.reactive}`,
			options: { method: 'POST' },
			payload,
			successType: USER_TYPE.model.login.success,
			errorType: USER_TYPE.model.login.error,
			next: async (err: any, res: any = {}) => {
				if (!err) {
					const { token } = res?.data?.record || {};
					if (token) {
						AuthStorage.value = { token };
					}
				}
			},
		},
	};
};

export const actionUserRegister = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.register}`,
			options: { method: 'POST', headers: { 'x-api-platform': platformEncoded } },
			payload: {
				...payload,
				device: g.DEVICE,
			},
			notif: true,
			successType: USER_TYPE.model.register.success,
			errorType: USER_TYPE.model.register.error,
			next: async (err: any, res: any = {}) => {
				if (!err) {
					const { token, refresh_token: refreshToken, id_token: idToken } = res?.data?.record || {};
					if (token && refreshToken && idToken) {
						AuthStorage.value = { token };
						refreshTokenStorage.value = { refreshToken };
						idTokenStorage.value = { idToken };
					}
				}
			},
		},
	};
};

export const actionOldUserRegister = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.setPin}`,
			options: { method: 'POST' },
			payload: {
				...payload,
				device: g.DEVICE,
			},
			notif: true,
			successType: USER_TYPE.model.setPin.success,
			errorType: USER_TYPE.model.setPin.error,
			next: async (err: any, res: any = {}) => {
				if (!err) {
					const { token, refresh_token: refreshToken, id_token: idToken } = res?.data?.record || {};
					if (token && refreshToken && idToken) {
						AuthStorage.value = { token };
						refreshTokenStorage.value = { refreshToken };
						idTokenStorage.value = { idToken };
					}
				}
			},
		},
	};
};

export const actionUserForgotPin = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.forgotPin}`,
			options: { method: 'POST' },
			payload: {
				...payload,
				device: g.DEVICE,
			},
			notif: true,
			successType: USER_TYPE.model.forgotPin.success,
			errorType: USER_TYPE.model.forgotPin.error,
		},
	};
};

export const actionUserChangePin = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.changePin}`,
			options: { method: 'POST' },
			payload: {
				...payload,
				device: g.DEVICE,
			},
			successType: USER_TYPE.model.changePin.success,
			errorType: USER_TYPE.model.changePin.error,
		},
	};
};

export const actionUserChangePinModify = async (payload: object | null = {}) => {
	return {
		type: MODIFY_DATA,
		payload: {
			apiType: 'v3_user',
			payload,
			successType: USER_TYPE.model.changePin.success,
			errorType: USER_TYPE.model.changePin.error,
		},
	};
};

export const actionUserProfile = async (uid: string | undefined) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.profile}/${uid}`,
			successType: USER_TYPE.model.profile.success,
			errorType: USER_TYPE.model.profile.error,
		},
	};
};

export const actionUpdateProfile = async (uid: string | undefined, payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.profile}/${uid}`,
			options: { method: 'PUT' },
			payload: {
				...payload,
				refresh_token: refreshTokenStorage.refreshToken,
			},
			notif: true,
			successType: USER_TYPE.model.updateProfile.success,
			errorType: USER_TYPE.model.updateProfile.error,
			next: async (err: any, res: any = {}) => {
				if (!err) {
					const { token, refresh_token: refreshToken, id_token: idToken } = res?.data?.record || {};
					if (token && refreshToken && idToken) {
						AuthStorage.value = { token };
						refreshTokenStorage.value = { refreshToken };
						idTokenStorage.value = { idToken };
					}
				} else {
					if (err?.status === 400 && /refresh.+required/i.test(err?.meta?.message)) {
						forceLogout();
					}
				}
			},
		},
	};
};

export const actionCheckNumber = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${PHONE.service}/${PHONE.model.checkNumber}`,
			options: { method: 'POST' },
			payload,
			successType: USER_TYPE.model.checkNumber.success,
			errorType: USER_TYPE.model.checkNumber.error,
		},
	};
};

export const actionChangeNumber = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${PHONE.service}/${PHONE.model.changeNumber}`,
			options: { method: 'POST' },
			payload: {
				...payload,
				refresh_token: refreshTokenStorage.refreshToken,
			},
			successType: USER_TYPE.model.changeNumber.success,
			errorType: USER_TYPE.model.changeNumber.error,
		},
		next: async (err: any, res: any = {}) => {
			if (!err) {
				const { token, refresh_token: refreshToken, id_token: idToken } = res?.data?.record || {};
				if (token && refreshToken && idToken) {
					AuthStorage.value = { token };
					refreshTokenStorage.value = { refreshToken };
					idTokenStorage.value = { idToken };
				}
			}
		},
	};
};

export const actionUpdateImageProfile = async (uid: string | undefined, payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.profile}/${uid}/${USER.model.updateImage}`,
			options: {
				method: 'POST',
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
			payload: {
				...payload,
				refresh_token: refreshTokenStorage.refreshToken,
				user_uid: uid,
			},
			successType: USER_TYPE.model.updateImage.success,
			errorType: USER_TYPE.model.updateImage.error,
			next: async (err: any, res: any = {}) => {
				if (!err) {
					const { token, refresh_token: refreshToken, id_token: idToken } = res?.data?.record || {};
					if (token && refreshToken && idToken) {
						AuthStorage.value = { token };
						refreshTokenStorage.value = { refreshToken };
						idTokenStorage.value = { idToken };
					}
				} else {
					if (err?.status === 400 && /refresh.+required/i.test(err?.meta?.message)) {
						forceLogout();
					}
				}
			},
		},
	};
};

export const actionUserListRelationship = async () => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.listRelationship}`,
			successType: USER_TYPE.model.listRelationship.success,
			errorType: USER_TYPE.model.listRelationship.error,
		},
	};
};

export const actionUserUpdateRelationship = async (
	uid: string | undefined,
	payload: object = {},
) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.profile}/${uid}/${USER.model.updateRelationship}`,
			options: { method: 'PUT' },
			payload,
			successType: USER_TYPE.model.updateRelationship.success,
			errorType: USER_TYPE.model.updateRelationship.error,
		},
	};
};

export const actionUserDeleteRelationship = async (relationId: string) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.deleteRelationship}/${relationId}`,
			options: { method: 'DELETE' },
			successType: USER_TYPE.model.deleteRelationship.success,
			errorType: USER_TYPE.model.deleteRelationship.error,
		},
	};
};

export const actionUserLogout = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.logout}`,
			options: { method: 'POST' },
			payload: {
				...payload,
				id_token: idTokenStorage.idToken,
			},
			successType: USER_TYPE.model.logout.success,
			next: () => {
				AuthStorage.destroy();
				idTokenStorage.destroy();
				refreshTokenStorage.destroy();
			},
		},
	};
};

export const actionUserReset = async () => {
	return {
		type: RESET_DATA,
		payload: {
			successType: USER_TYPE.model.reset.success,
		},
	};
};

export const actionUserListTopic = async () => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.listTopic}`,
			successType: USER_TYPE.model.listTopic.success,
			errorType: USER_TYPE.model.listTopic.error,
		},
	};
};

export const actionUserUpdateProfileIdentity = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.updateProfileIdentity}`,
			options: {
				method: 'POST',
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
			payload,
			successType: USER_TYPE.model.updateProfileIdentity.success,
			errorType: USER_TYPE.model.updateProfileIdentity.error,
		},
	};
};

export const actionUserLocationModify = async (payload: any) => {
	return {
		type: MODIFY_DATA,
		payload: {
			payload,
			successType: USER_TYPE.model.location.success,
			errorType: USER_TYPE.model.location.error,
		},
	};
};

export const actionCustomerInfo = async () => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'marketplace',
			url: '/customer/info',
			options: { method: 'GET' },
			successType: USER_TYPE.model.info.success,
			errorType: USER_TYPE.model.info.error,
		},
	};
};
