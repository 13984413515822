import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { StoreProduct } from 'src/interface';
import { startCase } from 'src/utils/func';

export const ucwords = (value: string) => startCase(value ? value.toString().toLowerCase() : '');

export const textAddon = (
	value: string,
	addonAfter: string = '',
	addonBefore: string = '',
	space: string = ' ',
	prettier: boolean = true,
) => {
	if ((value || value?.length > 0) && value !== '-') {
		return addonBefore + space + (prettier ? ucwords(value) : value) + space + addonAfter;
	} else {
		return '-';
	}
};

export const limitCharacter = (
	value: string,
	index: number = 0,
	limit: number = 10,
	ellipsis: boolean = true,
	prettier: boolean = true,
) => {
	const valueSlice = value?.slice(index, limit);

	if (value || value?.length > 0) {
		return (prettier ? ucwords(valueSlice) : valueSlice) + (ellipsis ? '...' : '');
	} else {
		return '-';
	}
};

export const truncateString = (str: string, num: number = 10) => {
	if (str?.length > num) {
		return str.slice(0, num) + '...';
	} else {
		return str;
	}
};

export const getProfileImage = (
	profileImages: Record<string, string>[],
	originImage: string,
	breakpoint: Partial<Record<Breakpoint, boolean>>,
): string => {
	const { xs, sm, md, lg, xl, xxl } = breakpoint;

	let url: string = '';

	const objImages = {
		desktopXL: '',
		desktop: '',
		tablet: '',
		mobile: '',
		original: '',
	};

	profileImages?.forEach((pi) => {
		if (pi.hasOwnProperty('ar-desktop-xl')) {
			objImages.desktopXL = pi['ar-desktop-xl'];
		}
		if (pi.hasOwnProperty('ar-desktop')) {
			objImages.desktop = pi['ar-desktop'];
		}
		if (pi.hasOwnProperty('ar-tablet')) {
			objImages.tablet = pi['ar-tablet'];
		}
		if (pi.hasOwnProperty('ar-mobile')) {
			objImages.mobile = pi['ar-mobile'];
		}
		if (pi.hasOwnProperty('original')) {
			objImages.original = pi.original;
		}
	});

	if ((xs || sm) && objImages.mobile) url = objImages.mobile;
	if (md && objImages.tablet) url = objImages.tablet;
	if (lg && objImages.desktop) url = objImages.desktop;
	if ((xl || xxl) && objImages.desktopXL) url = objImages.desktopXL;
	if (!url) url = objImages.original || originImage;

	return url;
};

export const getProfileListImage = (
	profileImages: Record<string, string>[],
	originImage: string,
	breakpoint: Partial<Record<Breakpoint, boolean>>,
): string => {
	const { xs, sm, md, lg, xl, xxl } = breakpoint;

	let url: string = '';

	const objImages = {
		desktop: '',
		tablet: '',
		mobile: '',
		original: '',
	};

	profileImages?.forEach((pi) => {
		if (pi.hasOwnProperty('ar-list-desktop')) {
			objImages.desktop = pi['ar-list-desktop'];
		}
		if (pi.hasOwnProperty('ar-list-tablet')) {
			objImages.tablet = pi['ar-list-tablet'];
		}
		if (pi.hasOwnProperty('ar-list-mobile')) {
			objImages.mobile = pi['ar-list-mobile'];
		}
		if (pi.hasOwnProperty('original')) {
			objImages.original = pi.original;
		}
	});

	if (xs || sm) url = objImages.mobile;
	if (md) url = objImages.tablet;
	if (lg || xl || xxl) url = objImages.desktop;
	if (!url) url = objImages.original || originImage;

	return url;
};

export const getProductListImage = (
	product: StoreProduct,
	originImage: string,
	breakpoint: Partial<Record<Breakpoint, boolean>>,
): string => {
	const { xs, sm, md, lg, xl, xxl } = breakpoint;

	let url: string = '';

	const objImages = {
		desktop: '',
		tablet: '',
		mobile: '',
		original: '',
	};

	if (product.hasOwnProperty('image_list_desktop')) {
		objImages.desktop =
			(product?.image_list_desktop !== ''
				? product.image_list_desktop
				: product?.image_mp_list_desktop) ?? '';
	}
	if (product.hasOwnProperty('image_list_tablet')) {
		objImages.tablet =
			(product?.image_list_tablet !== ''
				? product.image_list_tablet
				: product?.image_mp_list_tablet) ?? '';
	}
	if (product.hasOwnProperty('image_list_mobile')) {
		objImages.mobile =
			(product?.image_list_mobile !== ''
				? product.image_list_mobile
				: product?.image_mp_list_mobile) ?? '';
	}
	if (product.hasOwnProperty('image_original')) {
		objImages.original =
			(product?.image_original !== '' ? product.image_original : product?.image_mp_original) ?? '';
	}

	if (xs || sm) url = objImages.mobile;
	if (md) url = objImages.tablet;
	if (lg || xl || xxl) url = objImages.desktop;
	if (!url) url = objImages.original || originImage;

	return url;
};

export const highlightMatchingWord = (sentence: string, query: string) => {
	return sentence?.replaceAll(new RegExp('\\b' + query, 'ig'), '<b>' + ucwords(query) + '</b>');
};

export const censoredString = (word: string): string => {
	let name = '';
	if (word) {
		for (let i = 0; i < 0; i++) {
			if (i === 0 || i !== word.length - 1) {
				name = name + word[i];
			} else {
				name = name + '*';
			}
		}
		return name;
	}
	return 'A****m';
};

export const splitAndRemoveAlphabet = (list?: string) => {
	if (!list) return [];
	const filters = list?.split(',') || [];
	return filters?.map((v: any) => Number(v?.replace(/\D+/g, '')));
};

export function formatDigits(input: string): string {
	const digitsOnly = input.replace(/\s/g, '');
	const chunks = digitsOnly.match(/.{1,4}/g);

	return chunks ? chunks.join(' ') : '';
}
