import { useQuery } from 'react-query';

import g from 'src/constants/global';
import { HEALTH_TYPE, HOSPITAL, TYPESENSE } from 'src/constants/services';
import authStorage from 'src/utils/auth-storage';
import { fetchApiConsultation, fetchApiJM, fetchApiTypesense } from 'src/utils/fetch-api';
import { filterBy, ucwords } from 'src/utils/func';

const medicalFacilitiesServices = 'medical_facilities_services';
const medicalFacilitiesDoctor = 'medical_facilities_doctor';
const medicalFacilitiesList = 'medical_facilities_list';
const medicalFacilitiesSchedule = 'medical_facilities_schedule';
const hospitalGetDoctors = 'hospital_get_doctors';

export const useHospitalCategories = (enabled: boolean = true) => {
	return useQuery(
		['hospitalCategories', authStorage.tokenDecode.id || ''],
		() => {
			return fetchApiConsultation({
				url: `/${HOSPITAL.service}/${g.HOSPITAL.DEFAULT.id}/${HOSPITAL.model.categories}`,
			});
		},
		{ keepPreviousData: true, refetchOnMount: false, refetchOnWindowFocus: false, enabled },
	);
};

export const useMedicalFacilityDoctors = (
	id: any,
	filter: any,
	page?: number,
	pageSize?: number,
) => {
	return useQuery(
		[hospitalGetDoctors, id, filter, page],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							q: '*',
							query_by: 'specialist_name',
							collection: `${TYPESENSE.model.medicalFacilityDoctors}`,
							filter_by: filterBy([{ field_name: 'medical_facility_uid', value: [id] }]),
							max_facet_values: 100,
							sort_by: 'specialist_name:asc',
							exhaustive_search: true,
							facet_by: 'specialist_name',
						},
						{
							q: '*',
							query_by: 'specialist_name',
							collection: `${TYPESENSE.model.medicalFacilityDoctors}`,
							filter_by: filterBy([
								{ field_name: 'medical_facility_uid', value: [id] },
								{ field_name: 'specialist_name', value: filter ? [filter] : [] },
							]),
							page: page,
							per_page: pageSize,
							exhaustive_search: true,
							facet_by: '',
						},
					],
				},
			});
		},
		{ refetchOnWindowFocus: false, keepPreviousData: true },
	);
};

export const useMedicalFacilityServiceTab = (
	id: any,
	filter: any,
	page?: number,
	pageSize?: number,
) => {
	return useQuery(
		[medicalFacilitiesServices, id, filter, page],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							q: '*',
							query_by: 'category',
							collection: `${TYPESENSE.model.medicalFacilityServices}`,
							filter_by: filterBy([{ field_name: 'medical_facility_uid', value: [id] }]),
							max_facet_values: 100,
							sort_by: 'category:asc',
							exhaustive_search: true,
							facet_by: 'category',
						},
						{
							q: '*',
							query_by: 'category',
							collection: `${TYPESENSE.model.medicalFacilityServices}`,
							filter_by: filterBy([
								{ field_name: 'medical_facility_uid', value: [id] },
								{ field_name: 'category', value: filter ? [filter] : [] },
							]),
							page: page,
							per_page: pageSize,
							exhaustive_search: true,
							facet_by: '',
						},
					],
				},
			});
		},
		{ refetchOnWindowFocus: false, keepPreviousData: true },
	);
};

export const useMedicalFacilitiesDoctor = (uid: any, _uidDoctor: any) => {
	return useQuery(
		[medicalFacilitiesDoctor, uid, _uidDoctor],
		() => {
			if (uid && _uidDoctor) {
				return fetchApiJM({ url: `/medical-facilities/${uid}/doctors/${_uidDoctor}` });
			}
		},
		{ enabled: !!uid, refetchOnWindowFocus: false, refetchOnMount: true, keepPreviousData: true },
	);
};

export const useMedicalFacilitiesList = (slug: any, location: { lat: any; lng: any }) => {
	return useQuery(
		[medicalFacilitiesList, slug, location],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							exhaustive_search: true,
							q: '*',
							query_by: 'name',
							collection: `${TYPESENSE.model.medicalFacilityDoctors}`,
							sort_by: `location(${location.lat},${location.lng}):asc,name:asc`,
							filter_by: filterBy([{ field_name: 'slug', value: slug }]),
							per_page: 100,
						},
					],
				},
			});
		},
		{ enabled: !!slug, refetchOnWindowFocus: false, keepPreviousData: true },
	);
};

export const useMedicalFacilitiesGetSchedule = (
	uid: any,
	_uidDoctor: any,
	scheduleDate: string,
	scheduleType: any,
) => {
	const getAll = () => {
		if (uid && _uidDoctor && scheduleDate) {
			const payload = {
				date: scheduleDate,
			};
			const moduleType = scheduleType === HEALTH_TYPE.SERVICE ? 'services' : 'doctors';
			return fetchApiJM({
				url: `/medical-facilities/${uid}/${moduleType}/${_uidDoctor}/schedules`,
				payload,
			});
		}
	};

	return useQuery([medicalFacilitiesSchedule, uid, _uidDoctor, scheduleDate], () => getAll(), {
		refetchOnWindowFocus: false,
		enabled: !!uid,
	});
};

export const useFindSpecialityCategory = (speciality?: string, enabled?: boolean) => {
	const { data, isLoading } = useHospitalCategories(enabled);
	const items = data?.data?.record || data?.data?.records;

	return {
		category: items?.find?.((s: any) => ucwords(s.category) === speciality),
		isLoading,
	};
};
