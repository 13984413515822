import { HOMEPAGE_TYPE } from 'src/constants/redux';

export const initialState = {
	banner: null,
	download: null,
};

const reducer = (state = initialState, action: any = {}) => {
	switch (action.type) {
		case HOMEPAGE_TYPE.model.banner.success:
			return Object.assign({}, state, {
				banner: action.payload,
			});
		case HOMEPAGE_TYPE.model.download.success:
			return Object.assign({}, state, {
				download: action.payload,
			});
		default:
			return state;
	}
};

export default reducer;
