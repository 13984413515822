import { Row, Typography } from 'antd';

const HeaderNotification = (props: any) => {
	return (
		<>
			<Row justify="space-between" className="mt-4 mb-4 ml-auto w-90 mr-auto">
				<Typography.Text className={'fw-600 text-dark fs-16'}>{props.HeaderText}</Typography.Text>

				{!props.HiddenDirectLink && (
					<a className="fw-400 fs-14" onClick={props.DirectLink}>
						Lihat Semua
					</a>
				)}
			</Row>
		</>
	);
};

export default HeaderNotification;
