import { TERMS_CONDITION_TYPE } from 'src/constants/redux';
import { POLICY } from 'src/constants/services';
import { SINGLE_API } from 'src/redux/actions/types';

export const actionGetTerm = async (next?: (...f: any) => void) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `/${POLICY.service}`,
			successType: TERMS_CONDITION_TYPE.model.terms.success,
			next,
		},
	};
};
