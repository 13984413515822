import React, { Dispatch, SetStateAction, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import Link from 'next/link';
import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import { StoreCategory } from 'src/interface/KlikStore/Category';
import { getDefaultCategoryImg } from 'src/utils/func';
import { cleanSlug, renderStoreCategoryTree } from 'src/utils/func/klikstore';

import classes from './HeaderCategoryMenu.module.less';

const { Text } = Typography;

interface ICategoryMenuProps {
	isCategoryHovered: boolean;
	setCategoryHovered: Dispatch<SetStateAction<boolean>>;
}

const HeaderCategoryMenu = (props: ICategoryMenuProps) => {
	const { menu } = useSelector((state: RootStateOrAny) => state);

	const [selectedCategoryLevel1, setSelectedCategoryLevel1] = useState<{
		id: number;
		index: number;
	}>({
		id: menu.categories?.[0]?.id,
		index: 0,
	});

	const categoryTree = renderStoreCategoryTree(menu.categories);

	const hoveredLevel1Category = categoryTree?.[selectedCategoryLevel1.index];
	const hoveredLevel1CategorySub = categoryTree?.[selectedCategoryLevel1.index]?.sub;

	return (
		<div onMouseLeave={() => props.setCategoryHovered(false)} className={classNames(classes.sheet)}>
			<Row className={classes['row-menu']} gutter={[0, 0]}>
				<Col className={classes['col-menu']}>
					<div className="d-flex">
						<div className="mr-4 mt-3">
							{categoryTree?.map((category: StoreCategory, index: number) => (
								<Link key={category.id} href={cleanSlug(`/kalstore/c/${category.slug}`)}>
									<div
										className={classNames(
											classes['container-list'],
											{
												[classes.selected]: category.id === selectedCategoryLevel1.id,
												[classes['mt-8px']]: index !== 0,
											},
											'cursor-pointer',
										)}
										onMouseEnter={() => setSelectedCategoryLevel1({ id: category.id, index })}
										onClick={() => props.setCategoryHovered(false)}
									>
										<div className={classNames('d-flex align-items-center')}>
											<ImageC
												className={classes['category-1-icon']}
												alt={category.name}
												src={category.icon}
												defaultSrc={getDefaultCategoryImg()}
												objectFit="cover"
												height={24}
												width={24}
											/>
											<Text
												className={classNames('fs-14', classes['category-1-title'], {
													[classes['selected-category-1-title']]:
														category.id === selectedCategoryLevel1.id,
												})}
											>
												{category.name}
											</Text>
										</div>
									</div>
								</Link>
							))}
						</div>
					</div>
				</Col>
				<Col>
					<div className={classes.divider}></div>
				</Col>
				<Col flex="auto" className={classes['col-menu']}>
					<div className={classes['categories-list']}>
						<div className={classNames(classes['category-list-item'])}>
							<Link
								key={hoveredLevel1Category?.id}
								href={cleanSlug(`/kalstore/c/${hoveredLevel1Category?.slug}`)}
								passHref
							>
								<div
									className={classNames(classes.level, 'cursor-pointer')}
									style={{ marginBottom: 12 }}
									onClick={() => props.setCategoryHovered(false)}
								>
									<Text className="fs-16" strong>
										{hoveredLevel1Category?.name}
									</Text>
								</div>
							</Link>

							{hoveredLevel1CategorySub?.map((item: StoreCategory, indexItem: number) => {
								return (
									<Link key={item.id} href={cleanSlug(`/kalstore/c/${item.slug}`)} passHref>
										<div
											className={classNames(
												classes.itemlabel,
												classes.level,
												'fs-14 cursor-pointer',
												{ [classes['mt-8px']]: indexItem !== 0 },
											)}
											onClick={() => props.setCategoryHovered(false)}
										>
											{item.name}
										</div>
									</Link>
								);
							})}
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default HeaderCategoryMenu;
