import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

import { VIDEO_CALL } from 'src/constants/services';
import { fetchApiConsultation } from 'src/utils/fetch-api';

const query = 'videoCall';

interface VideoCallParams {
	setIsFirstLoad: (e: boolean) => void;
	filter: string | null;
}

export const useVideoCall = ({ setIsFirstLoad, filter }: VideoCallParams) => {
	return useInfiniteQuery(
		[query, filter],
		({ pageParam = 1 }) => {
			return fetchApiConsultation({
				url: `/${VIDEO_CALL.service}/`,
				payload: filter
					? { filter: JSON.stringify({ status: [filter] }), page: pageParam }
					: { page: pageParam },
			});
		},
		{
			refetchOnWindowFocus: false,
			onSettled: (res, err) => {
				if (!err && res?.pages[0]?.status === 200) setIsFirstLoad(true);
			},
			getNextPageParam: (res) =>
				res?.pagination?.page < res?.pagination?.total_page ? res?.pagination?.page + 1 : undefined,
		},
	);
};

export const useVideoCallDetail = (transId: any = '') => {
	return useQuery(
		[query, transId],
		() => fetchApiConsultation({ url: `/${VIDEO_CALL.service}/${transId}` }),
		{ refetchOnWindowFocus: false, enabled: !!transId },
	);
};

export const useVideoCallFilter = () => {
	return useQuery(
		[query, 'filter'],
		() => fetchApiConsultation({ url: `/${VIDEO_CALL.service}/${VIDEO_CALL.model.filter}` }),
		{ refetchOnWindowFocus: false, keepPreviousData: true, refetchOnMount: false },
	);
};

export const useJoinVideoCall = () => {
	const joinVideo = (transId: any) => {
		return fetchApiConsultation({
			url: `/${VIDEO_CALL.service}/${transId}/${VIDEO_CALL.model.patientJoin}`,
			options: { method: 'POST' },
			notif: true,
		});
	};

	return useMutation((transId: any) => joinVideo(transId));
};

export const useFinishVideoCall = () => {
	const finishVideo = (transId: any) => {
		return fetchApiConsultation({
			url: `/${VIDEO_CALL.service}/${transId}/${VIDEO_CALL.model.patientFinish}`,
			options: { method: 'POST' },
			notif: true,
		});
	};

	return useMutation((transId: any) => finishVideo(transId));
};

export const useCancelVideocallTrans = (transId: any) => {
	const cancel = (payload: any) => {
		return fetchApiConsultation({
			url: `/${VIDEO_CALL.service}/${transId}/${VIDEO_CALL.model.cancel}`,
			payload,
			options: { method: 'POST' },
		});
	};

	return useMutation((payload: any) => cancel(payload), {
		onError: (error: any) => {
			notification.error({
				message: error?.meta?.message || 'Maaf Koneksi anda Terputus',
			});
		},
	});
};
