import { OTP_TYPE } from 'src/constants/redux';
import { OTP } from 'src/constants/services';
import { RESET_DATA, SINGLE_API } from 'src/redux/actions/types';

export const actionOtp = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${OTP.service}`,
			options: { method: 'POST' },
			payload,
			successType: OTP_TYPE.model.otp.success,
			errorType: OTP_TYPE.model.otp.error,
		},
	};
};

export const actionOtpValidate = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${OTP.service}/${OTP.model.validate}`,
			options: { method: 'POST' },
			payload,
			successType: OTP_TYPE.model.validate.success,
			errorType: OTP_TYPE.model.validate.error,
		},
	};
};

export const actionOtpValidUser = async (payload: object = {}) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${OTP.service}/${OTP.model.validUser}`,
			options: { method: 'POST' },
			payload,
			successType: OTP_TYPE.model.validUser.success,
			errorType: OTP_TYPE.model.validUser.error,
		},
	};
};

export const actionOtpReset = async () => {
	return {
		type: RESET_DATA,
		payload: {
			successType: OTP_TYPE.model.reset.success,
		},
	};
};
