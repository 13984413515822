import Link from 'next/link';
import { Menu } from 'antd';

import classes from './FooterMenu.module.less';

type Props = {
	list: object[];
	firstMenu: boolean;
};

const FooterMenu = ({ list, firstMenu }: Props) => {
	const patternValidUrl = new RegExp(/^(http(s?)):\/\//i);
	return (
		<>
			<Menu mode="vertical" className={classes.menu} selectedKeys={['']}>
				{list
					?.filter((_: any, i: number) => (firstMenu ? i < list.length / 2 : i >= list.length / 2))
					?.map((menu: any) => {
						return (
							<Menu.Item key={menu.name}>
								<Link href={menu.deeplink} legacyBehavior>
									{patternValidUrl.test(menu.deeplink) ? (
										<a rel="nofollow">{menu.name}</a>
									) : (
										<a>{menu.name}</a>
									)}
								</Link>
							</Menu.Item>
						);
					})}
			</Menu>
		</>
	);
};

export default FooterMenu;
