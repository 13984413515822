import cookie from 'react-cookies';
import { HYDRATE } from 'next-redux-wrapper';
import { combineReducers } from 'redux';

import { USER_TYPE } from 'src/constants/redux';

import klikstoreFloatingChat, {
	initialState as klikstoreFloatingChatInital,
} from './klikstore/floatingchat';
import klikstoreLandingPage, {
	initialState as klikstoreLandingPageInitial,
} from './klikstore/landingpage';
import kalstoreFlag, { initialState as kalstoreFlagInitial } from './klikstore/module';
import klikstoreQuickAuthDisplay, {
	initialState as klikstoreQuickAuthDisplayInitial,
} from './klikstore/quickauth';
import klikstoreReviewPopup, {
	initialState as klikstoreReviewPopupInitial,
} from './klikstore/reviewpopup';
import about, { initialState as aboutpageInitial } from './about';
import article, { initialState as articleInitial } from './article';
import articleByGroup, { initialState as articleByGroupInitial } from './articleByGroup';
import auth, { initialState as authInitial } from './auth';
import categoryByGroup, { initialState as categoryByGroupInitial } from './categoryByGroup';
import footer, { initialState as footerInitial } from './footer';
import homepage, { initialState as homepageInitial } from './homepage';
import loader, { initialState as initialLoader } from './loader';
import menu, { initialState as menuInitial } from './menu';
import otp, { initialState as otpInitial } from './otp';
import profile, { initialState as profileInitial } from './profile';
import qiscus, { initialState as qiscusInitial } from './qiscus';
import relationship, { initialState as relationshipInitial } from './relationship';
import terms, { initialState as termsInitial } from './terms';

export const initialState = {
	about: aboutpageInitial,
	auth: authInitial,
	loader: initialLoader,
	menu: menuInitial,
	footer: footerInitial,
	homepage: homepageInitial,
	article: articleInitial,
	otp: otpInitial,
	profile: profileInitial,
	relationship: relationshipInitial,
	terms: termsInitial,
	qiscus: qiscusInitial,
	articleByGroup: articleByGroupInitial,
	categoryByGroup: categoryByGroupInitial,
	klikstoreLandingPage: klikstoreLandingPageInitial,
	klikstoreFloatingChat: klikstoreFloatingChatInital,
	kalstoreFlag: kalstoreFlagInitial,
	klikstoreQuickAuthDisplay: klikstoreQuickAuthDisplayInitial,
	klikstoreReviewPopup: klikstoreReviewPopupInitial,
};

export const appReducer = combineReducers({
	about,
	auth,
	loader,
	menu,
	footer,
	homepage,
	article,
	otp,
	profile,
	relationship,
	terms,
	qiscus,
	articleByGroup,
	categoryByGroup,
	klikstoreLandingPage,
	klikstoreFloatingChat,
	klikstoreQuickAuthDisplay,
	klikstoreReviewPopup,
	kalstoreFlag,
});

const reducers = (state: any, action: any) => {
	if (action.type === HYDRATE) {
		// persist user location when state hydration
		const cookieLocation = cookie?.load('user.location');
		let finalPayload = action.payload;

		if (cookieLocation) {
			finalPayload = {
				...finalPayload,
				auth: {
					...finalPayload.auth,
					userLocation: cookieLocation,
				},
			};
		}

		return {
			...state, // use previous state
			...finalPayload, // apply delta from hydration
		};
	}

	return appReducer(action.type === USER_TYPE.model.logout.success ? initialState : state, action);
};

export default reducers;
