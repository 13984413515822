import { REVIEWPOPUP_TYPE } from 'src/constants/redux';
import { STORE_DATA } from 'src/redux/actions/types';

export const actionSetReviewPopupDisplay = (payload: any = null) => {
	return {
		type: STORE_DATA,
		payload: {
			successType: REVIEWPOPUP_TYPE.model.reviewpopup.success,
			payload: payload,
		},
	};
};

export const actionSetReviewPopupData = (data: any = null) => {
	return {
		type: STORE_DATA,
		payload: {
			successType: REVIEWPOPUP_TYPE.model.reviewpopup.data,
			payload: { data: data },
		},
	};
};
