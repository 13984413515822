import { ARTICLE_TYPE } from 'src/constants/redux';
import { ARTICLES, CATEGORY_TOPIC_ARTICLE } from 'src/constants/services';
import { SINGLE_API } from 'src/redux/actions/types';

type Articles = {
	category: string;
	limit: string;
	page: string;
};

export const actionGetCategoryTopic = async (next?: (...f: any) => void) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `/${CATEGORY_TOPIC_ARTICLE.service}`,
			successType: ARTICLE_TYPE.model.category_topic.success,
			next,
		},
	};
};

export const actionGetArticles = async (payload: Articles) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `/${ARTICLES.service}/${ARTICLES.model.category}/${payload.category}?
			${ARTICLES.model.include}=${ARTICLES.service}&${ARTICLES.model.limit}=
			${payload.limit}&${ARTICLES.model.page}=${payload.page}`,
			successType: ARTICLE_TYPE.model.articles.success,
			errorType: ARTICLE_TYPE.model.articles.error,
		},
	};
};
