import { LOADER_TYPE } from 'src/constants/redux/loader';

export const initialState = {
	sending: false,
};

const reducer = (state = initialState, action: any = {}) => {
	switch (action.type) {
		case LOADER_TYPE.model.loading.toggle:
			return { sending: !state.sending };

		case LOADER_TYPE.model.loading.start:
			return { sending: true };

		case LOADER_TYPE.model.loading.stop:
			return { sending: false };

		default:
			return state;
	}
};

export default reducer;
