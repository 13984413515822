export const ARTICLE_TYPE = {
	model: {
		category_topic: {
			success: 'GET_CATEGORY_TOPIC_SUCCESS',
		},
		articles: {
			success: 'GET_ARTICLES_SUCCESS',
			error: 'GET_ARTICLES_ERROR',
		},
	},
};
