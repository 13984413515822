import { notification } from 'antd';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';

import firebaseConfig, { firebasePublicKey } from 'src/constants/firebase';

import { fetchApiV3User } from '../fetch-api';

export const firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

// Firebase Messaging
export const getFirebaseMessaging = () => getMessaging(firebaseApp);

export const getFirebaseMessagingToken = () => {
	return getToken(getFirebaseMessaging(), { vapidKey: firebasePublicKey });
};

export const saveFirebaseMessagingToken = () => {
	isSupported()
		.then(async () => {
			try {
				const token = await getFirebaseMessagingToken();
				if (token) {
					await fetchApiV3User({
						url: '/users/device',
						options: { method: 'POST' },
						payload: { fcm_token: token },
					});
				}
			} catch (err) {
				console.log(err);
			}
		})
		.catch((err) => {
			console.log(err);
		});
};

export const removeFirebaseMessagingToken = () => {
	isSupported()
		.then(async () => {
			try {
				const token = await getFirebaseMessagingToken();
				if (token) {
					await fetchApiV3User({
						url: '/users/device',
						options: { method: 'DELETE' },
						payload: { fcm_token: token },
					});
				}
			} catch (err) {
				console.log(err);
			}
		})
		.catch((err) => {
			console.log(err);
		});
};

export const listenFirebaseNotification = () => {
	isSupported()
		.then(async () => {
			onMessage(getFirebaseMessaging(), (payload: any) => {
				notification.open({
					message: payload.notification.title,
					description: payload.notification.body,
				});
			});
		})
		.catch((err) => {
			console.log(err);
		});
};
