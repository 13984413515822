import { useEffect, useRef, useState } from 'react';
import Geocode from 'react-geocode';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Button, Col, Form, Row, Space, Tag, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import classNames from 'classnames';

import Input from 'src/components/Forms/Input';
import Select from 'src/components/Forms/Select';
import Image from 'src/components/Image';
import messageC from 'src/components/Message';
import g from 'src/constants/global';
import {
	useCityList,
	useDistrictList,
	usePostalcodeList,
	useProvinceList,
	useSubdistrictList,
} from 'src/hooks';

import classes from './index.module.less';

type Props = {
	address: any;
	setAddress: (v: any) => void;
	nextStep: () => void;
	editStep?: () => void;
	selectedAddress?: any;
};

const AddressListForm = ({ address, setAddress, nextStep, editStep }: Props) => {
	const { xs } = useBreakpoint();

	const [form] = Form.useForm();

	const [showIcon, setShowIcon] = useState(true);
	const [label, setLabel] = useState('');

	const [provinceId, setProvinceId] = useState<number | undefined>(undefined);
	const [cityId, setCityId] = useState<number | undefined>(undefined);
	const [districtId, setDistrictId] = useState<number | undefined>(undefined);
	const [subdistrictId, setSubdistrictId] = useState<number | undefined>(undefined);

	const auth = useSelector((state: RootStateOrAny) => state.auth);

	const { full_name: name } = auth?.userLogged?.data?.record || {};
	const isEdit = !!address?.id;

	const addressRef = useRef(address);

	const lat = address?.latitude;
	const long = address?.longitude;
	const isLatLongError =
		(lat === 0 || lat === '' || lat === null) && (long === 0 || long === '' || long === null);

	const cityEnabled = provinceId !== undefined;
	const districtEnabled = cityId !== undefined;
	const subdistrictEnabled = districtId !== undefined;
	const postalcodeEnabled = subdistrictId !== undefined;

	const { data: dataProvince, isFetching: isFetchingProvince } = useProvinceList({});
	const provinceList = dataProvince?.data?.records;

	const { data: dataCity, isFetching: isFetchingCity } = useCityList({
		province_id: provinceId,
		enabled: cityEnabled,
	});
	const cityList = dataCity?.data?.records;

	const { data: dataDistrict, isFetching: isFetchingDistrict } = useDistrictList({
		city_id: cityId,
		enabled: districtEnabled,
	});
	const districtList = dataDistrict?.data?.records;

	const { data: dataSubdistrict, isFetching: isFetchingSubdistrict } = useSubdistrictList({
		district_id: districtId,
		enabled: subdistrictEnabled,
	});
	const subdistrictList = dataSubdistrict?.data?.records;

	const { data: dataPostalcode } = usePostalcodeList({
		subdistrict_id: subdistrictId,
		enabled: postalcodeEnabled,
	});
	const postalcodeList = dataPostalcode?.data?.records;

	const getLocationDescription = async (latitude: number, longitude: number) => {
		const { results } = await Geocode.fromLatLng(
			latitude?.toString(),
			longitude?.toString(),
			g.MAP.KEY,
			g.MAP.LANGUAGE,
			g.MAP.REGION,
		);

		if (latitude === addressRef.current.latitude && longitude === addressRef.current.longitude) {
			const detail: any = {
				latitude: latitude,
				longitude: longitude,
			};

			const secondResData = results.sort((a: any, b: any) => {
				const lengthA = a.address_components.length;
				const lengthB = b.address_components.length;

				if (lengthA > lengthB) {
					return -1;
				} else if (lengthA < lengthB) {
					return 1;
				} else {
					return 0;
				}
			});
			let kelurahan = '';
			let kecamatan = '';
			let city = '';
			let province = '';
			let postCode = '';

			const array = secondResData?.[0]?.address_components;
			array.map((item: any) => {
				if (item?.types.find((type: string) => type === g.MAP.TYPES.subdistrict)) {
					kelurahan = item.long_name;
				}
				if (item?.types.find((type: string) => type === g.MAP.TYPES.district)) {
					kecamatan = item.long_name;
				}
				if (item?.types.find((type: string) => type === g.MAP.TYPES.city)) {
					city = item.long_name;
				}
				if (item?.types.find((type: string) => type === g.MAP.TYPES.province)) {
					province = item.long_name;
				}
				if (item?.types.find((type: string) => type === g.MAP.TYPES.postal_code)) {
					postCode = item.long_name;
				}
			});

			const newLocationDescription = `${kelurahan}, ${kecamatan}, ${city}, ${province} ${postCode}`;
			if (newLocationDescription !== addressRef.current.location_description) {
				detail.location_description = newLocationDescription;

				setAddress({ ...addressRef.current, ...detail });
			}
		}
	};

	const getPostalcodeSimplified = (pc: string) => {
		let postalCode = pc;
		if (postalCode?.slice(-1)?.match(/[a-z]/i) !== null) {
			postalCode = postalCode?.substring(0, postalCode?.length - 1);
		}
		return postalCode;
	};

	useEffect(() => {
		if (JSON.stringify(addressRef.current) !== JSON.stringify(address)) {
			addressRef.current = address;
		}

		const isValidPhoneNumber = address?.phone?.substring(0, 2) === '62';

		if (isEdit) {
			form.setFieldsValue({
				label: address?.label,
				address: address?.address,
				notes: address?.notes,
				receiver_name: address?.receiver_name,
				phone: isValidPhoneNumber ? address?.phone?.substring(2) : address?.phone,
				province_id: address?.province_id,
				city_id: address.city_id,
				district_id: address?.district_id,
				subdistrict_id: address?.subdistrict_id,
				postal_code: address?.postal_code,
				postal_code_simplified: getPostalcodeSimplified(address?.postal_code),
			});

			if (!isLatLongError) {
				getLocationDescription(lat, long);
			}
		}
		if (form.getFieldValue('receiver_name') === undefined) {
			form.setFieldsValue({ receiver_name: name });
		}
		setLabel(address?.label);
	}, [address]);

	useEffect(() => {
		if (label?.length === 0) {
			setShowIcon(true);
		}
	}, [label]);

	useEffect(() => {
		const selectedProvince = provinceList?.find(
			({ province_id }: any) => province_id === form.getFieldValue('province_id'),
		);
		const selectedCity = cityList?.find(
			({ city_id }: any) => city_id === form.getFieldValue('city_id'),
		);
		const selectedDistrict = districtList?.find(
			({ district_id }: any) => district_id === form.getFieldValue('district_id'),
		);
		const selectedSubdistrict = subdistrictList?.find(
			({ subdistrict_id }: any) => subdistrict_id === form.getFieldValue('subdistrict_id'),
		);

		if (selectedProvince?.province_id !== provinceId) {
			setProvinceId(selectedProvince?.province_id);
		}
		if (selectedCity?.city_id !== cityId) {
			setCityId(selectedCity?.city_id);
		}
		if (selectedDistrict?.district_id !== districtId) {
			setDistrictId(selectedDistrict?.district_id);
		}
		if (selectedSubdistrict?.subdistrict_id !== subdistrictId) {
			setSubdistrictId(selectedSubdistrict?.subdistrict_id);
		}
	}, [address, provinceList, cityList, districtList, subdistrictList]);

	useEffect(() => {
		let postalCode = postalcodeList?.[0]?.zipcode;

		form.setFieldsValue({ postal_code: postalCode ?? null });
		form.setFieldsValue({ postal_code_simplified: getPostalcodeSimplified(postalCode) ?? null });
	}, [postalcodeList]);

	const onClickLabel = (title: string) => {
		form.setFieldsValue({ label: title });
		setLabel(title);
		setShowIcon(false);
	};

	const onChangePhone = (e: any) => {
		let phone = e.target.value.replace(/\D/g, '');
		if (phone.substring(0, 2) === '62') {
			phone = phone.substring(2);
		} else if (phone.charAt(0) === '0') {
			phone = phone.substring(1);
		}
		form.setFieldsValue({ phone: phone });
	};

	const onSelectProvinceChange = (province_id: any) => {
		setProvinceId(province_id);
		setCityId(undefined);
		setDistrictId(undefined);
		setSubdistrictId(undefined);

		form.setFieldsValue({
			city_id: null,
			district_id: null,
			subdistrict_id: null,
			postal_code: null,
			postal_code_simplified: null,
		});
	};

	const onSelectCityChange = (city_id: any) => {
		setCityId(city_id);
		setDistrictId(undefined);
		setSubdistrictId(undefined);

		form.setFieldsValue({
			district_id: null,
			subdistrict_id: null,
			postal_code: null,
			postal_code_simplified: null,
		});
	};

	const onSelectDistrictChange = (district_id: any) => {
		setDistrictId(district_id);
		setSubdistrictId(undefined);

		form.setFieldsValue({
			subdistrict_id: null,
			postal_code: null,
			postal_code_simplified: null,
		});
	};

	const onSelectSubdistrictChange = (subdistrict_id: any) => {
		setSubdistrictId(subdistrict_id);

		form.setFieldsValue({
			postal_code: null,
			postal_code_simplified: null,
		});
	};

	const onFinish = () => {
		if (isLatLongError) {
			messageC('Pinpoint wajib dipilih', 'error');
			return;
		}

		const kelurahan = subdistrictList?.find(
			({ subdistrict_id }: any) => subdistrict_id === subdistrictId,
		)?.subdistrict_name;
		const kecamatan = districtList?.find(({ district_id }: any) => district_id === districtId)
			?.district_name;
		const city = cityList?.find(({ city_id }: any) => city_id === cityId)?.city_name;
		const province = provinceList?.find(({ province_id }: any) => province_id === provinceId)
			?.province_name;

		let updatedAddress = { ...address, ...form.getFieldsValue() };
		updatedAddress = {
			...updatedAddress,
			kelurahan: kelurahan,
			kecamatan: kecamatan,
			city: city,
			province: province,
		};
		updatedAddress = { ...updatedAddress, district_id: updatedAddress.kecamatan };
		setAddress(updatedAddress);
		nextStep();
	};

	return (
		<div className={classes.form}>
			{isLatLongError ? (
				<div className={classes['pin-point']}>
					<Row gutter={[!xs ? 24 : 12, !xs ? 24 : 12]} align="top">
						<Col span={isEdit ? 5 : 4} className={classes.wrapper}>
							<div className={classes['wrapper-warning']}>
								<div className={classes.content}>
									<Image
										alt="Information"
										height={!xs ? 20 : 18}
										width={!xs ? 20 : 18}
										src="/icons/icon-warning-fill-24.svg"
									/>
									<Typography.Text className={classes.label}>Pin Point Kosong</Typography.Text>
								</div>
							</div>
						</Col>
						{isEdit && (
							<Col span={isEdit ? 19 : 20} className="m-auto">
								<Button
									className={classNames(
										'fs-14 fs-md-16 float-right',
										classes['select-pinpoint-btn'],
									)}
									type="primary"
									size="large"
									onClick={() => editStep && editStep()}
								>
									Pilih Pinpoint
								</Button>
							</Col>
						)}
					</Row>
				</div>
			) : (
				<div className={classes['pin-point']}>
					<Row gutter={[!xs ? 24 : 12, !xs ? 24 : 12]} align="top">
						<Col span={isEdit ? 5 : 4} className={classNames('d-flex', classes.wrapper)}>
							<Image alt="Information" height={86} width={86} src="/icons/pin-point-map.svg" />
						</Col>
						<Col xs={16} sm={isEdit ? 17 : 18} xl={isEdit ? 17 : 18}>
							<Typography.Text className={classes.location}>Pin Point Lokasi</Typography.Text>
							<Typography.Paragraph className={classes.sublocation} ellipsis={{ rows: 4 }}>
								{address?.location_description}
							</Typography.Paragraph>
						</Col>
						{isEdit && (
							<Col xs={3} sm={2} xl={2} className={classes.action}>
								<Button
									onClick={() => editStep && editStep()}
									icon={<Image alt="Edit" height={14} width={14} src="/icons/pencil.svg" />}
								/>
							</Col>
						)}
					</Row>
				</div>
			)}
			<Form
				autoComplete="off"
				form={form}
				layout="vertical"
				name="ant-form-custom"
				className={classes['form-custom']}
				onFinish={onFinish}
			>
				<div className={classes['form-wrapper']}>
					<Typography.Title level={4} className={classes['form-title']}>
						Lengkapi Detail Alamat
					</Typography.Title>
					<Input
						type="textarea"
						rows={1}
						showCount
						name="label"
						label="Label Alamat"
						placeholder="Contoh: Rumah, Kantor"
						onChange={(e: any) => setLabel(e.target.value)}
						useLabel
						maxLength={30}
						rules={[
							{
								required: true,
								message: 'Wajib diisi',
							},
						]}
						validateTrigger="onBlur"
					/>
					{showIcon && (
						<div className={classes['scroll-tag']}>
							<Space align="center" className={classes['tag-wrapper']}>
								{g.MENU.DROPDOWN.ADD_ADDRESS.map((item) => {
									return (
										<Tag.CheckableTag
											key={item.name}
											checked={label === item.name}
											onClick={() => onClickLabel(item.name)}
											className={classes.tag}
										>
											<Space align="start">
												<Image
													alt={item.name}
													height={16}
													width={16}
													src={item.icon}
													layout="fixed"
												/>
												<Typography.Text>{item.name}</Typography.Text>
											</Space>
										</Tag.CheckableTag>
									);
								})}
							</Space>
						</div>
					)}
					<Row gutter={[24, 0]}>
						<Col xs={24}>
							<Select
								name="province_id"
								label="Provinsi"
								placeholder="Pilih Provinsi"
								showSearch={true}
								disabled={isFetchingProvince}
								loading={isFetchingProvince}
								useLabel
								mode={undefined}
								rules={[
									{
										required: true,
										message: 'Wajib diisi',
									},
								]}
								options={provinceList?.map(({ province_id, province_name }: any) => ({
									value: province_id,
									label: province_name,
								}))}
								onChange={onSelectProvinceChange}
							/>
						</Col>

						<Col xs={24} md={12}>
							<Select
								name="city_id"
								label="Kota"
								placeholder="Pilih Kota"
								showSearch={true}
								disabled={!cityEnabled || isFetchingCity}
								loading={isFetchingCity}
								useLabel
								mode={undefined}
								rules={[
									{
										required: true,
										message: 'Wajib diisi',
									},
								]}
								options={
									!isFetchingCity
										? cityList?.map(({ city_id, city_name }: any) => ({
												value: city_id,
												label: city_name,
										  }))
										: []
								}
								onChange={onSelectCityChange}
							/>
						</Col>
						<Col xs={24} md={12}>
							<Select
								name="district_id"
								label="Kecamatan"
								placeholder="Pilih Kecamatan"
								showSearch={true}
								disabled={!districtEnabled || isFetchingDistrict}
								loading={isFetchingDistrict}
								useLabel
								mode={undefined}
								rules={[
									{
										required: true,
										message: 'Wajib diisi',
									},
								]}
								options={
									!isFetchingDistrict
										? districtList?.map(({ district_id, district_name }: any) => ({
												value: district_id,
												label: district_name,
										  }))
										: []
								}
								onChange={onSelectDistrictChange}
							/>
						</Col>
						<Col xs={24} md={12}>
							<Select
								name="subdistrict_id"
								label="Kelurahan"
								placeholder="Pilih Kelurahan"
								showSearch={true}
								disabled={!subdistrictEnabled || isFetchingSubdistrict}
								loading={isFetchingSubdistrict}
								useLabel
								mode={undefined}
								rules={[
									{
										required: true,
										message: 'Wajib diisi',
									},
								]}
								options={
									!isFetchingSubdistrict
										? subdistrictList?.map(({ subdistrict_id, subdistrict_name }: any) => ({
												value: subdistrict_id,
												label: subdistrict_name,
										  }))
										: []
								}
								onChange={onSelectSubdistrictChange}
							/>
						</Col>
						<Col xs={24} md={12}>
							<Input
								type="text"
								name="postal_code_simplified"
								label="Kode Pos"
								placeholder=" "
								useLabel
								rules={[
									{
										required: true,
										message: 'Wajib diisi',
									},
								]}
								validateTrigger="onBlur"
								disabled={true}
							/>
						</Col>
					</Row>
					<div style={{ display: 'none' }}>
						<Input name="postal_code" type="hidden" />
					</div>

					<Input
						type="textarea"
						rows={1}
						showCount
						name="address"
						label="Alamat Lengkap"
						placeholder="Tulis no. rumah, RT/RW, dll"
						useLabel
						maxLength={250}
						rules={[
							{
								required: true,
								message: 'Wajib diisi',
							},
							{
								min: 5,
								max: 250,
								message: 'Minimal 5 karakter',
							},
						]}
						validateTrigger="onBlur"
					/>
					<Input
						type="textarea"
						rows={1}
						showCount
						name="notes"
						label="Catatan (Opsional)"
						placeholder="Tulis Patokan, warna pagar, dll. (Optional)"
						useLabel
						maxLength={50}
					/>
					<Input
						type="textarea"
						rows={1}
						showCount
						name="receiver_name"
						label="Penerima"
						placeholder="Nama Penerima"
						useLabel
						maxLength={50}
						rules={[
							{
								required: true,
								message: 'Wajib diisi',
								validateTrigger: 'onBlur',
							},
							{
								pattern: new RegExp(/^[a-zA-Z\s]*$/),
								message: 'Hanya bisa diinput dengan Huruf',
							},
						]}
					/>
					<div className={classes['phone-wrapper']}>
						<Input
							type="tel"
							className={classes.phone}
							maxLength={14}
							name="phone"
							label="Nomor Ponsel*"
							placeholder="Contoh: 8786687768"
							useLabel
							addonBefore="+62"
							onChange={onChangePhone}
							rules={[
								{
									required: true,
									message: 'Wajib diisi',
								},
								{
									min: 9,
									max: 14,
									message: 'Silakan masukkan nomor telepon yang valid',
								},
							]}
							validateTrigger="onBlur"
						/>
					</div>
				</div>
				<Button type="primary" size="large" htmlType="submit">
					{isEdit ? 'Ubah Alamat' : 'Konfirmasi'}
				</Button>
			</Form>
		</div>
	);
};

export default AddressListForm;
