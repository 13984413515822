import { Col, Grid, Row, Typography } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import dayjs from 'src/utils/moment';

import classes from './CopyrightFooter.module.less';

const { Text } = Typography;

type Props = {
	isBackgroundWhite: boolean;
};

const CopyrightFooter = (props: Props) => {
	const { lg, xs } = Grid.useBreakpoint();

	const yearDisplay = dayjs().format('YYYY');

	return (
		<div
			className={classNames(classes['copyright-footer'], {
				'bg-white': props.isBackgroundWhite,
				'bg-footer': !props.isBackgroundWhite,
				[classes['copyright-footer-divider']]: props.isBackgroundWhite,
			})}
		>
			<div className={`${classes.copyright} container`}>
				<Row justify="space-between" align={xs ? 'top' : 'middle'}>
					<Col className={classes.kalbe}>
						<Text>Bagian dari:</Text>
						<ImageC
							width={xs ? 77 : 110}
							height={xs ? 35 : 50}
							src="/images/kalbe.svg"
							alt="Kalbe"
						/>
					</Col>
					{!xs && (
						<Col className={classes.copyright}>
							<div>
								<Text>Copyright @ {yearDisplay}</Text>
								{!lg && <br />} PT Medika Komunika Teknologi
							</div>
						</Col>
					)}
					<Col className={classes.kalbe}>
						<Text>Mitra Resmi dari:</Text>
						<ImageC
							width={xs ? 104 : 121}
							height={xs ? 35 : 50}
							src="/images/kemenkes.svg"
							alt="Kementerian Kesehatan Republik Indonesia"
						/>
					</Col>
					{xs && (
						<Col span={24} className={`${classes.copyright} mt-4`}>
							<div>
								<Text>Copyright @ {yearDisplay}</Text>
								{!lg && <br />} PT Medika Komunika Teknologi
							</div>
						</Col>
					)}
				</Row>
			</div>
		</div>
	);
};

export default CopyrightFooter;
