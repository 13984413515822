/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Grid } from 'antd';
import classNames from 'classnames';

import ButtonC from 'src/components/Forms/Button';
import ImageC from 'src/components/Image';
import EmptyC from 'src/components/Layout/Empty';

import classes from './index.module.less';

const AddressEmpty = (props: any) => {
	const { md } = Grid.useBreakpoint();

	return (
		<div className={classes['empty-address-wrap']}>
			<EmptyC
				className={classNames(classes['empty-address'], 'mt-4')}
				imageWidth={163}
				imageHeight={163}
				customHeaderEmpty="fs-22 fs-md-24"
				customParagraphEmpty="fs-12 fs-md-14"
				image="/images/empty/address-list.svg"
				title="Belum Ada Alamat"
				description={
					'Wah seperti kamu belum punya detail alamat apapun. ' +
					'Untuk mempermudah belanja produk kesehatan yuk tambahin alamat dulu.'
				}
				buttonText=""
				buttonHidden={true}
			/>
			<ButtonC
				className="mt-4 px-4"
				size="large"
				type="primary"
				text={
					<div className="d-flex align-items-center">
						<ImageC
							src="/icons/add-white.svg"
							alt="Icon Close"
							height={md ? 15 : 13}
							width={md ? 15 : 13}
							className={classes['close-btn']}
						/>
						<span className="ml-3 fs-14 fs-md-16">Tambah Alamat</span>
					</div>
				}
				onClick={props.handleCreateNewAddress}
			/>
		</div>
	);
};

export default AddressEmpty;
