import { useMemo } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';

import { platformEncoded } from 'src/constants/platform';
import {
	INSURANCE,
	INSURANCE_TRANSACTION,
	PUBLISHING_KD,
	USER_INSURANCE,
	VERIFY_INSURANCE,
} from 'src/constants/services';
import { Pagination, ResponseData } from 'src/interface/Commons';
import {
	BenefitHistory,
	CompanyInsurances,
	ConnectedInsurance,
	DeletedInsuranceResponse,
	DependentCard,
	Insurance,
	InsuranceBenefit,
	InsuranceCategory,
	InsuranceLetterConfirmation,
	InsuranceProduct,
	InsuranceStatus,
	InsuranceWithAvailability,
	PrincipalCard,
	VerifyError,
	VerifyUser,
	VerifyUserPayload,
} from 'src/interface/Insurance';
import authStorage from 'src/utils/auth-storage';
import { fetchApiV3Insurance, fetchApiV3Publishing } from 'src/utils/fetch-api';
import { isDependentCardType } from 'src/utils/func/insurance';
import dayjs from 'src/utils/moment';

export const companyInsurancesKey = ['companyInsurance'];
export const userInsurancesKey = () => ['userInsurances', authStorage.tokenDecode.id];
export const userInsurancesWithAvailabilityKey = () => [
	'userInsurancesWithAvailability',
	authStorage.tokenDecode.id,
];
export const connectedInsuranceKey = () => ['connectedInsurance', authStorage.tokenDecode.id];
export const insuranceStatusKey = () => ['insuranceStatus', authStorage.tokenDecode.id];
export const benefitHistoryKey = () => ['benefitHistory', authStorage.tokenDecode.id];
export const letterConfirmationKey = () => ['letterConfirmation', authStorage.tokenDecode.id];
export const insuranceCategoriesKey = ['insuranceCategories'];
export const insuranceProductsKey = ['insuranceProducts'];

export const useCompanyInsurances = () => {
	const { data, ...restAttributes } = useQuery(
		companyInsurancesKey,
		(): Promise<ResponseData<{ records: CompanyInsurances }, any>> =>
			fetchApiV3Insurance({
				url: `/${INSURANCE.service}?insurance_type=insurance`,
				options: { method: 'GET' },
			}),
		{
			retry: true,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);

	return {
		data: data?.data?.records,
		meta: data?.meta,
		...restAttributes,
	};
};

export const useVerifyUser = () => {
	const { data, error, ...restAttributes } = useMutation(
		(payload: VerifyUserPayload): Promise<ResponseData<{ record: VerifyUser }, any>> =>
			fetchApiV3Insurance({
				url: `/${VERIFY_INSURANCE.service}`,
				options: { method: 'POST' },
				payload: { ...payload },
			}),
	);

	const errorData = error as { error: VerifyError };

	return {
		data: data?.data?.record,
		meta: data?.meta,
		error: errorData?.error,
		...restAttributes,
	};
};

export const useConnectInsurance = () => {
	const queryClient = useQueryClient();
	const { data, error, ...restAttributes } = useMutation(
		(payload: VerifyUserPayload): Promise<ResponseData<{ record: ConnectedInsurance }, any>> =>
			fetchApiV3Insurance({
				url: `/${USER_INSURANCE.service}`,
				options: { method: 'POST' },
				payload: { ...payload },
			}),
		{
			onSuccess: (successData) => {
				queryClient.setQueryData(connectedInsuranceKey(), () => successData.data.record);
			},
			onSettled: () => {
				queryClient.refetchQueries(insuranceStatusKey());
				queryClient.refetchQueries(userInsurancesKey());
			},
		},
	);
	const errorData = error as { error: VerifyError };

	return {
		data: data?.data?.record,
		meta: data?.meta,
		error: errorData?.error,
		...restAttributes,
	};
};

export const useConnectedData = () => {
	const queryClient = useQueryClient();
	return queryClient.getQueryData<ConnectedInsurance>(connectedInsuranceKey());
};

export const useInsuranceList = (enabled = true) => {
	const { data, ...restAttributes } = useQuery(
		userInsurancesKey(),
		(): Promise<ResponseData<{ records: Insurance[] }, any>> =>
			fetchApiV3Insurance({
				url: `/${USER_INSURANCE.service}`,
				options: { method: 'GET' },
			}),
		{
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			enabled,
		},
	);

	return {
		data: data?.data?.records,
		meta: data?.meta,
		...restAttributes,
	};
};

export const useInsuranceAvailabilityList = (enabled = true, doctorId?: string) => {
	const { data, ...restAttributes } = useQuery(
		userInsurancesWithAvailabilityKey(),
		(): Promise<ResponseData<{ records: InsuranceWithAvailability[] }, any>> =>
			fetchApiV3Insurance({
				url: `/${USER_INSURANCE.service}`,
				payload: {
					doctor_id: doctorId,
					show_availability: true,
				},
				options: { method: 'GET' },
			}),
		{
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			enabled,
		},
	);

	return {
		data: data?.data?.records,
		meta: data?.meta,
		...restAttributes,
	};
};

export const useDeleteInsurance = () => {
	const queryClient = useQueryClient();
	const { data, ...restAttributes } = useMutation(
		(payload: {
			uid: string;
			pin: string;
		}): Promise<ResponseData<{ record: DeletedInsuranceResponse }, any>> =>
			fetchApiV3Insurance({
				url: `/${USER_INSURANCE.service}/${payload.uid}`,
				options: { method: 'DELETE' },
				payload: {
					pin: payload.pin,
				},
			}),
		{
			onSettled: () => {
				queryClient.refetchQueries(insuranceStatusKey());
				queryClient.refetchQueries(userInsurancesKey());
				queryClient.refetchQueries(userInsurancesWithAvailabilityKey());
			},
		},
	);

	return {
		data: data?.data?.record,
		meta: data?.meta,
		...restAttributes,
	};
};

type UseBenefitsProps = {
	principalUID: string;
	selectedCard: PrincipalCard | DependentCard;
};

type UseLetterConfirmationProps = {
	principalUID: string;
	orderID: string;
};

const getCardTypePayload = ({ principalUID, selectedCard }: UseBenefitsProps) => {
	const isDependentCard =
		isDependentCardType(selectedCard) && principalUID !== selectedCard.user_member_insurance_uid;

	return isDependentCard
		? {
				member_type: 'dependent',
				user_member_insurance_uid:
					isDependentCardType(selectedCard) && selectedCard.user_member_insurance_uid,
		  }
		: undefined;
};

export const useBenefits = ({ principalUID, selectedCard }: UseBenefitsProps) => {
	const payload = getCardTypePayload({ principalUID, selectedCard });
	const { data, ...restAttributes } = useQuery(
		[
			'insuranceBenefits',
			isDependentCardType(selectedCard) ? selectedCard.user_member_insurance_uid : principalUID,
		],
		(): Promise<ResponseData<{ records: InsuranceBenefit[] }, any>> =>
			fetchApiV3Insurance({
				url: `/${USER_INSURANCE.service}/${principalUID}/${USER_INSURANCE.model.benefits}`,
				options: { method: 'GET' },
				payload,
			}),
		{
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			enabled: !!principalUID,
			// set to 5 minutes
			cacheTime: 5 * 60 * 1000,
		},
	);

	return {
		data: data?.data?.records,
		meta: data?.meta,
		...restAttributes,
	};
};

export const useLetterConfirmation = (
	{ principalUID, orderID }: UseLetterConfirmationProps,
	enabled?: boolean,
) => {
	const { data, ...restAttributes } = useQuery(
		[letterConfirmationKey(), principalUID, orderID],
		(): Promise<ResponseData<{ record: InsuranceLetterConfirmation }, any>> =>
			fetchApiV3Insurance({
				url: `/${USER_INSURANCE.service}/${principalUID}/
				${USER_INSURANCE.model.letterConfirmations}/${orderID}`,
				options: { method: 'GET' },
			}),
		{
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			enabled: !!principalUID && enabled,
		},
	);

	return {
		data: data?.data?.record,
		meta: data?.meta,
		...restAttributes,
	};
};

export const useInsuranceStatus = (enabled = true) => {
	const { data, ...restAttributes } = useQuery(
		insuranceStatusKey(),
		(): Promise<ResponseData<{ record: InsuranceStatus }, any>> =>
			fetchApiV3Insurance({
				url: `/${USER_INSURANCE.service}/${USER_INSURANCE.model.status}`,
				options: { method: 'GET' },
			}),
		{
			keepPreviousData: true,
			retry: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			enabled,
		},
	);

	return {
		data: data?.data?.record,
		meta: data?.meta,
		isConnected: data?.data?.record?.status === 'connected',
		...restAttributes,
	};
};

export const useInsuranceInActive = (expiredDate?: string) =>
	useMemo(() => dayjs(expiredDate).diff(dayjs()) < 0, [expiredDate]);

export const useBenefitHistory = ({ principalUID, selectedCard }: UseBenefitsProps) => {
	const payload = getCardTypePayload({ principalUID, selectedCard });
	const { data, ...restAttributes } = useInfiniteQuery(
		[
			...benefitHistoryKey(),
			isDependentCardType(selectedCard) ? selectedCard.user_member_insurance_uid : principalUID,
		],
		({ pageParam = 1 }): Promise<ResponseData<{ records: BenefitHistory[] }, Pagination>> =>
			fetchApiV3Insurance({
				url: `/${USER_INSURANCE.service}/${principalUID}/${USER_INSURANCE.model.claims}`,
				options: { method: 'GET' },
				payload: { ...(payload || {}), page: pageParam, limit: 6 },
			}),
		{
			refetchOnWindowFocus: false,
			enabled: !!principalUID,
			getNextPageParam: (res) => {
				const pagination = res.meta.pagination;
				return pagination.page < pagination.total_page ? pagination.page + 1 : undefined;
			},
		},
	);

	const flattenedData = data?.pages?.flatMap?.((v) => v.data.records);

	return {
		data: flattenedData?.reduce?.<{
			[key: string]: BenefitHistory[];
		}>((acc, curr) => {
			const key = curr.transaction_date;
			return {
				...acc,
				[key]: [...(acc[key] || []), curr],
			};
		}, {}),
		dataLength: flattenedData?.length,
		...restAttributes,
	};
};
export const useLOADetails = (transId?: string) => {
	const { data, ...restAttributes } = useQuery(
		['LOADetails', transId],
		(): Promise<ResponseData<{ record: any }, any>> =>
			fetchApiV3Insurance({
				url: `/${INSURANCE_TRANSACTION.service}?module_transaction_id=${transId}`,
				options: { method: 'GET' },
			}),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!transId,
		},
	);

	return {
		data: data?.data?.record,
		meta: data?.meta,
		...restAttributes,
	};
};

export const useInsuranceCategories = () => {
	const { data, ...restAttributes } = useInfiniteQuery(
		insuranceCategoriesKey,
		({ pageParam = 1 }): Promise<ResponseData<{ records: InsuranceCategory[] }, Pagination>> =>
			fetchApiV3Publishing({
				url: `/${PUBLISHING_KD.service}/${PUBLISHING_KD.model.categoryInsurance}`,
				options: {
					method: 'GET',
					headers: {
						'x-api-platform': platformEncoded,
					},
				},
				payload: {
					page: pageParam,
					per_page: 8,
				},
			}),
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (res) => {
				const pagination = res.meta.pagination;
				return pagination.page < pagination.total_page ? pagination.page + 1 : undefined;
			},
		},
	);

	const flattenedData = data?.pages?.flatMap?.((v) => v.data.records);

	return {
		data: flattenedData,
		dataLength: flattenedData?.length,
		...restAttributes,
	};
};

export const useInsuranceProducts = (slug?: string) => {
	const { data, ...restAttributes } = useInfiniteQuery(
		[...insuranceProductsKey, slug],
		({ pageParam = 1 }): Promise<ResponseData<{ records: InsuranceProduct[] }, Pagination>> =>
			fetchApiV3Publishing({
				url: `/${PUBLISHING_KD.service}/${PUBLISHING_KD.model.productInsurance}/${slug}`,
				options: {
					method: 'GET',
					headers: {
						'x-api-platform': platformEncoded,
					},
				},
				payload: {
					page: pageParam,
					per_page: 4,
				},
			}),
		{
			enabled: !!slug,
			refetchOnWindowFocus: false,
			getNextPageParam: (res) => {
				const pagination = res.meta.pagination;
				return pagination.page < pagination.total_page ? pagination.page + 1 : undefined;
			},
		},
	);

	const flattenedData = data?.pages?.flatMap?.((v) => v.data.records);

	return {
		data: flattenedData,
		dataLength: flattenedData?.length,
		...restAttributes,
	};
};
