import { Button, Divider, Space, Typography } from 'antd';

import { useAddressListsCreate, useAddressListsUpdate } from 'src/hooks';
import { textAddon } from 'src/utils/func/format-string';

import classes from './index.module.less';

type Props = {
	address: any;
	nextStep: () => void;
};

const AddressListComfirmation = ({ address, nextStep }: Props) => {
	const { mutate: create, isLoading: isLoadingCreate } = useAddressListsCreate();
	const { mutate: update, isLoading: isLoadingUpdate } = useAddressListsUpdate();

	const handleConfirm = () => {
		const phone = textAddon(address.phone, '', '62', '');
		const payload = { ...address, phone: phone };

		if (payload?.id) {
			update(payload, {
				onSuccess: () => {
					nextStep();
				},
			});
		} else {
			create(payload, {
				onSuccess: () => {
					nextStep();
				},
			});
		}
	};

	return (
		<div className={classes.comfirmation}>
			<Space direction="vertical" className="w-100 pr-sm-5 pr-0">
				<Typography.Text className={classes.title}>Pin Point Lokasi</Typography.Text>
				<Typography.Paragraph className={classes.subtitle}>
					{address.location_description}
				</Typography.Paragraph>
			</Space>
			<Divider className={classes.divider} />
			<Space direction="vertical" className="w-100">
				<Typography.Text className={classes.title}>Label Alamat</Typography.Text>
				<Typography.Paragraph className={classes.subtitle}>{address.label}</Typography.Paragraph>
			</Space>
			<Space direction="vertical" className="w-100">
				<Typography.Text className={classes.title}>Alamat Lengkap</Typography.Text>
				<Typography.Paragraph className={classes.subtitle}>
					{address.address}
					<br />
					{
						// eslint-disable-next-line max-len
						`${address.kelurahan}, ${address.kecamatan}, ${address.city}, ${address.province} ${address.postal_code}`
					}
				</Typography.Paragraph>
			</Space>
			<Space direction="vertical" className="w-100">
				<Typography.Text className={classes.title}>Catatan</Typography.Text>
				<Typography.Paragraph className={classes.subtitle}>
					{address.notes || '-'}
				</Typography.Paragraph>
			</Space>
			<Divider className={classes.divider} />
			<Space direction="vertical" className="w-100">
				<Typography.Text className={classes.title}>Penerima</Typography.Text>
				<Typography.Paragraph className={classes.subtitle}>
					{address.receiver_name}
				</Typography.Paragraph>
			</Space>
			<Space direction="vertical" className="w-100">
				<Typography.Text className={classes.title}>No Ponsel</Typography.Text>
				<Typography.Paragraph className={classes.subtitle}>
					{textAddon(address.phone, '', '+62', '')}
				</Typography.Paragraph>
			</Space>
			<Button
				type="primary"
				size="large"
				loading={isLoadingCreate || isLoadingUpdate}
				onClick={() => handleConfirm()}
			>
				Simpan
			</Button>
		</div>
	);
};

export default AddressListComfirmation;
