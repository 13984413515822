import { useQuery } from 'react-query';

import { POLICY } from 'src/constants/services';
import { fetchApiPublishing } from 'src/utils/fetch-api';

const query = 'privacy-policy';

export const usePrivacyPolicy = () => {
	return useQuery(query, () => fetchApiPublishing({ url: `/${POLICY.service}` }), {
		keepPreviousData: true,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
};
