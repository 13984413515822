import CryptoJS from 'crypto-js';
import platform from 'platform';

import { getCurrentTimeZone } from 'src/utils/moment';

import g from './urls';

const platformConfig = {
	app_version: g.WEB_VERSION,
	platform: g.API_PLATFORM,
	manufacturer: platform?.manufacturer ?? platform?.layout,
	product: platform?.product ?? platform?.name,
	description: platform?.description,
	timezone: getCurrentTimeZone(),
};
export const platformEncoded = CryptoJS.enc.Base64.stringify(
	CryptoJS.enc.Utf8.parse(JSON.stringify(platformConfig)),
);

export const platformRaw = platformConfig;
