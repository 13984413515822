import { RootStateOrAny, useSelector } from 'react-redux';
import Router from 'next/router';
import { Badge, Card, Col, Dropdown, Grid, Row, Space, Typography } from 'antd';

import AvatarC from 'src/components/Avatar';
import Image from 'src/components/Image';

import classes from './UserAvatarMenu.module.less';

const UserAvatarMenuItem = () => {
	const { xs } = Grid.useBreakpoint();
	const auth = useSelector((state: RootStateOrAny) => state.auth);
	const {
		first_name: name,
		level_name: userLevel,
		icon: iconLevel,
	} = auth?.user?.data?.record || {};

	return (
		<div className={classes['user-avatar-menu-item']}>
			<Row>
				<Col className={classes.wrapper}>
					<Card className={classes['profile-card']} onClick={() => Router.push('/profil')}>
						<Row justify="space-between" align={xs ? 'top' : 'middle'} wrap={false}>
							<Col>
								<Typography.Title level={4} className={classes.title}>
									Hi, {name || ''}
								</Typography.Title>
								<Space align="center" size="small">
									<Image width={20} height={20} alt="status" src={iconLevel} layout="fixed" />
									<Typography.Paragraph className={classes.status}>
										{userLevel || '-'}
									</Typography.Paragraph>
								</Space>
							</Col>
						</Row>
					</Card>
				</Col>
				{/* TODO: hide for launching */}
				{/*<Col className={classes.wrapperspace}>
					<Divider type="vertical" className={classes.divider} />
				</Col>
				<Col className={classes.wrapper}>
					<Card className={classes['merchant-card']}>
						<Row gutter={xs ? 4 : 8} wrap={false}>
							<Col>
								<Image
									width={xs ? 16 : 24}
									height={xs ? 16 : 24}
									alt="merchant"
									src="/icons/shop-gray.svg"
									layout="fixed"
								/>
							</Col>
							<Col>
								<Typography.Paragraph className={classes.title}>
									Daftar Merchant
								</Typography.Paragraph>
								<Typography.Paragraph className={classes.subtitle}>
									Buka Merchant
								</Typography.Paragraph>
							</Col>
						</Row>
					</Card>
				</Col>*/}
			</Row>
		</div>
	);
};

const UserAvatarMenu = () => {
	const { xs } = Grid.useBreakpoint();
	const auth = useSelector((state: RootStateOrAny) => state.auth);
	const { avatar, first_name: name } = auth?.userLogged?.data?.record || {};

	return (
		<div className={classes['user-avatar-menu']}>
			<Dropdown
				overlayClassName={classes['user-avatar-dropdown']}
				overlay={UserAvatarMenuItem()}
				trigger={['click']}
				placement="bottomRight"
				arrow
				getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
			>
				<Badge className={classes.badge} offset={[-2, 2]}>
					<AvatarC className={classes.avatar} username={name} src={avatar} size={xs ? 20 : 24} />
				</Badge>
			</Dropdown>
		</div>
	);
};

export default UserAvatarMenu;
