import { useRouter } from 'next/router';
import { MessageOutlined } from '@ant-design/icons';
import { Button, Grid } from 'antd';

import classes from './index.module.less';

const FloatingChat = (props: any) => {
	const { md } = Grid.useBreakpoint();
	const r = useRouter();

	return (
		<>
			<Button
				onClick={() => {
					if (md) {
						props.setVisibleFloatingChat(true);
						setTimeout(() => {
							document.body.style.removeProperty('overflow');
						}, 0);
					} else {
						r.push('/kalstore/tanya-penjual');
					}
				}}
				icon={<MessageOutlined color="#1890ff" />}
				className={classes['float-chat-btn']}
			>
				Chat
			</Button>
		</>
	);
};

export default FloatingChat;
