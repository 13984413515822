const deviceSize = ({ lg, md, sm, xs }: any) => {
	let DS = 'desktop';

	if (xs) {
		DS = 'mobile';
	} else if ((!lg && md) || (!lg && sm)) {
		DS = 'tablet';
	}

	return DS;
};

export default deviceSize;
