import { USER_TYPE } from 'src/constants/redux';
import { USER } from 'src/constants/services';
import { SINGLE_API } from 'src/redux/actions/types';

export const actionVerifyRelationship = async (verification_uid: any) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${USER.model.verifyRelationship}/${verification_uid}`,
			successType: USER_TYPE.model.verifyRalationship.success,
			errorType: USER_TYPE.model.verifyRalationship.error,
		},
	};
};
