import { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Router from 'next/router';
import { Form, Typography } from 'antd';

import { ButtonOK } from 'src/components/Forms/Button';
import Input from 'src/components/Forms/Input';
import Image from 'src/components/Image';
import g from 'src/constants/global';
import { AuthProps } from 'src/interface/Auth';
import { actionUserCheck, actionUserCheckModify } from 'src/redux/actions/auth';
import { actionOtpValidUser } from 'src/redux/actions/otp';
import { timeToText } from 'src/utils/func/format-time';

import classes from './index.module.less';

const { Text, Title } = Typography;

const AuthC = (props: AuthProps) => {
	const dispatch = useDispatch();
	const { auth, loader, otp } = useSelector((state: RootStateOrAny) => state);

	const [form] = Form.useForm();
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	useEffect(() => {
		if (auth.userCheck && props.type === g.AUTH.LOGIN.label) {
			if (auth.userCheck?.status === 200) {
				if (auth.userCheck?.data?.record?.action === g.AUTH.LOGIN.label) {
					props.setAuthStep?.(g.AUTH.LOGIN.pin);
				} else {
					props.setAuthStep?.(g.AUTH.REGISTER.otp);
				}
			} else {
				form.setFields([
					{
						name: 'phoneNumber',
						errors: [
							auth.userCheck?.meta?.message.replace(
								'{{time_release_in_second}}',
								timeToText(auth.userCheck?.data?.record?.time_release_in_second),
							),
						],
					},
				]);
			}
		}
	}, [auth.userCheck]);

	useEffect(() => {
		if (otp.otpValidUser && props.type === g.AUTH.FORGOT.label) {
			if (otp.otpValidUser?.status === 200) {
				props.setAuthStep?.(g.AUTH.FORGOT.otp);
			} else {
				form.setFields([
					{
						name: 'phoneNumber',
						errors: [
							otp.otpValidUser?.meta?.message.replace(
								'{{time_release_in_second}}',
								timeToText(otp.otpValidUser?.data?.record?.time_release_in_second),
							),
						],
					},
				]);
			}
		}
	}, [otp.otpValidUser]);

	const handleBack = async () => {
		dispatch(await actionUserCheckModify(null));
		return Router.push('/signin');
	};

	const handlePhone = (e: any) => {
		let phone = e.target.value.replace(/\D/g, '');

		if (phone.substring(0, 2) === '62') {
			phone = phone.substring(2);
		} else if (phone.charAt(0) === '0') {
			phone = phone.substring(1);
		}

		setPhoneNumber('62' + phone);
		form.setFieldsValue({ phoneNumber: phone });
	};

	const handleSubmit = async () => {
		props.setAuthBody?.({ ...props.authBody, phoneNumber });
		dispatch(
			props.type === g.AUTH.FORGOT.label
				? await actionOtpValidUser({ phone: phoneNumber })
				: await actionUserCheck({ phone: phoneNumber }),
		);
	};

	return (
		<div className={`${classes.container} d-flex align-items-center`}>
			<div className={classes.content}>
				{props.type === g.AUTH.FORGOT.label && (
					<div className={classes['phone-button-back']} onClick={handleBack}>
						<Image alt="Icon Arrow Left" height={32} width={32} src="/icons/arrow-left.svg" />
					</div>
				)}
				<div className={classes['phone-top']}>
					<Title level={3}>
						{props.type === g.AUTH.FORGOT.label ? 'Atur Ulang PIN Saya' : 'Masuk atau Daftar'}
					</Title>
					<Text className={classes.description}>
						{props.type === g.AUTH.FORGOT.label
							? 'Kirimkan kode verifikasi ke nomor saya'
							: 'Cukup masukkan nomor ponsel dan nikmati semua fitur KlikDokter'}
					</Text>
				</div>
				<div className={classes['phone-main']}>
					<Form
						autoComplete="off"
						form={form}
						layout="vertical"
						name="auth-form"
						onFinish={handleSubmit}
					>
						<Input
							addonBefore="+62"
							label="Nomor Ponsel"
							maxLength={14}
							name="phoneNumber"
							onChange={handlePhone}
							placeholder="cth: 81234567890"
							rules={[
								{
									required: true,
									min: 9,
									max: 14,
									message: 'Silakan masukkan nomor telepon yang valid',
								},
							]}
							type="tel"
							useLabel
							validateTrigger="onBlur"
						/>
						<Form.Item>
							<ButtonOK
								block
								htmlType="submit"
								loading={loader.sending}
								text={props.type === g.AUTH.FORGOT.label ? 'Kirim Kode' : 'Lanjut'}
							/>
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default AuthC;
