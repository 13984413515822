import { isBrowser } from 'src/constants/environment';

import AuthStorage, { idTokenStorage, refreshTokenStorage } from './auth-storage';

export const forceLogout = () => {
	AuthStorage.destroy();
	refreshTokenStorage.destroy();
	idTokenStorage.destroy();

	if (isBrowser) {
		console.trace();
	}
};
