import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PaginationProps } from 'antd';

import messageC from 'src/components/Message';
import { IAddToCartBodyPayload } from 'src/interface';
import { fetchApiMarketplace } from 'src/utils/fetch-api';
import { removeEmptyParams } from 'src/utils/func';

import { getQuoteCode, klikStoreCart } from '../Payment';
import { productsFields } from '../Products';

export const masterProductDetailQueryKey = 'master_product_detail';
export const merchantProductListQueryKey = 'merchantProductListQueryKey';
export const merchantProductsFilter = 'merchantProductsFilter';
export const productDetailQueryKey = 'product_detail';
export const productDetailRatingQueryKey = 'product_detail_rating';
export const storeProductInfoQueryKey = 'store_product_info';
export const productRecommendationQueryKey = 'product_recommendation';
export const checkWishlistStatusQueryKey = 'check_wishlist_status';
export const listKlikstorePublicProductRatingQueryKey = 'list_klikstore_public_product_rating';
export const summaryKlikstorePublicProductRatingQueryKey =
	'summary_klikstore_public_product_rating';
export const overallKlikstorePublicProductRatingQueryKey =
	'overall_klikstore_public_product_rating';

export interface IKlikstoreProductRecommendation {
	pagination: PaginationProps;
	categoryUid: string;
	categoryLevel: string;
	storeUid: string;
}

export interface IKlikstoreProductCheckWishlist {
	item_uid: string;
}

export interface IKlikstoreProductRating {
	product_slug: string;
	page?: number;
	limit?: number;
	enabled?: boolean;
}

export interface IKlikstoreProductRatingSummary {
	product_slug: string;
	enabled?: boolean;
}

export const useKlikstoreMasterProductDetail = (slug: any) => {
	return useQuery(
		[masterProductDetailQueryKey, slug],
		() => {
			return fetchApiMarketplace({
				url: `/product_detail/${slug}`,
				options: { method: 'GET' },
				payload: {
					fields: productsFields,
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!slug },
	);
};

export const useKlikstoreMerchantProductList = ({ enabled = true, ...props }: any) => {
	const cleanedProps = removeEmptyParams(props);
	return useQuery(
		[merchantProductListQueryKey, props],
		() => {
			const sort = props.sort || 'relevance';
			const dir = props.dir || 'desc';

			return fetchApiMarketplace({
				url: `/product_detail/${props.slug}/merchants`,
				options: { method: 'GET' },
				payload: {
					...cleanedProps,
					sort: sort,
					dir: dir,
					fields: productsFields,
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!props.slug && enabled },
	);
};

export const useKlikStoreMerchantProductsFilter = ({ enabled = true, ...props }: any) => {
	const cleanedProps = removeEmptyParams(props);
	return useQuery(
		[merchantProductsFilter, cleanedProps],
		() => {
			return fetchApiMarketplace({
				url: `/product_detail/${props.slug}/merchants`,
				options: { method: 'GET' },
				payload: {
					...cleanedProps,
					aggs: true,
					limit: 0,
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!props.slug && enabled },
	);
};

export const useKlikStoreRenderMerchantProductsFilterData = (filtersAgg: any) => {
	// Location
	const filterCity = filtersAgg?.map(({ city_id, city }: any) => ({
		key: city_id.toString(),
		label: city,
	}));

	// PriceRange
	const filterPriceAgg = filtersAgg?.price_ranges;
	let filterPrice: any[] = [];
	for (const priceRangeCode in filterPriceAgg) {
		const item = filterPriceAgg[priceRangeCode];
		if (item.doc_count > 0) {
			filterPrice.push({
				key: priceRangeCode.toString(),
				label: `Rp ${item.from || 0} ${item.to ? '- Rp ' + item.to : 'ke atas'}`,
			});
		}
	}

	return {
		location: filterCity,
		price: filterPrice,
	};
};

export const useKlikstoreProductDetail = (props: any) => {
	return useQuery(
		[productDetailQueryKey, props],
		() => {
			const { slug, merchant_slug: merchantSlug } = props;
			if (slug) {
				return fetchApiMarketplace({
					url: `/product_detail/${slug}/merchant/${merchantSlug}`,
					options: { method: 'GET' },
					payload: {
						fields: productsFields,
					},
				});
			}
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!props.slug && !!props.merchant_slug,
		},
	);
};

export const useKlikstoreProductAddToCart = () => {
	const queryClient = useQueryClient();

	const addToCart = async (payload: IAddToCartBodyPayload) => {
		const quoteCode = await getQuoteCode();

		const data = {
			orderQuoteMerchants: [
				{
					merchant_id: payload?.merchant_id,
					orderQuoteItems: [
						{
							merchant_sku: payload?.merchant_sku,
							item_notes: payload?.item_notes ?? '',
							quantity: payload?.quantity,
							flag: 'addition',
							is_kliknow: payload?.is_kliknow,
						},
					],
				},
			],
		};

		if (quoteCode === '') {
			return fetchApiMarketplace({
				url: '/quote',
				options: { method: 'POST' },
				payload: data,
			});
		} else {
			return fetchApiMarketplace({
				url: `/quote/${quoteCode}`,
				options: { method: 'PUT' },
				payload: data,
			});
		}
	};
	return useMutation(
		(payload: IAddToCartBodyPayload) => {
			return addToCart(payload);
		},
		{
			onSettled: () => {
				queryClient.invalidateQueries([productDetailQueryKey]);
				queryClient.invalidateQueries([klikStoreCart]);
			},
			onError: (err: any) => {
				messageC(err?.meta?.message || err?.message || 'Terjadi kesalahan sistem.', 'error');
			},
		},
	);
};

export const useKlikstoreProductRatingList = ({
	product_slug,
	enabled = true,
	...props
}: IKlikstoreProductRating) => {
	const cleanedProps = removeEmptyParams(props);
	return useQuery(
		[listKlikstorePublicProductRatingQueryKey, props],
		() =>
			fetchApiMarketplace({
				url: `/merchant/product_review/popup/${product_slug}`,
				options: { method: 'GET' },
				payload: {
					...cleanedProps,
				},
			}),
		{
			enabled: enabled,
			refetchOnWindowFocus: false,
		},
	);
};

export const useKlikstoreProductRatingSummary = ({
	enabled = true,
	...props
}: IKlikstoreProductRatingSummary) => {
	return useQuery(
		[summaryKlikstorePublicProductRatingQueryKey, props],
		() =>
			fetchApiMarketplace({
				url: `/search/merchant-product-rating-summary/detail/${props.product_slug}`,
				payload: {
					...props,
					fields:
						'id,total_ulasan,average_score,max_score,' +
						'product_rating,product_slug,merchant_slug,' +
						'merchant_uid,merchant_id,product_id',
				},
			}),
		{
			enabled: enabled,
			refetchOnWindowFocus: false,
		},
	);
};
