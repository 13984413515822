import { Typography } from 'antd';
import classNames from 'classnames';

import { TitlePropsC } from 'src/interface';

import classes from './index.module.less';

export const Title = (props: TitlePropsC) => {
	return (
		<Typography.Title {...props}>
			<div
				className={classNames(`${classes.title} text-neutral-3`, {
					[props.className as string]: props.className,
				})}
			>
				{props.children || props.text}
			</div>
		</Typography.Title>
	);
};
