import { PROFILE_TYPE, USER_TYPE } from 'src/constants/redux';

export const initialState = {
	profileMenu: {
		contentSelected: true,
		userSelected: null,
	},
	pointReward: null,
	membershipInfo: null,
	membershipList: null,
	membershipPointCard: null,
	userSelected: null,
};

const reducer = (state = initialState, action: any = {}) => {
	switch (action.type) {
		case PROFILE_TYPE.model.menu.success:
		case PROFILE_TYPE.model.menu.error:
			return Object.assign({}, state, {
				profileMenu: action.payload,
			});

		case PROFILE_TYPE.model.userSelected.success:
		case PROFILE_TYPE.model.userSelected.error:
			return Object.assign({}, state, {
				userSelected: action.payload,
			});

		case USER_TYPE.model.logout.success:
		case USER_TYPE.model.reset.success:
			return Object.assign({}, state, initialState);

		case USER_TYPE.model.profile.error:
			return Object.assign({}, state, initialState);

		case PROFILE_TYPE.model.pointReward.success:
		case PROFILE_TYPE.model.pointReward.error:
			return Object.assign({}, state, {
				pointReward: action.payload,
			});
		case PROFILE_TYPE.model.membershipInfo.success:
		case PROFILE_TYPE.model.membershipInfo.error:
			return Object.assign({}, state, {
				membershipInfo: action.payload,
			});
		case PROFILE_TYPE.model.membershipList.success:
		case PROFILE_TYPE.model.membershipList.error:
			return Object.assign({}, state, {
				membershipList: action.payload,
			});
		case PROFILE_TYPE.model.membershipPoint.success:
		case PROFILE_TYPE.model.membershipPoint.error:
			return Object.assign({}, state, {
				membershipPointCard: action.payload,
			});

		default:
			return state;
	}
};

export default reducer;
