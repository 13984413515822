import { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useCookie } from 'react-use';
import Link from 'next/link';
import { Col, Form, Input, Row, Typography } from 'antd';
import usePlacesAutocomplete from 'use-places-autocomplete';

import LocationSliderC from 'src/components/Carousel/LocationSlider';
import ImageC from 'src/components/Image';
import ModalC from 'src/components/Modal';
// eslint-disable-next-line max-len
import AddressListPinPoint from 'src/components/Modal/Address/Update/AddressListPinPoint';
import ModalHeader from 'src/components/Modal/ModalHeader';
import g from 'src/constants/global';
import { Address, UserLocation } from 'src/interface';
import { actionUserLocationModify } from 'src/redux/actions';
import AuthStorage, { maxAgeDay } from 'src/utils/auth-storage';

import GeoLocationC from './GeoLocation';
import SearchSuggestionC from './SearchSuggestion';

import classes from './index.module.less';

interface LocationModalCProps {
	locationModal: boolean;
	setLocationModal: (e: boolean) => void;
}

const { Text } = Typography;

const LocationModalC = (props: LocationModalCProps) => {
	const dispatch = useDispatch();
	const { userLocation } = useSelector((state: RootStateOrAny) => state.auth);

	const places = usePlacesAutocomplete({ debounce: 500 });
	const [location, setLocation] = useCookie('user.location');

	const [typeModal, setTypeModal] = useState<'search' | 'maps'>('search');
	const [position, setPosition] = useState<UserLocation>(
		JSON.parse(location as string) || userLocation,
	);

	const [mapAddress, setMapAddress] = useState<Address | undefined>();
	const [mapPosition, setMapPosition] = useState(g.MAP.DATA.defaultPosition);
	const [mapFocus, setMapFocus] = useState(g.MAP.DATA.defaultPosition);

	const handleUserLocation = async () => dispatch(await actionUserLocationModify(position));

	useEffect(() => {
		if (userLocation.lat !== position.lat || userLocation.addressLabel !== position.addressLabel) {
			handleUserLocation();
			setLocation(JSON.stringify(position), { expires: maxAgeDay * 24 * 60 * 60 });
		}
	}, [position]);

	const handleMapAddress = () => {
		if (mapAddress?.latitude && mapAddress?.longitude) {
			setPosition({
				lat: Number(mapAddress.latitude),
				lng: Number(mapAddress.longitude),
				address: mapAddress?.location_description!,
				addressLabel: '',
			});
		}
	};

	const handleCancel = () => {
		places.setValue('');
		setTypeModal('search');
		props.setLocationModal(false);
	};

	const UserLoggedInC = () => {
		return (
			<>
				{AuthStorage.loggedIn ? (
					<LocationSliderC setPosition={setPosition} setLocationModal={props.setLocationModal} />
				) : (
					<Text className="fs-14">
						<Link href="/signin" className="text-primary fw-700 cursor-pointer">
							Masuk
						</Link>
						<Text> untuk melihat alamat kamu</Text>
					</Text>
				)}

				<GeoLocationC setPosition={setPosition} setLocationModal={props.setLocationModal} />
			</>
		);
	};

	const SearchOrUserLoggedInC = () => {
		return (
			<>
				{places.value.length > 0 ? (
					<SearchSuggestionC
						places={places}
						setPosition={setPosition}
						setLocationModal={props.setLocationModal}
					/>
				) : (
					<UserLoggedInC />
				)}
			</>
		);
	};

	return (
		<ModalC
			width={600}
			closable={false}
			visible={props.locationModal}
			onCancel={handleCancel}
			className={classes.modal}
			hideButton
		>
			<ModalHeader
				showBack={typeModal === 'maps'}
				onBack={() => setTypeModal('search')}
				showClose
				onClose={handleCancel}
			/>

			{typeModal === 'maps' ? (
				<>
					<div style={{ marginBottom: -16 }}>
						<Text className="fs-24 fw-700">Tambah Lokasi</Text>
					</div>

					<AddressListPinPoint
						address={mapAddress}
						setAddress={setMapAddress}
						position={mapPosition}
						setPosition={setMapPosition}
						focus={mapFocus}
						setFocus={setMapFocus}
						nextStep={handleMapAddress}
					/>
				</>
			) : (
				<>
					<Row align="middle" justify="space-between" className="my-2">
						<Col>
							<Text className="fs-18 fw-600 text-neutral-3">Tentukan Lokasi</Text>
						</Col>
						<Col>
							<div
								className="d-flex align-items-center cursor-pointer"
								onClick={() => setTypeModal('maps')}
							>
								<ImageC
									alt="Icon Map Location"
									width={20}
									height={20}
									src="/icons/map-location-green.svg"
								/>
								<Text className="fs-12 fw-600 text-primary ml-2">Pilih lewat peta</Text>
							</div>
						</Col>
					</Row>

					<Form autoComplete="off" className="mb-3">
						<Input
							name="search-location"
							size="large"
							placeholder="Cari lokasi"
							prefix={<ImageC alt="Icon Search" src="/icons/search.svg" height={20} width={20} />}
							value={places.value}
							onChange={(e) => places.setValue(e.target.value)}
							className={classes.search}
						/>
					</Form>

					<SearchOrUserLoggedInC />
				</>
			)}
		</ModalC>
	);
};

export default LocationModalC;
