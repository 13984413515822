import { KLIKSTORE_LANDINGPAGE_TYPE } from 'src/constants/redux';

export const initialState = {
	categoryList: null,
	banner: null,
	landingPageCategories: null,
	isLoadinglandingPageCategories: false,
	landingPageBanners: null,
	isLoadinglandingPageBanners: false,
	floorSectionList: null,
	floorSectionItems: null,
};

const reducer = (state = initialState, action: any = {}) => {
	switch (action.type) {
		case KLIKSTORE_LANDINGPAGE_TYPE.model.categoryList.fetch:
			return { ...state, isLoadingCategoryList: true };
		case KLIKSTORE_LANDINGPAGE_TYPE.model.categoryList.success:
			return Object.assign({}, state, {
				categoryList: action?.payload?.data?.records ?? [],
				isLoadingCategoryList: false,
			});
		case KLIKSTORE_LANDINGPAGE_TYPE.model.landingPageCategories.fetch:
			return { ...state, isLoadinglandingPageCategories: true };
		case KLIKSTORE_LANDINGPAGE_TYPE.model.landingPageCategories.success:
			return Object.assign({}, state, {
				landingPageCategories: action?.payload?.data?.records ?? [],
				isLoadinglandingPageCategories: false,
			});
		case KLIKSTORE_LANDINGPAGE_TYPE.model.landingPageBanners.fetch:
			return { ...state, isLoadinglandingPageBanners: true };
		case KLIKSTORE_LANDINGPAGE_TYPE.model.landingPageBanners.success:
			return Object.assign({}, state, {
				landingPageBanners: action?.payload?.data?.records ?? [],
				isLoadinglandingPageBanners: false,
			});
		case KLIKSTORE_LANDINGPAGE_TYPE.model.floorSectionList.success:
			return Object.assign({}, state, {
				floorSectionList:
					action?.payload?.data?.records?.sort((a: any, b: any) => {
						if (a.sort > b.sort) return 1;
						if (b.sort > a.sort) return -1;
						return 0;
					}) ?? [],
			});
		case KLIKSTORE_LANDINGPAGE_TYPE.model.floorSectionItems.success:
			return Object.assign({}, state, {
				floorSectionItems: action?.payload?.results ?? [],
			});
		default:
			return state;
	}
};

export default reducer;
