export const sortBy = (key: any) => {
	return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export const sortByDesc = (key: any) => {
	return (b: any, a: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export const capitalize = (string: string) => {
	return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
};

export const isEmpty = (obj: any) => {
	return [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
};

export const kebabCase = (string: string) => {
	return string
		? string
				.replace(/([a-z])([A-Z])/g, '$1-$2')
				.replace(/[\s_]+/g, '-')
				.toLowerCase()
		: '';
};

export const startCase = (string: string) => {
	return string
		? string
				.replace(/-/g, ' ')
				.toLowerCase()
				.split(' ')
				.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ')
		: '';
};
