import { useMemo } from 'react';

import { DataFilterTypesense } from 'src/interface';

const useKeyingFilter = (filter: DataFilterTypesense[]) => {
	return useMemo<Record<string, DataFilterTypesense['value']>>(
		() =>
			filter.reduce(
				(acc, curr) => ({
					...acc,
					[curr.field_name]: curr.value,
				}),
				{},
			),
		[filter],
	);
};

export default useKeyingFilter;
