export const QISCUS_TYPE = {
	model: {
		instance: {
			success: 'GET_INSTANCE_SUCCESS',
		},
		fbtoken: {
			success: 'GET_FB_TOKEN_SUCCESS',
		},
	},
};
