export const mapStyles = [
	{
		featureType: 'administrative',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#6da39b',
			},
		],
	},
	{
		featureType: 'administrative.province',
		elementType: 'geometry.stroke',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'landscape',
		elementType: 'geometry',
		stylers: [
			{
				lightness: '0',
			},
			{
				saturation: '0',
			},
			{
				gamma: '1',
			},
			{
				color: '#f5f5f2',
			},
		],
	},
	{
		featureType: 'landscape.man_made',
		elementType: 'all',
		stylers: [
			{
				lightness: '0',
			},
			{
				gamma: '1.00',
			},
		],
	},
	{
		featureType: 'landscape.man_made',
		elementType: 'geometry.stroke',
		stylers: [
			{
				visibility: 'on',
			},
			{
				color: '#c4c4c0',
			},
		],
	},
	{
		featureType: 'landscape.natural.terrain',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#a8b9b4',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'labels.icon',
		stylers: [
			{
				color: '#a8b9b4',
			},
		],
	},
	{
		featureType: 'poi.business',
		elementType: 'all',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'poi.medical',
		elementType: 'all',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'poi.medical',
		elementType: 'geometry.fill',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#bae5ce',
			},
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'poi.place_of_worship',
		elementType: 'all',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'all',
		stylers: [
			{
				saturation: -100,
			},
			{
				lightness: 45,
			},
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#ffffff',
			},
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.stroke',
		stylers: [
			{
				visibility: 'on',
			},
			{
				color: '#e0e3e7',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'labels.text',
		stylers: [
			{
				color: '#5b6268',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#7b838a',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'transit.line',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'transit.station.airport',
		elementType: 'labels.text',
		stylers: [
			{
				color: '#00a378',
			},
		],
	},
	{
		featureType: 'transit.station.airport',
		elementType: 'labels.icon',
		stylers: [
			{
				saturation: '-77',
			},
			{
				gamma: '0.57',
			},
			{
				lightness: '0',
			},
		],
	},
	{
		featureType: 'transit.station.bus',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'transit.station.rail',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#43321e',
			},
		],
	},
	{
		featureType: 'transit.station.rail',
		elementType: 'labels.icon',
		stylers: [
			{
				hue: '#ff6c00',
			},
			{
				lightness: '4',
			},
			{
				gamma: '0.75',
			},
			{
				saturation: '-68',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'all',
		stylers: [
			{
				color: '#eaf6f8',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#c7eced',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'labels.text.fill',
		stylers: [
			{
				lightness: '-49',
			},
			{
				saturation: '-53',
			},
			{
				gamma: '0.79',
			},
		],
	},
];
