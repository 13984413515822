import dayjs from 'src/utils/moment';

export const formatChatDate = (date: any) => {
	if (!date) return '--';

	const responseDate = (dateString: string) => {
		// Create 'today' Date object
		const todayDate = new Date();

		// Create 'someDate' Date object
		const dateTarget = new Date(dateString);

		// is Today
		if (todayDate.toDateString() === dateTarget.toDateString()) {
			return dayjs(date).format('HH:mm');
		}

		// Alter date to yesterday
		dateTarget.setDate(dateTarget.getDate() - 1);

		// is Yesterday
		if (dateTarget.toDateString() === todayDate.toDateString()) {
			return 'Yesterday';
		}

		// Fallback
		return dayjs(date).format('DD/MM/YYYY');
	};

	return responseDate(date);
};
