import { useMutation, useQuery, useQueryClient } from 'react-query';

import g from 'src/constants/global';
import { PAYMENT } from 'src/constants/services';
import { ASOTransactionCheck } from 'src/interface';
import { ResponseData } from 'src/interface/Commons';
import {
	AsoPaymentSummary,
	IPaymentSummary,
	PaymentAdmission,
	PaymentAdmissionPayload,
} from 'src/interface/SummaryPayment';
import { fetchApiPayment } from 'src/utils/fetch-api';
import { removeQueryParams } from 'src/utils/func';

const { prescriptionRedeem } = g.PROFILE.TRANSACTION.SERVICE;

export const summaryPaymentTeleconsultation = 'summaryPaymentTeleconsultation';
export const paymentMethodList = 'paymentMethodList';
export const paymentSubmit = 'paymentSubmit';
export const paymentSummaryDetail = 'paymentSummaryDetail';
export const paymentSummaryCheck = 'paymentSummaryCheck';
export const insufficientBenefits = 'insufficientBenefits';

const invoice = 'invoice';

export const useCreateInvoice = () => {
	const queryClient = useQueryClient();
	const getInvoice = ({ orderId, module }: any) => {
		return fetchApiPayment({
			url: `/${PAYMENT.service}/${PAYMENT.model.invoice}`,
			options: {
				method: 'post',
			},
			payload: {
				order_id: orderId,
				module: module,
			},
		});
	};

	return useMutation(({ orderId, module }: any) => getInvoice({ orderId, module }), {
		onSettled: () => {
			queryClient.invalidateQueries(invoice);
		},
	});
};

export const useAsoCreateInvoice = () => {
	const getInvoice = (parent_invoice: string) => {
		return fetchApiPayment({
			url: `/${PAYMENT.model.aso}/${PAYMENT.model.invoice}`,
			options: {
				method: 'POST',
			},
			payload: {
				parent_invoice,
			},
		});
	};

	return useMutation(({ parent_invoice }: { parent_invoice: string }) =>
		getInvoice(parent_invoice),
	);
};
export const isDefaultPaymentType = (
	data?: { record: AsoPaymentSummary } | IPaymentSummary,
): data is IPaymentSummary => (data as IPaymentSummary).grand_total !== undefined;

export const useSummaryPayment = (
	invoice_id: string,
	isAso: boolean = false,
	enabled: boolean = false,
) => {
	const getSummaryPayment = (id: string) => {
		return fetchApiPayment({
			url: `/${PAYMENT.service}/${PAYMENT.model.summary}`,
			options: { method: 'POST' },
			payload: { invoice: id },
		});
	};
	const getAsoPaymentSummary = (id: string) => {
		return fetchApiPayment({
			url: `/${PAYMENT.model.aso}/${PAYMENT.model.outstanding}/${PAYMENT.model.summary}/${id}`,
			options: { method: 'GET' },
		});
	};

	const {
		data,
		error: errorData,
		...restProps
	} = useQuery(
		[summaryPaymentTeleconsultation, invoice_id, isAso],
		(): Promise<
			ResponseData<{ record: AsoPaymentSummary }, any> | ResponseData<IPaymentSummary, any>
		> => (isAso ? getAsoPaymentSummary : getSummaryPayment)(removeQueryParams(invoice_id)),
		{ retry: false, refetchOnWindowFocus: false, enabled },
	);

	const dataPayment = data && isDefaultPaymentType(data.data) ? data.data : null;
	const dataAsoPayment = data && !isDefaultPaymentType(data?.data) ? data.data.record : null;
	const error = errorData as any;

	return {
		dataPayment,
		dataAsoPayment,
		error,
		...restProps,
	};
};

export const usePaymentMethodList = (invoice_id: string[] | string) => {
	const getPaymentMethodList = (id: string[] | string) => {
		return fetchApiPayment({
			url: `/${PAYMENT.service}/${PAYMENT.model.method}`,
			options: { method: 'POST' },
			payload: { invoice: id },
		});
	};

	return useQuery([paymentMethodList, invoice_id], () => getPaymentMethodList(invoice_id), {
		enabled: false,
	});
};

export const useCreateTransaction = (isASOOutstanding?: boolean) => {
	const queryClient = useQueryClient();
	const createTransaction = (params: any) => {
		return fetchApiPayment({
			url: `${isASOOutstanding ? `/${PAYMENT.model.aso}` : ''}/${PAYMENT.model.submit}`,
			options: { method: 'POST' },
			payload: {
				...params,
				invoice: removeQueryParams(params?.invoice),
			},
		});
	};

	return useMutation((params: any) => createTransaction(params), {
		onSettled: () => {
			queryClient.invalidateQueries(paymentSubmit);
		},
	});
};

export const useVoucherApplyTransaction = () => {
	const queryClient = useQueryClient();
	const voucherApplyTransaction = (params: any) => {
		return fetchApiPayment({
			url: `/${PAYMENT.service}/${PAYMENT.model.voucherApply}`,
			options: { method: 'POST' },
			payload: {
				...params,
				invoice: removeQueryParams(params?.invoice),
			},
		});
	};

	return useMutation((params: any) => voucherApplyTransaction(params), {
		onSettled: () => {
			queryClient.invalidateQueries(summaryPaymentTeleconsultation);
		},
	});
};

export const useVoucherDeleteTransaction = () => {
	const queryClient = useQueryClient();
	const voucherDeleteTransaction = (params: any) => {
		return fetchApiPayment({
			url: `/${PAYMENT.service}/${PAYMENT.model.voucherDelete}`,
			options: { method: 'POST' },
			payload: {
				...params,
				invoice: removeQueryParams(params?.invoice),
			},
		});
	};

	return useMutation((params: any) => voucherDeleteTransaction(params), {
		onSettled: () => {
			queryClient.invalidateQueries(summaryPaymentTeleconsultation);
		},
	});
};

export const useSummaryPaymentDetail = (invoice_id: string[] | string) => {
	const getSummaryPaymentDetail = (id: string[] | string) => {
		return fetchApiPayment({
			url: `/${PAYMENT.service}/${PAYMENT.model.summaryDetail}`,
			options: { method: 'POST' },
			payload: {
				invoice: removeQueryParams(id),
			},
		});
	};
	return useQuery([paymentSummaryDetail, invoice_id], () => getSummaryPaymentDetail(invoice_id));
};

export const useSummaryPaymentCheck = (invoiceId: string) => {
	return useQuery(
		[paymentSummaryCheck, invoiceId],
		() => {
			return fetchApiPayment({
				url: `/${PAYMENT.service}/${PAYMENT.model.check}`,
				options: { method: 'POST' },
				payload: {
					invoice: removeQueryParams(invoiceId),
				},
			});
		},
		{ retry: false, refetchOnWindowFocus: false },
	);
};

export const useJMTransactionLists = ({
	setIsFirstLoad,
	setIsFullEmpty,
	page,
	limit,
	module,
	filter,
	type = '',
	period,
}: any) => {
	return useQuery(
		['JMTransactionList', page, limit, module, filter, type, period],
		() => {
			return fetchApiPayment({
				url: `/${PAYMENT.service}/${PAYMENT.model.transactionList}`,
				options: { method: 'POST' },
				payload: {
					page: page,
					limit: limit,
					module: module,
					filter: filter[0] !== '' ? filter : [],
					type,
					period: period ? period : 'Semua Waktu',
				},
			});
		},
		{
			retry: false,
			refetchOnWindowFocus: false,
			onSettled: (res, err) => {
				if (!err && res.status === 200) {
					if (res.data == 0 && filter[0] == '') {
						setIsFullEmpty(true);
					}
					setIsFirstLoad(true);
				}
			},
		},
	);
};

export const usePaymentWaitingCount = () => {
	return useQuery(
		['paymentWaitingCount'],
		() => {
			return fetchApiPayment({
				url: `/${PAYMENT.service}/${PAYMENT.model.waitingCount}`,
				options: { method: 'POST' },
			});
		},
		{ retry: false, refetchOnWindowFocus: true },
	);
};

export const paymentAdmissionKey = 'paymentAdmission';

export const usePaymentAdmission = () => {
	const { data, ...restAttributes } = useMutation(
		(payload: PaymentAdmissionPayload): Promise<ResponseData<{ record: PaymentAdmission }, any>> =>
			fetchApiPayment({
				url: `/${PAYMENT.model.admission}`,
				options: { method: 'POST' },
				payload: {
					...payload,
					invoice: removeQueryParams(payload?.invoice),
				},
			}),
	);
	return {
		data: data?.data?.record,
		meta: data?.meta,
		...restAttributes,
	};
};

export const usePaymentAdmissionData = (invoiceId: string) => {
	const queryClient = useQueryClient();
	return queryClient.getQueryData<PaymentAdmission>([paymentAdmissionKey, invoiceId]);
};

export const useTransactionCheck = () => {
	const { data, ...restAttributes } = useMutation(
		({
			invoiceId,
		}: {
			invoiceId: string;
		}): Promise<ResponseData<{ record: ASOTransactionCheck }, any>> =>
			fetchApiPayment({
				url: `/${PAYMENT.model.transactionCheck}/${removeQueryParams(invoiceId)}`,
				options: { method: 'GET' },
			}),
	);
	return {
		data: data?.data?.record,
		meta: data?.meta,
		...restAttributes,
	};
};

export const usePaymentExcess = () => {
	const { mutate: checkTransaction } = useTransactionCheck();
	const { mutate: createInvoice } = useAsoCreateInvoice();

	const checkPaymentExcess = ({
		invoiceId,
		module,
		onFailed,
		onSuccess,
	}: {
		invoiceId: string;
		module: string;
		onFailed: () => void;
		onSuccess: (link?: string, query?: Record<string, any>) => void;
	}) => {
		checkTransaction(
			{ invoiceId: invoiceId },
			{
				onSuccess: (response) => {
					const data = response?.data?.record;
					if (data?.return_aso_outstanding) {
						createInvoice(
							{
								parent_invoice: data?.invoice,
							},
							{
								onError: onFailed,
								onSuccess: (invoiceRes) => {
									const { invoice: slug } = invoiceRes?.data?.record || {};
									const pathName =
										module === prescriptionRedeem ? 'resep/konfirmasi-pesanan' : 'checkout';
									onSuccess(`/${pathName}/${slug}`, {
										type: 'aso',
									});
								},
							},
						);
					} else {
						onFailed();
					}
				},
				onError: onFailed,
			},
		);
	};

	return {
		checkPaymentExcess,
	};
};
