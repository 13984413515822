import { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ImageProps } from 'next/legacy/image';
import { Avatar, AvatarProps, Badge, BadgeProps } from 'antd';

import Image from 'src/components/Image';
import { ucwords } from 'src/utils/func/format-string';

import classes from './index.module.less';

type Props = AvatarProps &
	ImageProps &
	Omit<BadgeProps, 'dot' | 'size'> & {
		username: string;
		imageclassname?: string;
		badgeclassname?: string;
		badgesize?: 'default' | 'small';
		dot?: string | boolean;
		defaultSrc?: string;
	};

const AvatarC = (props: Props) => {
	const { auth } = useSelector((state: RootStateOrAny) => state);
	const [key, setKey] = useState<string>('');

	useEffect(() => {
		if (!!auth.changeAvatar) setKey(`?${auth.changeAvatar?.data?.record?.token}`);
	}, [auth.changeAvatar]);

	return (
		<Badge
			className={props.badgeclassname}
			size={props.badgesize}
			dot={!!props.dot}
			color={props.color}
			status={props.status}
			offset={props.offset}
			count={props.count || null}
		>
			<Avatar
				className={`${classes['user-avatar']} ${props.className}`}
				size={props.size}
				shape={props.shape}
				style={{ background: `${props.src ? 'transparent' : ''}` }}
				icon={
					props.src && (
						<Image
							className={props.imageclassname}
							objectFit={props.objectFit || 'cover'}
							layout={props.layout || 'responsive'}
							alt={props.alt || 'User Avatar'}
							{...props}
							src={`${props.src}${key}`}
						/>
					)
				}
			>
				{ucwords(props.username?.charAt(0))}
			</Avatar>
		</Badge>
	);
};

export default AvatarC;
