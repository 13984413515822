import Countdown from 'react-countdown';
import { Button } from 'antd';
import classNames from 'classnames';

import { ButtonCountdownCProps, ButtonCProps } from 'src/interface/Layout/Button';
import { timeToTextFormatted } from 'src/utils/func/format-time';

import classes from './index.module.less';

const ButtonC = (props: ButtonCProps) => {
	return (
		<Button {...props} className={`${classes.button} ${props.className}`}>
			{props.text}
		</Button>
	);
};

export const ButtonOK = (props: ButtonCProps) => {
	return <ButtonC {...props} type="primary" text={props.text} />;
};

export const ButtonCountdown = (props: ButtonCountdownCProps) => {
	const renderer = ({ formatted, completed }: any) => {
		if (completed) {
			return (
				<Button {...props} className={`${classes.button} ${props.className}`}>
					{props.label}
				</Button>
			);
		}

		return (
			<Button {...props} className={`${classes.button} ${props.className}`}>
				<span>{props.text}</span>
				<span className="ml-2">
					{timeToTextFormatted(formatted.hours, formatted.minutes, formatted.seconds, false)}
				</span>
			</Button>
		);
	};

	return props.countdown > 0 ? (
		<Countdown date={Date.now() + props.countdown} renderer={renderer} />
	) : (
		<Button {...props} className={`${classes.button} ${props.className}`}>
			{props.text}
		</Button>
	);
};

export const ButtonBorder = ({ className, text, ...props }: ButtonCProps) => {
	return (
		<Button className={classNames(`${classes['button-border']}`, className)} {...props}>
			{text}
		</Button>
	);
};

export const ButtonText = (props: ButtonCProps) => {
	return (
		<Button
			{...props}
			className={classNames(classes['button-border'], classes['button-text'], props.className)}
		>
			{props.text}
		</Button>
	);
};

export default ButtonC;
