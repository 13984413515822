import React from 'react';
import { Typography } from 'antd';

import ButtonC from 'src/components/Forms/Button';
import ImageC from 'src/components/Image';
import ModalC, { ModalCProps } from 'src/components/Modal';
import { MenuProps } from 'src/interface';

type ComingSoonModalProps = ModalCProps & {
	data?: MenuProps['extra_description'];
};

const ComingSoonModal = (props: ComingSoonModalProps) => {
	const { data, ...modalProps } = props;
	return (
		<ModalC hideButton {...modalProps} width={600}>
			<div className="py-4">
				<ImageC src={data?.image} width={195} height={191} />
				<div className="my-4">
					<Typography.Title level={5} className={'fw-700'}>
						{data?.title}
					</Typography.Title>
					<Typography.Paragraph className={'text-neutral-7'}>{data?.content}</Typography.Paragraph>
				</div>
				<ButtonC
					text="Kembali ke Halaman Utama"
					size="large"
					type="primary"
					onClick={props.onCancel}
				/>
			</div>
		</ModalC>
	);
};

export default ComingSoonModal;
