import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Col, Grid, Row, Typography } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import { actionUserLocationModify } from 'src/redux/actions';
import AuthStorage from 'src/utils/auth-storage';
import { truncateString } from 'src/utils/func';

import LocationModalC from './LocationModal';

const { Text } = Typography;

const HeaderLocationC = () => {
	const { xs, sm, md, lg } = Grid.useBreakpoint();
	const responsive = (xs || sm || md) && !lg;

	const { userLocation } = useSelector((state: RootStateOrAny) => state.auth);
	const dispatch = useDispatch();

	const location = AuthStorage.loggedIn
		? userLocation.addressLabel || userLocation.address
		: userLocation.address;

	const locationModal = userLocation.modal;
	const setLocationModal = async (val: boolean) => {
		dispatch(await actionUserLocationModify({ ...userLocation, modal: val }));
	};

	return (
		<>
			<Row
				align="middle"
				justify="end"
				wrap={false}
				className={classNames({ 'mt-2': !responsive })}
			>
				<Col
					onClick={() => setLocationModal(true)}
					className="d-flex align-items-center cursor-pointer"
					style={{ height: 18 }}
				>
					<ImageC alt="Icon Location" width={16} height={16} src="/icons/location-green.svg" />
					<Text className="fs-12 text-neutral-3 ml-1 mr-2" ellipsis>
						{userLocation.address !== '' ? (
							<>
								<Text className="text-neutral-9">Lokasimu </Text>
								<Text>{truncateString(location, 20)}</Text>
							</>
						) : (
							<Text>Tambah Lokasi Kamu</Text>
						)}
					</Text>
					<ImageC alt="Icon Arrow" width={10} height={10} src="/icons/down-arrow.svg" />
				</Col>
			</Row>

			<LocationModalC locationModal={locationModal} setLocationModal={setLocationModal} />
		</>
	);
};

export default HeaderLocationC;
