import { Col, Grid, Row, Skeleton, Space, Typography } from 'antd';

import ButtonC from 'src/components/Forms/Button';
import ImageC from 'src/components/Image';
import { EmptyProps } from 'src/interface/Layout/Empty';

import classes from './index.module.less';

const { Paragraph, Text } = Typography;

const EmptyC = (props: EmptyProps) => {
	const { xs } = Grid.useBreakpoint();
	const imageSize = xs ? 200 : 250;

	return (
		<Space
			align="center"
			direction="vertical"
			size={props.size || 'middle'}
			className={`${classes.empty} ${props.className}`}
		>
			{props.image ? (
				<ImageC
					src={props.image}
					alt={'Icon ' + props.title ?? 'Empty'}
					width={props.imageWidth ?? imageSize}
					height={props.imageHeight ?? imageSize}
				/>
			) : (
				<Skeleton.Image
					style={{
						borderRadius: 6,
						width: props.imageWidth ?? imageSize,
						height: props.imageHeight ?? imageSize,
					}}
				/>
			)}

			<Row justify="center">
				<Col>
					{typeof props.title === 'string' ? (
						<Text className={`${classes.title} ${props.customHeaderEmpty}`}>{props.title}</Text>
					) : (
						props.title
					)}
					{typeof props.description === 'string' ? (
						<Paragraph className={`${classes.description} ${props.customParagraphEmpty}`}>
							{props.description}
						</Paragraph>
					) : (
						props.description
					)}
					{props.renderCustomFooter && props.renderCustomFooter()}
				</Col>
			</Row>

			{!props.buttonHidden && (
				<ButtonC
					size="large"
					type="primary"
					text={props.buttonText}
					loading={props.isLoading}
					onClick={props.onClick}
				/>
			)}
		</Space>
	);
};

export default EmptyC;
