import { Typography } from 'antd';

import ImageC from 'src/components/Image';

import WrapNotif from './WrapNotif';

import classes from '../../UserMenu/UserNotificationMenu.module.less';

const InfoMenu = (props: any) => {
	return (
		<>
			<WrapNotif cardStatus={props.info_data.status == 0} onClickNotifiation={props.onClick}>
				<div className={classes['img-notif-info']}>
					<ImageC
						src={props.info_data.url_icon}
						defaultSrc="/icons/notifikasi/ic_info.svg"
						height={14}
						width={14}
						alt={props.info_data.title}
						objectFit="cover"
					/>
				</div>

				<div className={'w-92'}>
					<Typography.Title className={classes['text-header']}>
						{props.info_data.title}
					</Typography.Title>

					<Typography.Paragraph className={classes['text-paragraph']}>
						{props.info_data.message}
					</Typography.Paragraph>

					<Typography.Text className={classes['text-label']}>
						{props.info_data.date_created}
					</Typography.Text>
				</div>
			</WrapNotif>
		</>
	);
};

export default InfoMenu;
