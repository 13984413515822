import cookie from 'react-cookies';

import g from 'src/constants/global';
import { IArticleByGroupDetail, IKlikStoreProduct } from 'src/interface';
import { EventName } from 'src/interface/GoogleAnalytics';

import { ucwords } from './format-string';
import { convertToArray } from './klikstore';
const {
	reservationDoctor,
	reservationService,
	consultationChat,
	consultationVideo,
	consultationFace,
} = g.PROFILE.TRANSACTION.SERVICE;
const eventKeyDeletedList = [
	'view_item_list',
	'view_item',
	'select_item',
	'add_to_cart',
	'view_cart',
	'remove_from_cart',
	'begin_checkout',
	'purchase',
];

const getDataLayer = () => {
	window.dataLayer = window.dataLayer || [];

	return window.dataLayer;
};

const destroyAnalyticsDataLayerEvent = () => {
	if (!!getDataLayer().find((dl) => dl.event && eventKeyDeletedList.includes(dl.event))) {
		getDataLayer().push({ ecommerce: null });
	}
};

const analyticsDataLayerProcess = (event: any, isDestroyEvent = true) => {
	setTimeout(() => {
		if (isDestroyEvent) {
			destroyAnalyticsDataLayerEvent();
		}

		getDataLayer().push(event);
	}, 2500);
};

export const trackEvent = <T>(name: EventName, properties: T) => {
	analyticsDataLayerProcess({
		event: name,
		...properties,
	});
};

export const googleSelectItemEvent = (item: any) => {
	const itemCategory = convertToArray(item.itemCategory);

	const generateItemObj = (procedure: string, attribute: any) => {
		if (procedure === 'Temu Dokter') {
			return {
				item_id: attribute.id || attribute.master_uid || '',
				item_name: attribute.name,
				item_category2: '',
				item_category3: attribute.specialist_name,
				item_category4: '',
				item_category5: attribute.medical_facility_name,
				item_variant: attribute.medical_facility_type_name || '',
				discount: attribute.price - attribute.special_price,
			};
		} else if (procedure === 'Layanan Medis Lab') {
			return {
				item_id: attribute.medical_facility_uid || '',
				item_name: attribute.medical_facility_name,
				item_category2: '',
				item_category3: '',
				item_category4: attribute.name,
				item_category5: '',
				item_variant: ucwords(attribute.medical_facility_type_code) || '',
				discount: attribute.price - attribute.special_price,
			};
		} else if (procedure === 'Tanya Dokter') {
			return {
				item_id: attribute.uid || '',
				item_name: attribute.display_name,
				item_category2: attribute.filterType || '',
				item_category3: attribute.speciality,
				item_category4: '',
				item_category5: '',
				item_variant: '',
				discount: item.initial_price - item.final_price,
			};
		} else if (procedure === 'KALStore') {
			const attr: IKlikStoreProduct = { ...attribute };
			return {
				affiliation: attr?.merchant?.name,
				item_id: attr?.id,
				item_name: attr?.name,
				discount: attr?.prices?.selling_price - attr?.prices?.special_price,
				item_brand: attr?.brand_code, // zee | fitbar | milna | blackmores | hydrococo | etc
				item_category: 'KALStore',
				item_category2: itemCategory[1] ?? '', // Ibu, bayi dan anak | Perawatan Pribadi | Nutrisi Khusus | etc
				item_category3: itemCategory[2] ?? '', // Susu anak | Perawatan Dewasa | Alat Bantu Kesehatan | etc
				item_category4: '',
				item_category5: '',
				item_list_name: item.itemListName, // Produk Terkait | Produk Rekomendasi | Berdasarkan Kategori
				item_variant: '',
				price: attr?.prices?.special_price,
				quantity: attr?.merchant?.stock ?? 1,
			};
		}
	};

	const itemEvent = {
		affiliation: 'KlikDokter',
		coupon: '',
		currency: 'IDR',
		discount: 0,
		index: 0,
		item_brand: 'KlikDokter',
		item_category: itemCategory[0] ?? '',
		item_list_id: '',
		item_list_name: item.itemListName,
		location_id: '',
		price: Number(item.price || item.final_price || 0),
		quantity: 1,
		...generateItemObj(itemCategory[0] ?? '', item),
	};

	const objSelectItem = {
		event: 'select_item',
		ecommerce: {
			items: [itemEvent],
		},
	};

	analyticsDataLayerProcess(objSelectItem);
};

export const googleAddToCartEvent = (item: any) => {
	let generateItemObj = {};
	let consultationType = '';

	if (item.consultation_type === reservationDoctor) {
		consultationType = 'Temu Dokter';
		generateItemObj = {
			item_name: item.item_appointment.doctor_service,
			item_category2: '',
			item_category3: item.item_appointment.speciality,
			item_category4: '',
			item_category5: item.item_appointment.hospital,
		};
	} else if (item.consultation_type === reservationService) {
		consultationType = 'Layanan Medis Lab';
		generateItemObj = {
			item_name: item.item_appointment.hospital,
			item_category2: '',
			item_category3: '',
			item_category4: item.item_appointment.doctor_service,
			item_category5: '',
		};
	} else if (
		[consultationChat, consultationVideo, consultationFace].includes(item.consultation_type)
	) {
		consultationType = 'Tanya Dokter';
		generateItemObj = {
			item_name: item.display_name || item.detail.doctor.name,
			item_category2: item.consultation_type || '',
			item_category3: item.speciality || item.detail.doctor.speciality,
			item_category4: '',
			item_category5: '',
		};
	} else if (convertToArray(item.itemCategory)[0] === 'KALStore') {
		consultationType = 'KALStore';
		generateItemObj = {
			affiliation: item.merchant?.name,
			item_id: item.id,
			item_name: item.name,
			discount: item.prices.selling_price - item.prices.special_price,
			item_brand: item.brand_code, // zee | fitbar | milna | blackmores | hydrococo | etc
			item_category: 'KALStore',
			item_category2: item.itemCategory?.[1] ?? '', // Ibu, bayi dan anak | Perawatan Pribadi | Nutrisi Khusus | etc
			item_category3: item.itemCategory?.[2] ?? '', // Susu anak | Perawatan Dewasa | Alat Bantu Kesehatan | etc
			item_category4: '',
			item_category5: '',
			item_list_name: item.itemListName, // Produk Terkait | Produk Rekomendasi | Berdasarkan Kategori
			item_variant: '',
			price: item.prices.special_price,
			quantity: item.quantity ?? 1,
		};
	}

	const itemEvent = {
		affiliation: 'KlikDokter',
		coupon: item.voucher_name || '',
		currency: 'IDR',
		discount: Number(item.promo || 0),
		index: 0,
		item_id: item?.uid || '',
		item_brand: 'KlikDokter',
		item_category: consultationType,
		item_list_id: '',
		item_list_name: item.itemListName,
		item_variant: item.itemVariant,
		location_id: '',
		price: Number(item.grand_total || item.final_price || 0),
		quantity: 1,
		...generateItemObj,
	};

	const objSelectItem = {
		event: 'add_to_cart',
		ecommerce: {
			currency: 'IDR',
			value: Number(
				item.grand_total ||
					item.final_price ||
					item.prices.special_price * (item.quantity ?? 1) ||
					0,
			),
			items: [itemEvent],
		},
	};

	analyticsDataLayerProcess(objSelectItem);
};

export const googleViewCartEvent = (
	items: any,
	itemCategory: string | string[],
	itemListName: string,
	grandTotal: number,
) => {
	const itemEvent: any[] = [];

	const generateItemObj = (procedure: string, attribute: any) => {
		if (procedure === 'KALStore') {
			const attr: any = { ...attribute };
			return {
				affiliation: attr.merchant_name,
				item_id: attr.product_merchant_uid,
				item_name: attr.name,
				discount: parseInt(attr.discount_amount ?? '0'),
				item_brand: attr.brand_code, // zee | fitbar | milna | blackmores | hydrococo | etc
				item_category: 'KALStore',
				item_category2: convertToArray(attr.categories)[0] ?? '', // Ibu, bayi dan anak | Perawatan Pribadi | Nutrisi Khusus | etc
				item_category3: convertToArray(attr.categories)[1] ?? '', // Susu anak | Perawatan Dewasa | Alat Bantu Kesehatan | etc
				item_category4: convertToArray(attr.categories)[2] ?? '',
				item_category5: convertToArray(attr.categories)[3] ?? '',
				item_list_name: itemListName, // Produk Terkait | Produk Rekomendasi | Berdasarkan Kategori
				item_variant: '',
				price: parseInt(attr.price),
				quantity: parseInt(attr.quantity ?? '1'),
			};
		}
	};

	items?.forEach((dt: any, index: number) => {
		const attribute = dt.document || dt;
		itemEvent.push({
			affiliation: 'KlikDokter',
			coupon: '',
			currency: 'IDR',
			discount: 0,
			index,
			item_brand: 'KlikDokter',
			item_category: convertToArray(itemCategory)[0] ?? '',
			item_list_id: '',
			item_list_name: itemListName,
			location_id: '',
			price: Number(attribute.price || attribute.final_price || 0),
			quantity: 1,
			...generateItemObj(convertToArray(itemCategory)[0] ?? '', attribute),
		});
	});

	const objViewItem = {
		event: 'view_cart',
		ecommerce: {
			currency: 'IDR',
			value: grandTotal,
			items: itemEvent,
		},
	};

	analyticsDataLayerProcess(objViewItem);
};

export const googleRemoveFromCartEvent = (item: any) => {
	let generateItemObj = {};

	if (convertToArray(item.itemCategory)[0] === 'KALStore') {
		const attr: any = { ...item };
		generateItemObj = {
			affiliation: attr.merchant_name,
			item_id: attr.product_merchant_uid,
			item_name: attr.name,
			discount: parseInt(attr.discount_amount ?? '0'),
			item_brand: attr.brand_code, // zee | fitbar | milna | blackmores | hydrococo | etc
			item_category: 'KALStore',
			item_category2: convertToArray(attr.categories)[0] ?? '', // Ibu, bayi dan anak | Perawatan Pribadi | Nutrisi Khusus | etc
			item_category3: convertToArray(attr.categories)[1] ?? '', // Susu anak | Perawatan Dewasa | Alat Bantu Kesehatan | etc
			item_category4: convertToArray(attr.categories)[2] ?? '',
			item_category5: convertToArray(attr.categories)[3] ?? '',
			item_list_name: attr.itemListName, // Produk Terkait | Produk Rekomendasi | Berdasarkan Kategori
			item_variant: '',
			price: parseInt(attr.price ?? '0'),
			quantity: attr.quantity ?? 1,
		};
	}

	const itemEvent = {
		affiliation: 'KlikDokter',
		coupon: item.voucher_name || '',
		currency: 'IDR',
		discount: Number(item.promo || 0),
		index: 0,
		item_id: item?.uid || '',
		item_brand: 'KlikDokter',
		item_category: convertToArray(item.itemCategory)[0],
		item_list_id: '',
		item_list_name: item.itemListName,
		item_variant: item.itemVariant,
		location_id: '',
		price: Number(item.price || 0),
		quantity: 1,
		...generateItemObj,
	};

	const objSelectItem = {
		event: 'remove_from_cart',
		ecommerce: {
			currency: 'IDR',
			value: Number(item.price * (item.quantity ?? 1) || 0),
			items: [itemEvent],
		},
	};

	analyticsDataLayerProcess(objSelectItem);
};

export const googleBeginCheckoutEvent = (item: any) => {
	let generateItemObj = {};
	let consultationType = '';

	if (item.consultation_type === reservationDoctor) {
		consultationType = 'Temu Dokter';
		generateItemObj = {
			item_name: item.item_appointment.doctor_service,
			item_category2: '',
			item_category3: item.item_appointment.speciality,
			item_category4: '',
			item_category5: item.item_appointment.hospital,
		};
	} else if (item.consultation_type === reservationService) {
		consultationType = 'Layanan Medis Lab';

		generateItemObj = {
			item_name: item.item_appointment.hospital,
			item_category2: '',
			item_category3: '',
			item_category4: item.item_appointment.doctor_service,
			item_category5: '',
		};
	} else if (
		[consultationChat, consultationVideo, consultationFace].includes(item.consultation_type)
	) {
		consultationType = 'Tanya Dokter';
		generateItemObj = {
			item_name: item.detail?.doctor?.name,
			item_category2: item.consultation_type || '',
			item_category3: item.detail?.doctor?.speciality,
			item_category4: '',
			item_category5: '',
		};
	}
	const itemEvent = {
		affiliation: 'KlikDokter',
		coupon: item.voucher_name,
		currency: 'IDR',
		discount: Number(item.promo || 0),
		index: 0,
		item_brand: 'KlikDokter',
		item_id: item.itemId,
		item_category: consultationType,
		item_list_id: '',
		item_list_name: item.itemListName,
		item_variant: item.itemVariant,
		location_id: '',
		price: Number(item.grand_total || 0),
		quantity: 1,
		...generateItemObj,
	};

	const objSelectItem = {
		event: 'begin_checkout',
		ecommerce: {
			items: [itemEvent],
		},
	};

	analyticsDataLayerProcess(objSelectItem);
};

export const googleMarketplaceBeginCheckoutEvent = (
	items: any,
	itemCategory: string | string[],
	itemListName: string,
	grandTotal: number,
	coupon: string,
) => {
	const itemEvent: any[] = [];

	const generateItemObj = (procedure: string, attribute: any) => {
		if (procedure === 'KALStore') {
			const attr: any = { ...attribute };
			return {
				affiliation: attr.merchant_name,
				item_id: attr.product_merchant_uid,
				item_name: attr.name,
				discount: parseInt(attr.discount_amount ?? '0'),
				item_brand: attr.brand_code, // zee | fitbar | milna | blackmores | hydrococo | etc
				item_category: 'KALStore',
				item_category2: convertToArray(attr.categories)[0] ?? '', // Ibu, bayi dan anak | Perawatan Pribadi | Nutrisi Khusus | etc
				item_category3: convertToArray(attr.categories)[1] ?? '', // Susu anak | Perawatan Dewasa | Alat Bantu Kesehatan | etc
				item_category4: convertToArray(attr.categories)[2] ?? '',
				item_category5: convertToArray(attr.categories)[3] ?? '',
				item_list_name: itemListName, // Produk Terkait | Produk Rekomendasi | Berdasarkan Kategori
				item_variant: '',
				price: parseInt(attr.price),
				quantity: parseInt(attr.quantity ?? '1'),
			};
		}
	};

	items?.forEach((dt: any, index: number) => {
		const attribute = dt.document || dt;
		itemEvent.push({
			affiliation: 'KlikDokter',
			coupon: '',
			currency: 'IDR',
			discount: 0,
			index,
			item_brand: 'KlikDokter',
			item_category: convertToArray(itemCategory)[0] ?? '',
			item_list_id: '',
			item_list_name: itemListName,
			location_id: '',
			price: Number(attribute.price || attribute.final_price || 0),
			quantity: 1,
			...generateItemObj(convertToArray(itemCategory)[0] ?? '', attribute),
		});
	});

	const objViewItem = {
		event: 'begin_checkout',
		ecommerce: {
			currency: 'IDR',
			value: grandTotal,
			coupon: coupon,
			items: itemEvent,
		},
	};

	analyticsDataLayerProcess(objViewItem);
};

export const googlePurchaseEvent = (item: any) => {
	let generateItemObj = {};
	let consultationType = '';
	const price = Number(item.grand_total || item.subtotal || item.total_price_all || 0);
	const itemDetail = item?.item?.[0];

	if (item.service === reservationDoctor) {
		consultationType = 'Temu Dokter';
		generateItemObj = {
			item_id: item.doctor_id,
			item_name: itemDetail?.item_name || '',
			item_category2: '',
			item_category3: itemDetail?.speciality || '',
			item_category4: '',
			item_category5: item.tempat_praktek || item.merchant || '',
			item_variant: ucwords(item.medical_facility_type),
		};
	} else if (item.service === reservationService) {
		consultationType = 'Layanan Medis Lab';
		generateItemObj = {
			item_id: item.hospital_id,
			item_name: item.tempat_praktek || item.merchant || '',
			item_category2: '',
			item_category3: '',
			item_category4: itemDetail?.item_name || '',
			item_category5: '',
			item_variant: ucwords(item.medical_facility_type),
		};
	} else if ([consultationChat, consultationVideo, consultationFace].includes(item.service)) {
		consultationType = 'Tanya Dokter';
		generateItemObj = {
			item_id: item.doctor_id,
			item_name: item.display_name || item.item_name,
			item_category2: item.service,
			item_category3: item.speciality,
			item_category4: '',
			item_category5: '',
			item_variant: item.module_code,
		};
	}

	const itemEvent = {
		transaction_id: item.order_id,
		affiliation: 'KlikDokter',
		value: price, // {{Total Transaction}}
		tax: '', // {{Tax Transaction}}
		shipping: '', // {{Shipping Transaction}}
		currency: 'IDR',
		coupon: item.voucher_name || '',
		items: [
			{
				item_id: '',
				affiliation: 'KlikDokter',
				coupon: item.voucher_name || '',
				currency: 'IDR',
				discount: Number(item.promo || 0),
				index: 0,
				item_brand: 'KlikDokter',
				item_category: consultationType,
				item_list_id: '',
				item_list_name: 'Faskes List',
				item_variant: '',
				location_id: '',
				price,
				quantity: 1,
				...generateItemObj,
			},
		],
	};

	const objSelectItem = {
		event: 'purchase',
		ecommerce: itemEvent,
	};

	analyticsDataLayerProcess(objSelectItem);
};

export const googleMarketplacePurchaseEvent = (
	items: any,
	transaction_id: string,
	value: number,
	shipping: string,
	voucher_code: string,
) => {
	const itemEvent: any[] = [];

	const generateItemObj = (attribute: any) => {
		const attr: any = { ...attribute };
		return {
			affiliation: attr.merchant ?? 'KlikDokter',
			item_id: `${attr.product_id}-${attr.merchant_uid}`,
			item_name: attr.item_name,
			item_brand: attr.brand_code, // zee | fitbar | milna | blackmores | hydrococo | etc
			item_category: 'KALStore',
			item_category2: convertToArray(attr.categories)[0] ?? '', // Ibu, bayi dan anak | Perawatan Pribadi | Nutrisi Khusus | etc
			item_category3: convertToArray(attr.categories)[1] ?? '', // Susu anak | Perawatan Dewasa | Alat Bantu Kesehatan | etc
			item_category4: convertToArray(attr.categories)[2] ?? '',
			item_category5: convertToArray(attr.categories)[3] ?? '',
			item_list_name: '', // Produk Terkait | Produk Rekomendasi | Berdasarkan Kategori
			item_variant: '',
			price: attr.price ?? 0,
			quantity: attr.qty ?? 1,
		};
	};

	let index = 0;
	items?.forEach((subitems: any) => {
		subitems?.forEach((attribute: any) => {
			itemEvent.push({
				coupon: voucher_code ?? '',
				currency: 'IDR',
				discount: 0,
				index,
				item_list_id: '',
				location_id: '',
				...generateItemObj(attribute),
			});
			index++;
		});
	});

	const objSelectItem = {
		event: 'purchase',
		ecommerce: {
			transaction_id: transaction_id,
			affiliation: 'KlikDokter',
			value: value, // {{Total Transaction}}
			tax: '', // {{Tax Transaction}}
			shipping: shipping, // {{Shipping Transaction}}
			currency: 'IDR',
			coupon: '',
			items: itemEvent,
		},
	};

	analyticsDataLayerProcess(objSelectItem);
};

export const googleViewItemEvent = (item: any) => {
	const generateItemObj = (procedure: string, attribute: any) => {
		if (procedure === 'Temu Dokter') {
			return {
				item_id: attribute.uid || '',
				item_name: attribute.name,
				item_category2: '',
				item_category3: attribute.speciality_name,
				item_category4: '',
				item_category5: attribute.medical_facility_name,
				discount: attribute.price - attribute.special_price,
				item_variant: attribute.medical_facility_type_name || '',
			};
		} else if (procedure === 'Layanan Medis Lab') {
			return {
				item_id: attribute.medical_facility_uid || '',
				item_name: attribute.medical_facility_name,
				item_category2: '',
				item_category3: '',
				item_category4: attribute.name,
				item_category5: '',
				item_variant: ucwords(attribute.medical_facility_type) || '',
				discount: attribute.price - attribute.special_price,
			};
		} else if (procedure === 'Tanya Dokter') {
			return {
				item_id: attribute.uid || '',
				item_name: attribute.display_name,
				item_category2: attribute.filterType || '',
				item_category3: attribute.speciality,
				item_category4: '',
				item_category5: '',
				item_variant: '',
				discount: item.initial_price - item.final_price,
			};
		} else if (procedure === 'KALStore') {
			const attr: IKlikStoreProduct = { ...attribute };
			return {
				affiliation: attr?.merchant?.name,
				item_id: attr?.id,
				item_name: attr?.name,
				discount: attr?.prices?.selling_price - attr?.prices?.special_price,
				item_brand: attr?.brand_code, // zee | fitbar | milna | blackmores | hydrococo | etc
				item_category: 'KALStore',
				item_category2: convertToArray(item.itemCategory)[1] ?? '', // Ibu, bayi dan anak | Perawatan Pribadi | Nutrisi Khusus | etc
				item_category3: convertToArray(item.itemCategory)[2] ?? '', // Susu anak | Perawatan Dewasa | Alat Bantu Kesehatan | etc
				item_category4: '',
				item_category5: '',
				item_list_name: item.itemListName, // Produk Terkait | Produk Rekomendasi | Berdasarkan Kategori
				item_variant: '',
				price: attr?.prices?.special_price,
				quantity: attr?.merchant?.stock ?? 1,
			};
		}
	};

	const itemEvent = {
		affiliation: 'KlikDokter',
		coupon: '',
		currency: 'IDR',
		discount: item.promo,
		index: 0,
		item_brand: 'KlikDokter',
		item_category: item.itemCategory,
		item_list_id: '',
		item_list_name: item.itemListName,
		location_id: '',
		price: Number(item.price || item.final_price || 0),
		quantity: 1,
		...generateItemObj(convertToArray(item.itemCategory)[0], item),
	};

	// check if kalstore
	let valueFinal: number = Number(item.price || item.final_price || 0);
	if (Array.isArray(item?.prices)) {
		// is kalstore
		const priceKalstore = item.prices;
		valueFinal = Number(priceKalstore.selling_price || priceKalstore.special_price || 0);
	}

	const objViewItem = {
		event: 'view_item',
		ecommerce: {
			currency: 'IDR',
			value: valueFinal,
			items: [itemEvent],
		},
	};

	analyticsDataLayerProcess(objViewItem);
};

export const googleViewItemListEvent = (
	items: any,
	itemCategory: string | string[],
	itemListName: string,
) => {
	const itemEvent: any[] = [];

	const generateItemObj = (procedure: string, attribute: any) => {
		if (procedure === 'Temu Dokter') {
			return {
				item_id: attribute.id || attribute.master_uid || '',
				item_name: attribute.name,
				item_category2: '',
				item_category3: attribute.specialist_name,
				item_category4: '',
				item_category5: attribute.medical_facility_name,
				item_variant: attribute.medical_facility_type_name || '',
				discount: attribute.normal_price - attribute.price,
			};
		} else if (procedure === 'Layanan Medis Lab') {
			return {
				item_id: attribute.medical_facility_uid,
				item_name: attribute.medical_facility_name,
				item_category2: '',
				item_category3: '',
				item_category4: attribute.name,
				item_category5: '',
				item_variant: ucwords(attribute.medical_facility_type_code) || '',
				discount: attribute.normal_price - attribute.price,
			};
		} else if (procedure === 'Tanya Dokter') {
			return {
				item_id: attribute.uid || '',
				item_name: attribute.display_name,
				item_category2: attribute.filterType || '',
				item_category3: attribute.speciality,
				item_category4: '',
				item_category5: '',
				item_variant: '',
				discount: attribute.initial_price - attribute.final_price,
			};
		} else if (procedure === 'KALStore') {
			const attr: any = { ...attribute };
			return {
				affiliation: attr?.merchant?.name,
				item_id: attr?.id,
				item_name: attr?.name,
				discount: attr?.prices?.selling_price - attr?.prices?.special_price,
				item_brand: attr?.brand_code, // zee | fitbar | milna | blackmores | hydrococo | etc
				item_category: 'KALStore',
				item_category2:
					convertToArray(attr.itemCategory)[1] ?? convertToArray(itemCategory)[1] ?? '', // Ibu, bayi dan anak | Perawatan Pribadi | Nutrisi Khusus | etc
				item_category3:
					convertToArray(attr.itemCategory)[2] ?? convertToArray(itemCategory)[2] ?? '', // Susu anak | Perawatan Dewasa | Alat Bantu Kesehatan | etc
				item_category4: '',
				item_category5: '',
				item_list_name: itemListName ?? '', // Produk Terkait | Produk Rekomendasi | Berdasarkan Kategori
				item_variant: '',
				price: attr?.prices?.special_price,
				quantity: attr?.merchant?.stock ?? 1,
			};
		}
	};

	items?.forEach((dt: any, index: number) => {
		const attribute = dt.document || dt;
		itemEvent.push({
			affiliation: 'KlikDokter',
			coupon: '',
			currency: 'IDR',
			discount: 0,
			index,
			item_brand: 'KlikDokter',
			item_category: convertToArray(itemCategory)[0],
			item_list_id: '',
			item_list_name: itemListName,
			location_id: '',
			price: Number(attribute.price || attribute.final_price || 0),
			quantity: 1,
			...generateItemObj(convertToArray(itemCategory)[0], attribute),
		});
	});

	const objViewItem = {
		event: 'view_item_list',
		ecommerce: {
			items: itemEvent,
		},
	};

	analyticsDataLayerProcess(objViewItem);
};

export const googleArticleEvent = (
	item: IArticleByGroupDetail,
	userAuth: any,
	procedure: 'click' | 'load',
) => {
	const event = `article_${procedure}`;
	const articleCategories =
		item.categories && item.categories[0].full_slug
			? ucwords(item.categories[0].full_slug.split('/')[0])
			: '';

	const articleSubCategories =
		item.categories && item.categories[0].name ? item.categories[0].name : '';

	analyticsDataLayerProcess(
		{
			event,
			article_id: item.uid,
			article_name: item.title,
			article_categories: articleCategories,
			article_subcategories: articleSubCategories,
			user_id: userAuth.id ? userAuth.id : '',
		},
		false,
	);
};

export const detectDoctorRecommendationGA = (routeQuery: any) => {
	const titleDokterElem = document.getElementById('title-dokter-rekomendasi');
	const isTitleDokterRekomendasi =
		titleDokterElem?.innerText === 'Dokter Rekomendasi' ||
		titleDokterElem?.textContent === 'Dokter Rekomendasi';
	const isRouteDokterRekomendasi = Object.keys(routeQuery).length && routeQuery.recommendation;

	return isTitleDokterRekomendasi || isRouteDokterRekomendasi;
};

export const getGAClientId = () => {
	const cookies = cookie.loadAll() as Record<string, string>;
	return cookies ? Object.values(cookies)?.find((val) => val?.startsWith?.('GA1.1')) : '';
};
