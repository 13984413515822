import { useRouter } from 'next/router';
import { Typography } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import { Title } from 'src/components/Layout/Typography';
import { PageHeaderProps } from 'src/interface';

import PageHeaderBreadcrumb from './PageHeaderBreadcrumb';

import classes from './index.module.less';

const { Text } = Typography;

const PageHeader = (props: PageHeaderProps) => {
	const r = useRouter();

	return (
		<>
			{props.title && (
				<div
					className={classNames(
						classes.wrapper,
						{
							[classes['wrapper-space']]: props.breadcrumbs,
							[classes['wrapper-space-white']]: props.isWhiteSpaceOnMobile,
						},
						props.className ?? '',
					)}
				>
					<div
						className={classes.header}
						style={props.color ? { backgroundColor: props.color } : {}}
					>
						<div className={`container ${classes.box}`}>
							<div className="d-flex align-items-center">
								{props.showBackButton && (
									<div className={classNames(classes['back-btn'], 'mr-3')} onClick={() => r.back()}>
										<ImageC alt="Icon Back" height={32} width={32} src="/icons/arrow-left.svg" />
									</div>
								)}

								{props.hideHeadingTitle ? (
									<Text className={classes.title}>{props.title}</Text>
								) : (
									<Title level={1} text={props.title} className={classes.title} />
								)}
							</div>
							<PageHeaderBreadcrumb breadcrumbs={props.breadcrumbs} withTitle={true} />
						</div>
					</div>
				</div>
			)}

			{!props.title && <PageHeaderBreadcrumb breadcrumbs={props.breadcrumbs} withTitle={false} />}
		</>
	);
};

export default PageHeader;
