import { useQuery } from 'react-query';

import { fetchApiMarketplace } from 'src/utils/fetch-api';

import { bannersFields } from '../Landing';

export const useFloorTypeBrandBanners = () => {
	return useQuery(
		['floorBrandBanners'],
		() =>
			fetchApiMarketplace({
				url: '/search/banners',
				options: { method: 'GET' },
				payload: {
					fields: bannersFields,
					category_slug: 'general-banner',
				},
			}),
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};
