import { useQuery } from 'react-query';
import { PaginationProps } from 'antd';

import { TYPESENSE } from 'src/constants/services';
import { DataFilterTypesense, UserLocation } from 'src/interface';
import { fetchApiTypesense } from 'src/utils/fetch-api';
import { filterBy } from 'src/utils/func';

export interface UseHealthServicesMedical {
	pagination: PaginationProps;
	search: string;
	filter: DataFilterTypesense[];
	location: UserLocation;
	disableTriggerFetch?: boolean;
}

export const useHealthServicesMedical = (props: UseHealthServicesMedical) => {
	const { disableTriggerFetch, ...exProps } = props;
	return useQuery(
		['useHealthServicesMedical', exProps],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							q: '*',
							query_by: 'name,type_name,city,facilities',
							collection: TYPESENSE.model.medicalFacilities,
							facet_by: 'city,type_name,facilities',
							max_facet_values: 5,
							filter_by: filterBy(props.filter),
							sort_by: `location(${props.location.lat},${props.location.lng}):asc`,
							include_fields: 'name,photo_highres,slug,type_code,type_name,id',
							page: props.pagination.current,
							per_page: props.pagination.pageSize,
						},
						{
							q: '*',
							query_by: 'name,type_name,city,facilities',
							collection: TYPESENSE.model.medicalFacilities,
							facet_by: 'city',
							facet_query: `city:${props.search}`,
							max_facet_values: 100,
							include_fields: 'name',
							per_page: 1,
						},
						{
							q: '*',
							query_by: 'name,type_name,city,facilities',
							collection: TYPESENSE.model.medicalFacilities,
							facet_by: 'type_name',
							max_facet_values: 5,
							include_fields: 'name',
							per_page: 1,
						},
						{
							q: '*',
							query_by: 'name,type_name,city,facilities',
							collection: TYPESENSE.model.medicalFacilities,
							facet_by: 'facilities',
							facet_query: `facilities:${props.search}`,
							max_facet_values: 100,
							include_fields: 'name',
							per_page: 1,
						},
						{
							q: '*',
							query_by: 'name,type_name,city,facilities',
							collection: TYPESENSE.model.medicalFacilities,
							facet_by: 'city',
							max_facet_values: 5,
							include_fields: 'name',
							per_page: 1,
						},
						{
							q: '*',
							query_by: 'name,type_name,city,facilities',
							collection: TYPESENSE.model.medicalFacilities,
							facet_by: 'facilities',
							max_facet_values: 5,
							include_fields: 'name',
							per_page: 1,
						},
					],
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !disableTriggerFetch },
	);
};
