const deviceSize = (ctx?: any) => {
	let DS = 'desktop';
	let UA = '';

	if (ctx?.req) {
		UA = ctx?.req?.headers['user-agent'];
	} else {
		UA = global.navigator?.userAgent;
	}

	if (UA) {
		const isMobile = Boolean(UA.match(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile/i));
		const isTablet = Boolean(UA.match(/(iPad|Tablet|kindle|playbook|silk)/i));

		if (isMobile) {
			DS = 'mobile';
		} else if (isTablet) {
			DS = 'tablet';
		}
	}

	return DS;
};

export default deviceSize;
