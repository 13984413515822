export const HOMEPAGE_TYPE = {
	model: {
		banner: {
			success: 'GET_BANNER_SUCCESS',
		},
		download: {
			success: 'GET_DOWNLOAD_SUCCESS',
		},
	},
};
