import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { PaginationProps } from 'antd';

import g from 'src/constants/global';
import { CHAT, DOCTOR, INSTANT_CHATS, MEET, VIDEO_CALL } from 'src/constants/services';
import authStorage from 'src/utils/auth-storage';
import { fetchApiConsultation, fetchApiJM } from 'src/utils/fetch-api';
import { splitAndRemoveAlphabet } from 'src/utils/func';
import dayjs from 'src/utils/moment';

const { chat, videoCall, janjiMedis, tatapMuka } = g.DOCTOR.SERVICES;
const { reservationDoctor, reservationService, consultationFace } = g.PROFILE.TRANSACTION.SERVICE;

const doctors = 'doctors';
const doctorFavorite = 'doctor_favorite';
const doctorPrice = 'doctor_price';
const doctorSchedules = 'doctor_schedules';
const doctorAvailability = 'doctor_availability';
const doctorClinics = 'doctor_clinics';
const doctorTabCalendar = 'doctor_tab_calendar';
const doctorInfo = 'doctor_info';
const doctorDetails = 'doctor_details';
const patientStory = 'patient_story';
const patientStoryLike = 'patient_story_like';
const patientStoryUnike = 'patient_story_unlike';
const createOrder = 'create_order';
const createAppointmentJMDoctor = 'create_appointment_doctor';
const updateAppointmentJMDoctor = 'update_appointment_doctor';

export const useDoctors = (
	location: string,
	search: string,
	consultation_type: string,
	consultation_price: string,
	experience: string,
	filter: any,
	pagination: PaginationProps,
	enabled: boolean,
	additionalFilter?: string,
) => {
	return useQuery(
		[
			doctors,
			location,
			search,
			consultation_type,
			consultation_price,
			experience,
			filter,
			pagination?.current,
			additionalFilter || '',
			authStorage.tokenDecode.id || '',
		],
		() => {
			return ['[]', ''].includes(filter)
				? ''
				: fetchApiConsultation({
						url: `/${DOCTOR.service}/`,
						payload: {
							search: !filter && !additionalFilter ? search : '',
							page: pagination?.current,
							limit: pagination?.pageSize,
							filter: JSON.stringify({
								speciality: splitAndRemoveAlphabet(filter),
								location: location ? [location] : [],
								consultation_type: consultation_type ? [consultation_type] : [],
								consultation_price: consultation_price ? [consultation_price] : [],
								years_experience: experience ? [experience] : [],
								...(additionalFilter ? JSON.parse(additionalFilter) : {}),
							}),
						},
				  });
		},
		{ retry: false, refetchOnWindowFocus: false, enabled: enabled },
	);
};
export const useDoctorRecommendation = ({
	enabled = true,
	limit,
	filter,
}: {
	enabled?: boolean;
	limit?: number;
	filter?: any;
}) => {
	return useQuery(
		['doctorRecommendation', authStorage.tokenDecode.id || ''],
		() => {
			return fetchApiConsultation({
				url: `/${DOCTOR.service}/${!filter ? DOCTOR.model.recommendations : ''}`,
				payload: { limit, filter },
			});
		},
		{ enabled, refetchOnWindowFocus: false },
	);
};

export const useSearchSuggestions = (
	search: string,
	search_type: string,
	enabled: boolean = true,
) => {
	return useQuery(
		['searchSuggestions', search],
		() => {
			if (search_type == 'medicalAppointment') {
				return fetchApiJM({
					url: `/${DOCTOR.model.searchSuggestions}`,
					payload: { search },
				});
			}
			return fetchApiConsultation({
				url: `/${DOCTOR.service}/${DOCTOR.model.searchSuggestions}`,
				payload: { search },
			});
		},
		{ enabled: !!search && enabled, refetchOnWindowFocus: false },
	);
};

export const useDoctorAvailability = (
	doctoruid: any,
	date: string,
	type: string,
	hospital_uid?: any,
	schedule_uid?: any,
) => {
	return useQuery(
		[doctorAvailability, doctoruid, schedule_uid],
		() => {
			const d = date.replace(/\//g, '') !== 'undefined' ? date.replace(/\//g, '') : dayjs();
			const setDate = dayjs(d).format('YYYY-MM-DD');

			if (doctoruid && type && schedule_uid !== null) {
				return fetchApiConsultation({
					url: `/${DOCTOR.service}/${doctoruid}/${DOCTOR.model.availability}`,
					payload: { date: setDate, type, hospital_uid, schedule_uid },
				});
			}
		},
		{
			retry: false,
			enabled: !!doctoruid && !!date && !!type && (type === 'chat' ? true : !!schedule_uid),
			refetchOnWindowFocus: false,
		},
	);
};

export const useDoctorCheckAvailability = () => {
	const queryClient = useQueryClient();
	const checkAvailability = ({ doctorUid, date, hospitalUid, scheduleUid, type }: any) => {
		const d = date.replace(/\//g, '') !== 'undefined' ? date.replace(/\//g, '') : dayjs();
		const setDate = dayjs(d).format('YYYY-MM-DD');

		return fetchApiConsultation({
			url: `/${DOCTOR.service}/${doctorUid}/${DOCTOR.model.availability}`,
			payload: { date: setDate, type, hospital_uid: hospitalUid, schedule_uid: scheduleUid },
		});
	};

	return useMutation(
		({ doctorUid, date, hospitalUid, scheduleUid, type }: any) =>
			checkAvailability({ doctorUid, date, hospitalUid, scheduleUid, type }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(patientStory);
			},
		},
	);
};

export const useDoctorInformation = (uid: any) => {
	return useQuery(
		[doctorInfo, uid],
		() => fetchApiConsultation({ url: `/${DOCTOR.service}/${uid}/${DOCTOR.model.information}` }),
		{ enabled: !!uid, keepPreviousData: true, refetchOnMount: false, refetchOnWindowFocus: false },
	);
};

export const useDoctorDetails = (slug: any) => {
	return useQuery(
		[doctorDetails, slug, authStorage.tokenDecode.id || ''],
		() => {
			if (slug) return fetchApiConsultation({ url: `/${DOCTOR.service}/${slug}` });
		},
		{ retry: false, refetchOnWindowFocus: false },
	);
};

export const usePatientStory = (uid: any) => {
	return useInfiniteQuery(
		[patientStory],
		({ pageParam = 1 }) => {
			return fetchApiConsultation({
				url: `/${DOCTOR.service}/${uid}/${DOCTOR.model.reviews}`,
				payload: { page: pageParam, limit: 5 },
			});
		},
		{
			retry: false,
			retryOnMount: false,
			enabled: !!uid,
			refetchOnWindowFocus: false,
			cacheTime: 2500,
			getNextPageParam: (res) =>
				res?.pagination?.page < res?.pagination?.total_page ? res?.pagination?.page + 1 : undefined,
		},
	);
};

export const useLikeReview = () => {
	const queryClient = useQueryClient();
	const addLike = ({ uid, reviewID }: any) => {
		return fetchApiConsultation({
			url: `/${DOCTOR.service}/${uid}/${DOCTOR.model.reviews}/${reviewID}`,
			options: { method: 'POST' },
		});
	};

	return useMutation(({ uid, reviewID }: any) => addLike({ uid, reviewID }), {
		onSettled: () => {
			queryClient.invalidateQueries(patientStoryLike);
		},
	});
};

export const useUnlikeReview = () => {
	const queryClient = useQueryClient();
	const deleteLike = ({ uid, reviewID }: any) => {
		return fetchApiConsultation({
			url: `/${DOCTOR.service}/${uid}/${DOCTOR.model.reviews}/${reviewID}`,
			options: { method: 'DELETE' },
		});
	};

	return useMutation(({ uid, reviewID }: any) => deleteLike({ uid, reviewID }), {
		onSettled: () => {
			queryClient.invalidateQueries(patientStoryUnike);
		},
	});
};

export const useDoctorSchedules = (uid: any, date: string, type: string, hospital_uid?: any) => {
	return useQuery(
		[doctorSchedules, uid, date, type, hospital_uid],
		() => {
			const d = date.replace(/\//g, '') !== 'undefined' ? date.replace(/\//g, '') : dayjs();
			const setDate = dayjs(d).format('YYYY-MM-DD');

			if (uid && type) {
				return fetchApiConsultation({
					url: `/${DOCTOR.service}/${uid}/${DOCTOR.model.schedules}`,
					payload: { date: setDate, type, hospital_uid },
				});
			}
		},
		{ refetchOnWindowFocus: false },
	);
};

export const useDoctorClinics = (uid: any) => {
	return useQuery(
		[doctorClinics, uid],
		() => fetchApiConsultation({ url: `/${DOCTOR.service}/${uid}/${DOCTOR.model.clinics}` }),
		{ enabled: !!uid, refetchOnWindowFocus: false },
	);
};

export const useDoctorTabCalendar = (
	uid: any,
	date: string,
	type: string,
	hospital_uid: any,
	calendarDates: string,
) => {
	return useQuery(
		[doctorTabCalendar, uid, type, hospital_uid, calendarDates],
		() => {
			if (uid && type) {
				return fetchApiConsultation({
					url: `/${DOCTOR.service}/${uid}/${DOCTOR.model.tabCalendar}
					?date=${date}&type=${type}&hospital_uid=${hospital_uid}`,
				});
			}
		},
		{ enabled: !!type, refetchOnWindowFocus: false },
	);
};

export const useDoctorPrice = (
	{
		uid,
		date,
		type,
		hospital_uid,
	}: {
		uid: any;
		date: string;
		type: string;
		hospital_uid: any;
	},
	queryOptions?: Record<string, any>,
) => {
	return useQuery(
		[doctorPrice, uid, type],
		() => {
			if (uid && type) {
				return fetchApiConsultation({
					url: `/${DOCTOR.service}/${uid}/${DOCTOR.model.price}`,
					payload: { date, type, hospital_uid },
				});
			}
		},
		{
			retry: false,
			retryOnMount: false,
			enabled: true,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			...(queryOptions || {}),
		},
	);
};

export const useAddFavoriteDoctor = () => {
	const queryClient = useQueryClient();
	const addFavorite = (uid: any) => {
		return fetchApiConsultation({
			url: `/${DOCTOR.service}/${uid}/${DOCTOR.model.favorite}`,
			options: { method: 'POST' },
		});
	};

	return useMutation((uid: any) => addFavorite(uid), {
		onSettled: () => {
			queryClient.invalidateQueries(doctorFavorite);
		},
	});
};

export const useRemoveFavoriteDoctor = () => {
	const queryClient = useQueryClient();
	const removeFavorite = (uid: any) => {
		return fetchApiConsultation({
			url: `/${DOCTOR.service}/${uid}/${DOCTOR.model.favorite}`,
			options: { method: 'DELETE' },
		});
	};

	return useMutation((uid: any) => removeFavorite(uid), {
		onSettled: () => {
			queryClient.invalidateQueries(doctorFavorite);
		},
	});
};

export const useCreateOrderConsultation = () => {
	const queryClient = useQueryClient();
	const getOrderId = ({ doctorUid, date, hospitalUid, scheduleUid, type }: any) => {
		const d = date.replace(/\//g, '') !== 'undefined' ? date.replace(/\//g, '') : dayjs();
		const setDate = dayjs(d).format('YYYY-MM-DD');
		switch (type) {
			case chat:
				return fetchApiConsultation({
					url: `/${CHAT.service}/${CHAT.model.order}`,
					options: { method: 'post' },
					payload: { doctor_uid: doctorUid },
				});
			case videoCall:
				return fetchApiConsultation({
					url: `/${VIDEO_CALL.service}/${VIDEO_CALL.model.order}`,
					options: { method: 'post' },
					payload: {
						doctor_uid: doctorUid,
						book_date: setDate,
						hospital_uid: hospitalUid,
						schedule_uid: scheduleUid,
					},
				});
			case janjiMedis:
				return fetchApiJM({
					url: `/${MEET.model.order}`,
					options: { method: 'POST' },
					payload: { schedule_uid: scheduleUid, book_date: date },
				});
			default:
				return fetchApiConsultation({
					url: `/${CHAT.service}/${CHAT.model.order}`,
					options: { method: 'post' },
					payload: { doctor_uid: doctorUid },
				});
		}
	};

	return useMutation(
		({ doctorUid, date, hospitalUid, scheduleUid, type }: any) =>
			getOrderId({ doctorUid, date, hospitalUid, scheduleUid, type }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(createOrder);
			},
		},
	);
};

export const useCreateAppointmentJMDoctor = () => {
	const queryClient = useQueryClient();
	const createAppointment = (params: any) => {
		return fetchApiJM({
			url: '/appointment-doctors',
			options: { method: 'POST' },
			payload: { schedule_uid: params?.schedule_uid, book_date: params?.book_date },
		});
	};

	return useMutation((params: any) => createAppointment(params), {
		onSettled: () => {
			queryClient.invalidateQueries(createAppointmentJMDoctor);
		},
	});
};

export const useUpdateAppointment = () => {
	const queryClient = useQueryClient();
	const updateAppointment = ({ params, orderId, consultationType }: any) => {
		let modulePah = '';

		if (consultationType === reservationDoctor || consultationType === reservationService) {
			const moduleOrderId = orderId.substr(0, 3);
			modulePah = moduleOrderId === 'JMD' ? 'doctors' : 'services';
		} else if (consultationType === consultationFace) {
			modulePah = tatapMuka;
		}
		return fetchApiJM({
			url: `/appointment-${modulePah}/${orderId}`,
			options: { method: 'PUT' },
			payload: params,
		});
	};
	return useMutation(
		({ params, orderId, consultationType }: any) =>
			updateAppointment({ params, orderId, consultationType }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(updateAppointmentJMDoctor);
			},
		},
	);
};

export const useDoctorRecommendationList = ({
	enabled = true,
	limit,
	filter,
	location,
}: {
	enabled?: boolean;
	limit?: number;
	filter?: any;
	location?: string;
}) => {
	return useQuery(
		['doctorRecommendationList', location, filter],
		() => {
			return fetchApiConsultation({
				url: `/${DOCTOR.service}/${DOCTOR.model.recommendations}`,
				payload: {
					limit,
					filter: JSON.stringify({
						location: location != '' ? [location] : [],
						consultation_type: filter.type && filter.type !== 'all' ? [filter.type] : [],
						consultation_price: filter.price ? [filter.price] : [],
						years_experience: filter.experience ? [filter.experience] : [],
					}),
				},
			});
		},
		{ enabled, refetchOnWindowFocus: false, refetchOnMount: false },
	);
};

export const useDoctorInstant = (enabled?: boolean) => {
	return useQuery(
		['doctorInstant'],
		() => {
			return fetchApiConsultation({
				url: `/${INSTANT_CHATS.service}/${INSTANT_CHATS.model.schedulesActive}`,
			});
		},
		{ enabled, refetchOnWindowFocus: false },
	);
};

export const useOrderInstantChat = () => {
	const orderInstantChat = (params: any) =>
		fetchApiConsultation({
			url: `/${INSTANT_CHATS.service}
			/${INSTANT_CHATS.model.schedules}/${params}/${INSTANT_CHATS.model.order}`,
			options: { method: 'POST' },
		});

	return useMutation(orderInstantChat);
};

export const useReorderInstantChat = () => {
	const reorderInstantChat = (transId: string) =>
		fetchApiConsultation({
			url: `/${INSTANT_CHATS.service}/${INSTANT_CHATS.model.orders}/${encodeURIComponent(
				transId,
			)}/${INSTANT_CHATS.model.requeue}`,
			options: { method: 'PUT' },
		});

	return useMutation(reorderInstantChat);
};

export const useCancelInstantChat = () => {
	const cancelInstantChat = (transId: string) =>
		fetchApiConsultation({
			url: `/${INSTANT_CHATS.service}/${INSTANT_CHATS.model.orders}/${encodeURIComponent(
				transId,
			)}/${INSTANT_CHATS.model.cancel}`,
			options: { method: 'DELETE' },
		});

	return useMutation(cancelInstantChat);
};
