import { QUICKAUTH_TYPE } from 'src/constants/redux/klikstore/quickauth';
import { STORE_DATA } from 'src/redux/actions/types';

export const actionSetQuickAuthDisplay = (payload: any = null) => {
	return {
		type: STORE_DATA,
		payload: {
			successType: QUICKAUTH_TYPE.model.quickauth.success,
			payload: payload,
		},
	};
};
