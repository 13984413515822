import LINK from 'src/constants/urls';

const metadata = {
	APP_NAME: 'KlikDokter',
	APP_DESCRIPTION: 'KlikDokter - Konsultasi Medis, Booking RS dan Pesan Obat',
	APP_NAME_MP: 'Jual Produk Kesehatan Lengkap dan Termurah - KALStore',
	APP_DESCRIPTION_MP:
		'Beli berbagai produk kesehatan secara online. ' +
		'Belanja terlengkap dengan harga terbaik, ' +
		'banyak promo dan pengiriman cepat hanya di KALStore.',
	FB_APP_ID: '',
	IMG_SHARE: LINK.WEB_URL + '/icons/512x512.png',
	WEB_URL: LINK.WEB_URL,
	PRIMARY_COLOR: '#00a376',
};

export default metadata;
