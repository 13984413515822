import { KALSTORE_MODULE_TYPE } from 'src/constants/redux/klikstore/module';
import { STORE_DATA } from 'src/redux/actions/types';

export const actionSetIsKalstoreModule = (payload: boolean = false) => {
	return {
		type: STORE_DATA,
		payload: {
			successType: KALSTORE_MODULE_TYPE.model.isKalstore.success,
			payload: payload,
		},
	};
};
