import { DataFilterTypesense, DataOptions, UserLocation } from 'src/interface';
import dayjs from 'src/utils/moment';

export const titleFilterCheckbox = (name: string) => {
	switch (name) {
		case 'city':
		case 'medical_facility_city':
		case 'location':
			return 'Lokasi';
		case 'merchant_type':
			return 'Tipe Toko';
		case 'type_name':
		case 'medical_facility_type_name':
			return 'Tipe Faskes';
		case 'facilities':
			return 'Fasilitas';
		case 'price':
			return 'Harga';
		case 'brand':
			return 'Brand';
		case 'graduation_date':
			return 'Pengalaman Praktik';
		default:
			return name;
	}
};

interface SortByOptions {
	location?: UserLocation;
	sortDefault?: string;
}

export const sortedBy = (sortKey: string, items: DataOptions[], options?: SortByOptions) => {
	let sort: string = '';

	items
		.filter((val: any) => val.value.length > 0)
		.forEach(({ value }: DataOptions, i: number) => {
			if (value !== 'nearby' && value === sortKey) {
				sort += `${i === 0 || sort === '' ? '' : ','}${String(value).replace('_', ':')}`;
			}
		});

	if (options?.location && items.find((val: DataOptions) => val.value === 'nearby')) {
		const { lat, lng } = options.location;
		sort += `${sort === '' ? '' : ','}location(${lat},${lng}):asc`;
	}

	if (options?.sortDefault) {
		sort += `${sort === '' ? '' : ','}${options.sortDefault}`;
	}

	return sort;
};

interface FilterByOptions {
	exceptFieldName?: string[];
	includeFieldName?: string[];
	operator?: string;
}

export const filterBy = (items: DataFilterTypesense[], options?: FilterByOptions) => {
	let filter: string = '';
	const operator = options?.operator || '';

	if (options?.includeFieldName) {
		items
			.filter((val: any) => val.value.length > 0)
			.forEach(({ field_name, value }: DataFilterTypesense, i: number) => {
				if (options?.includeFieldName?.includes(field_name)) {
					filter += `${i === 0 || filter === '' ? '' : '&&'}${field_name}:${operator}[${value}]`;
				}
			});

		return filter;
	}

	items
		.filter((val: any) => val.field_name.length > 0 && val.value.length > 0)
		.forEach(({ field_name, value }: DataFilterTypesense, i: number) => {
			if (!options?.exceptFieldName?.includes(field_name)) {
				filter += `${i === 0 || filter === '' ? '' : '&&'}${field_name}:${operator}[${value}]`;
			}
		});

	return filter;
};

export const getNestedFilters = (filtersData: any) => {
	let results: any = [];
	filtersData?.forEach((v: any) => {
		const value = v?.value;
		const splits = value?.split('/');
		const category = splits?.[0];
		const subcategory = splits?.[1];
		const findParentResult = results?.find((result: any) => result.category === category);
		if (findParentResult) {
			findParentResult?.subcategories?.push({ category, label: subcategory, value });
		} else {
			results.push({ category, subcategories: [{ category, label: subcategory, value }] });
		}
	});
	return results;
};

export const geoDistanceMeterToKm = (
	distance: number = 0,
	digit: number = 1,
	withUnit: boolean = true,
) => {
	const convertedDistance = distance / 1000;
	const fixedDigit = Math.pow(10, digit);
	const roundedDistance = Math.ceil(convertedDistance * fixedDigit) / fixedDigit;
	return withUnit ? `${roundedDistance} km` : roundedDistance;
};

export const calculateExperience = (graduationDate: number = 0) => {
	if (!graduationDate) return 0;

	return dayjs()
		.local()
		.diff(dayjs(dayjs.unix(graduationDate)), 'y');
};

export const serializeFilter = (filters: DataFilterTypesense[]) => {
	return filters.reduce((acc, filter) => {
		let value = `${filter.field_name}:`;
		if (Array.isArray(filter.value)) {
			if (filter.value.length === 0) return acc;
			value += `[${filter.value}]`;
		}
		acc += acc;
		return acc === '' ? value : `&&${value}`;
	}, '');
};

export const sortFilter = (filters: DataFilterTypesense[]) => {
	let newFilters = filters;
	// sort by field_name asc
	newFilters = [...newFilters]?.sort((a, b) => {
		if (a.field_name > b.field_name) return 1;
		if (b.field_name > a.field_name) return -1;
		return 0;
	});
	// sort each value of field_name asc
	newFilters = newFilters?.map(({ field_name, value }) => {
		return {
			field_name,
			value: [...value].sort(),
		};
	});
	return newFilters;
};

export const removeEmptyFilter = (filters: DataFilterTypesense[]) => {
	return filters.filter((val: any) => val.field_name.length > 0 && val.value.length > 0);
};

export const slugify = (string: string) => {
	return string
		.normalize('NFD') // split an accented letter in the base letter and the acent
		.replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
		.toLowerCase()
		.trim()
		.replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
		.replace(/\s+/g, '-')
		.replace(/^-?(.*?)-?$/, '$1'); // remove hyphen at first or last strings
};

export const slugToLabel = (string: string) =>
	string
		.split('-')
		.map((s) => `${s[0].toUpperCase()}${s.substring(1)}`)
		.join(' ');
