import g from 'src/constants/global';

const {
	consultationChat,
	consultationVideo,
	consultationFace,
	reservationDoctor,
	reservationService,
	prescriptionRedeem,
	prescriptionUpload,
	healthyShopping,
	klikstore,
} = g.PROFILE.TRANSACTION.SERVICE;

export const moduleIcon = (module: string) => {
	switch (module) {
		case klikstore:
			return '/icons/store-icon.svg';
		case consultationChat:
			return '/icons/chat.svg';
		case consultationVideo:
			return '/icons/video.svg';
		case consultationFace:
			return '/icons/face-to-face.svg';
		case reservationDoctor:
		case reservationService:
			return '/icons/hospital.svg';
		case prescriptionRedeem:
			return '/icons/prescription/prescription.svg';
		case prescriptionUpload:
			return '/icons/prescription/prescription.svg';
		case healthyShopping:
			return '/icons/shop-gray.svg';
		default:
			return '/icons/empty.svg';
	}
};
