import Geocode from 'react-geocode';

import g from 'src/constants/global';

export const getGeocodeFromLatLag = (latitude: number, longitude: number) => {
	return Geocode.fromLatLng(
		latitude?.toString(),
		longitude?.toString(),
		g.MAP.KEY,
		g.MAP.LANGUAGE,
		g.MAP.REGION,
	);
};

export const getGeocodeFromCityName = (cityName: string) => {
	return Geocode.fromAddress(cityName, g.MAP.KEY, g.MAP.LANGUAGE);
};

export const getGeocodeLocationDetail = (array: any, detail: any) => {
	array.map((item: any) => {
		if (item?.types.find((type: string) => type === g.MAP.TYPES.subdistrict)) {
			detail.kelurahan = item.long_name;
		}
		if (item?.types.find((type: string) => type === g.MAP.TYPES.district)) {
			detail.kecamatan = item.long_name;
		}
		if (item?.types.find((type: string) => type === g.MAP.TYPES.city)) {
			detail.city = item.long_name;
		}
		if (item?.types.find((type: string) => type === g.MAP.TYPES.province)) {
			detail.province = item.long_name;
		}
		if (item?.types.find((type: string) => type === g.MAP.TYPES.postal_code)) {
			detail.postal_code = item.long_name;
		}
	});
};
