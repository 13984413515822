import ImageC from 'src/components/Image';

import classes from './Arrow.module.less';

export const NextArrow = (props: any) => {
	return (
		<div
			className={`${props.className} ${classes['container-img']}`}
			style={{ ...props.style, width: `${props.size}px`, height: `${props.size}px` }}
			onClick={props.onClick}
		>
			<div
				className={classes['btn-prev']}
				style={{
					width: `${props.sizeArrow}px`,
					height: `${props.sizeArrow}px`,
				}}
			>
				<ImageC
					alt="Icon Prev"
					src="/icons/next-arrow.svg"
					layout="fill"
					objectFit="contain"
					onClick={props.onClick}
				/>
			</div>
		</div>
	);
};

export const PrevArrow = (props: any) => {
	return (
		<div
			className={`${props.className} ${classes['container-img']}`}
			style={{ ...props.style, width: `${props.size}px`, height: `${props.size}px` }}
			onClick={props.onClick}
		>
			<div
				className={classes['btn-prev']}
				style={{
					width: `${props.sizeArrow}px`,
					height: `${props.sizeArrow}px`,
				}}
			>
				<ImageC
					alt="Icon Prev"
					src="/icons/prev-arrow.svg"
					layout="fill"
					objectFit="contain"
					onClick={props.onClick}
				/>
			</div>
		</div>
	);
};
