import { useQuery } from 'react-query';

import { platformEncoded } from 'src/constants/platform';
import { PUBLISHING_KD } from 'src/constants/services';
import { ResponseData } from 'src/interface/Commons';
import { fetchApiV3Publishing } from 'src/utils/fetch-api';

const useCloseFeature = (module?: string, enabled?: boolean) => {
	const { data, ...restAttributes } = useQuery(
		[module],
		(): Promise<ResponseData<{ record: any }, any>> =>
			fetchApiV3Publishing({
				url: `/${PUBLISHING_KD.service}/${PUBLISHING_KD.model.closeFeature}/${module}`,
				options: {
					method: 'GET',
					headers: {
						'x-api-platform': platformEncoded,
					},
				},
			}),
		{
			retry: true,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			enabled,
		},
	);

	return {
		data: data?.data?.record,
		meta: data?.meta,
		...restAttributes,
	};
};

export default useCloseFeature;
