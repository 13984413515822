import { useState } from 'react';
import { notification, Typography } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import { isBrowser } from 'src/constants/environment';

import ModalC from '../Modal';

import classes from './index.module.less';

type ModalGeneralErrorProps = {};

const closeModal = () => notification.destroy();

const ModalGeneralError: React.FC<ModalGeneralErrorProps> = () => {
	const [isLoading, setIsLoading] = useState(false);

	const onClickRetry = () => {
		window?.location?.reload();
		setIsLoading(true);
	};

	return (
		<ModalC
			visible
			closable
			okText="Coba Lagi"
			justOkButton
			okButtonProps={{
				className: classNames('w-100', classes.button),
			}}
			onCancel={closeModal}
			onOk={onClickRetry}
			loading={isLoading}
		>
			<div className={classes['content-wrapper']}>
				<ImageC width={200} height={200} src="/icons/general-error.svg" alt="error-retry-image" />
				<Typography.Title level={5} className="fs-16 fs-sm-18">
					Terjadi Gangguan
				</Typography.Title>
				<Typography.Text
					className={classNames('fs-md-14 fw-400 fs-14 text-neutral-7', classes.description)}
				>
					Sepertinya ada sedikit gangguan di KlikDokter, silakan tap coba lagi yah!
				</Typography.Text>
			</div>
		</ModalC>
	);
};
export const setGeneralError = () => {
	if (isBrowser) {
		const isShouldShowError = window.location.pathname.includes('/dokter/');
		if (isShouldShowError) {
			notification.open({
				key: 'General Error',
				message: 'Oops!',
				description: <ModalGeneralError />,
				duration: 0,
				style: {
					display: 'none',
				},
			});
		}
	}
};

export default ModalGeneralError;
