import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Space, Typography } from 'antd';
import xs from 'xstream';

import global from 'src/constants/global';
import { actionUserReactivate } from 'src/redux/actions/auth';

import ButtonC from '../Forms/Button';
import ImageC from '../Image';
import ModalC from '../Modal';

import classes from './AuthReactivateModal.module.less';

type Props = {
	phone: any;
	account: any;
	setAuthStep: ((val: string) => void) | undefined;
	show: boolean;
	setShow: (v: any) => void;
};

const AuthReactivateModal = (props: Props) => {
	const dispatch = useDispatch();
	const { phone, account, setAuthStep, show, setShow } = props;
	const { loader } = useSelector((state: RootStateOrAny) => state);
	const { message } = account?.meta || {};
	const {
		reactive_token: token,
		custom_message: desc,
		string_total_days: days,
	} = account?.data?.record || {};

	const handleCancel = () => {
		if (!!setAuthStep) setAuthStep(global.AUTH.LOGIN.phone);
		setShow(false);
	};

	const handleSubmit = async () => {
		dispatch(
			await actionUserReactivate({
				phone: phone,
				reactive_token: token,
			}),
		);
	};

	return (
		<ModalC
			width={600}
			className={classes.modal}
			visible={show}
			hideButton={true}
			onCancel={() => handleCancel()}
		>
			<div className={classes.content}>
				<div className={classes.image}>
					<ImageC
						width={xs ? 150 : 200}
						height={xs ? 150 : 200}
						src="/images/confirmation/inactive.svg"
						alt="redeem-voucher"
					/>
				</div>
				<Typography.Title level={5} className={classes.title}>
					{message}
				</Typography.Title>
				<Typography.Paragraph className={classes.subtitle}>
					{desc?.replace('{{string_total_days}}', days)}
				</Typography.Paragraph>
				<Space size={16} direction="vertical" className={classes.actions}>
					<ButtonC
						type="primary"
						size="large"
						text="Aktifkan akun"
						loading={loader?.sending}
						onClick={() => handleSubmit()}
					/>
					<ButtonC
						ghost
						size="large"
						text="Masuk dengan akun lain"
						onClick={() => handleCancel()}
					/>
				</Space>
			</div>
		</ModalC>
	);
};

export default AuthReactivateModal;
