import { ARTICLE_BY_GROUP_TYPE } from 'src/constants/redux/articleByGroup';
import {
	IArticleByGroup,
	IArticleByGroupDetail,
	ISearchResultArticleByGroup,
} from 'src/interface/ArticleByGroup';
import { IPagination } from 'src/interface/Common';

interface IArticleByGroupInitialState {
	articles: IArticleByGroup[];
	articlesPagination: IPagination[];
	detail?: IArticleByGroupDetail | null;
	searchResult?: ISearchResultArticleByGroup[];
	autoCompleteResult?: ISearchResultArticleByGroup[];
	mostSearch?: IArticleByGroup[];
	articleByCategory?: {
		data: {
			records: IArticleByGroupDetail[];
		};
		meta: {
			pagination: IPagination;
		};
	};
	relatedArticles?: IArticleByGroupDetail[];
}

export const initialState: IArticleByGroupInitialState = {
	articles: [],
	articlesPagination: [],
	detail: null,
	searchResult: [],
	autoCompleteResult: [],
	mostSearch: [],
	articleByCategory: {
		data: {
			records: [],
		},
		meta: {
			pagination: {},
		},
	},
	relatedArticles: [],
};

const reducer = (state = initialState, action: any = {}) => {
	switch (action.type) {
		case ARTICLE_BY_GROUP_TYPE.model.articles.success:
			return {
				...state,
				articles: action.payload.data.records as IArticleByGroup[],
				articlesPagination: action.payload.meta.pagination,
			};
		case ARTICLE_BY_GROUP_TYPE.model.mostSearch.success:
			return { ...state, mostSearch: action.payload.data.records as IArticleByGroup[] };
		case ARTICLE_BY_GROUP_TYPE.model.detail.success:
			return { ...state, detail: action.payload.data.record as IArticleByGroupDetail };
		case ARTICLE_BY_GROUP_TYPE.model.autoComplete.success:
			return {
				...state,
				autoCompleteResult: action.payload.data.record.hits as ISearchResultArticleByGroup[],
			};
		case ARTICLE_BY_GROUP_TYPE.model.search.success:
			return {
				...state,
				searchResult: action.payload.data.record.hits as ISearchResultArticleByGroup[],
			};
		case ARTICLE_BY_GROUP_TYPE.model.articleByCategory.success:
			return {
				...state,
				articleByCategory: {
					data: {
						records: action.payload.data.records as IArticleByGroupDetail[],
					},
					meta: {
						pagination: action.payload.meta.pagination,
					},
				},
			};
		case ARTICLE_BY_GROUP_TYPE.model.related.success:
			return {
				...state,
				relatedArticles: {
					data: {
						records: action.payload.data.records as IArticleByGroupDetail[],
					},
					meta: {
						pagination: action.payload.meta.pagination,
					},
				},
			};
		default:
			return state;
	}
};

export default reducer;
