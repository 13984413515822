import { MENU_TYPE } from 'src/constants/redux';
import { FOOTER_MENU, MENU } from 'src/constants/services';
import { SINGLE_API } from 'src/redux/actions/types';

export const actionGetMenu = async (next?: (...f: any) => void) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `/${MENU.service}`,
			successType: MENU_TYPE.model.main.success,
			next,
		},
	};
};

export const actionGetFooterMenu = async (next?: (...f: any) => void) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `/${FOOTER_MENU.service}`,
			successType: MENU_TYPE.model.footer.success,
			next,
		},
	};
};
