import { useEffect, useRef, useState } from 'react';
import Geocode from 'react-geocode';
import { useGeolocated } from 'react-geolocated';
import { GoogleMap } from '@react-google-maps/api';
import { Button, Col, Row, Space, Typography } from 'antd';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

import Image from 'src/components/Image';
import ImageC from 'src/components/Image';
import g from 'src/constants/global';
import { mapStyles } from 'src/constants/mapStyles';

import AddressListAutoComplete from './AddressListAutoComplete';

import classes from './index.module.less';

type Props = {
	address: any;
	setAddress: (v: any) => void;
	position: any;
	setPosition: (v: any) => void;
	focus: any;
	setFocus: (v: any) => void;
	nextStep: () => void;
} & any;

const AddressListPinPoint = (props: Props) => {
	const mapRef: any = useRef(null);
	const { address, setAddress, position, setPosition, setFocus, nextStep } = props;

	const { coords } = useGeolocated();
	const [options, setOptions] = useState<any>([]);
	const { suggestions, setValue, clearSuggestions } = usePlacesAutocomplete({
		debounce: 500,
	});

	const getLocationTitle = (array: any, detail: any) => {
		for (const item of array) {
			if (item?.types.find((type: string) => type === g.MAP.TYPES.establishment)) {
				detail.location = item.long_name;
				break;
			}
			if (item?.types.find((type: string) => type === g.MAP.TYPES.subdistrict)) {
				detail.location = item.long_name;
				break;
			}
		}
	};

	const getLocationDetail = (array: any, detail: any) => {
		let kelurahan = '';
		let kecamatan = '';
		let city = '';
		let province = '';
		let postCode = '';

		array.map((item: any) => {
			if (item?.types.find((type: string) => type === g.MAP.TYPES.subdistrict)) {
				kelurahan = item.long_name;
			}
			if (item?.types.find((type: string) => type === g.MAP.TYPES.district)) {
				kecamatan = item.long_name;
			}
			if (item?.types.find((type: string) => type === g.MAP.TYPES.city)) {
				city = item.long_name;
			}
			if (item?.types.find((type: string) => type === g.MAP.TYPES.province)) {
				province = item.long_name;
			}
			if (item?.types.find((type: string) => type === g.MAP.TYPES.postal_code)) {
				postCode = item.long_name;
			}

			detail.location_description = `${kelurahan}, ${kecamatan}, ${city}, ${province} ${postCode}`;
		});
	};

	const getLocationByGeocode = (latitude: number, longitude: number) =>
		Geocode.fromLatLng(
			latitude?.toString(),
			longitude?.toString(),
			g.MAP.KEY,
			g.MAP.LANGUAGE,
			g.MAP.REGION,
		);

	const setLocationByGeocode = async ({ lat: latitude, lng: longitude }: any) => {
		const { results } = await getLocationByGeocode(latitude, longitude);
		const currentLocation: any = {
			latitude: latitude,
			longitude: longitude,
		};

		const secondResData = results.sort((a: any, b: any) => {
			const lengthA = a.address_components.length;
			const lengthB = b.address_components.length;

			if (lengthA > lengthB) {
				return -1;
			} else if (lengthA < lengthB) {
				return 1;
			} else {
				return 0;
			}
		});
		getLocationTitle(secondResData?.[0]?.address_components, currentLocation);
		getLocationDetail(secondResData?.[0]?.address_components, currentLocation);
		setAddress({ ...address, ...currentLocation });
	};

	const handleMapDragged = () => {
		const refData = mapRef?.current;
		const currentPosition: any = {
			lat: refData.state?.map?.getCenter()?.lat(),
			lng: refData.state?.map?.getCenter()?.lng(),
		};
		setPosition(currentPosition);
		setLocationByGeocode(currentPosition);
	};

	const handleGetMyLocation = () => {
		if (coords) {
			const currentPosition = {
				lat: coords?.latitude,
				lng: coords?.longitude,
			};

			setPosition(currentPosition);
			setFocus(currentPosition);
			setLocationByGeocode(currentPosition);
		}
	};

	const onChangeSearch = (value: string) => {
		if (value.length >= 3) {
			setValue(value);
		}
	};

	const onSelectSearch = async (value: string) => {
		if (value) {
			const selectedOption = suggestions.data.find(
				(suggestion) => suggestion.description === value,
			);
			const result = await getGeocode({ address: value });
			const currentPosition = {
				lat: result?.[0]?.geometry?.location?.lat(),
				lng: result?.[0]?.geometry?.location?.lng(),
			};
			const currentLocation: any = {
				location: selectedOption?.structured_formatting?.main_text,
				latitude: currentPosition.lat,
				longitude: currentPosition.lng,
			};
			getLocationDetail(result?.[0]?.address_components, currentLocation);
			if (!currentLocation.location_description) {
				const secondResult = await getLocationByGeocode(currentPosition.lat, currentPosition.lng);
				const secondResData = secondResult?.results;

				// sort address component yang paling banyak datanya
				secondResData.sort((a: any, b: any) => {
					const lengthA = a.address_components.length;
					const lengthB = b.address_components.length;

					if (lengthA > lengthB) {
						return -1;
					} else if (lengthA < lengthB) {
						return 1;
					} else {
						return 0;
					}
				});
				const addressComponents = secondResData?.[0]?.address_components;

				getLocationDetail(addressComponents, currentLocation);
			}
			setPosition(currentPosition);
			setFocus(currentPosition);
			setAddress({
				...address,
				location: currentLocation?.location,
				location_description: currentLocation?.location_description,
				latitude: currentLocation?.latitude,
				longitude: currentLocation?.longitude,
			});
			clearSuggestions();
		} else {
			handleGetMyLocation();
			setOptions([]);
		}
	};

	useEffect(() => {
		if (address?.id) {
			let addressPosition = g.MAP.DATA.defaultPosition;
			if (address?.latitude || address?.longitude) {
				addressPosition = { lat: Number(address?.latitude), lng: Number(address?.longitude) };
			}
			setLocationByGeocode(addressPosition);
			setPosition(addressPosition);
			setFocus(addressPosition);
		}
	}, []);

	useEffect(() => {
		if (address === undefined) setLocationByGeocode(position);
	}, [address]);

	return (
		<div className={classes.pinpoint}>
			<Typography.Title level={5} className={classes.subtitle}>
				Tentukan titik pin point lokasi kamu
			</Typography.Title>
			<div className={classes['wrap-google-map']}>
				<GoogleMap
					ref={mapRef}
					mapContainerClassName={classes.map}
					center={position}
					zoom={16}
					options={{
						disableDefaultUI: true,
						clickableIcons: false,
						styles: mapStyles,
					}}
					onDragEnd={handleMapDragged}
				>
					<AddressListAutoComplete
						suggestions={suggestions}
						options={options}
						setOptions={setOptions}
						onChangeSearch={onChangeSearch}
						onSelectSearch={onSelectSearch}
					/>
					<Button className={classes.mylocation} onClick={() => handleGetMyLocation()}>
						<Space align="start">
							<Image alt="point" height={24} width={24} src="/icons/point.svg" />
						</Space>
					</Button>
				</GoogleMap>
				<div className={classes['custom-map-marker']}>
					<ImageC alt="map marker" src={'/icons/map-pin.svg'} width={48} height={75} />
				</div>
			</div>
			<Row justify="space-between" className={classes.description}>
				<Col xs={24}>
					<div className={classes['location-action']}>
						<div className={classes['wrap-info']}>
							<div className={classes['icon-pin']}>
								<ImageC
									alt="pin-track"
									src={'/icons/pin-track.svg'}
									width={16}
									height={31}
									className={classes['icon-pin']}
								/>
							</div>
							<div className={classes.info} title={address?.location_description}>
								<Typography.Text className={classes.location}>{address?.location}</Typography.Text>
								<Typography.Paragraph className={classes.sublocation} ellipsis={{ rows: 1 }}>
									{address?.location_description}
								</Typography.Paragraph>
							</div>
						</div>
						<Button
							className={classes['confirm-button']}
							type="primary"
							size="large"
							onClick={() => nextStep()}
							disabled={position === g.MAP.DATA.defaultPosition}
						>
							Pilih Pin Point
						</Button>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default AddressListPinPoint;
