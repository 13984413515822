import { isEmpty } from './lodash';

export const removeEmptyParams = (queries: any) => {
	if (queries) {
		for (const key of Object.keys(queries)) {
			if (
				queries[key] === '' ||
				queries[key] === undefined ||
				(Array.isArray(queries[key]) && queries[key].length === 0)
			) {
				delete queries[key];
			}
		}
	}
	return queries;
};
export const removeDefaultKeys = (obj: any, defaults: any) => {
	for (const key in defaults) {
		if (defaults.hasOwnProperty(key) && obj.hasOwnProperty(key) && obj[key] === defaults[key]) {
			delete obj[key];
		}
	}
	return obj;
};

export const removeQueryParams = (url: any) => {
	let newUrl = url.split('?');

	if (newUrl.length > 1) {
		return newUrl[0];
	} else {
		return url;
	}
};

export const removeLastDirectory = (asPath: string) => {
	const path = asPath.split('/');
	path.pop();
	return path.join('/');
};

export const formatQueryParams = (
	queryObject: Partial<Record<string, string | string[]>>,
): string => {
	const queryParams = [];
	if (!isEmpty(queryObject)) {
		for (const key in queryObject) {
			if (queryObject.hasOwnProperty(key)) {
				const value = queryObject[key];
				if (value) {
					if (Array.isArray(value)) {
						const encodedKey = encodeURIComponent(key);
						const encodedValues = value.map((val) => encodeURIComponent(val));
						const joinedValues = encodedValues.join('&' + encodedKey + '=');
						queryParams.push(`${encodedKey}=${joinedValues}`);
					} else {
						const encodedKey = encodeURIComponent(key);
						const encodedValue = encodeURIComponent(value);
						queryParams.push(`${encodedKey}=${encodedValue}`);
					}
				}
			}
		}

		return `?${queryParams.join('&')}`;
	}
	return '';
};
