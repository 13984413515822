import { Fragment, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Col, Grid, Menu, MenuProps, Row } from 'antd';

import ComingSoonModal from 'src/components/Home/Main/ComingSoonModal';
import Image from 'src/components/Image';
import { IMenu } from 'src/interface/Layout/Menu';

import DrawerNotification from './TabMenuNotification/DrawerNotification';

import classes from './index.module.less';

const MainMenuC = (props: MenuProps) => {
	const { pathname } = useRouter();
	const menus = useSelector((state: RootStateOrAny) => state.menu);
	const headerMenus: IMenu[] = menus?.data?.record?.header_menu || [];

	const [showComingSoonModal, setShowComingSoonModal] = useState(false);

	const [comingSoonData, setComingSoonData] = useState<IMenu['extra_description'] | undefined>();

	const getSelectedKey = (path: string) => {
		const activePath = path?.split('/')?.[1];
		return headerMenus?.find((menu) => menu.deeplink === `/${activePath}`)?.id?.toString() || '';
	};

	const handleClick = (e: any, name: string, menuData: IMenu) => {
		if (menuData.is_coming_soon) {
			e.preventDefault();
			setComingSoonData(menuData.extra_description);
			setShowComingSoonModal(true);
		}
	};

	const handleCloseComingSoonModal = () => {
		setComingSoonData(undefined);
		setShowComingSoonModal(false);
	};

	return (
		<Menu {...props} className={classes.menu} selectedKeys={[getSelectedKey(pathname)]}>
			{headerMenus.map((menu: IMenu, i: number) => {
				if (props.mode !== 'horizontal') {
					return (
						<Fragment key={menu.id}>
							<Menu.Item key={menu.id}>
								<a href={menu.deeplink} onClick={(e: any) => handleClick(e, menu.title, menu)}>
									<Row justify="space-between">
										<Col>{menu.title}</Col>
										<Col>
											<Image
												src="/icons/arrow-chevron-right-gray.svg"
												height={16}
												width={16}
												alt="arrow-chevron-right"
											/>
										</Col>
									</Row>
								</a>
							</Menu.Item>
							{headerMenus.length !== i + 1 && <Menu.Divider />}
						</Fragment>
					);
				}

				return (
					<Menu.Item key={menu.id}>
						<Link href={menu.deeplink} onClick={(e: any) => handleClick(e, menu.title, menu)}>
							{menu.title}
						</Link>
					</Menu.Item>
				);
			})}
			<ComingSoonModal
				visible={showComingSoonModal}
				onCancel={handleCloseComingSoonModal}
				data={comingSoonData}
			/>
		</Menu>
	);
};

const MainDrawerMenuC = (props: any) => {
	const { xs } = Grid.useBreakpoint();
	const [currentTop, setCurrentTop] = useState<number>(0);

	useEffect(() => {
		const headerHeight = xs ? 45 : 5;
		const idealTop = headerHeight - document.documentElement.scrollTop;
		setCurrentTop(idealTop);
	}, [props.visibleComponent, xs]);

	return (
		<>
			<div
				className={classes['menu-btn']}
				onClick={() =>
					props.visibleComponent != 'main-menu'
						? props.setVisibleComponent('main-menu')
						: props.setVisibleComponent('')
				}
			>
				{props.visibleComponent == 'main-menu' ? (
					<Image src="/icons/close.svg" height={24} width={24} alt="Close" />
				) : (
					<Image src="/icons/menu.svg" height={24} width={24} alt="Menu" />
				)}
			</div>
			<DrawerNotification
				classesDrawers={classes['drawer-menu']}
				showingHeader={false}
				showingLogo={true}
				visibleDrawer={props.visibleComponent == 'main-menu'}
				paddingTopDrawer={currentTop}
				setVisibleDrawerClose={() => props.setVisibleComponent('')}
				hiddenBackIcon={xs ? true : false}
				placement={props.placement}
			>
				<MainMenuC onClick={props.setVisibleComponent} />
			</DrawerNotification>
		</>
	);
};

export { MainDrawerMenuC, MainMenuC };
