import type { ReactNode } from 'react';
import { Button, Modal, ModalFuncProps, ModalProps } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';

import classes from './index.module.less';

export type ModalCProps = ModalProps &
	ModalFuncProps & {
		children?: ReactNode;
		justOkButton?: boolean;
		disabledButton?: boolean;
		hideButton?: boolean;
		loading?: boolean;
		centered?: boolean;
		breakpoint?: 'mobile' | 'tablet';
		width?: number | string;
		hideCancel?: boolean;
		buttonClass?: any;
		wrapperClassButton?: any;
		bodyStyle?: any;
		isShowScroll?: boolean;
		disableScrollContent?: boolean;
		cancelLoading?: boolean;
	};

const ModalC = (props: ModalCProps) => {
	return (
		<Modal
			bodyStyle={props.bodyStyle}
			width={props.width ? props.width : 400}
			centered={props.centered ?? true}
			footer={null}
			closeIcon={
				<ImageC src="/icons/close.svg" height={32} width={32} className={classes['close-btn']} />
			}
			{...props}
			className={classNames(
				{
					[classes['modal-mobile']]: !props.breakpoint || props.breakpoint === 'mobile',
					[classes['modal-tablet']]: props.breakpoint === 'tablet',
					[classes['display-scroll']]: props.isShowScroll,
					[classes['disable-scroll-content']]: props.disableScrollContent === true,
				},
				props.className,
			)}
		>
			{props.children}

			{!props.hideButton && (
				<div className={classNames(classes.action, props.wrapperClassButton)}>
					{props.justOkButton ? (
						<Button
							type="primary"
							className="w-100"
							onClick={props.onOk}
							loading={props.loading}
							disabled={props.disabledButton}
							{...(props?.okButtonProps || {})}
						>
							{props.okText || 'OK'}
						</Button>
					) : (
						<>
							{!props.hideCancel && (
								<Button
									type="primary"
									onClick={props.onCancel}
									ghost
									loading={props.cancelLoading}
									{...(props?.cancelButtonProps || {})}
								>
									{props.cancelText || 'Cancel'}
								</Button>
							)}
							<Button
								className={props.buttonClass}
								type="primary"
								onClick={props.onOk}
								loading={props.loading}
								disabled={props.disabledButton}
								{...(props?.okButtonProps || {})}
							>
								{props.okText || 'OK'}
							</Button>
						</>
					)}
				</div>
			)}
		</Modal>
	);
};

export default ModalC;
