import CopyrightFooter from './CopyrightFooter';
import MainFooter from './MainFooter';

import classes from './index.module.less';

type Props = {
	isMiniFooter: boolean;
	isNoneFooter: boolean;
};

const Footer = (props: Props) => {
	if (props.isNoneFooter) return <></>;

	return (
		<footer className={classes.footer}>
			{!props.isMiniFooter ? <MainFooter /> : null}
			<CopyrightFooter isBackgroundWhite={props.isMiniFooter} />
		</footer>
	);
};

export default Footer;
