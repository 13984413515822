import { PROFILE_TYPE } from 'src/constants/redux';
import { PROFILE, USER } from 'src/constants/services';
import { MODIFY_DATA, SINGLE_API } from 'src/redux/actions/types';

type ProfilePayload = {
	category: string;
	type: any;
};

export const actionProfileMenuModify = async (payload: object = {}) => {
	return {
		type: MODIFY_DATA,
		payload: {
			payload,
			successType: PROFILE_TYPE.model.menu.success,
			errorType: PROFILE_TYPE.model.menu.error,
		},
	};
};

export const actionProfileUserSelectedModify = async (payload: object | null = {}) => {
	return {
		type: MODIFY_DATA,
		payload: {
			payload,
			successType: PROFILE_TYPE.model.userSelected.success,
			errorType: PROFILE_TYPE.model.userSelected.error,
		},
	};
};

export const actionPointReward = async (payload: ProfilePayload) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `/${PROFILE.service}?${PROFILE.model.category}=
			${payload.category}&${PROFILE.model.type}=${payload.type}`,
			successType: PROFILE_TYPE.model.pointReward.success,
			errorType: PROFILE_TYPE.model.pointReward.error,
		},
	};
};

export const actionListMembership = async () => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${PROFILE.model.listMembership}`,
			successType: PROFILE_TYPE.model.membershipList.success,
			errorType: PROFILE_TYPE.model.membershipList.error,
		},
	};
};

export const actionMembershipPointCard = async () => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'v3_user',
			url: `/${USER.service}/${PROFILE.model.membershipPoint}`,
			successType: PROFILE_TYPE.model.membershipPoint.success,
			errorType: PROFILE_TYPE.model.membershipPoint.error,
		},
	};
};

export const actionMembershipInfo = async (payload: ProfilePayload) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `/${PROFILE.service}?${PROFILE.model.type}=${payload.type}`,
			successType: PROFILE_TYPE.model.membershipInfo.success,
			errorType: PROFILE_TYPE.model.membershipInfo.error,
		},
	};
};
