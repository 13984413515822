import { useQuery } from 'react-query';

import { LIST_RELATIONSHIP } from 'src/constants/services';
import { fetchApiV3User } from 'src/utils/fetch-api';

const query = 'relationshipList';

export const useRelationshipLists = () => {
	return useQuery(query, () => fetchApiV3User({ url: `/${LIST_RELATIONSHIP.service}` }), {
		keepPreviousData: true,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
};
