import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import { getGeocode, getLatLng, HookReturn, Suggestion } from 'use-places-autocomplete';

import ImageC from 'src/components/Image';
import EmptyC from 'src/components/Layout/Empty';
import SpinC from 'src/components/Layout/Spin';
import { UserLocation } from 'src/interface';

interface SearchSuggestionCProps {
	places: HookReturn;
	setPosition: (e: UserLocation) => void;
	setLocationModal: (e: boolean) => void;
}

const { Paragraph, Text } = Typography;

const SearchSuggestionC = (props: SearchSuggestionCProps) => {
	const { ready, suggestions } = props.places;

	const handleSuggestion = ({ description }: Suggestion) => {
		props.places.setValue(description, false);
		props.places.clearSuggestions();

		getGeocode({ address: description }).then(async (res) => {
			const { lat, lng }: any = await getLatLng(res[0]);
			props.setPosition({ lat, lng, address: description, addressLabel: '' });
			props.setLocationModal(false);
		});
	};

	const EmptyOrLoadingC = () => {
		return ready && !suggestions.loading && suggestions.status === 'ZERO_RESULTS' ? (
			<EmptyC
				image="/images/empty/address-list.svg"
				imageHeight={160}
				imageWidth={160}
				title="Alamat tidak ditemukan"
				description={
					'Waduh, alamat yang kamu cari tidak ditemukan nih. ' +
					'Mungkin kamu bisa cari alamat lain yah.'
				}
				buttonText=""
				buttonHidden
			/>
		) : (
			<SpinC />
		);
	};

	return (
		<>
			{ready && !suggestions.loading && suggestions.status === 'OK' ? (
				<>
					{suggestions.data.map((item: Suggestion, i: number) => (
						<Row
							key={i}
							align="top"
							wrap={false}
							onClick={() => handleSuggestion(item)}
							className={classNames('cursor-pointer', { 'mt-3': i !== 0 })}
						>
							<Col flex="20px" className="mr-2" style={{ height: 20 }}>
								<ImageC alt="Icon Location" width={20} height={20} src="/icons/location-gray.svg" />
							</Col>
							<Col flex="auto">
								<Paragraph className="fs-14 fw-700 mb-0">
									{item.structured_formatting.main_text}
								</Paragraph>
								<Text className="fs-14">{item.structured_formatting.secondary_text}</Text>
							</Col>
						</Row>
					))}
				</>
			) : (
				<EmptyOrLoadingC />
			)}
		</>
	);
};

export default SearchSuggestionC;
