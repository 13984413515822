import { useRouter } from 'next/router';

import { useGetTotalNewMessages, useGetTotalNotification } from 'src/hooks/Notification';

const useTotalNotificationCount = () => {
	const r = useRouter();
	const { data: dataMainNotification } = useGetTotalNotification(r.pathname);
	const { data: dataMessageNotification } = useGetTotalNewMessages(r.pathname);

	return {
		main: dataMainNotification?.data?.record?.total || 0,
		message: dataMessageNotification?.data?.record?.total_unread || 0,
	};
};

export default useTotalNotificationCount;
