import { useCallback } from 'react';
import type { RootStateOrAny } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { actionSetFloatingChatFlag } from 'src/redux/actions';

const useCheckFloatingChat = () => {
	const dispatch = useDispatch();
	const { klikstoreFloatingChat } = useSelector((state: RootStateOrAny) => state);
	const isFloatingChatOpen = klikstoreFloatingChat?.klikstoreFloatingChatOpen || false;
	const r = useRouter();
	const isFloatingChat =
		(r.pathname?.includes('/kalstore') && !r.pathname?.includes('/kalstore/tanya-penjual')) ||
		r.asPath?.includes('/profil/daftar-transaksi/detail/ks-');

	const handleToggleFloating = useCallback((flag: boolean) => {
		dispatch(actionSetFloatingChatFlag(flag));
	}, []);

	return {
		isFloatingChat: isFloatingChat,
		visible: isFloatingChatOpen,
		setVisible: handleToggleFloating,
	};
};

export default useCheckFloatingChat;
