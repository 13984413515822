import { useQuery } from 'react-query';

import { fetchApiMarketplace } from 'src/utils/fetch-api';
import { removeEmptyParams } from 'src/utils/func';

import { bannersFields } from '../Landing';

export interface UseGetBrandList {
	q?: string;
	store_id?: number;
	page?: number;
	limit?: number;
	sort?: string;
	dir?: string;
	is_official?: number;
}

export const klikstoreBrandDetail = 'klikstoreBrandDetail';

export const brandFields = 'meta_title,meta_description';

export const useGetBrandList = (props: UseGetBrandList) => {
	return useQuery(
		['klikstoreBrandList', props],
		() => {
			return fetchApiMarketplace({
				url: '/search/brands',
				options: { method: 'GET' },
				payload: {
					...removeEmptyParams(props),
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useGetBrandDetail = (slug: string) => {
	return useQuery(
		[klikstoreBrandDetail, slug],
		() => {
			return fetchApiMarketplace({
				url: `/search/brand_detail/${slug}`,
				options: { method: 'GET' },
				payload: {
					fields: brandFields,
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useGetBrandBannerList = (slug: string) => {
	return useQuery(
		['klikstoreBrandBannerList', slug],
		() => {
			return fetchApiMarketplace({
				url: '/search/banners',
				options: { method: 'GET' },
				payload: {
					category_slug: slug,
					fields: bannersFields,
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};
