import { QUICKAUTH_TYPE } from 'src/constants/redux/klikstore/quickauth';

export const initialState = {
	klikstoreQuickAuthDisplayOpen: false,
};

const reducer = (state = initialState, action: any = {}) => {
	if (action.type === QUICKAUTH_TYPE.model.quickauth.success) {
		if (action.payload?.status === true) {
			return {
				...state,
				klikstoreQuickAuthDisplayOpen: true,
			};
		} else {
			return { ...state, klikstoreQuickAuthDisplayOpen: false };
		}
	} else {
		return state;
	}
};

export default reducer;
