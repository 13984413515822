import { useEffect, useState } from 'react';

export const useIsEmptyContentGoogleAd = () => {
	const [isEmptyContent, setIsEmptyContent] = useState<any>(true);
	let googletag: any;

	useEffect(() => {
		window.googletag = window.googletag || { cmd: [] };
		googletag = window.googletag;
		googletag.cmd.push(function () {
			googletag.pubads().addEventListener('slotRenderEnded', function (event: any) {
				setIsEmptyContent(event.isEmpty);
			});
		});
	}, []);

	return isEmptyContent;
};
