import { useQuery } from 'react-query';
import { PaginationProps } from 'antd';

import { fetchApiMarketplace } from 'src/utils/fetch-api';

export const klikStorePromoList = 'klikStorePromoList';
export const klikStorePromoDetail = 'klikStorePromoDetail';

interface UseKlikStorePromoListProps {
	type: string | 'all' | 'principal' | 'payment' | 'merchant';
	page: number;
	limit: number;
}

export interface UseKlikStorePromoProducts {
	pagination: PaginationProps;
	uidList: string[];
}

export const useKlikStorePromoList = (props: UseKlikStorePromoListProps) => {
	const { type } = props;

	const getList = () => {
		return fetchApiMarketplace({
			url: `/search/voucher/category/${type}/store/1`,
			payload: {
				sort: 'id',
				dir: 'desc',
				page: props.page,
				limit: props.limit,
			},
			notif: true,
		});
	};

	return useQuery([klikStorePromoList, props], () => getList(), {
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});
};

export const useKlikStorePromoDetail = (voucher_slug: string) => {
	const getData = () => {
		return fetchApiMarketplace({
			url: `/customer/voucher/detail/${voucher_slug}`,
		});
	};

	return useQuery([klikStorePromoDetail, voucher_slug], () => getData(), {
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});
};
