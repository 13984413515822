import { Fragment } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import Link from 'next/link';
import { Col, Divider, Grid, List, Row, Space, Typography } from 'antd';

import ImageC from 'src/components/Image';
import FooterMenu from 'src/components/Layout/Menu/FooterMenu';

import classes from './MainFooter.module.less';

const { Paragraph, Text } = Typography;
const firstArray = 0;

const MainFooter = () => {
	const { xl, lg, md } = Grid.useBreakpoint();
	const footer = useSelector((state: RootStateOrAny) => state.footer);

	const { description, get_close: getClose, feature, collaborate } = footer?.data?.record || {};
	const section1 = description ? description[firstArray] : {};
	const section2 = getClose ? getClose[firstArray] : {};
	const section3 = feature ? feature[firstArray] : {};
	const section4 = collaborate ? collaborate[firstArray] : {};

	return (
		<>
			<div className={classes['main-footer']}>
				<Row className="container" justify="space-between" align="top">
					<Col xs={22} md={24} lg={6}>
						<div className={classes['section-1']}>
							<div className={classes['sub-section']}>
								{section1?.detail && (
									<ImageC
										width={175}
										height={35}
										src="/images/klikdokter.svg"
										alt={section1?.detail[firstArray]?.name}
									/>
								)}
								<Text>
									<div
										dangerouslySetInnerHTML={{
											__html: section1?.detail && section1?.detail[firstArray]?.sub_title,
										}}
									/>
								</Text>
								<Paragraph className={classes.desription}>
									<div
										dangerouslySetInnerHTML={{
											__html: section1?.detail && section1?.detail[firstArray]?.description,
										}}
									/>
								</Paragraph>
							</div>
							<div className={classes['sub-section']}>
								<Paragraph className={classes.tagline}>
									<div
										dangerouslySetInnerHTML={{
											__html: section1?.detail && section1?.detail[firstArray]?.title_footer,
										}}
									/>
								</Paragraph>
								<Space className={classes.icons} size="middle">
									{section1?.detail &&
										section1?.detail[firstArray]?.social_media?.map((item: any) => {
											return (
												<Fragment key={item.name}>
													<Link href={item.deeplink} rel="nofollow">
														{item.icon && (
															<ImageC
																key={item.name}
																className={classes['socmed-icon']}
																width={!xl && lg ? 28 : 32}
																height={!xl && lg ? 28 : 32}
																src={item.icon}
																alt={item.name}
															/>
														)}
													</Link>
												</Fragment>
											);
										})}
								</Space>
							</div>
						</div>
					</Col>

					{!lg && <Divider className={classes.divider} />}
					<Col xs={24} md={8} lg={{ span: 5, offset: 1 }}>
						<div className={classes['section-2']}>
							<Text>
								<div>{section2?.name}</div>
							</Text>
							<div className={classes['main-section']}>
								<div className={classes['sub-section']}>
									<FooterMenu list={section2.detail} firstMenu={true} />
								</div>
								<div className={classes['sub-section']}>
									<FooterMenu list={section2.detail} firstMenu={false} />
								</div>
							</div>
						</div>
					</Col>

					{!md && <Divider className={classes.divider} />}
					<Col xs={24} md={8} lg={{ span: 5, offset: 1 }}>
						<div className={classes['section-3']}>
							<Text>
								<div>{section3?.name}</div>
							</Text>
							<div className={classes['main-section']}>
								<div className={classes['sub-section']}>
									<FooterMenu list={section3.detail} firstMenu={true} />
								</div>
								<div className={classes['sub-section']}>
									<FooterMenu list={section3.detail} firstMenu={false} />
								</div>
							</div>
						</div>
					</Col>

					{!md && <Divider className={classes.divider} />}
					<Col xs={24} md={8} lg={6}>
						<div className={classes['section-4']}>
							<Text>
								<div>{section4?.name}</div>
							</Text>
							{section4?.detail && (
								<List
									itemLayout="horizontal"
									dataSource={section4?.detail}
									renderItem={(menu: any, i) => (
										<>
											<List.Item>
												<div>
													<Paragraph className="mb-0">
														<Link href={menu.deeplink}>{menu.name}</Link>
													</Paragraph>
													<Text type="secondary">{menu.description}</Text>
												</div>
												{section4?.detail.length !== i + 1 && <Divider dashed />}
											</List.Item>
										</>
									)}
								/>
							)}
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default MainFooter;
