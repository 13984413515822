const urls = {
	// frontend
	WEB_VERSION: process.env.NEXT_PUBLIC_WEB_VERSION,
	WEB_URL: process.env.NEXT_PUBLIC_WEB_URL,

	// API URL
	API_URL: process.env.NEXT_PUBLIC_API_URL,
	API_URL_V3: process.env.NEXT_PUBLIC_API_URL_V3,
	APIV3_HC_URL: process.env.NEXT_PUBLIC_APIV3_HC_URL,
	APIV3_PB_URL: process.env.NEXT_PUBLIC_APIV3_PB_URL,
	API_PARTNER_URL: process.env.NEXT_PUBLIC_API_PARTNER_URL,
	APIV3_RATING_URL: process.env.NEXT_PUBLIC_APIV3_RATING_URL,
	APIV3_USER_URL: process.env.NEXT_PUBLIC_APIV3_USER_URL,
	API_INSURANCE_URL: process.env.NEXT_PUBLIC_API_INSURANCE_URL,

	// API Kalcare
	API_KALCARE_URL: process.env.NEXT_PUBLIC_API_KALCARE_URL,

	// API Typesense
	TYPESENSE_URL: process.env.NEXT_PUBLIC_API_TYPESENSE_URL,

	// Publishing
	API_PLATFORM: process.env.NEXT_PUBLIC_API_PLATFORM,

	// Payment
	API_PAYMENT_PLATFORM: process.env.NEXT_PUBLIC_API_PAYMENT_PLATFORM,
	API_PAYMENT_VERSION: process.env.NEXT_PUBLIC_API_PAYMENT_VERSION,

	// Insider
	INSIDER_URL: process.env.NEXT_PUBLIC_INSIDER_URL,
};

export default urls;
