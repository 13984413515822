import { configureRefreshFetch } from 'refresh-fetch';

import { isBrowser } from 'src/constants/environment';
import globalUrls from 'src/constants/urls';

import AuthStorage, { idTokenStorage, refreshTokenStorage } from './auth-storage';

const fetchJSONWithToken = async (
	url: string,
	options: {
		body: Request['body'];
		headers: Request['headers'] | {};
	},
) => {
	let optionsWithToken = options;
	if (AuthStorage.loggedIn) {
		optionsWithToken = {
			...options,
			headers: {
				...options.headers,
				Authorization: `Bearer ${AuthStorage.token}`,
			},
		};
	}

	const v = await fetch(url, optionsWithToken);
	if (v.status === 401 || v.status === 403) {
		return Promise.reject(v);
	}
	return v;
};

const doRefreshToken: (l?: number) => Promise<any> = async (limit = 3) => {
	const uri = `${globalUrls.APIV3_USER_URL}/users/refresh-token`;
	const body = !!refreshTokenStorage.refreshToken
		? JSON.stringify({
				refresh_token: refreshTokenStorage.refreshToken,
		  })
		: undefined;
	const opts = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${AuthStorage.token}`,
		},
		body,
	};

	return fetch(uri, opts)
		.then((v) => v.json())
		.then((v): any => {
			const { refresh_token: refreshToken, token, id_token: idToken } = v.data?.record || {};
			const replaceToken = () => {
				AuthStorage.value = { token };
				refreshTokenStorage.value = { refreshToken };
				idTokenStorage.value = { idToken };
			};

			if (token) {
				replaceToken();
				return {
					refreshToken,
					token,
					idToken,
				};
			} else {
				return Promise.reject(v);
			}
		})
		.catch(() => {
			if (--limit > 0) {
				return doRefreshToken(limit);
			}
		});
};

const fetchWithAuthorization = configureRefreshFetch({
	fetch: fetchJSONWithToken,
	shouldRefreshToken: (error) => isBrowser && error.status === 401 && AuthStorage.loggedIn,
	refreshToken: doRefreshToken,
});

export default fetchWithAuthorization;
