import { QISCUS_TYPE } from 'src/constants/redux';
import { STORE_DATA } from 'src/redux/actions/types';

export const actionSetQiscusInstance = (payload: object | null = {}) => {
	return {
		type: STORE_DATA,
		payload: {
			successType: QISCUS_TYPE.model.instance.success,
			payload: payload,
		},
	};
};

export const actionSetQiscusFBToken = (payload: string = '') => {
	return {
		type: STORE_DATA,
		payload: {
			successType: QISCUS_TYPE.model.fbtoken.success,
			payload: payload,
		},
	};
};
