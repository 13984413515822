import { useEffect } from 'react';
import PIN from 'react-otp-input-rc-18';
import { Form } from 'antd';
import classNames from 'classnames';

import { InputPinProps } from 'src/interface/Forms/Input/InputPin';

import classes from './InputPin.module.less';

const InputPin = (props: InputPinProps) => {
	const isRequired = props.rules
		? props.rules.filter((r: any) => r.required === true).length > 0
		: false;

	useEffect(() => {
		if (props.isTransform) {
			let inputs = document.getElementsByTagName('input');
			for (let input of inputs) {
				if (input.type.toLowerCase() === 'tel') {
					if (input.value) {
						setTimeout(() => {
							input.type = 'password';
						}, 300);
					}
				} else {
					if (!input.value) {
						input.type = 'tel';
					}
				}
			}
		}
	}, [props.value]);

	return (
		<Form.Item
			label={props.useLabel ? props.label : ''}
			name={props.name}
			required={isRequired}
			rules={props.rules}
			validateTrigger={props.validateTrigger}
		>
			<PIN
				errorStyle={props.errorStyle ?? classes['pin-input-code-error']}
				focusStyle={props.focusStyle ?? classes['pin-input-code-focus']}
				hasErrored={props.hasErrored}
				inputStyle={
					props.className ??
					classNames(classes['pin-input-code'], { [classes['max-width']]: props.maxWidth })
				}
				isInputNum={props.isInputNum ?? true}
				isInputSecure={props.isInputSecure}
				numInputs={props.numInputs}
				onChange={props.onChange}
				shouldAutoFocus={props.shouldAutoFocus}
				value={props.value}
				containerStyle={classes['wrap-input-pin']}
			/>
		</Form.Item>
	);
};

export default InputPin;
