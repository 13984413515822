import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Col, Row, Typography } from 'antd';

import ImageC from 'src/components/Image';
import ModalC from 'src/components/Modal';
import modulesList from 'src/constants/close_modules';
import useCloseFeature from 'src/hooks/useCloseFeature';
import useCustomBreakPoint from 'src/hooks/useCustomBreakPoint';

import useAnnounceState from './useAnnounceState';

type AnnouncementProps = {
	closeFeatureModule?: string;
};

const Announcement = ({ closeFeatureModule }: AnnouncementProps) => {
	const [isShowModal, setIsShowModal] = useState(false);
	const [state, setState] = useAnnounceState();
	const { isMobile } = useCustomBreakPoint();
	const { pathname } = useRouter();

	const currentModule = useMemo(() => {
		const isHomeModule = pathname === '/';
		if (isHomeModule) return 'general';

		const currentModuleName = modulesList.find((m) => {
			const path = pathname?.split('/')?.[1];

			return !!path && m.includes(path);
		});

		return currentModuleName || closeFeatureModule;
	}, [pathname, closeFeatureModule]);

	const isEnabled = !!currentModule && !state?.[currentModule];

	const closeFeature = useCloseFeature(currentModule, isEnabled);

	const onCancel = () => {
		setIsShowModal(false);
	};

	useEffect(() => {
		if (currentModule && closeFeature?.data?.status && !state?.[currentModule]) {
			setState(currentModule, true);
			setIsShowModal(true);
		}
	}, [currentModule, closeFeature?.data?.status]);

	if (!closeFeature.data || !closeFeature?.data?.status) {
		return null;
	}

	return (
		<ModalC visible={isShowModal} hideButton width={750} onCancel={onCancel}>
			<Row align="middle" justify="center" wrap={isMobile} className="p-2" gutter={[30, 10]}>
				<Col flex="300px" className="d-flex align-items-center justify-content-center">
					<div
						className={'w-fit-content position-relative'}
						style={{
							width: '50vw',
							height: '50vw',
							maxWidth: isMobile ? 250 : 300,
							maxHeight: isMobile ? 250 : 300,
						}}
					>
						<ImageC
							alt="Announcement"
							src={closeFeature?.data?.image}
							objectFit="cover"
							layout="fill"
						/>
					</div>
				</Col>
				<Col className="text-left">
					<Typography.Title className="fs-16 fs-md-18 fw-700">
						{closeFeature?.data?.title}
					</Typography.Title>
					<div
						dangerouslySetInnerHTML={{
							__html: closeFeature?.data?.description,
						}}
						className="fs-14 fs-md-16"
					/>
				</Col>
			</Row>
		</ModalC>
	);
};

export default Announcement;
