import { useQuery } from 'react-query';
import { PaginationProps } from 'antd';

import { TYPESENSE } from 'src/constants/services';
import { DataFilterTypesense, DataOptions, UserLocation } from 'src/interface';
import { fetchApiTypesense } from 'src/utils/fetch-api';
import { filterBy, sortedBy } from 'src/utils/func';

export interface UseHealthServicesCategory {
	pagination: PaginationProps;
	search: string;
	sortBy: string;
	dataSort: DataOptions[];
	filter: DataFilterTypesense[];
	location: UserLocation;
	category: string;
	subCategory?: string;
	disableTriggerFetch?: boolean;
}

export const useHealthServicesCategory = (props: UseHealthServicesCategory) => {
	const { disableTriggerFetch, ...exProps } = props;
	const queryFilter = [
		{ field_name: 'category_slug', value: [props.category] },
		{ field_name: 'sub_category_slug', value: props.subCategory ? [props.subCategory] : [] },
		...props.filter,
	];

	return useQuery(
		['useHealthServicesCategory', exProps],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.categoryServices,
							filter_by: `parent_slug:${props.category}`,
							sort_by: 'sort_order:asc',
							include_fields: 'name,slug,parent_slug',
							per_page: 250,
						},
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityServices,
							facet_by: 'medical_facility_city,medical_facility_type_name,price',
							max_facet_values: 5,
							filter_by: filterBy(queryFilter),
							sort_by: sortedBy(props.sortBy, props.dataSort, {
								location: props.location,
								sortDefault: 'name:asc',
							}),
							include_fields:
								// eslint-disable-next-line max-len
								'name,slug,price,normal_price,photo_highres,medical_facility_name,medical_facility_slug,medical_facility_type_code,id,medical_facility_uid',
							page: props.pagination.current,
							per_page: props.pagination.pageSize,
						},
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityServices,
							facet_by: 'medical_facility_city',
							facet_query: `medical_facility_city:${props.search}`,
							max_facet_values: 100,
							include_fields: 'name',
							per_page: 1,
						},
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityServices,
							facet_by: 'medical_facility_type_name',
							max_facet_values: 5,
							include_fields: 'name',
							per_page: 1,
						},
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityServices,
							facet_by: 'price',
							max_facet_values: 1,
							filter_by: filterBy(queryFilter, { includeFieldName: ['category_slug'] }),
							include_fields: 'name',
							per_page: 1,
						},
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilityServices,
							facet_by: 'medical_facility_city',
							max_facet_values: 5,
							include_fields: 'name',
							per_page: 1,
						},
					],
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !disableTriggerFetch },
	);
};

export const useHealthServicesCategoryService = () => {
	return useQuery(
		['useHealthServicesCategoryService'],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.categoryServices,
							filter_by: 'parent_uid:0',
							sort_by: 'sort_order:asc',
							include_fields: 'id,name,photo_highres,photo_lowres,slug',
						},
					],
				},
			});
		},
		{ keepPreviousData: true, refetchOnMount: false, refetchOnWindowFocus: false },
	);
};
