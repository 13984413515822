import Link from 'next/link';
import { Grid, Row, Typography } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import { Title } from 'src/components/Layout/Typography';
import { useKlikStoreMasterProducts } from 'src/hooks';
import { IKlikStoreMasterProduct } from 'src/interface';
import { cleanSlug } from 'src/utils/func';

import KlikStoreProductFloor from '../../Products/ProductFloor';

import classes from './index.module.less';

const { Paragraph } = Typography;

interface Props {
	product: IKlikStoreMasterProduct | undefined;
	setShow: (state: boolean) => void;
}

const ProductNotFound = ({ product, setShow }: Props) => {
	const { xs } = Grid.useBreakpoint();

	const data: any = useKlikStoreMasterProducts({
		q: product?.name,
		limit: 3,
	});
	const productList = data?.data?.data?.records ?? [];

	return (
		<div className={classNames(classes.body)}>
			<Row
				align="middle"
				justify="center"
				className={classNames('flex-column text-center mb-3', { 'px-5': !xs })}
			>
				<ImageC
					alt="icon merchant not found"
					src="/images/klikstore/merchant-not-found.svg"
					width={150}
					height={150}
				/>
				<Paragraph className="fs-22 fs-md-24 text-neutral-3 fw-700 mb-2">
					Toko Tidak Ditemukan
				</Paragraph>
				<Paragraph className="fs-12 fs-md-14 text-neutral-3 fw-400">
					Tidak ada toko yang ditemukan di daerah Kamu untuk produk ini, kami tidak bisa mengirimkan
					pesananmu dengan metode <span className="fw-700">KlikNOW</span>
				</Paragraph>
			</Row>
			<Row>
				<div className={classNames({ container: !xs }, { 'px-3': xs })}>
					<Row justify="space-between" className="align-items-baseline mb-3">
						<Title
							level={2}
							className="fs-14 fs-md-14 fs-lg-15 fs-xl-16 fw-600 mb-0"
							text="Sesuai dengan pencarianmu"
						/>
						<Link
							href={cleanSlug(`/kalstore/search?keyword=${product?.name}&page=1`)}
							className="fs-13 fs-md-13 fs-lg-14 fs-xl-15 fw-600"
							onClick={() => setShow(false)}
						>
							Lihat Semua
						</Link>
					</Row>
					{productList?.length > 0 && (
						<KlikStoreProductFloor productList={productList} slidesToShow={3} />
					)}
				</div>
			</Row>
		</div>
	);
};

export default ProductNotFound;
