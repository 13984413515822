/* eslint-disable @next/next/no-html-link-for-pages */
import { RightOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, Row, Typography } from 'antd';
import classNames from 'classnames';

import { Breadcrumb as IBreadcrumb, PageHeaderBreadcrumpProps } from 'src/interface';
import { isEmpty } from 'src/utils/func';

import classes from './PageHeaderBreadcrumb.module.less';

const PageHeaderBreadcrumb = (props: PageHeaderBreadcrumpProps) => {
	const { breadcrumbs, withTitle } = props;

	if (isEmpty(breadcrumbs)) return null;

	return (
		<Row
			className={classNames(classes.breadcrumb, {
				[classes['breadcrumb-with-title']]: withTitle,
				['container']: !withTitle,
				[classes['breadcrumb-without-title']]: !withTitle,
			})}
		>
			<Col xs={24}>
				<Breadcrumb separator={<RightOutlined className={classes.arrow} />}>
					<Breadcrumb.Item>
						<a href="/" className={classes.link}>
							Home
						</a>
					</Breadcrumb.Item>

					{breadcrumbs?.map((item: IBreadcrumb, i: number) => {
						if (item.navigatePath) {
							return (
								<Breadcrumb.Item key={i}>
									<a href={item.navigatePath} className={classes.link}>
										{item.label}
									</a>
								</Breadcrumb.Item>
							);
						}

						return (
							<Breadcrumb.Item key={i}>
								<Typography.Text className="text-neutral-3">{item.label}</Typography.Text>
							</Breadcrumb.Item>
						);
					})}
				</Breadcrumb>
			</Col>
		</Row>
	);
};

export default PageHeaderBreadcrumb;
