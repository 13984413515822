import { HOMEPAGE_TYPE } from 'src/constants/redux';
import { DOWNLOAD, HOMEPAGE } from 'src/constants/services';
import { SINGLE_API } from 'src/redux/actions/types';

export const actionGetBanner = async (next?: (...f: any) => void) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `/${HOMEPAGE.model.banner}/${HOMEPAGE.service}`,
			successType: HOMEPAGE_TYPE.model.banner.success,
			next,
		},
	};
};

export const actionGetDownload = async (next?: (...f: any) => void) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `/${DOWNLOAD.service}`,
			successType: HOMEPAGE_TYPE.model.download.success,
			next,
		},
	};
};
