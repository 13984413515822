import { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Col, Typography } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import Skeleton from 'src/components/Skeleton';
import { IKlikStoreMasterProduct } from 'src/interface';
import { getDefaultProductImg, textAmount } from 'src/utils/func';
import { cleanSlug } from 'src/utils/func/klikstore';

import classes from './index.module.less';

interface ProductItemProps {
	className?: string;
	item: IKlikStoreMasterProduct;
	size: any;
	isLoading?: boolean;
	keyword?: string;
	onProductClick?: (item: IKlikStoreMasterProduct) => void;
}

const { Paragraph } = Typography;

const KlikStoreMasterProduct = (props: ProductItemProps) => {
	const { size, item, isLoading = false, keyword = '', onProductClick } = props;

	const isActive = (item.status ?? 1) === 1 && (item.is_active ?? 1) === 1;

	const isAvailable =
		item.prices.special_price !== 0 &&
		item.prices.special_price !== null &&
		item.prices.selling_price !== 0 &&
		item.prices.selling_price !== null;

	const keywords =
		keyword
			?.trim()
			?.split(' ')
			?.map((word) => word.replace(/[\-\[\]{}()*+?.,\\\^$%#\s]/g, '\\$&')) ?? [];

	const [isEllipsis, setIsEllipsis] = useState<boolean>(false);

	const ProductName = ({ className, name, ...otherProps }: any) => {
		const cleanedName = name?.replace(/^\s/, '');
		return (
			<div
				ref={(ref: any) => {
					if (!ref) return;
					const isOverflowing = ref.scrollHeight > ref.clientHeight;
					if (isOverflowing) {
						setIsEllipsis(true);
					} else {
						setIsEllipsis(false);
					}
				}}
				className={classNames(
					classes['product-name'],
					'fs-12 fs-md-12 fs-lg-13 fs-xl-14 mb-1',
					className,
				)}
				{...otherProps}
				title={isEllipsis ? cleanedName : ''}
			>
				<Highlighter
					highlightClassName={classes['highlight-text']}
					searchWords={keywords}
					textToHighlight={cleanedName}
					highlightTag="strong"
				/>
			</div>
		);
	};

	const ProductPrices = ({ price }: any) => {
		return (
			<div className="mb-1">
				<Paragraph className="fs-12 fs-md-12 fs-lg-13 fs-xl-14 fw-600 text-neutral text-left mb-0">
					{textAmount(price)}
				</Paragraph>
			</div>
		);
	};

	const image = item?.image;

	const productSlug = cleanSlug(`/kalstore/p/${encodeURIComponent(item?.slug ?? '')}`);

	return (
		<>
			<Col
				xs={size?.xs || 12}
				sm={size?.sm || 8}
				md={size?.md || 6}
				lg={size?.lg || 6}
				className={classNames(classes.item, props.className)}
			>
				<Skeleton isLoading={isLoading} height="257px">
					<a href={productSlug} className="cursor-pointer" onClick={() => onProductClick?.(item)}>
						<div className={classes['product-image-container']}>
							<div className={classes['product-image']}>
								<ImageC
									alt={item?.name}
									width={100}
									height={100}
									layout="responsive"
									objectFit="contain"
									src={image}
									defaultSrc={getDefaultProductImg()}
								/>
							</div>
							{(!isActive || !isAvailable) && (
								<div className={classes['image-overlay']}>
									<div className={classNames(classes.label, 'fs-10 fs-md-10 fs-lg-11 fs-xl-12')}>
										{!isActive ? 'Produk Tidak Aktif' : 'Produk Tidak Tersedia'}
									</div>
								</div>
							)}
						</div>
						<div>
							<ProductName className="mt-1 mb-2" name={item?.name} />
							{isActive && isAvailable && (
								<>
									<div className="mb-1 fs-10 text-neutral-7">Mulai dari</div>
									<ProductPrices price={item?.prices?.special_price} />
								</>
							)}
						</div>
					</a>
				</Skeleton>
			</Col>
		</>
	);
};

export default KlikStoreMasterProduct;
