import { QISCUS_TYPE } from 'src/constants/redux';

export const initialState = {
	instance: null,
	fbtoken: null,
};

const reducer = (state = initialState, action: any = {}) => {
	if (action.type === QISCUS_TYPE.model.instance.success) {
		return Object.assign({}, state, {
			instance: action.payload,
		});
	} else if (action.type === QISCUS_TYPE.model.fbtoken.success) {
		return Object.assign({}, state, {
			fbtoken: action.payload,
		});
	} else {
		return state;
	}
};

export default reducer;
