import Link from 'next/link';
import { useRouter } from 'next/router';

import ImageC from 'src/components/Image';
import { Title } from 'src/components/Layout/Typography';

import classes from './Logo.module.less';

const Logo = (props: any) => {
	const r = useRouter();

	const LogoC = () => {
		return (
			<ImageC
				priority
				width={undefined}
				height={undefined}
				layout="fill"
				objectFit="contain"
				src="/images/klikdokter.svg"
				alt="KlikDokter"
			/>
		);
	};

	return (
		<Link href="/" onClick={props.ClickImage} className={classes['kd-logo']}>
			{r.pathname === '/' ? (
				<Title level={1}>
					<LogoC />
				</Title>
			) : (
				<LogoC />
			)}
		</Link>
	);
};

export default Logo;
