import { CATEGORY_TYPE } from 'src/constants/redux/categoryByGroup';
import { CATEGORY } from 'src/constants/services';
import { IFilterCategory, ISearchCategoryByGroup } from 'src/interface/CategoryByGroup';

import { SINGLE_API } from './types';

export const actionGetCategories = async (
	payload?: IFilterCategory,
	next?: (...f: any) => void,
) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `${CATEGORY.service}`,
			apiType: 'v3_pb',
			successType: CATEGORY_TYPE.model.categories.success,
			options: { method: 'GET' },
			payload,
			next,
		},
	};
};

export const actionGetCategoryHomePage = async (
	slug?: string,
	payload?: IFilterCategory,
	next?: (...f: any) => void,
) => {
	let serviceUrl = CATEGORY.service;
	if (slug === 'mytopics') {
		serviceUrl = CATEGORY.servicePrivate;
	}

	return {
		type: SINGLE_API,
		payload: {
			url: `${serviceUrl}/${slug}`,
			apiType: 'v3_pb',
			successType: CATEGORY_TYPE.model.groups.success,
			options: { method: 'GET' },
			payload,
			next,
		},
	};
};

export const actionGetCategoryDetail = async (slug: string, next?: (...f: any) => void) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `${CATEGORY.slug}/${slug}`,
			apiType: 'v3_pb',
			successType: CATEGORY_TYPE.model.detail.success,
			options: { method: 'GET' },
			next,
		},
	};
};

export const actionGetCategoryObatDetail = async (
	slug: string,
	type?: string,
	next?: (...f: any) => void,
) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `${CATEGORY.slugObat}all?&type=${type}&category=${slug}`,
			apiType: 'v3_pb',
			successType: CATEGORY_TYPE.model.detail_obat.success,
			options: { method: 'GET' },
			next,
		},
	};
};

export const actionSearchCategory = async (
	payload: ISearchCategoryByGroup,
	group: string,
	next?: (...f: any) => void,
) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `${CATEGORY.service}/${group}/search`,
			apiType: 'v3_pb',
			successType: CATEGORY_TYPE.model.search.success,
			options: { method: 'GET' },
			payload,
			next,
		},
	};
};

export const actionGetAllCategory = async (
	payload?: IFilterCategory,
	next?: (...f: any) => void,
) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `${CATEGORY.service}`,
			apiType: 'v3_pb',
			successType: CATEGORY_TYPE.model.all.success,
			options: { method: 'GET' },
			payload,
			next,
		},
	};
};

export const actionGetSubCategoryByParentCategorySlug = async (
	slug: string,
	payload?: IFilterCategory,
) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `${CATEGORY.slug}/${slug}/sub-categories`,
			apiType: 'v3_pb',
			successType: CATEGORY_TYPE.model.subCategories.success,
			options: { method: 'GET' },
			payload,
		},
	};
};
