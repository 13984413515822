import g from 'src/constants/global';
import { USER_TYPE } from 'src/constants/redux';

export const initialState = {
	userCheck: null,
	userCheckPin: null,
	userCheckRelation: null,
	userCheckNumber: null,
	userChangeNumber: null,
	userLogged: null,
	userToken: null,
	userForgotPin: null,
	userChangePin: null,
	changeAvatar: null,
	updateProfile: null,
	user: null,
	userListRelationship: null,
	userUpdateRelationship: null,
	userDeleteRelationship: null,
	userTopic: null,
	userIdentity: null,
	userLocation: {
		...g.MAP.DATA.defaultPosition,
		address: '',
		addressLabel: '',
		modal: false,
	},
	customerInfo: null,
};

const reducer = (state = initialState, action: any = {}) => {
	switch (action.type) {
		case USER_TYPE.model.listTopic.success:
		case USER_TYPE.model.listTopic.error:
			return Object.assign({}, state, {
				userTopic: action.payload,
			});
		case USER_TYPE.model.updateProfile.success:
		case USER_TYPE.model.updateProfile.error:
			return Object.assign({}, state, {
				updateProfile: action.payload,
			});

		case USER_TYPE.model.updateImage.success:
		case USER_TYPE.model.updateImage.error:
			return Object.assign({}, state, {
				changeAvatar: action.payload,
			});

		case USER_TYPE.model.check.success:
		case USER_TYPE.model.check.error:
			return Object.assign({}, state, {
				userCheck: action.payload,
			});

		case USER_TYPE.model.checkPin.success:
		case USER_TYPE.model.checkPin.error:
			return Object.assign({}, state, {
				userCheckPin: action.payload,
			});

		case USER_TYPE.model.checkNumber.success:
		case USER_TYPE.model.checkNumber.error:
			return Object.assign({}, state, {
				userCheckNumber: action.payload,
			});

		case USER_TYPE.model.changeNumber.success:
		case USER_TYPE.model.changeNumber.error:
			return Object.assign({}, state, {
				userChangeNumber: action.payload,
			});

		case USER_TYPE.model.checkRelation.success:
		case USER_TYPE.model.checkRelation.error:
			return Object.assign({}, state, {
				userCheckRelation: action.payload,
			});

		case USER_TYPE.model.logged.success:
		case USER_TYPE.model.logged.error:
			return Object.assign({}, state, {
				userLogged: action.payload,
			});

		case USER_TYPE.model.login.success:
		case USER_TYPE.model.login.error:
		case USER_TYPE.model.register.success:
		case USER_TYPE.model.register.error:
		case USER_TYPE.model.setPin.success:
		case USER_TYPE.model.setPin.error:
			return Object.assign({}, state, {
				userToken: action.payload,
			});

		case USER_TYPE.model.forgotPin.success:
		case USER_TYPE.model.forgotPin.error:
			return Object.assign({}, state, {
				userForgotPin: action.payload,
			});

		case USER_TYPE.model.changePin.success:
		case USER_TYPE.model.changePin.error:
			return Object.assign({}, state, {
				userChangePin: action.payload,
			});

		case USER_TYPE.model.logout.success:
		case USER_TYPE.model.reset.success:
			return Object.assign({}, state, initialState);

		case USER_TYPE.model.profile.success:
			return Object.assign({}, state, {
				user: action.payload,
			});

		case USER_TYPE.model.profile.error:
			return Object.assign({}, state, {
				user: null,
			});

		case USER_TYPE.model.listRelationship.success:
		case USER_TYPE.model.listRelationship.error:
			return Object.assign({}, state, {
				userListRelationship: action.payload,
			});

		case USER_TYPE.model.updateRelationship.success:
		case USER_TYPE.model.updateRelationship.error:
			return Object.assign({}, state, {
				userUpdateRelationship: action.payload,
			});

		case USER_TYPE.model.deleteRelationship.success:
		case USER_TYPE.model.deleteRelationship.error:
			return Object.assign({}, state, {
				userDeleteRelationship: action.payload,
			});

		case USER_TYPE.model.updateProfileIdentity.success:
		case USER_TYPE.model.updateProfileIdentity.error:
			return Object.assign({}, state, {
				userIdentity: action.payload,
			});

		case USER_TYPE.model.location.success:
		case USER_TYPE.model.location.error:
			return Object.assign({}, state, {
				userLocation: action.payload,
			});

		case USER_TYPE.model.info.success:
		case USER_TYPE.model.info.error:
			return Object.assign({}, state, {
				customerInfo: action.payload,
			});

		default:
			return state;
	}
};

export default reducer;
