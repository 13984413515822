import g from 'src/constants/global';

const {
	consultationChat,
	consultationVideo,
	consultationFace,
	reservationDoctor,
	reservationService,
	prescriptionRedeem,
	prescriptionUpload,
	healthyShopping,
} = g.PROFILE.TRANSACTION.SERVICE;

export const moduleRedirect = (module: string) => {
	switch (module) {
		case consultationChat:
			return '/tanya-dokter/konsultasi?type=chat';
		case consultationVideo:
			return '/tanya-dokter/konsultasi?type=video';
		case consultationFace:
			return '/tanya-dokter/konsultasi?type=meet';
		case reservationDoctor:
			return '/janji-medis/konsultasi?type=doctor';
		case reservationService:
			return '/janji-medis/konsultasi?type=service';
		case prescriptionRedeem:
			return '/'; // TODO: must double check flow redirect
		case prescriptionUpload:
			return '/'; // TODO: must double check flow redirect
		case healthyShopping:
			return '/'; // TODO: must double check flow redirect
		default:
			return '/';
	}
};

export const moduleRedirectToReorder = (
	module: string,
	slug: string = '',
	medFacilityType: string = '',
	medFacilitySlug: string = '',
) => {
	switch (module) {
		case consultationChat:
		case consultationVideo:
		case consultationFace:
			return `/dokter/${slug}`;
		case reservationDoctor:
			return `/temu-dokter/dokter/${slug}`;
		case reservationService:
			return `/${medFacilityType}/${medFacilitySlug}/${slug}`;
		case prescriptionRedeem:
			return '/'; // TODO: must double check flow redirect
		case prescriptionUpload:
			return '/'; // TODO: must double check flow redirect
		case healthyShopping:
			return '/cart'; // TODO: must double check flow redirect
		default:
			return '/';
	}
};
