import { KLIKSTORE_LANDINGPAGE_TYPE } from 'src/constants/redux';
import { bannersFields } from 'src/hooks';
import { SINGLE_API } from 'src/redux/actions/types';

export const actionGetCategoryList = async (next?: (...f: any) => void) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'marketplace',
			url: '/search/categoryTree',
			options: { method: 'GET' },
			beforeCallType: KLIKSTORE_LANDINGPAGE_TYPE.model.categoryList.fetch,
			successType: KLIKSTORE_LANDINGPAGE_TYPE.model.categoryList.success,
			next,
		},
	};
};

export const actionGetLandingPageCategories = async (next?: (...f: any) => void) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'marketplace',
			url: '/search/categories',
			options: { method: 'GET' },
			payload: {
				level: 2,
				in_homepage: 1,
				limit: 24,
			},
			beforeCallType: KLIKSTORE_LANDINGPAGE_TYPE.model.landingPageCategories.fetch,
			successType: KLIKSTORE_LANDINGPAGE_TYPE.model.landingPageCategories.success,
			next,
		},
	};
};

export const actionGetLandingPageBanners = async (next?: (...f: any) => void) => {
	return {
		type: SINGLE_API,
		payload: {
			apiType: 'marketplace',
			url: '/search/banners',
			options: { method: 'GET' },
			payload: {
				fields: bannersFields,
				category_slug: 'general-banner',
			},
			beforeCallType: KLIKSTORE_LANDINGPAGE_TYPE.model.landingPageBanners.fetch,
			successType: KLIKSTORE_LANDINGPAGE_TYPE.model.landingPageBanners.success,
			next,
		},
	};
};
