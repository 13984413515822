import { useMutation, useQuery } from 'react-query';
import { PaginationProps } from 'antd';

import { PAYMENT } from 'src/constants/services';
import { TransactionDetail } from 'src/interface';
import { ResponseData } from 'src/interface/Commons';
import AuthStorage from 'src/utils/auth-storage';
import { fetchApiMarketplace, fetchApiPayment } from 'src/utils/fetch-api';

const query = 'transaction';
const queryTransModule = 'module_transaction';

interface TransactionListParams {
	setIsFirstLoad?: (e: boolean) => void;
	pagination?: PaginationProps;
	module?: any;
	type?: '' | 'waiting' | 'transaction_done';
	search?: string;
	period?: string;
	enabled?: boolean;
}

export const useTransactionLists = ({
	pagination,
	module,
	type = '',
	search,
	period,
	enabled = true,
}: TransactionListParams) => {
	return useQuery(
		[query, pagination?.current, module, type, search, period],
		() => {
			let moduleCollection: any = [];
			if (typeof module === 'string') {
				moduleCollection = [module];
			}
			return fetchApiPayment({
				url: `/${PAYMENT.service}/${PAYMENT.model.transactionList}`,
				options: { method: 'POST' },
				payload: {
					page: pagination?.current,
					limit: pagination?.pageSize,
					module: moduleCollection?.length > 0 ? moduleCollection : undefined,
					filter: [],
					type,
					search,
					period: period ? period : 'Semua Waktu',
				},
			});
		},
		{
			retry: false,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			enabled: AuthStorage.loggedIn && enabled,
		},
	);
};
export const useTransactionListPerModule = ({
	transactionListFilter,
	type = '',
	enabled = true,
}: any) => {
	const { pagination, module, period, search } = transactionListFilter;
	return useQuery(
		[queryTransModule, pagination?.current, module, type, search, period],
		() => {
			let moduleCollection: any = [];
			if (module === 'konsultasi-chat') {
				moduleCollection = ['Konsultasi Chat'];
			} else if (module === 'konsultasi-video-call') {
				moduleCollection = ['Konsultasi Video Call'];
			} else if (module === 'temu-dokter') {
				moduleCollection = ['Janji Dokter'];
			} else if (module === 'layanan') {
				moduleCollection = ['Janji Layanan'];
			} else if (module === 'tebus-resep') {
				moduleCollection = ['Tebus Resep'];
			}
			return fetchApiPayment({
				url: `/${PAYMENT.service}/${PAYMENT.model.transactionList}`,
				options: { method: 'POST' },
				payload: {
					page: pagination?.current,
					limit: pagination?.pageSize,
					module: moduleCollection?.length > 0 ? moduleCollection : undefined,
					filter: [],
					type,
					search,
					period: period ? period : 'Semua Waktu',
				},
			});
		},
		{
			retry: false,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			enabled: AuthStorage.loggedIn && enabled,
		},
	);
};

export const useTransactionDetail = (invoice: string, enabled = true) => {
	return useQuery(
		[query, 'detail', invoice],
		() => {
			return fetchApiPayment({
				url: `/${PAYMENT.service}/${PAYMENT.model.transactionDetail}`,
				options: { method: 'POST' },
				payload: { invoice },
			});
		},
		{
			retry: false,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			enabled: AuthStorage.loggedIn && enabled,
		},
	);
};

export const useTrackingOrder = (trackingNo: string, enabled: boolean = false) => {
	return useQuery(
		['useTrackingOrder', trackingNo],
		() => {
			return fetchApiPayment({
				url: `/courier/tracking/${trackingNo}`,
			});
		},
		{
			retry: false,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			enabled,
		},
	);
};

export const useKlikstoreInvoiceDetail = (invoice: string, enabled = true) => {
	return useQuery(
		[query, 'detail', invoice],
		() => {
			return fetchApiMarketplace({
				url: `/order/draft/${invoice}`,
				options: { method: 'GET' },
			});
		},
		{
			retry: false,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			enabled: AuthStorage.loggedIn && enabled,
		},
	);
};

export const useMutationTransactionDetail = () =>
	useMutation(
		(invoice: string): Promise<ResponseData<TransactionDetail, any>> =>
			fetchApiPayment({
				url: `/${PAYMENT.service}/${PAYMENT.model.transactionDetail}`,
				options: { method: 'POST' },
				payload: { invoice },
			}),
	);

export const useTransactionReceipt = (invoice: string) => {
	return useQuery(
		[query, 'receipt', invoice],
		() => {
			return fetchApiPayment({
				url: `/${PAYMENT.service}/${PAYMENT.model.receiptDetail}`,
				options: { method: 'POST' },
				payload: { invoice },
			});
		},
		{ retry: false, keepPreviousData: true, refetchOnMount: false, refetchOnWindowFocus: false },
	);
};

export const useTransactionStatus = (invoice: string) => {
	return useQuery(
		[query, 'status', invoice],
		() => {
			return fetchApiPayment({
				url: `/${PAYMENT.service}/${PAYMENT.model.journey}`,
				options: { method: 'POST' },
				payload: { invoice },
			});
		},
		{ retry: false, keepPreviousData: true, refetchOnMount: false, refetchOnWindowFocus: false },
	);
};

export const usePaymentWaiting = () => {
	const paymentWaiting = (invoice: string) => {
		return fetchApiPayment({
			url: `/${PAYMENT.service}/${PAYMENT.model.waiting}`,
			options: { method: 'POST' },
			payload: { invoice },
		});
	};

	return useMutation((invoice: string) => paymentWaiting(invoice), {
		onError: (error: any) => {
			console.log(error);
		},
	});
};
