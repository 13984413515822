import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ModalC from 'src/components/Modal';
import g from 'src/constants/global';
import { AuthBody, AuthProps } from 'src/interface';
import { actionUserCheckModify } from 'src/redux/actions';
import { actionSetQuickAuthDisplay } from 'src/redux/actions/klikstore/quickauth';

import Auth from './Auth';

import classes from './index.module.less';

interface IModalAuth {
	visible: boolean;
	loading?: boolean;
	mode?: AuthProps['mode'];
	step: string;
	onSucessAuthCallBack?: () => void;
}
const ModalAuth = (props: IModalAuth) => {
	const dispatch = useDispatch();
	const [authStep, setAuthStep] = useState<string>(props.step);
	const [authBody, setAuthBody] = useState<AuthBody>({
		firstName: '',
		lastName: '',
		otp: undefined,
		phoneNumber: '',
		pin: undefined,
		pinConfirm: undefined,
		topic: [],
	});
	const notClosableStep = [g.AUTH.REGISTER.otp, g.AUTH.FORGOT.otp].includes(authStep) ||
		[g.AUTH.LOGIN.pin, g.AUTH.REGISTER.pin, g.AUTH.FORGOT.pin].includes(authStep) || [
			g.AUTH.REGISTER.pinConfirm,
			g.AUTH.FORGOT.pinConfirm,
		];

	const onCancel = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault();
		dispatch(
			actionSetQuickAuthDisplay({
				status: false,
				callBackFn: null,
			}),
		);
		dispatch(await actionUserCheckModify(null));
		setAuthStep(g.AUTH.REGISTER.user);
		setAuthBody({
			firstName: '',
			lastName: '',
			otp: undefined,
			phoneNumber: '',
			pin: undefined,
			pinConfirm: undefined,
			topic: [],
		});
	};

	const handleSuccessAuthCallback = () => {
		if (props?.onSucessAuthCallBack) {
			props?.onSucessAuthCallBack();
		}

		dispatch(
			actionSetQuickAuthDisplay({
				status: false,
				callBackFn: null,
			}),
		);
	};

	return (
		<ModalC
			className={classes['modal-login']}
			closable={!notClosableStep}
			width={430}
			hideButton
			visible={props.visible}
			destroyOnClose
			onCancel={onCancel}
		>
			<Auth
				step={g.AUTH.LOGIN.phone}
				loading={props.loading}
				authStep={authStep}
				setAuthStep={setAuthStep}
				authBody={authBody}
				setAuthBody={setAuthBody}
				onSucessAuthCallBack={handleSuccessAuthCallback}
			/>
		</ModalC>
	);
};

export default ModalAuth;
