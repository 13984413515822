import Storage from './storage';

class AnnounceStorage extends Storage {
	get isShown() {
		return this.value?.isShown;
	}

	set isShown(val) {
		this.value = {
			...this.value,
			isShown: {
				...this.isShown,
				...val,
			},
		};
	}
}

const announceStorage = new AnnounceStorage(
	'settings.announce',
	{
		isShown: {
			'tanya-dokter': false,
		},
	},
	{
		maxAge: process.env.CLOSE_FEATURE_POPUP_EXPIRED,
	},
);

export default announceStorage;
