import { useMutation, useQuery, useQueryClient } from 'react-query';

import { USER, WEARABLE_DEVICE } from 'src/constants/services';
import { fetchApiConsultation, fetchApiPartner, fetchApiV3User } from 'src/utils/fetch-api';

const wearableDevice = 'wearable_device';
const wearableDeviceConnect = 'wearable_device_connect';
const wearableDeviceOmron = 'wearable_device_omron';

export const useWearableDeviceList = () => {
	return useQuery(
		[wearableDevice],
		() => fetchApiV3User({ url: `/${USER.service}/${WEARABLE_DEVICE.service}` }),
		{
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
};

export const useWearableDeviceBound = () => {
	return useQuery(
		[wearableDeviceConnect],
		() =>
			fetchApiV3User({
				url: `/${USER.service}/${WEARABLE_DEVICE.service}/${WEARABLE_DEVICE.model.numberConnected}`,
			}),
		{
			retry: false,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
};

export const useConnectDevice = () => {
	const queryClient = useQueryClient();
	const connectWearableDevice = ({ device_name, provider_user_id, kii_succeeded, code }: any) => {
		const dataDevice = { provider_user_id, kii_succeeded, code };
		return fetchApiV3User({
			url: `/${USER.service}/${WEARABLE_DEVICE.service}/${WEARABLE_DEVICE.model.connect}`,
			options: { method: 'PUT' },
			payload: { code: device_name, data_device: dataDevice },
		});
	};

	return useMutation(
		({ device_name, provider_user_id, kii_succeeded, code }: any) =>
			connectWearableDevice({ device_name, provider_user_id, kii_succeeded, code }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(wearableDevice);
				queryClient.invalidateQueries(wearableDeviceConnect);
			},
		},
	);
};

export const useDisconnectDevice = () => {
	const queryClient = useQueryClient();
	const disconnectWearableDevice = (device_name: string) => {
		return fetchApiV3User({
			url: `/${USER.service}/${WEARABLE_DEVICE.service}/${WEARABLE_DEVICE.model.disconnect}`,
			options: { method: 'PUT' },
			payload: { code: device_name },
		});
	};

	return useMutation((device_name: string) => disconnectWearableDevice(device_name), {
		onSettled: () => {
			queryClient.invalidateQueries(wearableDevice);
			queryClient.invalidateQueries(wearableDeviceConnect);
		},
	});
};

export const useDataOmron = (omronConnectId: string) => {
	return useQuery(
		[wearableDeviceOmron],
		() =>
			fetchApiPartner({
				url: `/${WEARABLE_DEVICE.device.omron}`,
				options: { method: 'POST' },
				payload: { omron_connect_id: omronConnectId },
			}),
		{
			enabled: !!omronConnectId,
			retry: false,
			retryOnMount: false,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
};

export const useShareDataOmron = () => {
	const queryClient = useQueryClient();
	const shareDataOmron = ({ type, token, room_id }: any) => {
		return fetchApiConsultation({
			url: `/${USER.service}/${WEARABLE_DEVICE.model.shareWearable}`,
			options: { method: 'POST' },
			payload: { type, token, room_id },
		});
	};

	return useMutation(({ type, token, room_id }: any) => shareDataOmron({ type, token, room_id }), {
		onSettled: () => {
			queryClient.invalidateQueries(wearableDevice);
		},
	});
};
