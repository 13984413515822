import cookie from 'react-cookies';

import { isBrowser } from 'src/constants/environment';

const cookieName = 'header';
const cookieValue = 'marketplace';

const saveToCookie = (layout: any) => {
	if (layout) {
		const maxAgeDay = process.env.COOKIE_DEFAULT_MAXAGE
			? parseInt(process.env.COOKIE_DEFAULT_MAXAGE, 10)
			: 7;

		cookie.save(cookieName, cookieValue, {
			path: '/',
			maxAge: maxAgeDay * 24 * 60 * 60,
		});
	}
};

const headerMP = (router: any) => {
	const params = isBrowser ? new URLSearchParams(window.location.search) : null;
	let lm = (router?.query?.[cookieName] || params?.get?.(cookieName)) === cookieValue;

	if (!lm) {
		let lmc = cookie.load(cookieName) === cookieValue;

		if (!lmc) {
			saveToCookie(lm);
			return lm;
		}

		return lmc;
	}

	saveToCookie(lm);

	return lm;
};

export default headerMP;
