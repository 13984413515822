import { ReactNode } from 'react';
import { Col, message, Row } from 'antd';
import classNames from 'classnames';

import Image from 'src/components/Image';

import classes from './index.module.less';

type Types = 'success' | 'error' | 'neutral';

const messageC = (
	text: string | ReactNode,
	type?: Types,
	options?: {
		dynamicHeight?: boolean;
		hideIcon?: boolean;
	},
) => {
	const msgType = classes[`message-${type}`];

	message.success({
		className: classNames(`${classes.message} ${msgType}`, {
			[classes['dynamic-height']]: options?.dynamicHeight,
		}),
		key: 'z',
		content: [
			<Row key="z" justify="space-between">
				<Col className="text-left" span={22}>
					<p>{text}</p>
				</Col>
				{!options?.hideIcon ? (
					<Col span={2} onClick={() => message.destroy('z')}>
						<Image width={16} height={16} src="/icons/close-circle.svg" alt="close" />
					</Col>
				) : null}
			</Row>,
		],
	});
};

export default messageC;
