import { ARTICLE_TYPE } from 'src/constants/redux';

export const initialState = {
	category_topic: null,
	articles: null,
};

const reducer = (state = initialState, action: any = {}) => {
	switch (action.type) {
		case ARTICLE_TYPE.model.category_topic.success:
			return Object.assign({}, state, {
				category_topic: action.payload,
			});
		case ARTICLE_TYPE.model.articles.success:
			return Object.assign({}, state, {
				articles: action.payload,
			});

		default:
			return state;
	}
};

export default reducer;
