import { ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Select, SelectProps, Spin } from 'antd';

import ImageC from 'src/components/Image';

import classes from './index.module.less';

interface DataType {
	value: any;
	label?: any;
	children?: ReactNode;
	disabled?: boolean;
}

type Size = 'large' | 'middle' | 'small';
export type SelectCProps = SelectProps<any> & {
	WithoutForm?: boolean;
	showSearch?: boolean;
	allowClear?: boolean;
	name: string;
	options: DataType[];
	initialValue?: any;
	defaultValue?: any;
	value?: any;
	disabled?: boolean;
	useLabel?: boolean;
	label?: string;
	className?: string;
	dropdownClassName?: string;
	optionClassName?: string;
	rules?: object[];
	placeholder?: string | ReactNode;
	loading?: boolean;
	mode?: 'multiple' | 'tags';
	size?: Size;
	open?: boolean;
	onChange?: (e?: any) => void;
};

export const addSuffix = (prefix?: string, text?: string) => {
	return (
		<div className={classes.suffix}>
			<ImageC width={20} height={20} alt="suffix" src={prefix} />
			<p>{text}</p>
		</div>
	);
};

const SelectC = (props: SelectCProps) => {
	const useChildren = props.showSearch ? false : true;
	const useLabel = props.useLabel ? props.label : '';
	const isRequired = props.rules
		? props.rules.filter((r: any) => r.required === true).length > 0
		: false;

	const filterOption = (input: string, option: any) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

	const SelectWrapper = (v: any) => {
		return (
			<Select
				showSearch={props.showSearch}
				filterOption={props.showSearch ? filterOption : undefined}
				allowClear={props.allowClear}
				size={props.size}
				defaultValue={props.defaultValue}
				className={props.className}
				dropdownClassName={props.dropdownClassName}
				value={v}
				disabled={props.disabled}
				placeholder={props.placeholder}
				loading={props.loading}
				mode={props.mode}
				open={props.open}
				onChange={props.onChange}
				style={props.style}
				dropdownStyle={props.dropdownStyle}
				options={!useChildren ? props.options : undefined}
				suffixIcon={
					props?.suffixIcon ||
					(!props.loading ? (
						<ImageC src="/icons/down-arrow.svg" height={20} width={20} />
					) : (
						<Spin indicator={antIcon} />
					))
				}
			>
				{useChildren &&
					props.options?.map((o, i) => (
						<Select.Option
							value={o.value}
							key={i}
							className={props.optionClassName}
							disabled={o?.disabled ?? false}
						>
							{o.label || o.children}
						</Select.Option>
					))}
			</Select>
		);
	};

	return (
		<>
			{props.WithoutForm ? (
				<>{SelectWrapper(props.initialValue)}</>
			) : (
				<Form.Item
					label={useLabel}
					name={props.name}
					required={isRequired}
					rules={props.rules}
					initialValue={props.initialValue}
				>
					{SelectWrapper(props.value)}
				</Form.Item>
			)}
		</>
	);
};

export default SelectC;
