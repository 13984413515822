import { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Router from 'next/router';
import { Form, Typography } from 'antd';

import InputPin from 'src/components/Forms/Input/InputPin';
import Image from 'src/components/Image';
import g from 'src/constants/global';
import { AuthProps } from 'src/interface/Auth';
import { actionUserForgotPin } from 'src/redux/actions/auth';

import classes from './AuthPinConfirm.module.less';

const { Text, Title } = Typography;

const AuthPinConfirm = (props: AuthProps) => {
	const dispatch = useDispatch();
	const { auth, otp } = useSelector((state: RootStateOrAny) => state);

	const [pin, setPin] = useState<string>('');
	const [hasErrored, setHasErrored] = useState<boolean>(false);

	useEffect(() => {
		if (auth.userForgotPin && auth.userForgotPin?.status === 200) Router.push('/');
	}, [auth.userForgotPin]);

	useEffect(() => {
		if (props.type === g.AUTH.FORGOT.label) {
			if (
				otp.otpValidUser === null ||
				otp.otpValidUser?.status !== 200 ||
				otp.otpValidate === null ||
				otp.otpValidate?.status !== 200
			) {
				props.setAuthStep?.(g.AUTH.FORGOT.phone);
			}
		} else {
			if (
				auth.userCheck === null ||
				auth.userCheck?.status !== 200 ||
				otp.otpValidate === null ||
				otp.otpValidate?.status !== 200 ||
				props.authBody?.pin === undefined
			) {
				props.setAuthStep?.(g.AUTH.REGISTER.phone);
			}
		}
	}, [auth.userCheck, otp.otpValidate, otp.otpValidUser]);

	const handleBack = () => {
		props.setAuthBody?.({ ...props.authBody, pin: undefined });
		props.setAuthStep?.(
			props.type === g.AUTH.FORGOT.label ? g.AUTH.FORGOT.pin : g.AUTH.REGISTER.pin,
		);
	};

	const handlePinCheck = (rules: any, val: string, callback: any) => {
		try {
			if (val?.length === 6) {
				if (val === props.authBody?.pin) {
					setHasErrored(false);
					return Promise.resolve();
				} else {
					setHasErrored(true);
					return Promise.reject(new Error('PIN tidak sama, masukan kembali dengan benar'));
				}
			} else {
				setHasErrored(false);
				return Promise.resolve();
			}
		} catch (err) {
			callback(err);
		}
	};

	const handlePinConfirm = async (e: any) => {
		let pinFormated = e.replace(/\D/g, '');
		setPin(pinFormated);

		if (pinFormated.length === 6 && pinFormated === props.authBody?.pin) {
			if (props.type === g.AUTH.FORGOT.label) {
				dispatch(
					await actionUserForgotPin({
						phone: props.authBody!.phoneNumber,
						otp: props.authBody!.otp,
						pin: props.authBody!.pin,
						pin_confirmation: pinFormated,
					}),
				);
			} else {
				await props.setAuthBody?.({ ...props.authBody, pinConfirm: pinFormated });
				props.setAuthStep?.(g.AUTH.REGISTER.user);
			}
		}
	};

	return (
		<div className={`${classes.container} d-flex align-items-center`}>
			<div className={classes.content}>
				<div className={classes['pin-button-back']} onClick={handleBack}>
					<Image alt="Icon Arrow Left" height={32} width={32} src="/icons/arrow-left.svg" />
				</div>
				<div className={classes['pin-top']}>
					<Title level={3}>Konfirmasi PIN Kamu</Title>
					<Text className={classes.description}>PIN digunakan untuk masuk ke akun kamu</Text>
				</div>
				<div className={classes['pin-main']}>
					<Form autoComplete="off" layout="vertical" name="auth-form">
						<InputPin
							hasErrored={hasErrored}
							name="pinConfirm"
							numInputs={6}
							onChange={handlePinConfirm}
							rules={[{ validator: handlePinCheck }]}
							shouldAutoFocus={true}
							value={pin}
						/>
						<Form.Item />
					</Form>
				</div>
			</div>
		</div>
	);
};

export default AuthPinConfirm;
