export const ARTICLE_BY_GROUP_TYPE = {
	model: {
		articles: {
			success: 'GET_ARTICLE_BY_GROUP_SUCCESS',
		},
		detail: {
			success: 'GET_ARTICLE_BY_GROUP_DETAIL_SUCCESS',
		},
		search: {
			success: 'SEARCH_ARTICLE_BY_GROUP_SUCCESS',
		},
		mostSearch: {
			success: 'GET_MOST_SEARCH_ARTICLE_BY_GROUP_SUCCESS',
		},
		autoComplete: {
			success: 'SEARCH_AUTO_COMPLETE_ARTICLE_BY_GROUP_SUCCESS',
		},
		articleByCategory: {
			success: 'GET_ARTICLE_BY_CATEGORY',
		},
		related: {
			success: 'GET_ARTICLE_RELATED',
		},
	},
};
