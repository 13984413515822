import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Col, Divider, Grid, Input, Rate, Row, Typography } from 'antd';
import classNames from 'classnames';

import { ButtonBorder, ButtonOK } from 'src/components/Forms/Button';
import ImageC from 'src/components/Image';
import SpinC from 'src/components/Layout/Spin';
import messageC from 'src/components/Message';
import { QUERYKEYDETAIL } from 'src/hooks/KlikStore/Order/useKlikStoreOrderHistory';
import {
	useDetailOrderQueryKey,
	useDetailReviewQueryKey,
	userReviewListQueryKey,
	useSubmitReviewProduct,
	useSubmitReviewStore,
} from 'src/hooks/Profile/useUserReview';
import { formatDate, getDefaultProductImg } from 'src/utils/func';

import { IReviewContentData, IReviewContentProductData } from './DetailReviewModal';

import classes from './DetailReviewContent.module.less';

const { Text, Paragraph } = Typography;
const { TextArea } = Input;

const storeRatings = [
	{
		label: 'Kecewa',
		value: 1,
		emoji: '/images/klikstore/not-satisfied.svg',
		activeEmoji: '/images/klikstore/not-satisfied-active.svg',
	},
	{
		label: 'Puas',
		value: 2,
		emoji: '/images/klikstore/satisfied.svg',
		activeEmoji: '/images/klikstore/satisfied-active.svg',
	},
	{
		label: 'Sangat Puas',
		value: 3,
		emoji: '/images/klikstore/very-satisfied.svg',
		activeEmoji: '/images/klikstore/very-satisfied-active.svg',
	},
];

interface IInputCommentReviewProduct {
	index: number;
	product: IReviewContentProductData;
	error: any;
	isCommentInputEmpty: (index: number) => boolean;
	onChangeProductInput: (value: any, name: string, index: number) => void;
}

const InputCommentReviewProduct = (props: IInputCommentReviewProduct) => {
	const onChangeProductInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		props.onChangeProductInput(e.target.value, 'comment', props.index);
	};

	return (
		<div className={classes['input-area']}>
			<Text className="d-block text-left mb-2 fs-14 fw-600">
				{props.product?.is_product_reviewed ? 'Komentar' : 'Berikan Komentar?'}
			</Text>
			{props.product?.is_product_reviewed ? (
				<Text className="fs-16">{props.product?.comment ?? '-'}</Text>
			) : (
				<>
					<TextArea
						value={props.product.comment}
						onChange={onChangeProductInput}
						placeholder="Tulis komentarmu disini"
						showCount
						maxLength={150}
						rows={4}
						className={classNames({ [classes.error]: props.isCommentInputEmpty(props.index) })}
					/>
					{props.isCommentInputEmpty(props.index) && (
						<Text className="fs-12 text-error mt-2">{props.error[`comment-${props.index}`]}</Text>
					)}
					{!props.isCommentInputEmpty(props.index) && (
						<Text className="fs-12 text-neutral-9 text-left d-block mt-2">Minimal 10 Karakter</Text>
					)}
				</>
			)}
		</div>
	);
};

interface ILabelReviewStore {
	selectedStoreRating: any;
	data: IReviewContentData | undefined;
}

const LabelReviewStore = (props: ILabelReviewStore) => {
	if (props.selectedStoreRating) {
		return (
			<Text className={classNames('d-block fs-14 fw-600', classes.label)}>
				Kamu memberi penilaian{' '}
				<Text className="text-primary">{props.selectedStoreRating.label}</Text>?
			</Text>
		);
	}
	return (
		<Text className={classNames('d-block fs-14 fw-600', classes.label)}>
			{props.data?.is_merchant_reviewed ? 'Penilaian Anda' : 'Beri Penilaian Kepada Penjual'}
		</Text>
	);
};

interface IEmojiRating {
	rating: any;
	isReviewed: boolean;
	setSelectedStoreRating: any;
}

const EmojiRating = (props: IEmojiRating) => {
	const setSelectedStoreRating = () => {
		props.setSelectedStoreRating(props.rating);
	};

	return (
		<Col
			sm={4}
			md={8}
			className={classNames(classes.ratings, 'cursor-pointer', {
				[classes.disabled]: props.isReviewed,
			})}
			onClick={setSelectedStoreRating}
		>
			<ImageC
				src={props.isReviewed ? props.rating.activeEmoji : props.rating.emoji}
				width={32}
				height={32}
			/>
			<Text className="d-block fs-12 text-neutral-9 mt-2">{props.rating.label}</Text>
		</Col>
	);
};

interface IRatingReviewStore {
	selectedStoreRating: any;
	setSelectedStoreRating: any;
	isLoading: boolean;
	responsive?: boolean;
	ratingStore: typeof storeRatings;
	isReviewed: boolean;
	onSubmitReviewStore: () => void;
}

const RatingReviewStore = (props: IRatingReviewStore) => {
	const setSelectedStoreRating = () => {
		props.setSelectedStoreRating(undefined);
	};

	if (props.selectedStoreRating) {
		return (
			<Row gutter={[8, 0]} justify="center" className={classes['store-info']}>
				{props.isLoading ? (
					<Col sm={24}>
						<SpinC className="m-0" />
					</Col>
				) : (
					<>
						<Col sm={12}>
							<ButtonBorder
								onClick={setSelectedStoreRating}
								className={classes['btn-store-submit']}
								text="Batal"
							/>
						</Col>
						<Col sm={12}>
							<ButtonBorder
								onClick={props.onSubmitReviewStore}
								className={classes['btn-store-submit']}
								text="Kirim"
							/>
						</Col>
					</>
				)}
			</Row>
		);
	}
	return (
		<Row
			justify={props.responsive ? 'center' : 'end'}
			className={classes['store-info']}
			gutter={[{ xs: 40, sm: 30 }, 0]}
			style={props.isReviewed && !props.responsive ? { padding: '0 30px' } : {}}
		>
			{props.ratingStore?.map((rating) => {
				return (
					<EmojiRating
						key={rating.value}
						setSelectedStoreRating={props.setSelectedStoreRating}
						rating={rating}
						isReviewed={props.isReviewed}
					/>
				);
			})}
		</Row>
	);
};

interface IStoreInfo {
	data: IReviewContentData | undefined;
	responsive?: boolean;
}

const StoreInfo = (props: IStoreInfo) => {
	return (
		<Row wrap={false} gutter={[{ xs: 12, sm: 12, md: 12 }, 0]} className={classes['store-info']}>
			<Col>
				<div style={{ width: 48 }}>
					<ImageC
						alt={`Gambar ${props.data?.orderShipping?.merchant_name}`}
						src={props.data?.merchant_image}
						defaultSrc="/images/empty/store.png"
					/>
				</div>
			</Col>
			<Col>
				<Paragraph
					ellipsis={{ rows: 2 }}
					className={classNames('fs-14 fs-md-16 fw-600 text-left m-0', classes['store-title'])}
				>
					{props.data?.orderShipping?.merchant_name}
				</Paragraph>
				<Text className="d-block fs-12 fw-400 text-neutral-5 text-left">
					{props.data?.orderShipping?.merchant_city}
				</Text>
			</Col>
		</Row>
	);
};

interface IProductInfo {
	product: any;
	responsive?: boolean;
}

const ProductInfo = (props: IProductInfo) => {
	return (
		<Row gutter={[12, 0]} align="middle" wrap={false}>
			<Col>
				<div className={classes['product-image']}>
					<ImageC defaultSrc={getDefaultProductImg()} src={props.product.image} />
				</div>
			</Col>
			<Col>
				<Paragraph
					ellipsis={{ rows: 2 }}
					className={classNames('fw-600 text-left', classes['product-title'])}
				>
					{props.product.product_name ?? '-'}
				</Paragraph>
			</Col>
		</Row>
	);
};

interface IDetailReviewContent {
	data?: IReviewContentData;
}

const DetailReviewContent = ({ data }: IDetailReviewContent) => {
	const [productState, setProductState] = useState<IReviewContentProductData[]>([]);
	const [selectedStoreRating, setSelectedStoreRating] = useState<any>(undefined);
	const [error, setError] = useState<any>();
	const [loadingFormList, setLoadingFormList] = useState<number[]>([]);

	const { xs, sm, md } = Grid.useBreakpoint();

	const { mutate: submitReviewProduct } = useSubmitReviewProduct();
	const { mutate: submitReviewStore, isLoading: submitReviewStoreLoading } = useSubmitReviewStore();

	const queryClient = useQueryClient();
	const responsive = (xs || sm) && !md;

	useEffect(() => {
		if (data?.order_items && JSON.stringify(data?.order_items) !== JSON.stringify(productState)) {
			let newProductState = data?.order_items?.map((item: IReviewContentProductData) => {
				if (item.is_product_reviewed) {
					return {
						...item,
					};
				}

				const pState = productState?.find(
					(p: IReviewContentProductData) => p.product_id === item.product_id,
				);

				return {
					...item,
					rating: pState?.rating ?? 5,
					comment: pState?.comment ?? '',
				};
			});

			setProductState(newProductState ?? []);
		}
	}, [data?.order_items]);

	const onChangeProductInput = (value: any, name: string, index: number) => {
		const newProduct = [...productState] as any;
		newProduct[index][name] = value;
		setProductState(newProduct);
	};

	const isError = (index: number): boolean => {
		const product = [...productState] as any;
		const requiredField = ['rating', 'comment'];
		const err: any = {};

		Object.keys(product?.[index] ?? {})?.forEach((val) => {
			if (requiredField.includes(val) && !product[index][val]) {
				err[`${val}-${index}`] = 'Wajib diisi';
			}
		});

		if (product?.[index]?.[requiredField[1]] && product?.[index]?.[requiredField[1]]?.length < 10) {
			err[`${requiredField[1]}-${index}`] = 'Minimal 10 Karakter';
		}

		setError(err);

		return Object.keys(err).length > 0;
	};

	const afterSubmitReview = () => {
		queryClient.invalidateQueries(userReviewListQueryKey);
		queryClient.invalidateQueries(useDetailOrderQueryKey);
		queryClient.invalidateQueries(useDetailReviewQueryKey);
		queryClient.invalidateQueries(QUERYKEYDETAIL);
	};

	const onSubmitReviewProduct = (index: number) => {
		if (isError(index)) {
			return;
		}

		if (data?.merchant_rating_score === 0) {
			return messageC('Silakan berikan penilaian kepada penjual terlebih dahulu', 'error');
		}

		let listLoading = [...loadingFormList, index];
		setLoadingFormList(listLoading);

		const bodyPayload = {
			product_rating_score: productState?.[index]?.rating ?? 0,
			merchant_product_id: productState?.[index]?.merchant_product_id?.toString() ?? '',
			comment: productState?.[index]?.comment ?? '',
			product_id: productState?.[index]?.product_id?.toString() ?? '',
			order_no: data?.order_no ?? '',
			merchant_id: data?.merchant_id ?? 0,
		};

		const afterHit = {
			onSettled: () => {
				listLoading = listLoading.filter((loading) => loading !== index);
				setLoadingFormList(listLoading);
			},

			onError: (err: any) => {
				afterSubmitReview();
				messageC(err?.meta?.message || err?.message || 'Terjadi kesalahan sistem.', 'error');
			},
		};

		submitReviewProduct(bodyPayload, {
			...afterHit,
			onSuccess: () => {
				afterSubmitReview();
				messageC('Berhasil memberi ulasan');
			},
		});
	};

	const onSubmitReviewStore = () => {
		submitReviewStore(
			{
				rating_score: selectedStoreRating?.value ?? 0,
				order_no: data?.order_no ?? '',
				merchant_id: data?.merchant_id?.toString() ?? '',
			},
			{
				onSettled: () => {
					setSelectedStoreRating(undefined);
				},
				onSuccess: () => {
					afterSubmitReview();
					messageC('Berhasil memberi ulasan');
				},
				onError: (err: any) => {
					afterSubmitReview();
					messageC(err?.meta?.message || err?.message || 'Terjadi kesalahan sistem.', 'error');
				},
			},
		);
	};

	const isCommentInputEmpty = (index: number): boolean => {
		return !!error?.[`comment-${index}`];
	};

	const isRatingValueEmpty = (index: number): boolean => !!error?.[`rating-${index}`];

	const ratingStore = useMemo((): typeof storeRatings => {
		if (data?.merchant_rating_score !== 0) {
			return storeRatings.filter((rating) => {
				return rating.value === data?.merchant_rating_score ?? 0;
			});
		}
		return storeRatings;
	}, [data]);

	return (
		<>
			<div className={classes.order}>
				<Text className="fs-14">Pesanan Dibuat {formatDate(data?.order_date)}</Text>
				<Text className="fs-14 text-primary">No. Pesanan: #{data?.order_no}</Text>
			</div>
			<Divider className={classes.divider} />
			<div className={classes['wrapper-content']}>
				<Row justify="space-between" gutter={[10, { xs: 24, sm: 24 }]}>
					<Col xs={24} md={14}>
						<Text className="fs-12 text-neutral-7">
							Tanggal Pesanan Diterima{' '}
							<Text className="ml-2 text-warning">
								{formatDate(data?.orderHistory?.[data?.orderHistory?.length - 1]?.created_at ?? '')}
							</Text>
						</Text>
						<StoreInfo data={data} responsive={responsive} />
					</Col>
					<Col xs={24} md={10}>
						<LabelReviewStore selectedStoreRating={selectedStoreRating} data={data} />
						<RatingReviewStore
							selectedStoreRating={selectedStoreRating}
							setSelectedStoreRating={setSelectedStoreRating}
							isLoading={submitReviewStoreLoading}
							responsive={responsive}
							onSubmitReviewStore={onSubmitReviewStore}
							isReviewed={data?.merchant_rating_score !== 0 ?? false}
							ratingStore={ratingStore}
						/>
					</Col>
				</Row>
				{productState?.map((product, index) => {
					return (
						<Fragment key={product.product_id}>
							<Divider className={classes.divider} />
							<Row gutter={[0, { xs: 20, sm: 20 }]} justify="space-between">
								<Col xs={24} md={12}>
									<ProductInfo product={product} />
								</Col>
								<Col xs={24} md={12}>
									<div className={classes['store-rating']}>
										<Text className="fs-12 text-neutral-5 d-block">Bagaimana produknya?</Text>
										<Rate
											key={product?.rating}
											disabled={product?.is_product_reviewed}
											onChange={(val) => onChangeProductInput(val, 'rating', index)}
											defaultValue={product?.rating ?? 0}
										/>
										{isRatingValueEmpty(index) && (
											<Text className="fs-12 text-error d-block mt-2">
												{error[`rating-${index}`]}
											</Text>
										)}
									</div>
								</Col>
							</Row>
							<InputCommentReviewProduct
								index={index}
								product={product}
								onChangeProductInput={onChangeProductInput}
								isCommentInputEmpty={isCommentInputEmpty}
								error={error}
							/>
							{/* <div className={classes['input-checkbox']}>
										{!product?.is_product_reviewed && (
											<Checkbox
												onChange={(e) =>
													onChangeProductInput(e.target.checked, 'is_anonymous', index)
												}
											>
												<Text className="fs-14 text-neutral-5">
													Kirim ulasan anonim {censoredString(user?.first_name)}
												</Text>
											</Checkbox>
										)}
									</div> */}
							{!product?.is_product_reviewed && (
								<ButtonOK
									text="Tulis Ulasan"
									onClick={() => onSubmitReviewProduct(index)}
									loading={loadingFormList.includes(index)}
									className={classNames(classes['btn-submit'])}
								/>
							)}
						</Fragment>
					);
				})}
			</div>
		</>
	);
};

export default DetailReviewContent;
