export const CATEGORY_TYPE = {
	model: {
		categories: {
			success: 'GET_CATEGORIES_SUCCESS',
		},
		detail: {
			success: 'GET_CATEGORY_DETAIL_SUCCESS',
		},
		detail_obat: {
			success: 'GET_CATEGORY_DETAIL_OBAT_SUCCESS',
		},
		search: {
			success: 'SEARCH_CATEGORY_SUCCESS',
		},
		all: {
			success: 'GET_ALL_CATEGORY_SUCCESS',
		},
		groups: {
			success: 'GET_CATEGORY_GROUPS',
		},
		subCategories: {
			success: 'GET_SUB_CATEGORIES_SUCCESS',
		},
	},
};
