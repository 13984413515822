import { TERMS_CONDITION_TYPE } from 'src/constants/redux';

export const initialState = {
	terms: null,
};

const reducer = (state = initialState, action: any = {}) => {
	if (action.type === TERMS_CONDITION_TYPE.model.terms.success) {
		return Object.assign({}, state, {
			terms: action.payload.data,
		});
	}

	return state;
};

export default reducer;
