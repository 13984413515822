import { Divider, Grid, List, Row, Typography } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import LottieAnimation from 'src/components/LottieAnimation';

import classes from './index.module.less';

const { Paragraph } = Typography;

const FindingProduct = () => {
	const { xs, md } = Grid.useBreakpoint();

	const desc = [
		'Merchant yang ada di KlikDokter adalah rekanan dan sudah diverifikasi oleh KlikDokter',
		'Harga produk kesehatan bersaing dengan kualitas yang terpercaya',
		// eslint-disable-next-line max-len
		'Batas waktu pengantaran 60 menit sejak produk diambil oleh kurir dari merchant rekanan KlikDokter. (Hanya berlaku untuk kurir instan)',
	];
	return (
		<div className={classNames(classes.body, 'mt-5')}>
			<Row
				align="middle"
				justify="center"
				className={classNames('flex-column text-center', { 'px-5': !xs })}
			>
				<LottieAnimation
					importAnimation={(cb) => import('src/utils/lotties/findPharmacies.json').then(cb)}
					lottieProps={{
						className: classes.animation,
					}}
				/>
				<Paragraph className="fs-22 fs-md-24 text-neutral-3 fw-700 mb-2">
					Dalam Proses Pencarian
				</Paragraph>
				<Paragraph className="fs-12 fs-md-14 text-neutral-3 fw-400">
					KlikDokter sedang mencari produk dengan lokasi yang paling dekat dengan kamu..
				</Paragraph>
			</Row>
			<Divider className="mt-0 mb-4" />
			<Row>
				<List
					grid={{ gutter: 16 }}
					dataSource={desc}
					renderItem={(item: string) => (
						<List.Item>
							<div className="d-flex align-items-center">
								<div className="d-flex mr-1" style={{ flexShrink: 0 }}>
									<ImageC
										alt="Icon Check"
										src="/images/klikstore/icon-check.svg"
										width={md ? 24 : 20}
										height={md ? 24 : 20}
									/>
								</div>
								<span className="text-left fs-10 fs-md-12 text-neutral-3 fw-400 ml-2 mb-0">
									{item}
								</span>
							</div>
						</List.Item>
					)}
				/>
			</Row>
		</div>
	);
};

export default FindingProduct;
