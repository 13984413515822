import { StoreCategory } from 'src/interface';

export const convertToArray = (value: string | string[]) => {
	let finalValue: string[] = [];

	if (value) {
		if (Array.isArray(value)) {
			finalValue = value;
		} else {
			finalValue = [value];
		}
	}

	finalValue = finalValue?.filter((val) => val !== '');
	return finalValue;
};

export const convertCommaSeparatedStringToArray = (value: string) => {
	let finalValue: string[] = value?.split(',') ?? [];
	finalValue = finalValue?.filter((val) => val !== '');
	return finalValue;
};

export const cleanSlug = (path: string) => {
	let cleanedSlug = path?.replace(/\/\//g, '/');
	if (cleanedSlug?.[cleanedSlug.length - 1] === '/') {
		cleanedSlug = cleanedSlug?.slice(0, cleanedSlug.length - 1);
	}
	return cleanedSlug;
};

export const renderStoreCategoryTree = (dataRaw: StoreCategory[]) => {
	const data = dataRaw
		? [...dataRaw]?.sort((a, b) => {
				const aSort = a?.sort_order ?? 0;
				const bSort = b?.sort_order ?? 0;
				if (aSort > bSort) return 1;
				if (bSort > aSort) return -1;
				return 0;
		  })
		: [];

	// category level 1
	let dataLevel1: StoreCategory[] = [];
	let level1: StoreCategory[] = data?.filter((item: StoreCategory) => item.level === 2);

	level1?.forEach((item1: StoreCategory) => {
		// category level 2
		let dataLevel2: StoreCategory[] = [];
		let level2: StoreCategory[] = data
			?.filter((item2: StoreCategory) => item2.parent_id === item1.id)
			?.map((item2: StoreCategory) => {
				return {
					...item2,
					slug: cleanSlug(`/${item1.slug}/${item2.slug}`),
				};
			});

		level2?.forEach((item2: StoreCategory) => {
			// category level 3
			let level3: StoreCategory[] = data
				?.filter((item3: StoreCategory) => item3.parent_id === item2.id)
				?.map((item3: StoreCategory) => {
					return {
						...item3,
						slug: cleanSlug(`/${item2.slug}/${item3.slug}`),
					};
				});

			dataLevel2.push({
				...item2,
				sub: level3,
			});
		});

		dataLevel1.push({
			...item1,
			slug: cleanSlug(`/${item1.slug}`),
			sub: dataLevel2,
		});
	});

	return dataLevel1;
};

export const getCategoryListFromCategoryTree = (dataTree: StoreCategory[] = []) => {
	let categoryList: StoreCategory[] = [];
	dataTree.forEach((lvl1) => {
		categoryList.push(lvl1);

		lvl1.sub?.forEach((lvl2) => {
			categoryList.push(lvl2);

			lvl2.sub?.forEach((lvl3) => {
				categoryList.push(lvl3);
			});
		});
	});
	return categoryList;
};

export const getAllCategorySlugs = (data: StoreCategory[]) => {
	let slugs: string[] = [];

	const level1: StoreCategory[] = data?.filter((item: StoreCategory) => item.level === 2);
	level1?.forEach((item1: StoreCategory) => {
		slugs.push(cleanSlug(`/${item1.slug}`));

		const level2: StoreCategory[] = data?.filter(
			(item: StoreCategory) => item.parent_id === item1.id,
		);
		level2?.forEach((item2: StoreCategory) => {
			slugs.push(cleanSlug(`/${item1.slug}/${item2.slug}`));

			const level3: StoreCategory[] = data?.filter(
				(item: StoreCategory) => item.parent_id === item2.id,
			);
			level3?.forEach((item3: StoreCategory) => {
				slugs.push(cleanSlug(`/${item1.slug}/${item2.slug}/${item3.slug}`));
			});
		});
	});

	return slugs;
};

export const getAllCategorySlugsFromTree = (data: StoreCategory[]) => {
	let slugs: string[] = [];

	data?.forEach((item1: StoreCategory) => {
		slugs.push(cleanSlug(`/${item1.slug}`));

		item1?.sub?.forEach((item2: StoreCategory) => {
			slugs.push(cleanSlug(`/${item1.slug}/${item2.slug}`));

			item2?.sub?.forEach((item3: StoreCategory) => {
				slugs.push(cleanSlug(`/${item1.slug}/${item2.slug}/${item3.slug}`));
			});
		});
	});

	return slugs;
};

export const getCategoryBySlug = (categoryTree: StoreCategory[], slug: string) => {
	const categoryList = getCategoryListFromCategoryTree(categoryTree);
	return categoryList?.filter(
		(item: StoreCategory) => cleanSlug(`/${item?.slug}`) === cleanSlug(`/${slug}`),
	)?.[0];
};

export const getCategoryBySlugFromTree = (categoryTree: StoreCategory[], slug: string) => {
	return categoryTree?.filter(
		(item: StoreCategory) => cleanSlug(`/${item?.slug}`) === cleanSlug(`/${slug}`),
	)?.[0];
};

export const getCategoryUidBySlug = (categoryList: StoreCategory[], slug: string) => {
	return (
		categoryList?.filter(
			(item: StoreCategory) => cleanSlug(`/${item?.slug}`) === cleanSlug(`/${slug}`),
		)?.[0]?.id ?? ''
	);
};

export const getCategoryNameBySlug = (categoryList: StoreCategory[], slug: string) => {
	return categoryList?.filter(
		(item: StoreCategory) => cleanSlug(`/${item?.slug}`) === cleanSlug(`/${slug}`),
	)?.[0]?.name;
};

export const getCategoryNameBySlugFromTree = (categoryTree: StoreCategory[], slug: string) => {
	const categoryList = getCategoryListFromCategoryTree(categoryTree);
	return categoryList?.filter(
		(item: StoreCategory) => cleanSlug(`/${item?.slug}`) === cleanSlug(`/${slug}`),
	)?.[0]?.name;
};

export const getCategoryBannerBySlug = (categoryList: StoreCategory[], slug: string) => {
	return categoryList?.filter(
		(item: StoreCategory) => cleanSlug(`/${item?.slug}`) === cleanSlug(`/${slug}`),
	)?.[0]?.banner;
};

export const getSubCategoryListBySlug = (
	categoryList: StoreCategory[],
	slug1: string = '',
	slug2: string = '',
	slug3: string = '',
	slug4: string = '',
) => {
	let subcategory: any = [];
	const categoryTree = renderStoreCategoryTree(categoryList);

	if (slug1 && slug1 !== '') {
		const level2List: StoreCategory[] = categoryTree?.filter(
			(item: StoreCategory) => cleanSlug(`/${item.slug}`) === cleanSlug(`/${slug1}`),
		)?.[0]?.sub;

		subcategory = level2List;

		if (slug2 && slug2 !== '') {
			const level3List: StoreCategory[] = level2List?.filter(
				(item: StoreCategory) => cleanSlug(`/${item.slug}`) === cleanSlug(`/${slug1}/${slug2}`),
			)?.[0]?.sub;

			subcategory = level3List;

			if (slug3 && slug3 !== '') {
				const level4List: StoreCategory[] = level3List?.filter(
					(item: StoreCategory) =>
						cleanSlug(`/${item.slug}`) === cleanSlug(`/${slug1}/${slug2}/${slug3}`),
				)?.[0]?.sub;

				subcategory = level4List;

				if (slug4 && slug4 !== '') {
					const level5List: StoreCategory[] = level4List?.filter(
						(item: StoreCategory) =>
							cleanSlug(`/${item.slug}`) === cleanSlug(`/${slug1}/${slug2}/${slug3}/${slug4}`),
					)?.[0]?.sub;

					subcategory = level5List;
				}
			}
		}
	}

	return subcategory ?? [];
};

export const getSubCategoryListBySlugFromTree = (
	categoryTree: StoreCategory[],
	slug1: string = '',
	slug2: string = '',
	slug3: string = '',
	slug4: string = '',
) => {
	let subcategory: any = [];

	if (slug1 && slug1 !== '') {
		const level2List: StoreCategory[] = categoryTree?.filter(
			(item: StoreCategory) => cleanSlug(`/${item.slug}`) === cleanSlug(`/${slug1}`),
		)?.[0]?.sub;

		subcategory = level2List;

		if (slug2 && slug2 !== '') {
			const level3List: StoreCategory[] = level2List?.filter(
				(item: StoreCategory) => cleanSlug(`/${item.slug}`) === cleanSlug(`/${slug2}`),
			)?.[0]?.sub;

			subcategory = level3List;

			if (slug3 && slug3 !== '') {
				const level4List: StoreCategory[] = level3List?.filter(
					(item: StoreCategory) => cleanSlug(`/${item.slug}`) === cleanSlug(`/${slug3}`),
				)?.[0]?.sub;

				subcategory = level4List;

				if (slug4 && slug4 !== '') {
					const level5List: StoreCategory[] = level4List?.filter(
						(item: StoreCategory) => cleanSlug(`/${item.slug}`) === cleanSlug(`/${slug4}`),
					)?.[0]?.sub;

					subcategory = level5List;
				}
			}
		}
	}

	return subcategory ?? [];
};

export const getCategoryLevelByUid = (categoryList: StoreCategory[], id: number) => {
	const level: number = categoryList.filter((category: StoreCategory) => category.id === id)?.[0]
		?.level;
	return level;
};

export const getCategorySlugById = (categoryList: StoreCategory[], id: number) => {
	return categoryList.filter((cat: StoreCategory) => cat.id === id)?.[0]?.slug;
};

export const getCategoryFullSlugById = (categoryList: StoreCategory[], id: number) => {
	const categoryTree = renderStoreCategoryTree(categoryList);
	const newCategoryList = getCategoryListFromCategoryTree(categoryTree);
	return newCategoryList.filter((cat: StoreCategory) => cat.id === id)?.[0]?.slug;
};

export const renderDiscountPercentage = (value: number) => {
	let discountPercent = value;
	if (discountPercent !== 0) {
		if (Math.floor(discountPercent) !== 0) {
			discountPercent = Math.floor(discountPercent);
		} else {
			const dpArr = discountPercent.toString().split('');
			const nonZeroIndex = dpArr.findIndex((item) => item !== '0' && item !== '.');
			if (nonZeroIndex > -1) {
				discountPercent = parseFloat(dpArr.slice(0, nonZeroIndex + 1).join(''));
			}
		}
	}

	return discountPercent;
};
