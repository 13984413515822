import { Button, Col, Row, Space, Typography } from 'antd';

import Image from 'src/components/Image';

import classes from './ModalHeader.module.less';

type Props = {
	className?: any;
	title?: any;
	showBack?: boolean;
	onBack?: any;
	showClose?: boolean;
	onClose?: any;
};

const ModalHeader = (props: Props) => {
	const { className, showBack, title, showClose, onBack, onClose } = props;

	return (
		<Row justify="space-between" align="middle" className={`${classes.header} ${className}`}>
			<Col>
				<Space align="center" size="large">
					{showBack && (
						<Button
							onClick={() => onBack()}
							icon={<Image alt="Back" height={32} width={32} src="/icons/arrow-left.svg" />}
						/>
					)}
					{title && (
						<Typography.Title level={3} className={classes.title}>
							{title}
						</Typography.Title>
					)}
				</Space>
			</Col>
			<Col>
				{showClose && (
					<Button
						onClick={() => onClose()}
						icon={<Image alt="Close" height={32} width={32} src="/icons/close.svg" />}
					/>
				)}
			</Col>
		</Row>
	);
};

export default ModalHeader;
