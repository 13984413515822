export const renderEmbedYoutube = (content: string) => {
	const iframeRegex = /<iframe[^>]*src="([^"]+)"[^>]*>\s*<\/iframe>/;
	const match = content.match(iframeRegex);
	var newContent = content;

	if (match) {
		const removeQueryStringSrc = match[1].split('?')[0];
		const getUid = removeQueryStringSrc.split('/');
		const uid = getUid[getUid.length - 1];

		newContent = content.replace(
			match[0],
			`<iframe class="youtube-video" src="https://www.youtube.com/embed/${uid}" title="YouTube video player"
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;
            gyroscope; picture-in-picture" allowfullscreen></iframe>`,
		);
	}

	return newContent;
};

export const renderLinkExternal = (content: string) => {
	const anchorRegex = /<a[^>]*>.*?<\/a>/g;
	const match = content.match(anchorRegex);
	var newContent = content;

	if (match) {
		for (let i = 0; i < match.length; i++) {
			const isInternalLink = match[i].includes('klikdokter.com');

			if (!isInternalLink) {
				const replaceRel = match[i].replace(/rel="([^"]*)"/, 'rel="nofollow"');
				newContent = newContent.replaceAll(match[i], replaceRel);
			}
		}
	}

	return newContent;
};

export const renderDetailArticle = (content: string) => {
	var newContent = content;
	const embedYoutube = renderEmbedYoutube(newContent);
	newContent = renderLinkExternal(embedYoutube);

	return newContent;
};
