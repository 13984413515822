import React, { Dispatch, SetStateAction } from 'react';
import { Drawer, Dropdown, Typography } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';

import HeaderCategoryMenu from './HeaderCategoryMenu';

import classes from './index.module.less';

const { Text } = Typography;
interface IHeaderCategorySheetCProps {
	data?: undefined; // todo
	isCategoryHovered: boolean;
	setCategoryHovered: Dispatch<SetStateAction<boolean>>;
}

const HeaderCategorySheetC = (props: IHeaderCategorySheetCProps) => {
	return (
		<>
			<Dropdown
				overlay={
					<HeaderCategoryMenu
						isCategoryHovered={props.isCategoryHovered}
						setCategoryHovered={props.setCategoryHovered}
					/>
				}
				visible={props.isCategoryHovered}
			>
				<div
					onMouseEnter={(e) => {
						e.stopPropagation();
						props.setCategoryHovered(true);
					}}
					className={classNames('cursor-pointer d-flex', classes.parent)}
				>
					<Text className="mr-3 fs-14">Kategori</Text>
					<ImageC
						alt="Icon Chevron"
						src={`/icons/arrow-chevron-${props.isCategoryHovered ? 'up' : 'down'}.svg`}
						height={8}
						width={10}
					/>
				</div>
			</Dropdown>
			<Drawer
				visible={props.isCategoryHovered}
				className={classes['backdrop-drawer']}
				placement="top"
			>
				<div className={classes['backdrop-overlay']} />
			</Drawer>
		</>
	);
};

export default HeaderCategorySheetC;
