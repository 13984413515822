export const KLIKSTORE_LANDINGPAGE_TYPE = {
	model: {
		categoryList: {
			success: 'GET_CATEGORY_LIST_SUCCESS',
			fetch: 'GET_CATEGORY_LIST_FETCH',
		},
		landingPageCategories: {
			success: 'GET_LANDING_PAGE_CATEGORIES_SUCCESS',
			fetch: 'GET_LANDING_PAGE_CATEGORIES_FETCH',
		},
		landingPageBanners: {
			success: 'GET_LANDING_PAGE_BANNERS_SUCCESS',
			fetch: 'GET_LANDING_PAGE_BANNERS_FETCH',
		},
		floorSectionList: {
			success: 'GET_FLOOR_SECTION_LIST_SUCCESS',
		},
		floorSectionItems: {
			success: 'GET_FLOOR_SECTION_ITEMS_SUCCESS',
		},
	},
};
