import Slider from 'react-slick';
import { Grid } from 'antd';
import classNames from 'classnames';

import { NextArrow, PrevArrow } from 'src/components/Layout/Slick/Arrow';
import { IKlikStoreMasterProduct } from 'src/interface';

import KlikStoreMasterProduct from '../ProductList/MasterProduct';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classes from './index.module.less';

interface KlikStoreProductFloorProps {
	productList: IKlikStoreMasterProduct[];
	slidesToShow?: number;
	onProductClick?: (item: IKlikStoreMasterProduct) => void;
}

const KlikStoreProductFloor = (props: KlikStoreProductFloorProps) => {
	const { xs, sm, md, lg } = Grid.useBreakpoint();
	const responsive = (xs || sm) && !md;

	let slidesToShow;
	if (props.slidesToShow) {
		slidesToShow = props.slidesToShow;
	} else {
		if (lg) {
			slidesToShow = 6;
		} else if (sm) {
			slidesToShow = 4;
		} else {
			slidesToShow = 2;
		}
	}

	const isEmpty = props.productList && props.productList?.length === 0 ? true : false;

	return (
		<>
			{!isEmpty && (
				<div
					className={classNames(
						classes['slider-container'],
						{ [classes.responsive]: responsive },
						{ [classes['responsive-mobile']]: xs },
						'fs-9 fs-md-9 fs-lg-10 fs-xl-11 text-left',
					)}
				>
					<Slider
						className={classes.slider}
						infinite={false}
						swipeToSlide={false}
						slidesToShow={slidesToShow}
						slidesToScroll={slidesToShow}
						nextArrow={<NextArrow size={32} sizeArrow={16} />}
						prevArrow={<PrevArrow size={32} sizeArrow={16} />}
					>
						{props.productList?.map((item: IKlikStoreMasterProduct) => {
							return (
								<KlikStoreMasterProduct
									key={item.id}
									className={classes.item}
									size={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
									item={item}
									onProductClick={props.onProductClick}
								/>
							);
						})}
					</Slider>
				</div>
			)}
		</>
	);
};

export default KlikStoreProductFloor;
