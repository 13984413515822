import { ARTICLE_BY_GROUP_TYPE } from 'src/constants/redux/articleByGroup';
import { ARTICLE_BY_GROUP } from 'src/constants/services';
import {
	IFilterArticleByGroup,
	IPagingArticle,
	ISortingRelatedArticle,
} from 'src/interface/ArticleByGroup';
import { SINGLE_API } from 'src/redux/actions/types';

export const actionGetArticleByGroup = async (
	payload?: IFilterArticleByGroup,
	next?: (...f: any) => void,
) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `${ARTICLE_BY_GROUP.service}/all`,
			apiType: 'v3_pb',
			successType: ARTICLE_BY_GROUP_TYPE.model.articles.success,
			options: { method: 'GET' },
			payload,
			next,
		},
	};
};

export const actionGetArticleDetail = async (payload: string, next?: (...f: any) => void) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `${ARTICLE_BY_GROUP.detail}${payload}`,
			apiType: 'v3_pb',
			successType: ARTICLE_BY_GROUP_TYPE.model.detail.success,
			options: { method: 'GET' },
			next,
		},
	};
};

export const actionSearchArticle = async (
	payload: IFilterArticleByGroup,
	group: string,
	next?: (...f: any) => void,
	type?: string,
) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `${ARTICLE_BY_GROUP.service}/type/${group}/search`,
			apiType: 'v3_pb',
			successType: type ?? ARTICLE_BY_GROUP_TYPE.model.search.success,
			options: { method: 'GET' },
			payload,
			next,
		},
	};
};

export const actionGetMostSearchArticleByGroup = async (
	slug: string,
	payload?: IFilterArticleByGroup,
	next?: (...f: any) => void,
) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `${ARTICLE_BY_GROUP.groups}/${slug}`,
			apiType: 'v3_pb',
			successType: ARTICLE_BY_GROUP_TYPE.model.mostSearch.success,
			options: { method: 'GET' },
			payload,
			next,
		},
	};
};

export const actionGetArticleByCategorySlug = async (slug: string, payload?: IPagingArticle) => {
	let serviceUrl = ARTICLE_BY_GROUP.byCategory;
	if (slug === 'mytopics') {
		serviceUrl = ARTICLE_BY_GROUP.byCategoryPrivate;
	}

	return {
		type: SINGLE_API,
		payload: {
			url: `${serviceUrl}/${slug}`,
			apiType: 'v3_pb',
			successType: ARTICLE_BY_GROUP_TYPE.model.articleByCategory.success,
			options: { method: 'GET' },
			payload,
		},
	};
};

export const actionGetArticleByCategorySlugByType = async (
	slug: string,
	payload?: IPagingArticle,
) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `${ARTICLE_BY_GROUP.byType}/${slug}`,
			apiType: 'v3_pb',
			successType: ARTICLE_BY_GROUP_TYPE.model.articleByCategory.success,
			options: { method: 'GET' },
			payload,
		},
	};
};

export const actionGetRelatedArticles = async (
	articleSlug: string,
	payload?: ISortingRelatedArticle,
) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `${ARTICLE_BY_GROUP.service}/related${articleSlug}`,
			apiType: 'v3_pb',
			successType: ARTICLE_BY_GROUP_TYPE.model.related.success,
			options: { method: 'GET' },
			payload,
		},
	};
};
