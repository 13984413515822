import { FC } from 'react';
import Link from 'next/link';
import { Grid, Layout } from 'antd';

import ImageC from 'src/components/Image';
import LogoC from 'src/components/Layout/Header/Logo';

import classes from './index.module.less';

interface ErrorPageInterface {
	imageUrl?: string;
	title?: string;
	description?: string;
}

const ErrorPage: FC<ErrorPageInterface> = ({ imageUrl, title, description }) => {
	const { lg } = Grid.useBreakpoint();
	return (
		<div className={classes['wrap-status-page']}>
			<Layout.Header className={classes.header}>
				<div className={`${classes.wrapper} container`}>
					<LogoC />
				</div>
			</Layout.Header>
			<div className={classes['container-splash']}>
				<div className={classes.content}>
					<div className={classes['wrap-image']}>
						<ImageC
							src={imageUrl}
							width={lg ? 825 : 320}
							height={lg ? 464 : 180}
							alt="KlikDokter sedang maintenance"
						/>
					</div>
					<h1 className={classes['page-title']}>{title}</h1>
					<p
						className={classes['page-desc']}
						dangerouslySetInnerHTML={{
							__html: `${description || ''}`,
						}}
					/>
					<Link href={'/'} className={classes['link-button']} title="Kembali ke Beranda">
						Kembali ke Beranda
					</Link>
				</div>
			</div>
		</div>
	);
};

ErrorPage.defaultProps = {
	imageUrl: '/images/vectors/maintenance.svg',
	title: 'Terjadi Kesalahan',
	description:
		'Sepertinya ada kesalahan dari sistem KlikDokter. Kamu bisa coba <br />beberapa saat lagi ya.',
};

export default ErrorPage;
