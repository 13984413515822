import { useEffect } from 'react';

import g from 'src/constants/global';
import { useMutationTransactionDetail } from 'src/hooks';
import { useNotificationTransaction } from 'src/hooks/Notification';
import authStorage from 'src/utils/auth-storage';
import { googlePurchaseEvent, trackEvent } from 'src/utils/func';
import {
	getSuccessPaymentAttribute,
	removeSuccessPaymentAttribute,
} from 'src/utils/func/transaction';

const TrackSuccessPayment = () => {
	const { invoice, attribs } = getSuccessPaymentAttribute();
	const notificationTransaction = useNotificationTransaction(
		1,
		!!invoice && authStorage.loggedIn ? '1' : '',
	);
	const { mutate: getTransactionDetail } = useMutationTransactionDetail();
	const transactionList = notificationTransaction?.data?.data?.records;

	useEffect(() => {
		if (!!invoice && transactionList && attribs) {
			const transactionPaid = transactionList.find(
				(t: any) =>
					invoice === t.invoice_id &&
					t.title?.includes?.(g.STATUS.TRANSACTION.settlement) &&
					t.type === 'transaction',
			);

			if (!!transactionPaid) {
				const trackingModuleMapper = {
					[g.PROFILE.TRANSACTION.SERVICE.prescriptionRedeem]: () => {
						trackEvent('success_payment', attribs);
					},
				};

				const trackPurchaseEvent = () => {
					getTransactionDetail(invoice, {
						onSuccess: (response) => {
							const transactionDetail = response.data;
							googlePurchaseEvent({
								service: transactionPaid.module,
								...transactionDetail,
								...(transactionDetail?.item?.[0] || {}),
								...attribs,
							});
						},
					});
				};

				const trackingFn = trackingModuleMapper[transactionPaid.module] || trackPurchaseEvent;

				trackingFn();
				removeSuccessPaymentAttribute();
			}
		}
	}, [transactionList, invoice]);

	return null;
};

export default TrackSuccessPayment;
