import React from 'react';

import g from 'src/constants/global';
import RegisterUser from 'src/containers/Auth/Register/RegisterUser';

import AuthOtp from './AuthStep/AuthOtp';
import AuthPin from './AuthStep/AuthPin';
import AuthPinConfirm from './AuthStep/AuthPinConfirm';
import AuthPhone from './AuthStep';

const Auth = (props: any) => {
	const { authStep, setAuthStep, authBody, setAuthBody, loading, mode, onSucessAuthCallBack } =
		props;

	if ([g.AUTH.REGISTER.otp, g.AUTH.FORGOT.otp].includes(authStep)) {
		return (
			<AuthOtp
				type={authStep === g.AUTH.FORGOT.otp ? g.AUTH.FORGOT.label : g.AUTH.REGISTER.label}
				authBody={authBody}
				setAuthBody={setAuthBody}
				setAuthStep={setAuthStep}
			/>
		);
	} else if ([g.AUTH.REGISTER.pinConfirm, g.AUTH.FORGOT.pinConfirm].includes(authStep)) {
		return (
			<AuthPinConfirm
				type={authStep === g.AUTH.FORGOT.pinConfirm ? g.AUTH.FORGOT.label : g.AUTH.REGISTER.label}
				authBody={authBody}
				setAuthBody={setAuthBody}
				setAuthStep={setAuthStep}
			/>
		);
	} else if (authStep === g.AUTH.REGISTER.user) {
		return (
			<RegisterUser
				type={g.AUTH.REGISTER.label}
				authBody={authBody}
				setAuthBody={setAuthBody}
				setAuthStep={setAuthStep}
				inModal={true}
			/>
		);
	} else if ([g.AUTH.LOGIN.pin, g.AUTH.REGISTER.pin, g.AUTH.FORGOT.pin].includes(authStep)) {
		let authType = g.AUTH.REGISTER.label;

		if (authStep === g.AUTH.LOGIN.pin) {
			authType = g.AUTH.LOGIN.label;
		} else if (authStep === g.AUTH.FORGOT.pin) {
			authType = g.AUTH.FORGOT.label;
		}

		const onFinishLogin: any = () => {
			if (onSucessAuthCallBack) {
				return onSucessAuthCallBack();
			}

			return false;
		};

		return (
			<AuthPin
				type={authType}
				authBody={authBody}
				onFinishLogin={onFinishLogin}
				setAuthBody={setAuthBody}
				mode={mode}
				additionalLoading={loading}
				setAuthStep={setAuthStep}
			/>
		);
	} else {
		return (
			<AuthPhone
				type={authStep === g.AUTH.FORGOT.phone ? g.AUTH.FORGOT.label : g.AUTH.LOGIN.label}
				authBody={authBody}
				setAuthBody={setAuthBody}
				setAuthStep={setAuthStep}
			/>
		);
	}
};

export default Auth;
