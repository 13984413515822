import { useMutation, useQuery, useQueryClient } from 'react-query';

import messageC from 'src/components/Message';
import { PROFILE, USER } from 'src/constants/services';
import AuthStorage from 'src/utils/auth-storage';
import { fetchApiMarketplace, fetchApiPublishing, fetchApiV3User } from 'src/utils/fetch-api';

const queryKey = 'user';
export const useCustomerInfoQueryKey = 'useCustomerInfo';

export const useResendRelationshipVerification = () => {
	const queryClient = useQueryClient();
	const resend = (phoneNumber: string | number) => {
		return fetchApiV3User({
			url: `/${USER.service}/${USER.model.resendRelationshipVerification}/${phoneNumber}`,
		});
	};

	return useMutation((phoneNumber: string | number) => resend(phoneNumber), {
		onSettled: () => {
			queryClient.invalidateQueries(queryKey);
		},
	});
};

export const useTncIdCard = () => {
	return useQuery(
		['tncIdCard'],
		() => {
			return fetchApiPublishing({
				url: `/${PROFILE.service}`,
				payload: { type: PROFILE.model.tncIdCard },
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export interface UseCustomerInfo {
	enabled?: boolean;
}

export const useCustomerInfo = ({ enabled = true }: UseCustomerInfo) => {
	return useQuery(
		[useCustomerInfoQueryKey],
		() => {
			return fetchApiMarketplace({
				url: '/customer/info',
			});
		},
		{
			onError: (err: any) => {
				if (err.status !== 401 && err.status !== 403) {
					messageC(err?.meta?.message || err?.message || 'Terjadi kesalahan sistem.', 'error');
				}
			},
			keepPreviousData: true,
			refetchOnWindowFocus: true,
			enabled: AuthStorage.loggedIn && enabled,
		},
	);
};
