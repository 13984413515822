import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { notification } from 'antd';

import { USER } from 'src/constants/services';
import { Address } from 'src/interface/Profile/AddressList';
import AuthStorage from 'src/utils/auth-storage';
import { fetchApiMarketplace, fetchApiV3User } from 'src/utils/fetch-api';

const query = 'addressList';
const queryAddressListInfinite = 'addressList-infinite';

export const useAddressLists = (
	page: string = '1',
	limit: number = 10,
	search: string | undefined = undefined,
	enabled: boolean = true,
) => {
	return useQuery(
		[query, page, search],
		() => {
			return fetchApiV3User({
				url: `/${USER.service}/${USER.model.address}`,
				payload: { limit, page, search },
			});
		},
		{
			enabled: AuthStorage.loggedIn && enabled,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
};

export const useInfiniteAddressLists = ({ keyword }: any) => {
	return useInfiniteQuery(
		[queryAddressListInfinite, keyword],
		(fetchProps) => {
			const { pageParam = 1 } = fetchProps;
			let payloadReq: any = { page: pageParam };
			if (keyword?.length > 0) {
				payloadReq = {
					...payloadReq,
					search: keyword,
				};
			}

			return fetchApiV3User({
				url: `/${USER.service}/${USER.model.address}`,
				payload: { limit: 10, page: payloadReq.page, search: payloadReq.search },
			});
		},
		{
			refetchOnWindowFocus: true,
			getNextPageParam: (res) => {
				const currentPage = parseInt(res?.meta?.pagination?.page);
				const totalPage = parseInt(res?.meta?.pagination?.totalpage);

				return currentPage < totalPage ? currentPage + 1 : undefined;
			},
		},
	);
};
export const useAddressListsCreate = () => {
	const queryClient = useQueryClient();

	const create = (payload: Address) => {
		return fetchApiV3User({
			//url: `/${USER.service}/${USER.model.address}`,
			url: `/${USER.service}/${USER.model.address}`,
			payload,
			options: {
				method: 'POST',
			},
		});
	};

	return useMutation((payload: Address) => create(payload), {
		onError: (err: any) => {
			notification.error({ message: err?.meta?.message });
		},
		onSettled: () => {
			queryClient.invalidateQueries(query);
			queryClient.invalidateQueries(queryAddressListInfinite);
		},
	});
};

export const useAddressListsUpdate = () => {
	const queryClient = useQueryClient();

	const update = (payload: Address) => {
		return fetchApiV3User({
			url: `/${USER.service}/${USER.model.address}/${payload.id}`,
			payload,
			options: { method: 'PUT' },
		});
	};

	return useMutation((payload: Address) => update(payload), {
		onError: (err: any) => {
			notification.error({ message: err?.meta?.message });
		},
		onSettled: () => {
			queryClient.invalidateQueries(query);
			queryClient.invalidateQueries(queryAddressListInfinite);
		},
	});
};

export const useAddressListsDelete = () => {
	const queryClient = useQueryClient();

	const remove = (uid: number | string) => {
		return fetchApiV3User({
			url: `/${USER.service}/${USER.model.address}/${uid}`,
			options: { method: 'DELETE' },
		});
	};

	return useMutation((uid: number | string) => remove(uid), {
		onError: (error: any) => {
			notification.error({ message: error?.meta?.message });
		},
		onSettled: () => {
			queryClient.invalidateQueries(query);
			queryClient.invalidateQueries(queryAddressListInfinite);
		},
	});
};

export const useProvinceList = ({ enabled = true, ...props }: any) => {
	return useQuery(
		['provinceList', props],
		() => {
			return fetchApiMarketplace({
				url: '/search/province',
				payload: { limit: 1000, q: props.search },
			});
		},
		{
			enabled: enabled,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
};

export const useCityList = ({ enabled = true, ...props }: any) => {
	return useQuery(
		['cityList', props],
		() => {
			return fetchApiMarketplace({
				url: '/search/city',
				payload: { limit: 1000, province_id: props.province_id, q: props.search },
			});
		},
		{
			enabled: enabled,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
};

export const useDistrictList = ({ enabled = true, ...props }: any) => {
	return useQuery(
		['disctrictList', props],
		() => {
			return fetchApiMarketplace({
				url: '/search/district',
				payload: { limit: 1000, city_id: props.city_id, q: props.search },
			});
		},
		{
			enabled: enabled,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
};

export const useSubdistrictList = ({ enabled = true, ...props }: any) => {
	return useQuery(
		['subdisctrictList', props],
		() => {
			return fetchApiMarketplace({
				url: '/search/subdistrict',
				payload: { limit: 1000, district_id: props.district_id, q: props.search },
			});
		},
		{
			enabled: enabled,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
};

export const usePostalcodeList = ({ enabled = true, ...props }: any) => {
	return useQuery(
		['postalcodeList', props],
		() => {
			return fetchApiMarketplace({
				url: '/search/postalcode',
				payload: { limit: 1000, subdistrict_id: props.subdistrict_id },
			});
		},
		{
			enabled: enabled,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
};
