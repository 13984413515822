import { useInfiniteQuery, useQuery } from 'react-query';

import { IKlikStoreBrand, StoreCategory } from 'src/interface';
import { fetchApiMarketplace } from 'src/utils/fetch-api';
import { removeEmptyParams } from 'src/utils/func';
import { getCategoryListFromCategoryTree, renderStoreCategoryTree } from 'src/utils/func/klikstore';

import { useKlikStoreCategories } from '../Category';

export const categoryLevels = ['category_lv1_uid', 'category_lv2_uid', 'category_lv3_uid'];

export const infiniteklikStoreMasterProducts = 'infiniteklikStoreMasterProducts';
export const klikStoreMasterProducts = 'klikStoreMasterProducts';
export const klikStoreMasterProductsFilter = 'klikStoreMasterProductsFilter';
export const klikStoreProducts = 'klikStoreProducts';
export const klikStoreProductsFilter = 'klikStoreProductsFilter';

export const productsFields =
	'meta_title,meta_description,meta_keyword,' +
	'description,weight,sku,' +
	'is_active,status,merchant,' +
	'breadcrumbs,brand_code';

export interface UseKlikStoreProducts {
	q?: string;
	category?: string;
	bread?: string;
	brand?: string;
	merchant?: string;
	cid?: string;
	price?: string;
	mcatid?: string;
	page?: number;
	limit?: number;
	sort?: string;
	dir?: string;
	is_family_gift?: number;
	not?: string;
	is_spot?: number;
	enabled?: boolean;
}

export interface UseKlikStoreProductsFilter {
	q?: string;
	category?: string;
	bread?: string;
	brand?: string;
	merchant?: string;
	is_family_gift?: number;
	enabled?: boolean;
}

export const useInfiniteKlikStoreMasterProducts = ({
	enabled = true,
	...props
}: UseKlikStoreProducts) => {
	const cleanedProps = removeEmptyParams(props);
	return useInfiniteQuery(
		[infiniteklikStoreMasterProducts, cleanedProps],
		({ pageParam = 1 }) => {
			const sort = props.sort || 'relevance';
			const dir = props.dir || 'desc';

			return fetchApiMarketplace({
				url: '/products',
				options: { method: 'GET' },
				payload: {
					...cleanedProps,
					page: pageParam ?? 1,
					sort: sort,
					dir: dir,
					fields: productsFields,
				},
			});
		},
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (res) =>
				res?.meta.pagination?.page < res?.meta.pagination?.total_page
					? res?.meta.pagination?.page + 1
					: undefined,
			enabled,
		},
	);
};

export const useKlikStoreMasterProducts = ({ enabled = true, ...props }: UseKlikStoreProducts) => {
	const cleanedProps = removeEmptyParams(props);
	return useQuery(
		[klikStoreMasterProducts, cleanedProps],
		() => {
			const sort = props.sort || 'relevance';
			const dir = props.dir || 'desc';

			return fetchApiMarketplace({
				url: '/products',
				options: { method: 'GET' },
				payload: {
					...cleanedProps,
					sort: sort,
					dir: dir,
					fields: productsFields,
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled },
	);
};

export const useKlikStoreMasterProductsFilter = ({
	enabled = true,
	...props
}: UseKlikStoreProductsFilter) => {
	const cleanedProps = removeEmptyParams(props);
	return useQuery(
		[klikStoreMasterProductsFilter, cleanedProps],
		() => {
			return fetchApiMarketplace({
				url: '/products',
				options: { method: 'GET' },
				payload: {
					...cleanedProps,
					aggs: true,
					limit: 0,
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled },
	);
};

export const useKlikStoreProducts = ({ enabled = true, ...props }: UseKlikStoreProducts) => {
	const cleanedProps = removeEmptyParams(props);
	return useQuery(
		[klikStoreProducts, cleanedProps],
		() => {
			const sort = props.sort || 'relevance';
			const dir = props.dir || 'desc';

			return fetchApiMarketplace({
				url: '/products',
				options: { method: 'GET' },
				payload: {
					...cleanedProps,
					sort: sort,
					dir: dir,
					fields: productsFields,
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled },
	);
};

export const useKlikStoreProductsFilter = ({
	enabled = true,
	...props
}: UseKlikStoreProductsFilter) => {
	const cleanedProps = removeEmptyParams(props);
	return useQuery(
		[klikStoreProductsFilter, cleanedProps],
		() => {
			return fetchApiMarketplace({
				url: '/products',
				options: { method: 'GET' },
				payload: {
					...cleanedProps,
					aggs: true,
					limit: 0,
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled },
	);
};

export const useKlikStoreRenderProductsFilterData = (filtersAgg: any) => {
	const dataCategories = useKlikStoreCategories();
	const masterAllCategory = dataCategories?.data?.data?.records;
	const allCategoryList = getCategoryListFromCategoryTree(masterAllCategory);

	// Category
	const filterCategoryAgg = filtersAgg?.breadcrumbs;
	const slugsFilterCategory = filterCategoryAgg?.map(({ slug }: any) => {
		const slugs = slug?.split('/');
		return slugs?.[slugs?.length - 1] ?? '';
	});
	const filterCategory = allCategoryList?.filter(
		(cat: StoreCategory) => slugsFilterCategory?.includes(cat.slug),
	);
	const categoryTree = renderStoreCategoryTree(filterCategory);

	// Location
	const filterCity = filtersAgg?.cities?.map(({ city_id, city }: any) => ({
		key: city_id.toString(),
		label: city,
	}));

	// Brand
	const filterBrand = filtersAgg?.brands?.map(({ code, name }: IKlikStoreBrand) => ({
		key: code.toString(),
		label: name,
	}));

	// PriceRange
	const filterPrice = filtersAgg?.price_ranges?.map(({ key, from, to }: any) => ({
		key: key.toString(),
		label: `Rp ${from || 0} ${to && to !== null ? '- Rp ' + to : 'ke atas'}`,
	}));

	return {
		category: categoryTree,
		location: filterCity,
		brand: filterBrand,
		price: filterPrice,
	};
};
