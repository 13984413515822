import { useEffect, useState } from 'react';

export const useWebViewAndroid = () => {
	// Check for specific keywords in the User-Agent string
	const [isWebViewAndroid, setIsWebViewAndroid] = useState<any>(null);

	useEffect(() => {
		const webViewAndroid =
			/Android/.test(navigator.userAgent) && /Version/.test(navigator.userAgent);
		setIsWebViewAndroid(webViewAndroid);
	}, []);

	return isWebViewAndroid;
};

export const useWebViewIOS = () => {
	// Check for specific keywords in the User-Agent string
	const [isWebViewIOS, setIsWebViewIOS] = useState<any>(null);

	useEffect(() => {
		const webViewIOS =
			/iPhone|iPod|iPad/.test(navigator.userAgent) &&
			!/Safari|CriOS|FxiOS|OPiOS|Mercury/.test(navigator.userAgent);
		setIsWebViewIOS(webViewIOS);
	}, []);

	return isWebViewIOS;
};

export const useWebViewFrom = () => {
	const isIOS = useWebViewIOS();
	const isAndroid = useWebViewAndroid();

	if (isAndroid) {
		return 'android';
	}
	if (isIOS) {
		return 'ios';
	}
	return undefined;
};

export const useDetectWebView = () => {
	const isIOS = useWebViewIOS();
	const isAndroid = useWebViewAndroid();

	if (isAndroid || isIOS) {
		return true;
	}

	return false;
};
