import { useQuery } from 'react-query';

import { fetchApiMarketplace } from 'src/utils/fetch-api';

export const bannersFields = 'title,image,link';

export const useKlikStoreGetAllCity = () => {
	return useQuery(
		['klikStoreGetAllCity'],
		() => {
			return fetchApiMarketplace({
				url: '/search/cities',
				options: { method: 'GET' },
				payload: {
					limit: 2000,
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};
