import { useMutation, useQuery } from 'react-query';

import { HEALTHCARE_TOOLS } from 'src/constants/services';
import { MutationProps } from 'src/interface/Commons';
import { AnswerTanyaObat, ResResultTanyaObat } from 'src/interface/HealthcareTools';
import { fetchApiV3Healthcare } from 'src/utils/fetch-api';

export const healthCareListQuery = 'healthCareList';
export const healthCareQuestionsQuery = 'healthCareQuestions';

const { service, servicePrivate, model } = HEALTHCARE_TOOLS;

export const useHeathCareList = (Name: string) => {
	return useQuery(
		[healthCareListQuery],
		() =>
			fetchApiV3Healthcare({
				url: `/${service}`,
				payload: { Name },
			}),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			refetchOnMount: false,
		},
	);
};

export const useHealthCareTanyaObat = (param: {
	limit?: number;
	page?: number;
	sort?: string;
	dir?: string;
}) => {
	return useQuery(
		[healthCareListQuery],
		() =>
			fetchApiV3Healthcare({
				url: `/${service}`,
				payload: {
					limit: param.limit,
					page: param.page,
					sort: param.sort,
					dir: param.dir,
					filter: JSON.stringify({ group: 'tanya-obatmu' }),
				},
			}),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			refetchOnMount: false,
		},
	);
};

export const useHeathCareQuestions = (parameter: string) => {
	return useQuery(
		[healthCareQuestionsQuery, parameter],
		() =>
			fetchApiV3Healthcare({
				url: `/${service}/${model.content}/${parameter}`, //parameter -> uid or slug_name
			}),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			refetchOnMount: false,
			enabled: !!parameter,
		},
	);
};

export const useHealthCareTanyaObatResult = ({
	onError,
	onSuccess,
}: MutationProps<ResResultTanyaObat>) => {
	return useMutation(
		(payload: AnswerTanyaObat) =>
			fetchApiV3Healthcare({
				url: `/${servicePrivate}/${model.tanya_obatmu}/${payload.slug_name}`,
				payload,
				options: {
					method: 'POST',
				},
			}),
		{
			onError: (error: any) => onError?.(error),
			onSuccess: (response: any) => {
				if (response) {
					onSuccess(response);
				}
			},
		},
	);
};
