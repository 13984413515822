import { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Form, Typography } from 'antd';

import { ButtonOK } from 'src/components/Forms/Button';
import InputPin from 'src/components/Forms/Input/InputPin';
import Image from 'src/components/Image';
import g from 'src/constants/global';
import { AuthProps } from 'src/interface/Auth';
import { actionUserCheckModify } from 'src/redux/actions/auth';
import { actionOtp, actionOtpValidate, actionOtpValidUser } from 'src/redux/actions/otp';
import { timeToText } from 'src/utils/func/format-time';

import classes from './AuthOtp.module.less';

const { Text, Title } = Typography;

const AuthOtp = (props: AuthProps) => {
	const dispatch = useDispatch();
	const { auth, loader, otp } = useSelector((state: RootStateOrAny) => state);

	const [form] = Form.useForm();

	const [pin, setPin] = useState<string>('');
	const [counter, setCounter] = useState<number>(60);
	const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
	const [hasErrored, setHasErrored] = useState<boolean>(false);
	useEffect(() => {
		const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		return () => clearInterval(timer as NodeJS.Timeout);
	}, [counter]);

	useEffect(() => {
		setBtnDisabled(props.authBody?.otp?.length !== 6);

		if (hasErrored) {
			setHasErrored(false);
			form.setFields([{ name: 'otp', errors: [''] }]);
		}
	}, [props.authBody?.otp]);

	useEffect(() => {
		if (props.type === g.AUTH.FORGOT.label) {
			if (otp.otpValidUser === null || otp.otpValidUser?.status !== 200) {
				props.setAuthStep?.(g.AUTH.FORGOT.phone);
			}
		} else {
			if (
				(auth.userCheck === null || auth.userCheck?.status !== 200) &&
				auth.otpValidate === null
			) {
				props.setAuthStep?.(g.AUTH.REGISTER.phone);
			}
		}
	}, [auth.userCheck, otp.otpValidUser]);

	useEffect(() => {
		if (otp.otp) {
			if (otp.otp?.status === 200) {
				setCounter(60);
			} else if (otp.otp?.status === 400) {
				setCounter(otp.otp?.data?.record?.time_release_in_second);
			}
		}
	}, [otp.otp]);

	useEffect(() => {
		if (otp.otpValidate && props.authBody?.otp?.length === 6) {
			if (otp.otpValidate?.status === 200) {
				props.setAuthStep?.(
					props.type === g.AUTH.FORGOT.label ? g.AUTH.FORGOT.pin : g.AUTH.REGISTER.pin,
				);
			} else {
				setHasErrored(true);
				form.setFields([
					{
						name: 'otp',
						errors: [
							otp.otpValidate?.meta?.message.replace(
								'{{time_release_in_second}}',
								timeToText(otp.otpValidate?.data?.record?.time_release_in_second),
							),
						],
					},
				]);
			}
		}
	}, [otp.otpValidate]);

	const handlePin = (e: string) => {
		let pinFormated = e.replace(/\D/g, '');
		setPin(pinFormated);
		props.setAuthBody?.({ ...props.authBody, otp: pinFormated });
	};

	const handlePinCheck = (rules: any, val: string, callback: any) => {
		try {
			if (val?.length === 6) {
				setHasErrored(false);
				return Promise.resolve();
			} else {
				setHasErrored(true);
				return Promise.reject(
					new Error('Kode verifikasi tidak sesuai. Coba kembali atau kirim ulang.'),
				);
			}
		} catch (err) {
			callback(err);
		}
	};

	const handleBack = async () => {
		if (props.type === g.AUTH.FORGOT.label) {
			props.setAuthStep?.(g.AUTH.FORGOT.phone);
		} else {
			dispatch(await actionUserCheckModify(null));
			props.setAuthStep?.(g.AUTH.REGISTER.user);
		}
	};

	const handleRetryOtp = async () => {
		dispatch(
			props.type === g.AUTH.FORGOT.label
				? await actionOtpValidUser({ phone: props.authBody!.phoneNumber })
				: await actionOtp({ phone: props.authBody!.phoneNumber }),
		);
	};

	const handleSubmit = async () => {
		dispatch(
			await actionOtpValidate({
				type: 'otp',
				phone: props.authBody!.phoneNumber,
				password: props.authBody!.otp,
			}),
		);
	};

	return (
		<div className={`${classes.container} d-flex align-items-center`}>
			<div className={classes.content}>
				<div className={classes['otp-button-back']} onClick={handleBack}>
					<Image alt="Icon Arrow Left" height={32} width={32} src="/icons/arrow-left.svg" />
				</div>
				<div className={classes['otp-top']}>
					<Title level={3}>Verifikasi Nomor HP Kamu</Title>
					<Text className={classes.description}>
						Masukkan kode verifikasi yang dikirim ke nomor
					</Text>
				</div>
				<div className={classes['otp-main']}>
					<Text className={classes['otp-user-number']}>
						+{props.authBody?.phoneNumber?.replace(/\d{3}$/g, 'xxx')}
					</Text>
					<Form
						autoComplete="off"
						form={form}
						layout="vertical"
						name="auth-form"
						onFinish={handleSubmit}
					>
						<InputPin
							hasErrored={hasErrored}
							name="otp"
							maxWidth
							numInputs={6}
							onChange={handlePin}
							rules={[{ validator: handlePinCheck }]}
							shouldAutoFocus={true}
							validateTrigger="onBlur"
							value={pin}
							isTransform={true}
						/>
						<Form.Item>
							<ButtonOK
								block
								disabled={btnDisabled}
								htmlType="submit"
								loading={loader.sending}
								text="Verifikasi"
							/>
						</Form.Item>
					</Form>
				</div>
				<div className={classes['otp-bottom']}>
					<Text className={classes.check}>Belum dapat kode?</Text>
					<Text className={classes.timer}>
						{counter > 0 ? (
							<>
								Kirim ulang dalam <span>{timeToText(counter)}</span>
							</>
						) : (
							<div onClick={handleRetryOtp}>
								<span>Kirim Ulang</span>
							</div>
						)}
					</Text>
				</div>
			</div>
		</div>
	);
};

export default AuthOtp;
