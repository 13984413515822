import { useEffect, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { Col, Row, Typography } from 'antd';

import ImageC from 'src/components/Image';
import SpinC from 'src/components/Layout/Spin';
import messageC from 'src/components/Message';
import { UserLocation } from 'src/interface';
import { getGeocodeFromLatLag } from 'src/utils/func';

const { Paragraph, Text } = Typography;

interface GeoLocationCProps {
	setPosition: (e: UserLocation) => void;
	setLocationModal: (e: boolean) => void;
}

const GeoLocationC = (props: GeoLocationCProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [myLocation, setMyLocation] = useState<string>('');

	const { coords, positionError, isGeolocationEnabled, isGeolocationAvailable }: any =
		useGeolocated({
			positionOptions: { enableHighAccuracy: false },
			userDecisionTimeout: 5000,
		});

	const findLocation = async () => {
		setLoading(true);

		await getGeocodeFromLatLag(coords.latitude, coords.longitude).then((res: any) => {
			if (res.status === 'OK') {
				setMyLocation(res.results[0].formatted_address);
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		if (coords) findLocation();
	}, [coords]);

	const handleMyLocation = () => {
		props.setPosition({
			lat: coords.latitude,
			lng: coords.longitude,
			address: myLocation,
			addressLabel: '',
		});
		props.setLocationModal(false);
	};

	const handlePermission = () => {
		if (positionError?.message) messageC(positionError.message);
	};

	const PermissionOrLoadingC = () => {
		return !loading && !myLocation ? (
			<div className="d-flex align-items-center" onClick={handlePermission}>
				<ImageC alt="Icon Point" height={20} width={20} src="/icons/point.svg" />
				<Text className="fs-14 ml-2">
					<Text className="text-primary fw-700">Izinkan lokasi</Text>
					<Text> agar kami dapat mendeteksi secara otomatis</Text>
				</Text>
			</div>
		) : (
			<SpinC className="my-2" />
		);
	};

	return (
		<div className="mt-3 cursor-pointer">
			{!loading && myLocation && isGeolocationEnabled && isGeolocationAvailable ? (
				<Row wrap={false} onClick={handleMyLocation}>
					<Col flex="20px" className="mr-2">
						<ImageC alt="Icon Point" height={20} width={20} src="/icons/point.svg" />
					</Col>
					<Col flex="auto">
						<Text className="fs-14">
							<Paragraph className="fw-700 mb-1">Gunakan lokasimu saat ini</Paragraph>
							<Text>{myLocation}</Text>
						</Text>
					</Col>
				</Row>
			) : (
				<PermissionOrLoadingC />
			)}
		</div>
	);
};

export default GeoLocationC;
