import { KALSTORE_MODULE_TYPE } from 'src/constants/redux/klikstore/module';

export const initialState = {
	isKalstoreModule: false,
};

const reducer = (state = initialState, action: any = {}) => {
	if (action.type === KALSTORE_MODULE_TYPE.model.isKalstore.success) {
		if (action.payload === true) {
			return { ...state, isKalstoreModule: true };
		} else {
			return { ...state, isKalstoreModule: false };
		}
	} else {
		return state;
	}
};

export default reducer;
