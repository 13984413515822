import { useMutation } from 'react-query';

import { fetchApiMarketplace } from 'src/utils/fetch-api';

interface KlikNowATCProps {
	q: string;
	lat: number;
	lon: number;
}

export const useKlikNowGetProduct = () => {
	const getProduct = (payload: KlikNowATCProps) => {
		return fetchApiMarketplace({
			url: '/search/kliknow',
			options: {
				method: 'GET',
			},
			payload,
			notif: true,
		});
	};

	return useMutation((payload: KlikNowATCProps) => getProduct(payload));
};
