import { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Router, { useRouter } from 'next/router';
import { Col, Divider, Form, Grid, Row, Skeleton, Tag, Typography } from 'antd';
import classNames from 'classnames';

import { ButtonOK } from 'src/components/Forms/Button';
import Input from 'src/components/Forms/Input';
import Image from 'src/components/Image';
import g from 'src/constants/global';
import { useCategoryTopicRegisters } from 'src/hooks';
import { AuthProps, CategoryTopic } from 'src/interface';
import { actionOldUserRegister, actionUserRegister } from 'src/redux/actions/auth';
import AuthStorage from 'src/utils/auth-storage';
import { saveFirebaseMessagingToken } from 'src/utils/func/firebase';

import classes from './RegisterUser.module.less';

const { useBreakpoint } = Grid;
const { CheckableTag } = Tag;
const { Text, Title } = Typography;

interface Props extends AuthProps {
	inModal?: boolean;
}

const RegisterUser = ({ inModal = false, ...props }: Props) => {
	const dispatch = useDispatch();
	const { auth, loader, otp } = useSelector((state: RootStateOrAny) => state);
	const { xs } = useBreakpoint();

	const router = useRouter();
	const referralCode = router.query.referral_code;

	const {
		isLoading: categoryTopicsLoading,
		error: categoryTopicsError,
		data: categoryTopicsItem,
	} = useCategoryTopicRegisters();

	const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
	const [selectedTopic, setSelectedTopic] = useState<string[]>([]);
	const [selectedTopicId, setSelectedTopicId] = useState<string[]>([]);
	const oldUser = auth.userCheck?.data?.record?.action === g.AUTH.SET_PIN.label ? true : false;

	useEffect(() => {
		if (AuthStorage.loggedIn) {
			saveFirebaseMessagingToken();
			Router.push('/');
		}
	}, [auth.userToken]);

	useEffect(() => {
		if (
			auth.userCheck === null ||
			auth.userCheck?.status !== 200 ||
			otp.otpValidate === null ||
			otp.otpValidate?.status !== 200 ||
			props.authBody?.pin === undefined ||
			props.authBody?.pinConfirm === undefined
		) {
			props.setAuthStep?.(g.AUTH.REGISTER.phone);
		}
	}, [auth.userCheck, otp.otpValidate]);

	useEffect(() => {
		setBtnDisabled(props.authBody!.topic!.length < 3);
	}, [props.authBody]);

	const handleTopic = (topic: CategoryTopic, checked: boolean) => {
		const selected = checked
			? [...selectedTopic, topic.name]
			: selectedTopic.filter((item: string) => item !== topic.name);

		const selectedId = checked
			? [...selectedTopicId, topic.id.toString()]
			: selectedTopicId.filter((item: string) => item !== topic.id.toString());

		setSelectedTopic(selected);
		setSelectedTopicId(selectedId);
		props.setAuthBody?.({ ...props.authBody, topic: selectedId });
	};

	const handleSubmit = async () => {
		let payloadUserRegister = {
			full_name: props.authBody!.firstName + ' ' + props.authBody?.lastName,
			first_name: props.authBody!.firstName,
			last_name: props.authBody?.lastName,
			otp: props.authBody!.otp,
			phone: props.authBody!.phoneNumber,
			pin: props.authBody!.pin,
			pin_confirmation: props.authBody!.pinConfirm,
			topics: props.authBody?.topic ? props.authBody?.topic.map(Number) : [],
			referral_code: referralCode,
		};

		var payloadActionUserRegister = JSON.parse(JSON.stringify(payloadUserRegister));

		dispatch(
			oldUser
				? await actionOldUserRegister({
						otp: props.authBody!.otp,
						phone: props.authBody!.phoneNumber,
						pin: props.authBody!.pin,
						pin_confirmation: props.authBody!.pinConfirm,
						topics: props.authBody?.topic ? props.authBody?.topic.map(Number) : [],
				  })
				: await actionUserRegister(payloadActionUserRegister),
		);
	};

	return (
		<div
			className={classNames(`${classes.container} d-flex align-items-center`, {
				[classes['in-modal']]: inModal,
			})}
		>
			<div className={classes.content}>
				<div className={classes['user-top']}>
					<Title level={3}>Hi, Siapa Nama Kamu?</Title>
				</div>
				<div className={classes['user-main']}>
					<Form
						initialValues={{
							firstName: oldUser ? auth.userCheck?.data?.record?.first_name : '',
							lastName: oldUser ? auth.userCheck?.data?.record?.last_name : '',
						}}
						autoComplete="off"
						layout="vertical"
						name="auth-form"
						onFinish={handleSubmit}
					>
						<Row>
							<Col className={classes['first-name']} span={12}>
								<Input
									label="Nama Depan"
									maxLength={25}
									name="firstName"
									onChange={(e: any) =>
										props.setAuthBody?.({ ...props.authBody, firstName: e.target.value })
									}
									placeholder="e.g. John"
									rules={[{ required: true, message: 'Nama harus berisikan karakter' }]}
									useLabel
									validateTrigger="onChange"
									disabled={oldUser}
								/>
							</Col>
							<Col className={classes['last-name']} span={12}>
								<Input
									label="Nama Belakang"
									maxLength={25}
									name="lastName"
									onChange={(e: any) =>
										props.setAuthBody?.({ ...props.authBody, lastName: e.target.value })
									}
									placeholder="e.g. Doe"
									useLabel
									disabled={oldUser}
								/>
							</Col>
						</Row>
						<div className={classes['user-interest']}>
							<div className={classes.label}>
								<Text>Pilih minimal 3 topik kesehatan kamu</Text>
							</div>
							{!categoryTopicsLoading && !categoryTopicsError ? (
								categoryTopicsItem?.data?.records.map((item: CategoryTopic) => (
									<CheckableTag
										key={item.id}
										checked={selectedTopic.indexOf(item.name) > -1}
										className={classes.tags}
										onChange={(checked) => handleTopic(item, checked)}
									>
										{item.name}
									</CheckableTag>
								))
							) : (
								<Skeleton active />
							)}
						</div>
						<Divider />
						<ButtonOK
							block
							disabled={btnDisabled}
							htmlType="submit"
							loading={loader.sending}
							text="Masuk"
						/>
					</Form>
				</div>
			</div>
			{!xs && (
				<div className={classes['part-of-kable']}>
					<Image alt="Part of Kalbe" height={50} src="/images/part-of-kalbe.svg" width={193.58} />
				</div>
			)}
		</div>
	);
};

export default RegisterUser;
