import { ABOUT_TYPE } from 'src/constants/redux';

export const initialState = {
	about: null,
};

const reducer = (state = initialState, action: any = {}) => {
	if (action.type === ABOUT_TYPE.model.about.success) {
		return Object.assign({}, state, {
			about: action.payload,
		});
	}

	return state;
};

export default reducer;
