export * from './about';
export * from './article';
export * from './homepage';
export * from './klikstore/landingpage';
export * from './klikstore/reviewpopup';
export * from './loader';
export * from './menu';
export * from './otp';
export * from './profile';
export * from './qiscus';
export * from './termscondition';
export * from './user';
