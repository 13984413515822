import { OTP_TYPE } from 'src/constants/redux';

export const initialState = {
	otp: null,
	otpValidate: null,
	otpValidUser: null,
};

const reducer = (state = initialState, action: any = {}) => {
	switch (action.type) {
		case OTP_TYPE.model.otp.success:
		case OTP_TYPE.model.otp.error:
			return Object.assign({}, state, {
				otp: action.payload,
			});

		case OTP_TYPE.model.validate.success:
		case OTP_TYPE.model.validate.error:
			return Object.assign({}, state, {
				otpValidate: action.payload,
			});

		case OTP_TYPE.model.validUser.success:
		case OTP_TYPE.model.validUser.error:
			return Object.assign({}, state, {
				otpValidUser: action.payload,
			});

		case OTP_TYPE.model.reset.success:
			return Object.assign({}, state, initialState);

		default:
			return state;
	}
};

export default reducer;
