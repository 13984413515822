import { Grid } from 'antd';

const useCustomBreakPoint = () => {
	const { xs, sm, md, lg, xl } = Grid.useBreakpoint();

	return {
		isSmallDesktop: sm && (md || lg),
		isLargeDesktop: sm && md && lg && xl,
		isTablet: sm && !lg,
		isMobile: xs,
	};
};

export default useCustomBreakPoint;
