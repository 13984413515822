import { useEffect } from 'react';
import { AutoComplete, Col, Divider, Input, Row, Space, Typography } from 'antd';
import { Suggestions } from 'use-places-autocomplete';

import Image from 'src/components/Image';

import classes from './AddressListAutoComplete.module.less';

type Props = {
	suggestions: Suggestions;
	options: any;
	setOptions: (v: any) => void;
	onChangeSearch: (v: any) => void;
	onSelectSearch: (v: any) => void;
};

const AddressListAutoComplete = (props: Props) => {
	const { suggestions, options, setOptions, onChangeSearch, onSelectSearch } = props;
	const defaultOptions = [
		{
			options: [
				{
					key: 'My Location',
					value: '',
					label: (
						<Space align="start">
							<Image alt="point" height={16} width={16} src="/icons/point.svg" />
							<Typography.Text>Gunakan Lokasi Saya</Typography.Text>
						</Space>
					),
				},
			],
		},
		{
			label: <Divider className="m-0" />,
			options: [
				{
					key: 'No Location',
					value: '',
					disabled: true,
					label: (
						<Row align="stretch" className={classes['option-wrapper']}>
							<Col span={4} className={classes['icon-default-wrapper']}>
								<Image
									width={72}
									height={72}
									objectFit="cover"
									src="/icons/temporary-image.svg"
									alt="temporary-image"
									style={{ borderRadius: '5px' }}
								/>
							</Col>
							<Col span={20} className={classes['location-wrapper']}>
								<Typography.Title level={5} className={classes['location-title']}>
									Lokasi tidak ditemukan
								</Typography.Title>
								<Typography.Paragraph className={classes['location-subtitle']}>
									Cek ulang alamatmu dan coba lagi yah
								</Typography.Paragraph>
							</Col>
						</Row>
					),
				},
			],
		},
	];

	const autocompleteOptions = suggestions?.data?.map((suggestion) => {
		return {
			label: <Divider className="m-0" />,
			options: [
				{
					key: suggestion.place_id,
					value: suggestion.description,
					label: (
						<Row align="top" className={classes['option-wrapper']}>
							<Col span={2} className={classes['icon-wrapper']}>
								<Image
									width={15}
									height={20}
									objectFit="cover"
									src="/icons/location-gray.svg"
									alt="temporary-image"
									style={{ borderRadius: '5px' }}
								/>
							</Col>
							<Col span={22} className={classes['location-wrapper']}>
								<Typography.Title level={5} className={classes['location-title']}>
									{suggestion.structured_formatting.main_text}
								</Typography.Title>
								<Typography.Paragraph className={classes['location-subtitle']}>
									{suggestion.structured_formatting.secondary_text}
								</Typography.Paragraph>
							</Col>
						</Row>
					),
				},
			],
		};
	});

	useEffect(() => {
		setOptions(suggestions.status === 'ZERO_RESULTS' ? defaultOptions : autocompleteOptions);
	}, [suggestions]);

	return (
		<AutoComplete
			className={classes.autocomplete}
			dropdownClassName={classes['dropdown-autocomplete-map']}
			onChange={onChangeSearch}
			onSelect={onSelectSearch}
			options={options}
		>
			<Input
				prefix={<Image src="/icons/search.svg" height={20} width={20} alt="Icon Search" />}
				className={classes.search}
				placeholder="Search"
			/>
		</AutoComplete>
	);
};

export default AddressListAutoComplete;
