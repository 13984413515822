export const USER_TYPE = {
	model: {
		listTopic: {
			success: 'USER_LIST_TOPIC_SUCCESS',
			error: 'USER_LIST_TOPIC_ERROR',
		},
		updateImage: {
			success: 'USER_UPDATE_IMAGE_SUCCESS',
			error: 'USER_UPDATE_IMAGE_ERROR',
		},
		check: {
			success: 'USER_CHECK_SUCCESS',
			error: 'USER_CHECK_ERROR',
		},
		checkPin: {
			success: 'USER_CHECK_PIN_SUCCESS',
			error: 'USER_CHECK_PIN_ERROR',
		},
		checkNumber: {
			success: 'USER_CHECK_NUMBER_SUCCESS',
			error: 'USER_CHECK_NUMBER_ERROR',
		},
		changeNumber: {
			success: 'USER_CHANGE_NUMBER_SUCCESS',
			error: 'USER_CHANGE_NUMBER_ERROR',
		},
		checkRelation: {
			success: 'USER_CHECK_RELATION_SUCCESS',
			error: 'USER_CHECK_RELATION_ERROR',
		},
		logged: {
			success: 'USER_LOGGED_SUCCESS',
			error: 'USER_LOGGED_ERROR',
		},
		login: {
			success: 'USER_LOGIN_SUCCESS',
			error: 'USER_LOGIN_ERROR',
		},
		register: {
			success: 'USER_REGISTER_SUCCESS',
			error: 'USER_REGISTER_ERROR',
		},
		forgotPin: {
			success: 'USER_FORGOT_PIN_SUCCESS',
			error: 'USER_FORGOT_PIN_ERROR',
		},
		changePin: {
			success: 'USER_CHANGE_PIN_SUCCESS',
			error: 'USER_CHANGE_PIN_ERROR',
		},
		setPin: {
			success: 'USER_SET_PIN_SUCCESS',
			error: 'USER_SET_PIN_ERROR',
		},
		logout: {
			success: 'USER_LOGOUT_SUCCESS',
		},
		profile: {
			success: 'USER_PROFILE_SUCCESS',
			error: 'USER_PROFILE_ERROR',
		},
		updateProfile: {
			success: 'USER_UPDATE_PROFILE_SUCCESS',
			error: 'USER_UPDATE_PROFILE_ERROR',
		},
		listRelationship: {
			success: 'USER_LIST_RELATIONSHIP_SUCCESS',
			error: 'USER_LIST_RELATIONSHIP_ERROR',
		},
		updateRelationship: {
			success: 'USER_UPDATE_RELATIONSHIP_SUCCESS',
			error: 'USER_UPDATE_RELATIONSHIP_ERROR',
		},
		deleteRelationship: {
			success: 'USER_DETELE_RELATIONSHIP_SUCCESS',
			error: 'USER_DETELE_RELATIONSHIP_ERROR',
		},
		reset: {
			success: 'USER_RESET_SUCCESS',
		},
		verifyRalationship: {
			success: 'USER_VERIFY_RELATIONSHIP_SUCCESS',
			error: 'USER_VERIFY_RELATIONSHIP_ERROR',
		},
		updateProfileIdentity: {
			success: 'USER_PROFILE_IDENTITY_SUCCESS',
			error: 'USER_PROFILE_IDENTITY_ERROR',
		},
		location: {
			success: 'USER_LOCATION_SUCCESS',
			error: 'USER_LOCATION_ERROR',
		},
		info: {
			success: 'USER_INFO_SUCCESS',
			error: 'USER_INFO_ERROR',
		},
	},
};
