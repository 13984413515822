import { useMutation, useQuery } from 'react-query';

import {
	ISubmitReviewProductPayload,
	ISubmitReviewStorePayload,
} from 'src/interface/Profile/UserReview';
import { fetchApiMarketplace } from 'src/utils/fetch-api';

export interface IUserReviewFilter {
	type: 'reviewed' | 'unreviewed' | '';
	page: number;
	limit: number;
	dir: 'asc' | 'desc';
	enabled?: boolean;
}

export interface IUserDetailReview {
	id?: string;
	enabled?: boolean;
}

export interface IDetailProductRating {
	filter: object;
	limit?: number;
	page?: number;
}

export const userReviewListQueryKey = 'user_review_list_query_key';
export const userDetailProductRating = 'user_detail_product_rating_query_key';
export const useDetailReviewQueryKey = 'user_detail_reviewed_query_key';
export const useDetailOrderQueryKey = 'user_detail_unreviewed_query_key';

export const useReviewList = ({ enabled, ...props }: IUserReviewFilter) => {
	return useQuery(
		[userReviewListQueryKey, props],
		() =>
			fetchApiMarketplace({
				url: '/search/orders',
				payload: {
					status: 8,
					is_reviewed: props.type === 'unreviewed' ? 0 : 1,
					limit: props.limit,
					page: props.page,
					sort: 'newest',
					dir: 'desc',
					fields: 'order_items,is_reviewed',
				},
			}),
		{
			enabled: (enabled ?? true) && !!props.type,
			refetchOnWindowFocus: false,
		},
	);
};

export const useDetailOrder = ({ enabled, ...props }: IUserDetailReview) => {
	return useQuery(
		[useDetailOrderQueryKey, props],
		() =>
			fetchApiMarketplace({
				url: `/order/${props.id}`,
			}),
		{
			enabled: enabled && !!props.id,
			refetchOnWindowFocus: false,
		},
	);
};

export const useDetailReview = ({ enabled, ...props }: IUserDetailReview) => {
	return useQuery(
		[useDetailReviewQueryKey, props],
		() =>
			fetchApiMarketplace({
				url: `/customer/review/${props.id}`,
			}),
		{
			enabled: enabled && !!props.id,
			refetchOnWindowFocus: false,
		},
	);
};

export const useSubmitReviewProduct = () => {
	const submitReview = (payload: ISubmitReviewProductPayload) => {
		return fetchApiMarketplace({
			url: '/merchant/product_review',
			options: {
				method: 'POST',
			},
			payload: { ...payload },
		});
	};

	return useMutation((payload: ISubmitReviewProductPayload) => submitReview(payload));
};

export const useSubmitReviewStore = () => {
	const submitReview = (payload: ISubmitReviewStorePayload) => {
		return fetchApiMarketplace({
			url: '/merchant/order_review',
			options: {
				method: 'POST',
			},
			payload: { ...payload },
		});
	};

	return useMutation((payload: ISubmitReviewStorePayload) => submitReview(payload));
};
