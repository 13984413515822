import { ABOUT_TYPE } from 'src/constants/redux';
import { ABOUT } from 'src/constants/services';
import { SINGLE_API } from 'src/redux/actions/types';

export const actionGetAbout = async (next?: (...f: any) => void) => {
	return {
		type: SINGLE_API,
		payload: {
			url: `/${ABOUT.service}`,
			successType: ABOUT_TYPE.model.about.success,
			next,
		},
	};
};
