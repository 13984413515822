import { useInfiniteQuery } from 'react-query';

import { USER } from 'src/constants/services';
import { fetchApiV3User } from 'src/utils/fetch-api';

const prescriptionAddress = 'addressList';

export const usePrescriptionAddress = ({ keyword }: any) => {
	return useInfiniteQuery(
		[prescriptionAddress, keyword],
		(fetchProps) => {
			const { pageParam = 1 } = fetchProps;
			let payloadReq: any = { page: pageParam };
			if (keyword.length > 0) {
				payloadReq = {
					...payloadReq,
					search: keyword,
				};
			}
			return fetchApiV3User({
				url: `/${USER.service}/${USER.model.address}`,
				payload: payloadReq,
			});
		},
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (res) => {
				const currentPage = parseInt(res?.meta?.pagination?.page);
				const totalPage = parseInt(res?.meta?.pagination?.totalpage);

				return currentPage < totalPage ? currentPage + 1 : undefined;
			},
		},
	);
};
