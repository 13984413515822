/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import AddressListConfirmation from 'src/components/Modal/Address/Update/AddressListConfirmation';
import AddressListForm from 'src/components/Modal/Address/Update/AddressListForm';
import AddressListPinPoint from 'src/components/Modal/Address/Update/AddressListPinPoint';
import AddressListSuccess from 'src/components/Modal/Address/Update/AddressListSuccess';
import ModalHeader from 'src/components/Modal/ModalHeader';
import g from 'src/constants/global';

interface Props {
	onBack: () => void;
	onClose: () => void;
	onSuccessAddAddress: () => void;
}

const AddressListAdd = (props: Props) => {
	const { onBack, onClose, onSuccessAddAddress } = props;
	const [step, setStep] = useState(g.PROFILE.ADDRESS_LIST.pinPointAdd);
	const [position, setPosition] = useState(g.MAP.DATA.defaultPosition);
	const [focus, setFocus] = useState(g.MAP.DATA.defaultPosition);
	const [address, setAddress] = useState<any>();

	useEffect(() => {
		if (step === g.PROFILE.ADDRESS_LIST.successAdd) {
			onSuccessAddAddress();
		}
	}, [step]);

	const nextStep = () => {
		switch (step) {
			case g.PROFILE.ADDRESS_LIST.pinPointAdd:
				setStep(g.PROFILE.ADDRESS_LIST.formAdd);
				break;
			case g.PROFILE.ADDRESS_LIST.formAdd:
				setStep(g.PROFILE.ADDRESS_LIST.confirmAdd);
				break;
			case g.PROFILE.ADDRESS_LIST.confirmAdd:
				setStep(g.PROFILE.ADDRESS_LIST.successAdd);
				break;
			default:
				break;
		}
	};

	const backStep = () => {
		switch (step) {
			case g.PROFILE.ADDRESS_LIST.successAdd:
				setStep(g.PROFILE.ADDRESS_LIST.confirmAdd);
				break;
			case g.PROFILE.ADDRESS_LIST.confirmAdd:
				setStep(g.PROFILE.ADDRESS_LIST.formAdd);
				break;
			case g.PROFILE.ADDRESS_LIST.formAdd:
				setStep(g.PROFILE.ADDRESS_LIST.pinPointAdd);
				break;
			default:
				break;
		}
	};

	const closeModal = () => {
		onClose();
		setStep(g.PROFILE.ADDRESS_LIST.pinPointAdd);
		setAddress(undefined);
		setPosition(g.MAP.DATA.defaultPosition);
		setFocus(g.MAP.DATA.defaultPosition);
	};

	const renderBody = () => {
		switch (step) {
			case g.PROFILE.ADDRESS_LIST.pinPointAdd:
				return (
					<>
						<ModalHeader
							title="Tambah Alamat"
							showBack
							onBack={onBack}
							showClose
							onClose={closeModal}
						/>
						<AddressListPinPoint
							address={address}
							setAddress={setAddress}
							position={position}
							setPosition={setPosition}
							focus={focus}
							setFocus={setFocus}
							nextStep={nextStep}
						/>
					</>
				);
			case g.PROFILE.ADDRESS_LIST.formAdd:
				return (
					<>
						<ModalHeader
							title="Tambah Alamat"
							showBack
							onBack={backStep}
							showClose
							onClose={closeModal}
						/>
						<AddressListForm address={address} setAddress={setAddress} nextStep={nextStep} />
					</>
				);
			case g.PROFILE.ADDRESS_LIST.confirmAdd:
				return (
					<>
						<ModalHeader
							title="Konfirmasi Alamat"
							showBack
							onBack={backStep}
							showClose
							onClose={closeModal}
						/>
						<AddressListConfirmation address={address} nextStep={nextStep} />
					</>
				);
			case g.PROFILE.ADDRESS_LIST.successAdd:
				return (
					<>
						<AddressListSuccess closeModal={closeModal} />
					</>
				);
			default:
				return <></>;
		}
	};

	return <>{renderBody()}</>;
};

export default AddressListAdd;
