import dayjs from 'src/utils/moment';

export const renderMonth = (month: number) => {
	switch (month) {
		case 0:
			return 'Januari';
		case 1:
			return 'Februari';
		case 2:
			return 'Maret';
		case 3:
			return 'April';
		case 4:
			return 'Mei';
		case 5:
			return 'Juni';
		case 6:
			return 'Juli';
		case 7:
			return 'Agustus';
		case 8:
			return 'September';
		case 9:
			return 'Oktober';
		case 10:
			return 'November';
		case 11:
			return 'Desember';
		default:
			return '';
	}
};

export const changeFormatDate = (date: string) => {
	let initial = date.split(/\//);
	return [initial[1], initial[0], initial[2]].join('/');
};

export const formatDateResultMenstrual = (date: string) => {
	return dayjs(date).format('DD MMMM');
};

export const formatDateResultPregnancy = (date: string) => {
	return dayjs(date).format('DD MMMM YYYY');
};
