import dayjs, { FORMAT_DATE } from 'src/utils/moment';

export const formatDate = (date: any, format: string = FORMAT_DATE, utc: boolean = false) => {
	if (!date) return '-';

	if (utc) {
		return (
			dayjs(date).format(format) +
			' (UTC' +
			dayjs(date).format('Z')?.split(':')?.[0] +
			')'
		).toUpperCase();
	} else {
		return dayjs(date).format(format);
	}
};

export const formatDateWithText = (
	date: any,
	format: string = FORMAT_DATE,
	additionalFormat?: string,
) => {
	if (!date) return '-';

	const time = dayjs(date).format(additionalFormat ?? 'HH:mm');

	if (dayjs(date).isToday()) {
		return `Hari ini, ${time}`;
	} else if (dayjs(date).isTomorrow()) {
		return `Besok, ${time}`;
	} else if (dayjs(date).isYesterday()) {
		return `Kemarin, ${time}`;
	} else {
		return dayjs(date).format(format);
	}
};

export const formatDateProductComment = (date: any, format: string = 'DD-MM-YYYY') => {
	const diffMinute = dayjs().diff(date, 'minute');
	const diffHour = dayjs().diff(date, 'hour');
	const diffDay = dayjs().diff(date, 'day');
	if (!date) return '-';
	if (diffMinute < 60) {
		return `${diffMinute} menit yang lalu`;
	} else if (diffHour < 24) {
		return `${diffHour} jam yang lalu`;
	} else if (diffDay < 7) {
		return `${diffDay} hari yang lalu`;
	} else {
		return dayjs(date).format(format);
	}
};

export const getDateRange = (from: string, to: string) => {
	let dateRange = dayjs(from).format('D MMM YYYY - ') + dayjs(to).format('D MMM YYYY');

	if (dayjs(from).format('D MMM YYYY') === dayjs(to).format('D MMM YYYY')) {
		dateRange = dayjs(to).format('D MMM YYYY');
	} else if (dayjs(from).format('YYYY') === dayjs(to).format('YYYY')) {
		dateRange = dayjs(from).format('D MMM - ') + dayjs(to).format('D MMM YYYY');
	}
	return dateRange ?? '';
};
