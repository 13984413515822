import jwt_decode, { JwtPayload } from 'jwt-decode';

import Storage from './storage';

type KDJwtPayload = {
	id?: string;
	full_name?: string;
	avatar?: string;
	email?: string;
	phone?: string;
	username?: string;
	last_sign_in_at?: string;
	signature?: string;
	topic?: number[];
};

type JwtDecodedPayload = JwtPayload & KDJwtPayload;

export const maxAgeDay = process.env.COOKIE_AUTH_MAXAGE
	? parseInt(process.env.COOKIE_AUTH_MAXAGE, 10)
	: 7;

export const maxAge = maxAgeDay * 24 * 60 * 60;

type Constructor<T> = new (...args: any[]) => T;

const createStorage = <T>(name: string, constructor: Constructor<T>) =>
	new constructor(name, null, { maxAge });
class AuthStorage extends Storage {
	get loggedIn() {
		return !!this.value?.token;
	}

	get token() {
		return this.value?.token;
	}

	get tokenDecode(): JwtDecodedPayload {
		return this?.token ? jwt_decode<JwtDecodedPayload>(this?.token) : {};
	}
}

class RefreshTokenStorage extends Storage {
	get refreshToken() {
		return this.value?.refreshToken;
	}
}
class IdTokenStorage extends Storage {
	get idToken() {
		return this.value?.idToken;
	}
}

const authStorage = createStorage('auth._token', AuthStorage);
const idTokenStorage = createStorage('auth._id_token', IdTokenStorage);
const refreshTokenStorage = createStorage('auth._refresh_token', RefreshTokenStorage);

export { idTokenStorage, refreshTokenStorage };

export default authStorage;
