import { MENU_TYPE } from 'src/constants/redux';

export const initialState = {};

const reducer = (state = initialState, action: any = {}) => {
	if (action.type === MENU_TYPE.model.main.success) {
		return action.payload;
	} else {
		return state;
	}
};

export default reducer;
