export const OTP_TYPE = {
	model: {
		otp: {
			success: 'OTP_SUCCESS',
			error: 'OTP_ERROR',
		},
		validate: {
			success: 'OTP_VALIDATE_SUCCESS',
			error: 'OTP_VALIDATE_ERROR',
		},
		validUser: {
			success: 'OTP_VALID_USER_SUCCESS',
			error: 'OTP_VALID_USER_ERROR',
		},
		reset: {
			success: 'OTP_RESET_SUCCESS',
		},
	},
};
