import { DefaultSeoProps } from 'next-seo/lib/types';

import METADATA from './metadata';

export const DefaultSEO: DefaultSeoProps = {
	defaultTitle: METADATA.APP_NAME,
	description: METADATA.APP_DESCRIPTION,
	facebook: {
		appId: METADATA.FB_APP_ID,
	},
	twitter: {
		cardType: 'summary',
		site: '@klikdokter_id',
	},
	openGraph: {
		type: 'article',
		locale: 'id_ID',
		title: METADATA.APP_NAME,
		description: METADATA.APP_DESCRIPTION,
		defaultImageHeight: 200,
		defaultImageWidth: 200,
		images: [
			{
				url: METADATA.IMG_SHARE,
				alt: 'Image Share',
			},
		],
	},
	additionalMetaTags: [
		{
			name: 'twitter:title',
			content: METADATA.APP_NAME,
		},
		{
			name: 'twitter:description',
			content: METADATA.APP_DESCRIPTION,
		},
		{
			name: 'twitter:image',
			content: METADATA.IMG_SHARE,
		},
		{
			name: 'viewport',
			content:
				// eslint-disable-next-line max-len
				'width=device-width, minimum-scale=1, maximum-scale=3, initial-scale=1, shrink-to-fit=no, height=device-height, viewport-fit=cover',
		},
		{
			name: 'mobile-web-app-capable',
			content: 'yes',
		},
		{
			name: 'apple-mobile-web-app-capable',
			content: 'yes',
		},
		{
			name: 'application-name',
			content: METADATA.APP_NAME,
		},
		{
			name: 'apple-mobile-web-app-title',
			content: METADATA.APP_NAME,
		},
		{
			name: 'apple-mobile-web-app-status-bar-style',
			content: 'white-' + METADATA.PRIMARY_COLOR,
		},
		{
			name: 'msapplication-starturl',
			content: '/',
		},
		{
			name: 'msapplication-navbutton-color',
			content: METADATA.PRIMARY_COLOR,
		},
		{
			name: 'theme-color',
			content: METADATA.PRIMARY_COLOR,
		},
	],
	additionalLinkTags: [
		{
			rel: 'manifest',
			href: '/manifest.json',
		},
		{
			rel: 'icon',
			href: '/icons/512x512.png',
			type: 'image/png',
			sizes: '512x512',
		},
		{
			rel: 'apple-touch-icon',
			href: '/icons/512x512.png',
			type: 'image/png',
			sizes: '512x512',
		},
		{
			rel: 'icon',
			href: '/icons/192x192.png',
			type: 'image/png',
			sizes: '192x192',
		},
		{
			rel: 'apple-touch-icon',
			href: '/icons/192x192.png',
			type: 'image/png',
			sizes: '192x192',
		},
	],
};
