import { CATEGORY_TYPE } from 'src/constants/redux/categoryByGroup';
import { ISearchResultArticleByGroup } from 'src/interface/ArticleByGroup';
import { ICategoryByGroup, ICategoryByGroupDetail } from 'src/interface/CategoryByGroup';

interface ICategoryByGroupInitialState {
	categories: ICategoryByGroup[];
	detail?: ICategoryByGroupDetail | null;
	detail_obat?: [];
	searchResult: ISearchResultArticleByGroup[] | [];
	all: ICategoryByGroupDetail[];
	categoryGroups: ICategoryByGroup[];
	subCategories: ICategoryByGroupDetail[];
}

export const initialState: ICategoryByGroupInitialState = {
	categories: [],
	detail: null,
	detail_obat: [],
	searchResult: [],
	all: [],
	categoryGroups: [],
	subCategories: [],
};

const reducer = (state = initialState, action: any = {}) => {
	switch (action.type) {
		case CATEGORY_TYPE.model.categories.success:
			return { ...state, categories: action.payload.data.records as ICategoryByGroup[] };
		case CATEGORY_TYPE.model.detail.success:
			return { ...state, detail: action.payload.data.record as ICategoryByGroupDetail };
		case CATEGORY_TYPE.model.detail_obat.success:
			return { ...state, detail_obat: action.payload.data.records };
		case CATEGORY_TYPE.model.search.success:
			return {
				...state,
				searchResult: action.payload.data.record.hits as ISearchResultArticleByGroup[],
			};
		case CATEGORY_TYPE.model.all.success:
			return { ...state, all: action.payload.data.records as ICategoryByGroupDetail[] };
		case CATEGORY_TYPE.model.groups.success:
			return {
				...state,
				categoryGroups: action.payload.data.records as ICategoryByGroup[],
			};
		case CATEGORY_TYPE.model.subCategories.success:
			return {
				...state,
				subCategories: action.payload.data.records as ICategoryByGroupDetail[],
			};
		default:
			return state;
	}
};

export default reducer;
