import { useQuery } from 'react-query';

import { TYPESENSE } from 'src/constants/services';
import { UserLocation } from 'src/interface';
import { fetchApiTypesense } from 'src/utils/fetch-api';

interface UseHealthServicesRecommendation {
	location: UserLocation;
	module: 'service' | 'doctor';
}

export const useHealthServicesRecommendation = (props: UseHealthServicesRecommendation) => {
	const queryFilter = props.module === 'service' ? 'has_services:true' : 'has_doctors:true';

	return useQuery(
		['useHealthServicesRecommendation', props],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.medicalFacilities,
							filter_by: queryFilter,
							sort_by: `location(${props.location.lat},${props.location.lng}):asc,name:asc`,
							include_fields: 'name,photo_highres,slug,type_code',
						},
					],
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useCategoryHealthServices = (perPage: number) => {
	return useQuery(
		['useCategoryHealthServices', perPage],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							q: '*',
							query_by: 'name',
							collection: TYPESENSE.model.categoryServices,
							filter_by: 'parent_uid:0',
							sort_by: 'sort_order:asc',
							include_fields: 'id,name,photo_highres,photo_lowres,slug',
							per_page: perPage,
						},
					],
				},
			});
		},
		{ keepPreviousData: true, refetchOnMount: false, refetchOnWindowFocus: false },
	);
};
