import { useMutation, useQuery, useQueryClient } from 'react-query';

import messageC from 'src/components/Message';
import { checkWishlistStatusQueryKey, productDetailQueryKey } from 'src/hooks/KlikStore/Product';
import { IProductAddRemoveFavorite, IProductFavoriteFilter } from 'src/interface';
import { fetchApiMarketplace } from 'src/utils/fetch-api';

export const listProductFavoriteQueryKey = 'list_product_favorite_query_key';

export const useListProductFavorite = (params?: IProductFavoriteFilter) => {
	return useQuery(
		[listProductFavoriteQueryKey, params],
		() =>
			fetchApiMarketplace({
				url: '/customer/wishlists',
				payload: {
					limit: params?.limit,
					page: params?.page,
					// fields: productsFields,
				},
			}),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
		},
	);
};

export const useProductAddToFavorite = () => {
	const queryClient = useQueryClient();

	const addToWishlist = (payload: IProductAddRemoveFavorite) => {
		return fetchApiMarketplace({
			url: '/customer/wishlist',
			options: { method: 'POST' },
			payload: { product_id: payload.product_id, merchant_id: payload.merchant_id },
		});
	};

	return useMutation(
		(payload: IProductAddRemoveFavorite) => {
			return addToWishlist(payload);
		},
		{
			onSettled: () => {
				queryClient.invalidateQueries(checkWishlistStatusQueryKey);
				queryClient.invalidateQueries(productDetailQueryKey);
				queryClient.invalidateQueries(listProductFavoriteQueryKey);
			},
			onSuccess: () => {
				messageC('Berhasil menambah barang ke Wishlist');
			},
			onError: (e: any) => {
				messageC(`Terjadi Kesalahan : ${e?.meta?.message || e?.message}`, 'error');
			},
		},
	);
};

export const useProductRemoveFromFavorite = () => {
	const queryClient = useQueryClient();

	const removeFromWishlist = (payload: IProductAddRemoveFavorite) => {
		return fetchApiMarketplace({
			url: `/customer/wishlist/${payload.product_id}/${payload.merchant_id}`,
			options: { method: 'DELETE' },
		});
	};

	return useMutation(
		(payload: IProductAddRemoveFavorite) => {
			return removeFromWishlist(payload);
		},
		{
			onSettled: () => {
				queryClient.invalidateQueries(checkWishlistStatusQueryKey);
				queryClient.invalidateQueries(productDetailQueryKey);
				queryClient.invalidateQueries(listProductFavoriteQueryKey);
			},
			onSuccess: () => {
				messageC('Barang telah dihapus dari Wishlist');
			},
			onError: (e: any) => {
				messageC(`Terjadi Kesalahan ${e?.meta?.message}`, 'error');
			},
		},
	);
};
