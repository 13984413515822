import { useQuery } from 'react-query';

import { fetchApiMarketplace } from 'src/utils/fetch-api';

export const categoryFields =
	'parent_id,store_id,description,meta_title,meta_description,meta_title_h1';

export const klikStoreCategories = 'klikStoreCategories';

export const useKlikStoreCategoryDetail = (slug: string) => {
	return useQuery(
		['klikStoreCategoryDetail', slug],
		() => {
			return fetchApiMarketplace({
				url: `/search/category_detail/${slug}`,
				options: { method: 'GET' },
				payload: {
					fields: categoryFields,
				},
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useKlikStoreCategories = () => {
	return useQuery(
		[klikStoreCategories],
		() => {
			return fetchApiMarketplace({
				url: '/search/categoryTree',
				options: { method: 'GET' },
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};
