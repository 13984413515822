import { FLOATING_CHAT_TYPE } from 'src/constants/redux/klikstore/floatingchat';
import { STORE_DATA } from 'src/redux/actions/types';

export const actionSetFloatingChatFlag = (payload: boolean = false) => {
	return {
		type: STORE_DATA,
		payload: {
			successType: FLOATING_CHAT_TYPE.model.floatingChat.success,
			payload: payload,
		},
	};
};
export const actionSetFloatingChatReference = (payload: any) => {
	return {
		type: STORE_DATA,
		payload: {
			successType: FLOATING_CHAT_TYPE.model.floatingChat.addedRef,
			payload: payload,
		},
	};
};
