import { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Button, Col, Divider, Grid, Layout, Row } from 'antd';
import classNames from 'classnames';

import ModalKlikNow from 'src/components/KlikStore/KlikNow/ModalKlikNow';
import Download from 'src/components/Layout/Header/Download';
import LogoC from 'src/components/Layout/Header/Logo';
import { MainDrawerMenuC } from 'src/components/Layout/Menu';
import UserMenuC from 'src/components/Layout/Menu/UserMenu';
import { useDetectWebView } from 'src/hooks';
import { IKlikStoreMasterProduct, PageHeaderProps } from 'src/interface';
import AuthStorage from 'src/utils/auth-storage';
import deviceSize from 'src/utils/device-size';

import HeaderCategorySheetC from './HeaderCategory';
import HeaderLocationC from './HeaderLocation';

import classes from './index.module.less';
interface HeaderPropsC {
	isWhiteBackground: boolean;
	pageHeaderContent: PageHeaderProps;
	isDrawerOnly?: boolean;
}

const HeaderC = (props: HeaderPropsC) => {
	const { xs, sm, md, lg } = Grid.useBreakpoint();
	const r = useRouter();
	const responsive = (xs || sm || md) && !lg;

	const mpModule = r.pathname.includes('/kalstore') || r.pathname.includes('/cart');
	const buttonSize = deviceSize() === 'desktop' ? 'large' : 'middle';
	const [isVisibleComponent, setIsVisibleComponent] = useState<string>('');
	const [isCategoryHovered, setCategoryHovered] = useState<boolean>(false);

	const [showKlikNowModal, setShowKlikNowModal] = useState<boolean>(false);
	const [klikNowProduct, setKlikNowProduct] = useState<IKlikStoreMasterProduct | undefined>(
		undefined,
	);
	const { isKalstoreModule: forceKalstoreModule } = useSelector(
		(state: RootStateOrAny) => state.kalstoreFlag,
	);

	const isKalstoreModule = mpModule || forceKalstoreModule;

	const isWebView = useDetectWebView();

	useEffect(() => {
		if (klikNowProduct) {
			setShowKlikNowModal(true);
		} else {
			setShowKlikNowModal(false);
		}
	}, [klikNowProduct]);

	return (
		<>
			{!isWebView && <Download />}
			<Layout.Header className={classNames(classes.header, 'h-25')}>
				<div className={classNames('container', classes['header-wrap'])}>
					<Row
						gutter={responsive ? 8 : 32}
						wrap={false}
						style={{ padding: responsive ? '5px 0 0 0' : '0' }}
					>
						<Col
							className={classNames({ 'pb-lg-4': !isKalstoreModule })}
							flex="none"
							style={{ alignItems: 'center', display: 'flex' }}
						>
							<div className="d-flex align-items-center">
								<MainDrawerMenuC
									placement="left"
									visibleComponent={isVisibleComponent}
									setVisibleComponent={setIsVisibleComponent}
								/>

								<div className={classes['mobile-logo-click']}>
									<div className="ml-0 ml-sm-1 ml-md-3">
										<LogoC ClickImage={() => setIsVisibleComponent('')} />
									</div>
								</div>
							</div>
						</Col>
						{!responsive && isKalstoreModule && (
							<Col
								flex="none"
								style={{ alignItems: 'center', display: 'flex' }}
								onMouseLeave={() => {
									setCategoryHovered(false);
								}}
							>
								<HeaderCategorySheetC
									isCategoryHovered={isCategoryHovered}
									setCategoryHovered={setCategoryHovered}
								/>
							</Col>
						)}
						<Col
							flex="none"
							className="text-right align-self-center"
							style={{ marginLeft: 'auto' }}
						>
							{!props.isDrawerOnly ? (
								<>
									{AuthStorage.loggedIn ? (
										<UserMenuC
											setVisibleComponent={setIsVisibleComponent}
											visibleComponent={isVisibleComponent}
										/>
									) : (
										<Row align="middle" justify="end">
											<Col>
												<Button
													type="primary"
													size={buttonSize}
													className={classNames(classes.button, 'd-flex align-items-center')}
												>
													<Link href="/signin" className="fs-14 fs-lg-16 mx-sm-2 mx-md-3">
														Masuk
													</Link>
												</Button>
											</Col>
										</Row>
									)}
									{!responsive && !isKalstoreModule && <HeaderLocationC />}
								</>
							) : null}
						</Col>
					</Row>
					{isKalstoreModule && (
						<div className={classes['wrap-kalstore-link']}>
							<div className={classes['kalstore-link-button']}>
								<Link href="/kalstore" className={classes.link} title="Ke Halaman KALStore">
									<span className={classes['logo-bg']} />
								</Link>
							</div>
						</div>
					)}
				</div>
				{responsive && !isKalstoreModule && (
					<Row
						align="middle"
						justify="center"
						wrap={false}
						className={classNames({ [classes.location]: responsive })}
					>
						<Col>
							<HeaderLocationC />
						</Col>
					</Row>
				)}
			</Layout.Header>
			{props.isWhiteBackground && !props.pageHeaderContent.title && (
				<Divider className={classes.divider} />
			)}

			<ModalKlikNow
				show={showKlikNowModal}
				setShow={setShowKlikNowModal}
				product={klikNowProduct}
				setProduct={setKlikNowProduct}
			/>
		</>
	);
};

export default HeaderC;
