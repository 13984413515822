import { useQuery } from 'react-query';

import { TYPESENSE } from 'src/constants/services';
import { fetchApiTypesense } from 'src/utils/fetch-api';

export const useMeetDoctorCategory = (perPage: number) => {
	return useQuery(
		['useMeetDoctorCategory', perPage],
		() => {
			return fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							q: '*',
							collection: `${TYPESENSE.model.categorySpecialities}`,
							sort_by: 'sort_order:asc,name:asc',
							include_fields: 'id,name,slug,icon_highres,icon_lowres,specialist_uids',
							per_page: perPage,
						},
					],
				},
			});
		},
		{ keepPreviousData: true, refetchOnMount: false, refetchOnWindowFocus: false },
	);
};
