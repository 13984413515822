import g from 'src/constants/global';

const teleConsultationModule: any = [g.DOCTOR.SERVICES.chat, g.DOCTOR.SERVICES.videoCall];

// get medical facility ID
export const getMFId = (moduleType: string, selectedMFId?: string) => {
	let type: string = moduleType?.toLowerCase();

	let mfID = selectedMFId;
	if (teleConsultationModule.includes(type)) {
		mfID = g.HOSPITAL.DEFAULT.id;
	}

	return mfID;
};
