import { createWrapper } from 'next-redux-wrapper';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import { isBrowser } from 'src/constants/environment';
import reducer, { initialState } from 'src/redux/reducers';
import apiMiddleware from 'src/redux/thunk/middleware';
import SettingsDebugStorage from 'src/utils/settings-debug-storage';

const DEV = isBrowser && process.env.NODE_ENV === 'development';

const bindMiddleware = (middleware: any) => {
	if (DEV) {
		const { createLogger } = require('redux-logger');
		const logger = createLogger({
			collapsed: (getState: any, action: any, logEntry: any) => !logEntry.error,
		});

		return applyMiddleware(...middleware, logger);
	}

	return applyMiddleware(...middleware);
};

const makeStore = () => {
	return createStore(reducer, initialState, bindMiddleware([apiMiddleware, thunk]));
};

const isDebug = () => {
	return process.env.DEBUG_KEY ? process.env.DEBUG_KEY === SettingsDebugStorage.value : false;
};

export default createWrapper(makeStore, {
	debug:
		isDebug() ||
		(process.env.NODE_ENV === 'development' && process.env.DISABLE_REDUX_LOG === 'false'),
});
