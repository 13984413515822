import { useQuery } from 'react-query';

import { COMMON } from 'src/constants/services';
import { fetchApiPublishing } from 'src/utils/fetch-api';

const socialMedia = 'social_media';

export const useSocialMedia = () => {
	const getList = () => {
		return fetchApiPublishing({
			url: `/${COMMON.model.socialMedia}`,
		});
	};

	return useQuery([socialMedia], () => getList(), { refetchOnWindowFocus: false });
};
