export const PROFILE_TYPE = {
	model: {
		menu: {
			success: 'USER_MENU_SUCCESS',
			error: 'USER_MENU_ERROR',
		},
		userSelected: {
			success: 'USER_SELECTED_SUCCESS',
			error: 'USER_SELECTED_ERROR',
		},
		pointReward: {
			success: 'USER_POINT_REWARD_SUCCESS',
			error: 'USER_POINT_REWARD_ERROR',
		},
		membershipInfo: {
			success: 'USER_MEMBERSHIP_INFO_SUCCESS',
			error: 'USER_MEMBERSHIP_INFO_ERROR',
		},
		membershipList: {
			success: 'USER_MEMBERSHIP_LIST_SUCCESS',
			error: 'USER_MEMBERSHIP_LIST_ERROR',
		},
		membershipPoint: {
			success: 'USER_MEMBERSHIP_POINT_SUCCESS',
			error: 'USER_MEMBERSHIP_POINT_ERROR',
		},
	},
};
