import { useQuery } from 'react-query';

import { CONTACTS_OFFICE } from 'src/constants/services';
import { fetchApiPublishing } from 'src/utils/fetch-api';

const query = 'contact-us-office';

export const useContactOffice = () => {
	return useQuery(query, () => fetchApiPublishing({ url: `${CONTACTS_OFFICE.service}` }), {
		keepPreviousData: true,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
};
