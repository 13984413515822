import { FLOATING_CHAT_TYPE } from 'src/constants/redux/klikstore/floatingchat';

export const initialState = {
	klikstoreFloatingChatOpen: false,
	klikstoreFloatingChatReference: null,
};

const reducer = (state = initialState, action: any = {}) => {
	if (action.type === FLOATING_CHAT_TYPE.model.floatingChat.success) {
		if (action.payload === true) {
			return { ...state, klikstoreFloatingChatOpen: true };
		} else {
			return { ...state, klikstoreFloatingChatOpen: false, klikstoreFloatingChatReference: null };
		}
	} else if (action.type === FLOATING_CHAT_TYPE.model.floatingChat.addedRef) {
		return { ...state, klikstoreFloatingChatReference: action.payload };
	} else if (action.type === FLOATING_CHAT_TYPE.model.floatingChat.clearRef) {
		return { ...state, klikstoreFloatingChatReference: null };
	} else {
		return state;
	}
};

export default reducer;
