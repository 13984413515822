import global from 'src/constants/global';
import { HEALTH_TYPE } from 'src/constants/services';

const {
	consultationChat,
	consultationVideo,
	consultationFace,
	reservationDoctor,
	reservationService,
	prescriptionRedeem,
	prescriptionUpload,
	healthyShopping,
	klikstore,
} = global.PROFILE.TRANSACTION.SERVICE;

export const getDoctorImg = (gender: string = '') => {
	switch (gender) {
		case 'M':
		case 'Laki-Laki':
			return '/images/default/doctor-male.png';
		case 'F':
		case 'Perempuan':
			return '/images/default/doctor-female.png';
		default:
			return '/images/default/doctor-general.png';
	}
};

export const getDefaultAuthor = () => {
	return '/images/author/author-default.svg';
};

export const getAppointmentImg = (type: string = '', isWide: boolean = false) => {
	switch (type) {
		case HEALTH_TYPE.HOSPITAL:
		case HEALTH_TYPE.MEDICAL_FACILITES:
			return `/images/default/hospital-${isWide ? 'wide' : 'square'}.png`;
		case HEALTH_TYPE.SERVICE:
			return `/images/default/service-${isWide ? 'wide' : 'square'}.png`;
		default:
			return `/images/default/medicine-${isWide ? 'wide' : 'square'}.png`;
	}
};

export const getDefaultCategoryImg = () => {
	return '/images/klikstore/empty-category.svg';
};

export const getDefaultProductImg = () => {
	return '/images/empty/product.png';
};

export const getStoreIcon = (storeType: string) => {
	if (storeType?.toLowerCase()?.indexOf('official') > -1) {
		return '/images/klikstore/store/icon-official.svg';
	}
	return '/images/klikstore/store/icon-other.svg';
};

export const getStoreIconWhite = (storeType: string) => {
	if (storeType?.toLowerCase()?.indexOf('official') > -1) {
		return '/images/klikstore/store/icon-official-white.svg';
	}
	return '/images/klikstore/store/icon-other-white.svg';
};

export const getDefaultImg = (module: string, gender: string = '', isWide: boolean = false) => {
	switch (module) {
		case consultationChat:
		case consultationVideo:
		case consultationFace:
		case reservationDoctor:
			return getDoctorImg(gender);
		case reservationService:
			return getAppointmentImg(HEALTH_TYPE.SERVICE, isWide);
		case prescriptionRedeem:
		case prescriptionUpload:
			return getAppointmentImg();
		case healthyShopping:
		case klikstore:
			return '/images/empty/product.png';
		default:
			return null;
	}
};
