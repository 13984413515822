import cookie from 'react-cookies';

import { isBrowser } from 'src/constants/environment';

const mobile = 'mobile';

const saveToCookie = (layout: any) => {
	if (layout) {
		const maxAgeDay = process.env.COOKIE_DEFAULT_MAXAGE
			? parseInt(process.env.COOKIE_DEFAULT_MAXAGE, 10)
			: 7;

		cookie.save('layout', mobile, {
			path: '/',
			maxAge: maxAgeDay * 24 * 60 * 60,
		});
	}
};

const layoutMobile = (router: any) => {
	const params = isBrowser ? new URLSearchParams(window.location.search) : null;
	let lm = (router?.query?.layout || params?.get?.('layout')) === mobile;

	if (!lm) {
		let lmc = cookie.load('layout') === mobile;

		if (!lmc) {
			saveToCookie(lm);
			return lm;
		}

		return lmc;
	}

	saveToCookie(lm);

	return lm;
};

export default layoutMobile;
