import { REVIEWPOPUP_TYPE } from 'src/constants/redux/klikstore/reviewpopup';

export const initialState = {
	klikstoreReviewPopupDisplayOpen: false,
	klikstoreReviewPopupData: null,
};

const reducer = (state = initialState, action: any = {}) => {
	if (action.type === REVIEWPOPUP_TYPE.model.reviewpopup.success) {
		if (action.payload?.status === true) {
			return {
				...state,
				klikstoreReviewPopupDisplayOpen: true,
			};
		} else {
			return { ...state, klikstoreReviewPopupDisplayOpen: false };
		}
	} else if (action.type === REVIEWPOPUP_TYPE.model.reviewpopup.data) {
		return {
			...state,
			klikstoreReviewPopupData: action.payload?.data,
		};
	} else {
		return state;
	}
};

export default reducer;
