import React from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { ModalProps } from 'antd';

import SpinC from 'src/components/Layout/Spin';
import ModalC from 'src/components/Modal';
import ModalHeader from 'src/components/Modal/ModalHeader';
import { IUseReview } from 'src/hooks/KlikStore/useReviewAction';
import { useDetailOrder, useDetailReview } from 'src/hooks/Profile/useUserReview';
import {
	IDetailOrderData,
	IDetailOrderProductData,
	IDetailReviewData,
	IDetailReviewProductData,
} from 'src/interface/Profile/UserReview';
import { actionSetReviewPopupDisplay } from 'src/redux/actions/klikstore/reviewpopup';

import DetailReviewContent from './DetailReviewContent';

import classes from './DetailReviewModal.module.less';

interface IDetailReviewModal {
	visible: ModalProps['visible'];
	title: string;
}

export interface IReviewContentProductData extends IDetailOrderProductData {
	rating: number;
	comment: string;
	is_product_reviewed: boolean;
}

export interface IReviewContentData extends IDetailOrderData {
	is_reviewed: boolean;
	merchant_rating_score: number;
	is_merchant_reviewed: boolean;
	order_items: IReviewContentProductData[];
}

const DetailReviewModal = ({ visible, title }: IDetailReviewModal) => {
	const dispatch = useDispatch();

	const { klikstoreReviewPopup } = useSelector((state: RootStateOrAny) => state);

	const dataUseReview: IUseReview = klikstoreReviewPopup?.klikstoreReviewPopupData;

	const { data: orderData } = useDetailOrder({
		id: dataUseReview?.order_no,
		enabled: dataUseReview !== undefined && dataUseReview !== null,
	});
	const detailOrderRecord: IDetailOrderData = orderData?.data?.record;

	const { data: reviewData } = useDetailReview({
		id: dataUseReview?.order_no,
		enabled: dataUseReview !== undefined && dataUseReview !== null,
	});
	const detailReviewRecord: IDetailReviewData = reviewData?.data?.record;

	let orderItems: IReviewContentProductData[] =
		detailOrderRecord?.orderItems?.map((p: IDetailOrderProductData) => {
			const pState = detailReviewRecord?.detail?.find(
				(d: IDetailReviewProductData) =>
					d?.merchant_id === detailOrderRecord?.merchant_id && d?.product_sku === p.product_sku,
			);

			const rating = pState?.product_rating_score ?? 0;
			const comment = pState?.comment ?? '';

			return {
				...p,
				rating: rating,
				comment: comment,
				is_product_reviewed: rating !== 0,
			};
		}) ?? [];

	const merchantRating =
		detailOrderRecord?.orderReview?.rating_score ??
		detailReviewRecord?.header?.merchant_rating_score ??
		0;

	let data: IReviewContentData = {
		...(detailOrderRecord ?? {}),
		is_reviewed: dataUseReview?.is_reviewed,
		merchant_rating_score: merchantRating,
		is_merchant_reviewed: merchantRating !== 0,
		order_items: orderItems,
	};

	let isLoading =
		dataUseReview === null || detailOrderRecord === undefined || detailReviewRecord === undefined;

	const onCancel = () => {
		dispatch(
			actionSetReviewPopupDisplay({
				status: false,
				callBackFn: null,
			}),
		);
	};

	return (
		<ModalC
			width={600}
			className={classes.modal}
			hideButton
			closable={false}
			visible={visible}
			destroyOnClose
		>
			<ModalHeader title={title} showClose onClose={onCancel} className={classes.header} />
			{isLoading ? <SpinC /> : <DetailReviewContent data={data} />}
		</ModalC>
	);
};

export default DetailReviewModal;
