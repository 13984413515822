import Link from 'next/link';
import { useRouter } from 'next/router';
import { Grid, Typography } from 'antd';

import ButtonC from 'src/components/Forms/Button';

import classes from './Download.module.less';

const Download = () => {
	const { xs } = Grid.useBreakpoint();
	const router = useRouter();

	return (
		<>
			{xs && router.pathname != '/download-aplikasi' && (
				<div className={`${classes.download} container`}>
					<Typography.Text className="fs-12 text-neutral-5">
						Lebih Praktis dengan Aplikasi
					</Typography.Text>
					<Link href={'/download-aplikasi'} passHref>
						<ButtonC text="Download" ghost />
					</Link>
				</div>
			)}
		</>
	);
};

export default Download;
