import type { ReactNode } from 'react';
import { Form, Input, InputNumber } from 'antd';

type Size = 'large' | 'middle' | 'small';
type NamePath = string | number | (string | number)[];
type Props = {
	refInput?: any;
	name: NamePath;
	type?: string;
	placeholder?: string;
	readOnly?: boolean;
	disabled?: boolean;
	useLabel?: boolean;
	label?: string;
	className?: string;
	formItemClassName?: string;
	rules?: object[];
	validateTrigger?: string | string[] | false;
	keyboard?: boolean;
	size?: Size;
	value?: any;
	min?: number;
	max?: number;
	rows?: number;
	maxLength?: number;
	showCount?: boolean;
	prefix?: ReactNode;
	suffix?: ReactNode;
	addonBefore?: ReactNode;
	addonAfter?: ReactNode;
	onBlur?: (e?: any) => void;
	onChange?: (e?: any) => void;
	defaultValue?: any;
	autoSize?: boolean | object;
	onKeyDown?: (e?: any) => void;
	help?: ReactNode;
};

const InputC = (props: Props) => {
	const isRequired = props.rules
		? props.rules.filter((r: any) => r.required === true).length > 0
		: false;
	const inputRender = () => {
		if (props.type === 'number') {
			return (
				<InputNumber
					defaultValue={props.defaultValue}
					type="number"
					placeholder={props.placeholder || props.label}
					className={props.className}
					readOnly={props.readOnly}
					disabled={props.disabled}
					keyboard={props.keyboard}
					size={props.size}
					min={props.min}
					max={props.max}
					maxLength={props.maxLength}
					onBlur={props.onBlur}
					onChange={props.onChange}
				/>
			);
		} else if (props.type === 'password') {
			return (
				<Input.Password
					placeholder={props.placeholder || props.label}
					className={props.className}
					readOnly={props.readOnly}
					disabled={props.disabled}
					size={props.size}
					prefix={props.prefix}
					suffix={props.suffix}
					addonBefore={props.addonBefore}
					addonAfter={props.addonAfter}
					maxLength={props.maxLength}
					onBlur={props.onBlur}
					onChange={props.onChange}
				/>
			);
		} else if (props.type === 'textarea') {
			return (
				<Input.TextArea
					defaultValue={props.defaultValue}
					ref={props.refInput}
					placeholder={props.placeholder || props.label}
					className={props.className}
					readOnly={props.readOnly}
					disabled={props.disabled}
					size={props.size}
					rows={props.rows}
					maxLength={props.maxLength}
					showCount={props.showCount}
					onBlur={props.onBlur}
					onChange={props.onChange}
					value={props.value}
					autoSize={props.autoSize}
					onKeyDown={props.onKeyDown}
				/>
			);
		} else {
			return (
				<Input
					defaultValue={props.defaultValue}
					ref={props.refInput}
					type={props.type}
					placeholder={props.placeholder || props.label}
					className={props.className}
					readOnly={props.readOnly}
					disabled={props.disabled}
					size={props.size}
					prefix={props.prefix}
					suffix={props.suffix}
					addonBefore={props.addonBefore}
					addonAfter={props.addonAfter}
					maxLength={props.maxLength}
					onBlur={props.onBlur}
					onChange={props.onChange}
					value={props.value}
				/>
			);
		}
	};

	return (
		<Form.Item
			label={props.useLabel ? props.label : ''}
			name={props.name}
			required={isRequired}
			rules={props.rules}
			validateTrigger={props.validateTrigger}
			className={props.formItemClassName}
			help={props.help}
		>
			{inputRender()}
		</Form.Item>
	);
};

export default InputC;
