import { useEffect, useState } from 'react';
import Router from 'next/router';

import SpinC from 'src/components/Layout/Spin';
import messageC from 'src/components/Message';
import ModalC from 'src/components/Modal';
import ModalHeader from 'src/components/Modal/ModalHeader';
import { useAddressLists, useKlikstoreProductAddToCart } from 'src/hooks';
import { useKlikNowGetProduct } from 'src/hooks/KlikStore/KlikNow';
import { Address, IKlikStoreMasterProduct } from 'src/interface';
import AuthStorage from 'src/utils/auth-storage';

import AddressEmpty from '../AddressEmpty';
import AddressListAdd from '../AddressListAdd';
import FindingProduct from '../FindingProduct';
import ProductNotFound from '../ProductNotFound';

interface Props {
	show: boolean;
	setShow: (show: boolean) => void;
	product: IKlikStoreMasterProduct | undefined;
	setProduct: (product: IKlikStoreMasterProduct | undefined) => void;
}

const steps = {
	addressEmpty: 'addressEmpty',
	addressAdd: 'addressAdd',
	findingProduct: 'findingProduct',
	productNotFound: 'productNotFound',
};

const ModalKlikNow = ({ show, setShow, product, setProduct }: Props) => {
	const isLogin = AuthStorage.loggedIn;

	const [step, setStep] = useState<string | undefined>(undefined);

	const { mutate: getKlikNowProduct } = useKlikNowGetProduct();
	const { mutate: addToCartMutate } = useKlikstoreProductAddToCart();

	const {
		data,
		isLoading: isLoadingAddressList,
		isFetching: isFetchingAddressList,
	} = useAddressLists('1', undefined, undefined, show);
	const items = data?.data?.records;

	const onSuccessAddAddress = () => {
		setStep(steps.findingProduct);
	};

	const closeModal = () => {
		setShow(false);
		setProduct?.(undefined);
	};

	const addToCart = async (merchantId: any, merchantSku: any) => {
		addToCartMutate(
			{
				merchant_id: merchantId,
				merchant_sku: merchantSku,
				quantity: 1,
				is_kliknow: 1,
			},
			{
				onError: (err: any) => {
					messageC(err?.meta?.message || err?.message || 'Terjadi kesalahan sistem.', 'error');
					closeModal();
				},
				onSuccess: () => {
					// when success, redirect to /cart
					Router.push('/cart');
					closeModal();
				},
			},
		);
	};

	useEffect(() => {
		if (!show) {
			setStep(undefined);
		}
	}, [show]);

	useEffect(() => {
		if (show) {
			if (isFetchingAddressList) return;

			if (items && items.length > 0) {
				setStep(steps.findingProduct);
			} else {
				setStep(steps.addressEmpty);
			}
		}
	}, [show, isFetchingAddressList, items]);

	useEffect(() => {
		if (
			show &&
			isLogin &&
			product?.name &&
			!isFetchingAddressList &&
			step === steps.findingProduct
		) {
			const defaultAddress: Address = items.filter(
				(address: Address) => address.default === true,
			)?.[0];

			if (!defaultAddress) {
				setStep(steps.addressEmpty);
				return;
			}

			// call add product to cart API
			const payload = {
				q: product?.name,
				lat: parseFloat(defaultAddress.latitude),
				lon: parseFloat(defaultAddress.longitude),
			};

			getKlikNowProduct(payload, {
				onSuccess: (res: any) => {
					const merchantProduct = res?.data?.record;
					const merchantId = merchantProduct?.merchant_id;
					const merchantSku = merchantProduct?.merchant_sku;

					if (!merchantId) {
						setStep(steps.productNotFound);
						return;
					}

					addToCart(merchantId, merchantSku);
				},
				onError: (err: any) => {
					messageC(err?.meta?.message || err?.message || 'Terjadi kesalahan sistem.', 'error');
					closeModal();
				},
			});
		}
	}, [show, isLogin, isFetchingAddressList, items, step]);

	return (
		<>
			<ModalC width={600} visible={show && isLogin} closable={false} hideButton={true}>
				{isLoadingAddressList || isFetchingAddressList ? (
					<>
						<ModalHeader title="" showClose={true} onClose={closeModal} />
						<SpinC spinning={true} />
					</>
				) : (
					<>
						{step === steps.findingProduct && (
							<>
								<ModalHeader title="" showClose={true} onClose={closeModal} />
								<FindingProduct />
							</>
						)}

						{step === steps.addressEmpty && (
							<>
								<ModalHeader className="mb-3" title="" showClose onClose={closeModal} />
								<AddressEmpty handleCreateNewAddress={() => setStep(steps.addressAdd)} />
							</>
						)}

						{step === steps.addressAdd && (
							<AddressListAdd
								onBack={() => setStep(steps.addressEmpty)}
								onClose={closeModal}
								onSuccessAddAddress={onSuccessAddAddress}
							/>
						)}

						{step === steps.productNotFound && (
							<>
								<ModalHeader className="mb-3" title="" showClose onClose={closeModal} />
								<ProductNotFound product={product} setShow={setShow} />
							</>
						)}
					</>
				)}
			</ModalC>
		</>
	);
};

export default ModalKlikNow;
