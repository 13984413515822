import { USER_TYPE } from 'src/constants/redux';

export const initialState = {};

const reducer = (state = initialState, action: any = {}) => {
	switch (action.type) {
		case USER_TYPE.model.verifyRalationship.success:
			return action.payload;
		case USER_TYPE.model.verifyRalationship.error:
			return {};
		default:
			return state;
	}
};

export default reducer;
