import { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Router from 'next/router';
import { Form, Typography } from 'antd';

import AuthReactivateModal from 'src/components/Auth/AuthReactivateModal';
import { ButtonOK } from 'src/components/Forms/Button';
import InputPin from 'src/components/Forms/Input/InputPin';
import Image from 'src/components/Image';
import g from 'src/constants/global';
import { AuthProps } from 'src/interface/Auth';
import { actionUserCheckModify, actionUserLogin } from 'src/redux/actions/auth';
import AuthStorage from 'src/utils/auth-storage';
import { saveFirebaseMessagingToken } from 'src/utils/func/firebase';
import { timeToText } from 'src/utils/func/format-time';

import classes from './AuthPin.module.less';

const { Text, Title } = Typography;

const AuthPin = (props: AuthProps) => {
	const dispatch = useDispatch();
	const { auth, loader, otp } = useSelector((state: RootStateOrAny) => state);
	const [form] = Form.useForm();

	const [pin, setPin] = useState<string>('');
	const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
	const [hasErrored, setHasErrored] = useState<boolean>(false);
	const [deletedAccount, setDeletedAccount] = useState<any>(null);
	const [showReactivateModal, setShowReactivateModal] = useState<boolean>(false);
	useEffect(() => {
		setBtnDisabled(props.authBody?.pin?.length !== 6);

		if (hasErrored) {
			setHasErrored(false);
			form.setFields([{ name: 'pin', errors: [''] }]);
		}
	}, [props.authBody?.pin]);

	useEffect(() => {
		if (props.type === g.AUTH.LOGIN.label) {
			if (auth.userCheck === null || auth.userCheck?.status !== 200) {
				if (!deletedAccount) props.setAuthStep?.(g.AUTH.LOGIN.phone);
			}
		} else if (props.type === g.AUTH.FORGOT.label) {
			if (
				otp.otpValidUser === null ||
				otp.otpValidUser?.status !== 200 ||
				otp.otpValidate === null ||
				otp.otpValidate?.status !== 200
			) {
				props.setAuthStep?.(g.AUTH.FORGOT.phone);
			}
		} else {
			if (
				auth.userCheck === null ||
				auth.userCheck?.status !== 200 ||
				otp.otpValidate === null ||
				otp.otpValidate?.status !== 200
			) {
				props.setAuthStep?.(g.AUTH.REGISTER.phone);
			}
		}
	}, [auth.userCheck, otp.otpValidate, otp.otpValidUser]);

	useEffect(() => {
		if (AuthStorage.loggedIn) {
			saveFirebaseMessagingToken();
			/*Router.push(
				(router.query.return_url && decodeURIComponent(router.query.return_url as string)) ?? '/',
			);*/
			if (props.onFinishLogin) {
				props.onFinishLogin();
			}
		}

		if (auth.userToken && auth.userToken?.status !== 200) {
			if (props.type === g.AUTH.LOGIN.label) setBtnDisabled(true);
			if (!!auth.userToken?.data?.record?.reactive_token) {
				setDeletedAccount(auth.userToken);
				setShowReactivateModal(true);
			}

			setHasErrored(true);
			form.setFields([
				{
					name: 'pin',
					errors: [
						auth.userToken?.meta?.message.replace(
							'{{time_release_in_second}}',
							timeToText(auth.userToken?.data?.record?.time_release_in_second),
						),
					],
				},
			]);
		}
	}, [auth.userToken]);

	const handlePin = (e: string) => {
		let pinFormated = e.replace(/\D/g, '');
		setPin(pinFormated);
		props.setAuthBody?.({ ...props.authBody, pin: pinFormated });
	};

	const handlePinCheck = (_rules: any, val: string, callback: any) => {
		try {
			if (val?.length === 6) {
				setHasErrored(false);
				return Promise.resolve();
			} else {
				setHasErrored(true);
				return Promise.reject(
					new Error(
						props.type === g.AUTH.LOGIN.label
							? 'PIN yang anda masukkan salah. Silakan coba kembali.'
							: 'PIN hanya boleh terdiri dari angka',
					),
				);
			}
		} catch (err) {
			callback(err);
		}
	};

	const handleSubmit = async () => {
		if (props.type === g.AUTH.LOGIN.label) {
			dispatch(
				await actionUserLogin({
					type: 'pin',
					phone: props.authBody?.phoneNumber,
					password: props.authBody?.pin,
				}),
			);
		} else {
			props.setAuthStep?.(
				props.type === g.AUTH.FORGOT.label ? g.AUTH.FORGOT.pinConfirm : g.AUTH.REGISTER.pinConfirm,
			);
		}
	};

	const handleBack = async () => {
		if (props.type === g.AUTH.FORGOT.label) {
			props.setAuthStep?.(g.AUTH.FORGOT.phone);
		} else {
			dispatch(await actionUserCheckModify(null));
			props.setAuthStep?.(g.AUTH.REGISTER.user);
		}
	};

	return (
		<>
			<div className={`${classes.container} d-flex align-items-center`}>
				<div className={classes.content}>
					<div className={classes['otp-button-back']} onClick={handleBack}>
						<Image alt="Icon Arrow Left" height={32} width={32} src="/icons/arrow-left.svg" />
					</div>
					<div className={classes['pin-top']}>
						<Title level={3}>
							{props.type === g.AUTH.LOGIN.label ? 'Masukkan PIN kamu' : 'Buat PIN Kamu'}
						</Title>
						<Text className={classes.description}>PIN digunakan untuk masuk ke akun kamu</Text>
					</div>
					<div className={classes['pin-main']}>
						<Form
							autoComplete="off"
							form={form}
							layout="vertical"
							name="auth-form"
							onFinish={handleSubmit}
						>
							<InputPin
								hasErrored={hasErrored}
								name="pin"
								maxWidth
								numInputs={6}
								onChange={handlePin}
								rules={[{ validator: handlePinCheck }]}
								shouldAutoFocus={true}
								validateTrigger="onBlur"
								value={pin}
								isTransform={true}
							/>
							<Form.Item>
								<ButtonOK
									block
									disabled={btnDisabled}
									htmlType="submit"
									loading={loader.sending || props.additionalLoading}
									text={props.type === g.AUTH.LOGIN.label ? 'Masuk' : 'Lanjut'}
								/>
							</Form.Item>
						</Form>
					</div>
					{props.type === g.AUTH.LOGIN.label && (
						<div className={classes['pin-bottom']}>
							<Text className={classes['reset-pin']}>
								Lupa PIN kamu?{' '}
								<span onClick={() => Router.push('/signin/forgotpin')}>Atur Ulang</span>
							</Text>
						</div>
					)}
				</div>
			</div>
			<AuthReactivateModal
				phone={props.authBody?.phoneNumber}
				account={deletedAccount}
				setAuthStep={props.setAuthStep}
				show={showReactivateModal}
				setShow={setShowReactivateModal}
			/>
		</>
	);
};

export default AuthPin;
