import { useMemo } from 'react';

import announceStorage from 'src/utils/announce-storage';

const useAnnounceState = (): [Record<string, boolean>, (k: string, v: boolean) => void] => {
	return useMemo(
		() => [
			announceStorage.isShown,
			(key: string, value: boolean) => {
				announceStorage.isShown = {
					[key]: value,
				};
			},
		],
		[],
	);
};

export default useAnnounceState;
